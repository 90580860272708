import { environment } from 'environments/environment';

export const auth0Api = {
  changePassword: async (email: string) => {
    const response = await fetch(`https://${environment.domain}/dbconnections/change_password`, {
      method: 'POST',
      headers: { 'content-type': 'application/json' },
      body: JSON.stringify({
        connection: 'Username-Password-Authentication',
        client_id: environment.clientId,
        email,
      }),
    });

    if (!response.ok) {
      console.error('Error changing password', response.status, response.statusText);
      return false;
    }

    return true;
  },
};
