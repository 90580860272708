import { Button, Flex, useColorModeValue, Text, useToast } from '@chakra-ui/react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { MdDeleteOutline } from 'react-icons/md';
import {
  EntityType,
  useCreateUniversalDocumentMutation,
  useDeleteUniversalDocumentMutation,
  useUploadUniversalDocumentMutation,
} from '../../../../api';
import { SigningSingleResponse } from '../../../../api/signing';
import { FileCard } from '../../../../components/card/FileCard';
import { DocumentsViewer } from '../../../../components/documentsViewer/DocumentsViewer';
import { DocumentOutFilledIcon } from '../../../../components/icons/Icons';
import { VCDropzone, VCMimeTypes } from '../../../../libs/ui/atoms/src/lib/dropzone/Dropzone';

export interface SigningDetailsDocumentsWidgetProps {
  signing: SigningSingleResponse;
  onDocumentsChanged: () => void;
}

export const SigningDetailsDocumentsWidget = ({ signing, onDocumentsChanged }: SigningDetailsDocumentsWidgetProps) => {
  const { t } = useTranslation();
  const toast = useToast();

  const widgetBackgroundColor = useColorModeValue('white', 'navy.800');
  const buttonColor = useColorModeValue('brand.500', 'brand.500');
  const iconColor = useColorModeValue('navy.750', 'white');

  const [createDocument] = useCreateUniversalDocumentMutation();
  const [uploadDocument] = useUploadUniversalDocumentMutation();
  const [deleteDocument] = useDeleteUniversalDocumentMutation();

  const handleDrop = useCallback(
    async (droppedFiles: File[]) => {
      try {
        await Promise.all(
          droppedFiles.map(async file => {
            const document = await createDocument({
              name: file.name,
              entityType: EntityType.SIGNING,
              entityId: signing.id,
            }).unwrap();

            const formData = new FormData();
            formData.set('inputFile', file);

            return uploadDocument({ id: document.id, formData }).unwrap();
          }),
        );
        onDocumentsChanged();
      } catch (error) {
        toast({
          title: t('error'),
          description: t('error'),
          status: 'error',
          duration: 1500,
        });
      }
    },
    [onDocumentsChanged, createDocument, uploadDocument, toast, t, signing],
  );

  const handleDelete = useCallback(
    async (i: number) => {
      const documentToDelete = signing.documents[i];
      try {
        await deleteDocument({ id: documentToDelete.id });
      } catch (error) {
        toast({
          title: t('error'),
          description: t('error'),
          status: 'error',
          duration: 1500,
        });
      }
      onDocumentsChanged();
    },
    [deleteDocument, onDocumentsChanged, signing],
  );

  return (
    <>
      <Flex p="0px 24px 0px 24px" direction="column" bg={widgetBackgroundColor}>
        <VCDropzone
          title={t('pages.signing.uploadFiles')}
          onDrop={handleDrop}
          allowedFileTypes={[VCMimeTypes.PDF]}
          dropzoneFlexProps={{ p: '16px 32px', borderRadius: '16px' }}
        />
      </Flex>
      <DocumentsViewer
        gridProps={{
          columns: 4,
          padding: '24px',
        }}
        documentCards={signing.documents.map((doc, i) => (
          <FileCard
            key={i}
            title={doc.name}
            icon={<DocumentOutFilledIcon width="24px" height="24px" color={iconColor} />}
            footer={
              <Flex align="center" justify="space-between" gap="8px">
                <Text
                  fontSize={{ base: 'xs', md: 'sm' }}
                  fontStyle="normal"
                  fontWeight="500"
                  lineHeight="normal"
                  color={iconColor}
                >
                  {t('documents')}
                </Text>
                <Button
                  bg={buttonColor}
                  borderRadius="10px"
                  color={'white'}
                  width="70px"
                  height="28px"
                  fontWeight="700"
                  fontSize="10px"
                  lineHeight="15px"
                  fontFamily="DM Sans"
                  leftIcon={<MdDeleteOutline size="16px" />}
                  onClick={() => handleDelete(i)}
                >
                  {t('delete')}
                </Button>
              </Flex>
            }
            cardProps={{ borderRadius: '15px' }}
          />
        ))}
      />
    </>
  );
};
