import { Box, Button, Flex, Icon, Text, useBreakpointValue, useColorModeValue } from '@chakra-ui/react';

import { Account, RecycleBing } from 'libs/ui/atoms/src';
import { UserSimple, UserStatus } from 'api';
import { useTranslation } from 'react-i18next';
import { ReloadIcon } from '../../../../../../components/icons/Icons';
import { UserTooltip } from './UserTooltip';
import { UserRolePills } from '../../../../../../components/user/roles-management/RolePills';

export interface UserItemProps {
  user: UserSimple;
  isDisabled?: boolean;
  onClick?: () => void;

  onResendInvite: () => void;
  onDelete: () => void;
}

const isInvited = (user: UserSimple) => user.status === UserStatus.INVITED;

export function UserItem(props: UserItemProps) {
  const { user, onClick, onResendInvite, isDisabled, onDelete } = props;

  const textColor = useColorModeValue('gray.600', 'white');
  const primaryBgHover = useColorModeValue('blue.50', 'navy.750');
  const subtitleMainTextColor = useColorModeValue('gray.600', 'gray.200');
  const relationIconColor = useColorModeValue('brand.800', 'white');
  const resendInviteButtonBg = useColorModeValue('gray.360', 'whiteAlpha.200');

  const isShowRolePills = useBreakpointValue({ sm: false, md: true });

  const { t } = useTranslation();

  return (
    <Flex
      alignItems={'center'}
      gap={'16px'}
      flex={1}
      borderBottom={'1px'}
      borderColor={'gray.400'}
      p={'12px 0 12px 24px'}
      cursor={'pointer'}
      _last={{ border: 'none' }}
      _hover={{ bg: primaryBgHover }}
      onClick={onClick}
      overflow={'hidden'}
    >
      <Account color={relationIconColor} h={'20px'} w={'20px'} />

      <Flex flexDirection={{ sm: 'column', md: 'row' }} gap="8px" justifyContent={'space-between'} flex="1">
        <Flex
          minW={'150px'}
          minH={'38px'}
          flex={1}
          justifyContent="center"
          alignItems={'baseline'}
          direction={'column'}
          gap={'4px'}
          overflow="hidden"
        >
          <Flex alignItems={'center'} gap={'8px'}>
            <Text
              overflow={'hidden'}
              textOverflow={'ellipsis'}
              flexWrap={'nowrap'}
              fontSize={'md'}
              lineHeight={'20px'}
              fontWeight={'bold'}
              textAlign={'start'}
              color={textColor}
            >
              {user?.name}
            </Text>

            {isInvited(user) && (
              <Box
                borderRadius={'8px'}
                p="3px 10px"
                bg={'orange.400'}
                fontSize="10px"
                lineHeight={'10px'}
                fontWeight="bold"
                color={'white'}
              >
                {t('invited')}
              </Box>
            )}
          </Flex>

          <Text
            title={user?.email}
            fontSize={'14px'}
            lineHeight={'15px'}
            color={subtitleMainTextColor}
            fontWeight={'500'}
            overflow={'hidden'}
            textOverflow={'ellipsis'}
            className="no-text-wrap"
          >
            {user?.email}
          </Text>
        </Flex>

        <Flex gap="8px" alignItems={'center'}>
          {isShowRolePills && <UserRolePills roles={user.roles} />}

          {isInvited(user) && (
            <Button
              size={'xs'}
              leftIcon={<Icon as={ReloadIcon} w="12px" h="12px" color={textColor} />}
              borderRadius={'16px'}
              color={textColor}
              p={'0 12px'}
              border={`1px solid`}
              bg={resendInviteButtonBg}
              borderColor={resendInviteButtonBg}
              isDisabled={isDisabled}
              _hover={{ borderColor: 'gray.700' }}
              onClick={e => {
                e.stopPropagation();
                onResendInvite();
              }}
            >
              {t('resendInvite')}
            </Button>
          )}

          <UserTooltip user={user} />

          <Button
            isDisabled={isDisabled}
            size="xs"
            variant="ghost"
            color={'red.500'}
            p="0"
            borderColor={'red.500'}
            onClick={e => {
              e.stopPropagation();
              onDelete();
            }}
          >
            <RecycleBing boxSize={5} />
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
}
