// Chakra imports
import { Flex, useColorModeValue } from '@chakra-ui/react';

// Custom components
import { VeriCasaLogo, VeriCasaLogoSmall } from 'components/icons/Icons';
import { HSeparator } from 'components/separator/Separator';
import { Link } from 'react-router-dom';

export function SidebarBrand({ isCollapsed }: { isCollapsed: boolean }) {
  //   Chakra color mode
  const logoColor = useColorModeValue('navy.700', 'white');

  return (
    <Flex alignItems="start" flexDirection="column">
      <Link to={'home'}>
        {isCollapsed ? (
          <VeriCasaLogoSmall h="41px" w="41px" ml="36px" mt="24px" mb="10px" color={logoColor} />
        ) : (
          <VeriCasaLogo h="41px" w="170px" ml="36px" mt="20px" mb="16px" color={logoColor} />
        )}
      </Link>
      <HSeparator mb="0px" />
    </Flex>
  );
}

export default SidebarBrand;
