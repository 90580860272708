import {
  Button,
  Popover,
  PopoverContent,
  PopoverTrigger as OrigPopoverTrigger,
  Text,
  useColorModeValue,
  Portal as ChakraPortal,
  useModalContext,
} from '@chakra-ui/react';
import { ReactElement, ReactNode } from 'react';

export interface VcTooltipProps {
  text?: string;
  iconChildren?: ReactElement;
  contentChildren?: ReactNode;
}

export const PopoverTrigger: React.FC<{ children: React.ReactNode }> = OrigPopoverTrigger;

export function Portal({ children }: { children: ReactNode }) {
  try {
    const modalContext = useModalContext();
    return <ChakraPortal containerRef={modalContext?.dialogRef}>{children}</ChakraPortal>;
  } catch {
    return <ChakraPortal>{children}</ChakraPortal>;
  }
}

export const VcTooltip = ({ text, iconChildren, contentChildren }: VcTooltipProps) => {
  const textColor = useColorModeValue('gray.700', 'white');
  const buttonBackground = useColorModeValue('gray.360', 'whiteAlpha.200');
  const tooltipBg = useColorModeValue('white', 'navy.800');

  return (
    <Popover trigger="hover" offset={[-156, -26]}>
      <PopoverTrigger>
        <Button
          title={text}
          width={'24px'}
          size="xs"
          color={textColor}
          bg={buttonBackground}
          border={`1px solid`}
          borderColor={buttonBackground}
          _hover={{ borderColor: textColor }}
          onClick={e => e.stopPropagation()}
        >
          {iconChildren}
          {text && (
            <Text textOverflow={'ellipsis'} overflow={'hidden'} maxW={'100%'}>
              {text}
            </Text>
          )}
        </Button>
      </PopoverTrigger>
      <Portal>
        <PopoverContent
          rootProps={{ style: { right: 0, transform: 'scale(0)' } }}
          zIndex={'popover'}
          bg={tooltipBg}
          _focus={{ boxShadow: '0px 18px 40px 0px rgba(112, 144, 176, 0.12)' }}
          border={'none'}
          borderRadius="16px"
          width={'420px'}
          boxShadow="0px 18px 40px 0px rgba(112, 144, 176, 0.12)"
          onClick={e => e.stopPropagation()}
        >
          {contentChildren}
        </PopoverContent>
      </Portal>
    </Popover>
  );
};
