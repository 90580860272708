import DocViewer from '@cyntler/react-doc-viewer';
import { useColorMode } from '@chakra-ui/system';

interface ViewerDocProps {
  url: string;
}
export default function ViewerDoc({ url }: ViewerDocProps) {
  const { colorMode } = useColorMode();

  const darkTheme = {
    primary: '#2B3674',
    secondary: '#000',
    tertiary: '#5296d899',
    textPrimary: '#ffffff',
    textSecondary: '#5296d8',
    textTertiary: '#00000099',
  };

  return (
    <DocViewer
      style={{ borderRadius: '8px' }}
      documents={[{ uri: url, fileType: 'doc' }]}
      theme={colorMode === 'dark' ? darkTheme : {}}
    />
  );
}
