import { JobStatus } from '../../../../api';
import { IconType } from 'react-icons';
import { MdArchive, MdCancel, MdCheckCircle, MdOutlineError } from 'react-icons/md';
import { ReloadIcon } from '../../../../components/icons/Icons';

export const iconColorByJobStatus: Record<JobStatus, string | undefined> = {
  [JobStatus.Draft]: 'gray.500',
  [JobStatus.Submitted]: undefined,
  [JobStatus['Error occurred']]: 'red.500',
  [JobStatus['Problems detected']]: 'red.500',
  [JobStatus['Job is completed']]: 'green.400',
  [JobStatus['job is archived']]: 'yellow.400',
};

export const iconByJobStatus: Record<JobStatus, IconType> = {
  [JobStatus.Draft]: MdCancel,
  [JobStatus.Submitted]: ReloadIcon as any,
  [JobStatus['Error occurred']]: MdOutlineError,
  [JobStatus['Problems detected']]: MdOutlineError,
  [JobStatus['Job is completed']]: MdCheckCircle,
  [JobStatus['job is archived']]: MdArchive,
};

export const jobStatusTranslateByJobStatus: Record<JobStatus, string> = {
  [JobStatus.Draft]: 'jobStatuses.draft',
  [JobStatus.Submitted]: 'jobStatuses.inProgress',
  [JobStatus['Error occurred']]: 'jobStatuses.problemDetected',
  [JobStatus['Problems detected']]: 'jobStatuses.problemsDetected',
  [JobStatus['Job is completed']]: 'jobStatuses.jobIsCompleted',
  [JobStatus['job is archived']]: 'jobStatuses.jobIsArchived',
};
