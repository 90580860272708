import React, { useState } from 'react';
import { Input, useColorModeValue } from '@chakra-ui/react';
import { useToast } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

export interface DecimalOrIntegerInputProps {
  type?: string;
  color?: string;
  label: string;
  placeholder?: string;
  value?: string;
  decimalPlaces?: number;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const DecimalOrIntegerInput = ({
  type,
  color,
  label,
  placeholder,
  value,
  decimalPlaces = 2,
  onChange,
  onBlur,
}: DecimalOrIntegerInputProps) => {
  const [fieldValue, setFieldValue] = useState(value);
  const [isInvalid, setIsInvalid] = useState(false);
  const { t } = useTranslation();
  const toast = useToast();
  const borderColor = useColorModeValue('red.500', 'red.500');

  /* Validation logic is based on upstream Casa Pronta so our users get the same experience as the upstream
  form they are used to, for better or worse, so we are at least 'consistent' with it */
  const validateInput = (value: string) => {
    let re;
    let valueNotDecimal;

    if ((type === 'decimal' || type === 'integer') && value.length !== 0) {
      re = /[0-9.,]*/g;
      valueNotDecimal = value.replace(re, '');

      if (valueNotDecimal.length !== 0) {
        toast({
          title: t('decimalInputFieldOnlyNumbers', { label }),
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
        return false;
      }

      if (decimalPlaces < 0) throw new Error("Number of decimal places can't be negative"); // if there is no decimalPlaces or they are negative

      valueNotDecimal = value.replace(new RegExp(`(-)?\\d+(,\\d{1,${decimalPlaces}})?`), ''); // get all the text that is not in the regex format

      if (
        valueNotDecimal.length !== 0 || // if there is a comma, checks if there is a decimal part
        value.split(',').length > 2 || // checks if there is more than one comma
        value.split(',')[1]?.length > decimalPlaces || // checks if the decimal part is bigger than the allowed decimal places
        value.split(',')[1]?.length === 0 // checks if there is a comma but no decimal part
      ) {
        toast({
          title: t('decimalInputFieldInvalidValue', { label, decimalPlaces }),
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
        return false;
      }
    }
    return true;
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setFieldValue(value);
    onChange(e);
  };

  const handleBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const isValid = validateInput(value);

    setIsInvalid(!isValid); // Update the invalid state based on validation
    if (onBlur && (isValid || value.length === 0 || value.split(',').length === 2)) {
      onBlur(e);
    }
  };

  return (
    <Input
      height={'50px'}
      color={color}
      borderColor={isInvalid ? borderColor : undefined}
      placeholder={placeholder}
      type="text"
      value={fieldValue}
      onChange={handleChange}
      onBlur={handleBlur}
      backgroundColor={isInvalid ? 'red.100' : undefined} // Change background color if invalid
    />
  );
};

export default DecimalOrIntegerInput;
