import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalOverlay,
  Text,
  Button,
  useColorModeValue,
  Flex,
  ModalFooter,
} from '@chakra-ui/react';
import { Information } from 'libs/ui/atoms/src';
import { useTranslation } from 'react-i18next';
import { RelatedPartyEntityType } from '../../../../../api';

interface ResetToDefaultConfirmModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  entityType: RelatedPartyEntityType;
}

export const ResetToDefaultConfirmModal = ({
  isOpen,
  onClose,
  onConfirm,
  entityType,
}: ResetToDefaultConfirmModalProps) => {
  const { t } = useTranslation();

  const buttonTextColor = useColorModeValue('white', 'white');
  const textColor = useColorModeValue('brand.800', 'white');

  const cancelBgColor = useColorModeValue('blue.50', 'gray.600');
  const cancelBgHoverColor = useColorModeValue('blue.100', 'gray.500');

  return (
    <Modal size={'md'} onClose={onClose} isOpen={isOpen} isCentered>
      <ModalOverlay />
      <ModalContent color={textColor} borderRadius="16" padding={'2'} paddingBottom="6">
        <ModalHeader>{t('relatedParties.resetToDefault')}</ModalHeader>
        <ModalCloseButton top={'6'} right="4" />
        <ModalBody>
          <Flex flexDirection={'column'} gap={'16px'} alignItems={'center'}>
            <Information w="64px" h="64px" color={textColor} />

            <Text fontSize="20px" fontWeight={'bold'} color={textColor} textAlign="center" pb={'16px'}>
              {entityType === RelatedPartyEntityType.BUYER
                ? t('relatedParties.thisWillResetBuyersIntoDefault')
                : t('relatedParties.thisWillResetSellersIntoDefault')}
            </Text>
          </Flex>
        </ModalBody>

        <ModalFooter justifyContent="center" gap="24px" p="0">
          <Button
            onClick={onClose}
            maxWidth="250px"
            h="46px"
            fontSize="14px"
            borderRadius="100px"
            _hover={{ bg: cancelBgHoverColor }}
            bg={cancelBgColor}
            p="0 24px"
          >
            {t('cancel')}
          </Button>

          <Button
            colorScheme="brand"
            onClick={onConfirm}
            maxWidth="250px"
            h="46px"
            fontSize="14px"
            borderRadius="100px"
            color={buttonTextColor}
            p="0 24px"
          >
            {t('ok')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
