import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Flex,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { UserRole } from '../../../api';
import { UserRoleListItem } from './RoleListItem';
import { userRoleTKeyMap } from './roles-utils';

export interface UserRolesWidgetProps {
  selectedRoles: UserRole[];
  onToggle: (role: UserRole) => void;

  isReadonly?: boolean;

  /**
   * Shows roles on accordion button
   */
  isShowRolesInButton?: boolean;
}

const roles = Object.values(UserRole).filter(i => i !== UserRole.MASTER_USER);

export const UserRolesWidget = (props: UserRolesWidgetProps) => {
  const { isReadonly, selectedRoles, onToggle, isShowRolesInButton } = props;

  const { t } = useTranslation();

  const textColor = useColorModeValue('_brand.800', 'white');

  return (
    <Accordion allowMultiple allowToggle border="none">
      <AccordionItem border="1px solid #CBD5E0" borderRadius={'10px'} borderColor={'_gray.300'}>
        <AccordionButton
          p="13px 18px"
          _focus={{ boxShadow: 'none' }}
          _hover={{ bg: undefined }}
          pr="24px"
          maxW="100%"
          overflow={'hidden'}
        >
          <Text color={textColor} fontSize="14px" flex={1} textAlign="start">
            {isShowRolesInButton && selectedRoles?.length
              ? t('roles') +
                ' (' +
                [...selectedRoles]
                  .sort()
                  .map(i => t(userRoleTKeyMap[i]))
                  .join(', ') +
                ')'
              : t('roles')}
          </Text>

          <AccordionIcon />
        </AccordionButton>

        <AccordionPanel p="0 16px 13px 16px">
          <Flex gap={'12px'} direction={'column'}>
            {roles.map((role, idx) => (
              <UserRoleListItem
                key={idx}
                isReadonly={isReadonly}
                title={t(userRoleTKeyMap[role])}
                isChecked={selectedRoles.includes(role)}
                onCheck={() => onToggle(role)}
              />
            ))}
          </Flex>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};
