import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react';

import { UserSetRolesDto, UserSimple, UserUpdateDto } from 'api';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UserMutateForm } from '../invite/MutateUserForm';
import { UserFormState, useUserForm } from '../invite/use-user-form';
import { UserItem } from './UserItem';

export interface UserItemProps {
  user: UserSimple;
  isDisabled?: boolean;

  onUpdate: (data: UserUpdateDto) => void;
  onSetRoles: (data: UserSetRolesDto) => void;
  onResendInvite: () => void;
  onDelete: () => void;
}

export function UserAccordionItem(props: UserItemProps) {
  const { user, onResendInvite, onDelete, onUpdate, onSetRoles, isDisabled } = props;

  const textColor = useColorModeValue('gray.600', 'white');
  const secondaryBg = useColorModeValue('gray.50', 'navy.700');
  const primaryBgHover = useColorModeValue('blue.50', 'navy.750');

  const { t } = useTranslation();
  const toast = useToast();

  const { form, dispatch, validate } = useUserForm();

  useEffect(() => {
    dispatch({
      type: 'setInitialState',
      value: {
        name: user.name,
        email: user.email,
        nif: user.nifNips,
        address: user.address,
        postalCode: user.postalCode,
        location: user.location,
        roles: user.roles,
      },
    });
  }, [dispatch, user]);

  const [isExpanded, setIsExpanded] = useState(false);
  const [prevForm, setPrevForm] = useState<UserFormState>(form);

  const onBlur = useCallback(() => {
    setPrevForm(form);
    const validationMessages = validate();
    if (validationMessages?.length > 0) {
      toast({
        title: t('validationError'),
        description: validationMessages.map(i => t(i)).join(', \n'),
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    const changedFields = Object.keys(form).filter(
      key => form[key as keyof UserFormState] !== prevForm[key as keyof UserFormState],
    );
    const changedObj = changedFields.reduce((acc, key) => {
      acc[key as keyof UserFormState] = form[key as keyof UserFormState] as any;
      return acc;
    }, {} as UserFormState);
    const updateKeys = ['name', 'nif', 'address', 'postalCode', 'location'];
    if (changedFields.some(key => updateKeys.includes(key))) {
      onUpdate({
        name: changedObj.name,
        nifNips: changedObj.nif,
        address: changedObj.address,
        postalCode: changedObj.postalCode,
        location: changedObj.location,
        id: user.id,
      });
    }
  }, [form, onUpdate, prevForm, t, toast, user.id, validate]);

  return (
    <Accordion
      index={isExpanded ? 0 : -1}
      allowMultiple
      allowToggle
      borderLeft="none"
      borderRight="none"
      borderColor={'gray.400'}
    >
      <AccordionItem color={textColor} border={'none'}>
        <AccordionButton
          p="0"
          _focus={{ boxShadow: 'none' }}
          _hover={{ bg: primaryBgHover }}
          bg={isExpanded ? primaryBgHover : undefined}
          borderBottom={isExpanded ? undefined : '1px solid'}
          borderColor={isExpanded ? undefined : 'gray.400'}
          onClick={e => e.preventDefault()}
          maxW="100%"
          overflow={'hidden'}
        >
          <UserItem
            user={user}
            onDelete={onDelete}
            onResendInvite={onResendInvite}
            onClick={() => setIsExpanded(!isExpanded)}
          />
          <AccordionIcon w={'43px'} onClick={() => setIsExpanded(!isExpanded)} />
        </AccordionButton>

        <AccordionPanel p="16px 24px" bg={secondaryBg}>
          <UserMutateForm
            isEmailDisabled
            isReadonly={isDisabled}
            form={form}
            dispatch={dispatch}
            isSowRolesInAccordionButton
            onBlur={onBlur}
            onRolesChange={roles => onSetRoles({ userId: user.id, roles })}
          />
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
}
