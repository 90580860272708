import { Box } from '@chakra-ui/react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useLazyGetSigningQuery } from '../../../api/signing';
import { useTypedSelector } from '../../../store';
import { SigningDetailsWidget } from './widgets/SigningDetailsWidget';

export const SigningDetailPage = () => {
  const { t } = useTranslation();

  const { signingId } = useParams<{ signingId: string }>();
  const [fetchSigning, { isLoading }] = useLazyGetSigningQuery();

  const signing = useTypedSelector(state => state.signing.selectedSigning ?? undefined);

  useEffect(() => {
    fetchSigning({ signingId: signingId ?? '' });
  }, [fetchSigning, signingId]);

  if (isLoading) {
    return <Box>...{t('loading')}</Box>;
  }
  if (!signing) {
    return <Box>...{t('pages.signing.signingFetchError')}</Box>;
  }

  return (
    <SigningDetailsWidget
      signing={signing}
      onDocumentsChanged={() => {
        fetchSigning({ signingId: signing.id });
      }}
    ></SigningDetailsWidget>
  );
};
