import { JobPrivateApiUtil, useLazyMePrivateQuery } from '../api';
import { environment } from '../environments/environment';
import { useAppDispatch, useTypedSelector } from '../store';
import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useState } from 'react';
import { resetAuth, setUserAuthAndAccessToken } from '../store/auth';
import { useDidUpdateEffect } from 'hooks/useDidUpdateEffect';
import { sec } from '../api/security';

export interface Context {
  userId: string;
  organizationId: string | null;
}

export const createContextToken = (context: Context) => btoa(JSON.stringify(context));

export const useAuthInitializer = () => {
  const { isLoading, user, isAuthenticated, loginWithRedirect, logout, getAccessTokenSilently } = useAuth0();
  const [isLoadingAccessToken, setIsLoadingAccessToken] = useState(true);

  sec.setAccessTokenSilently(getAccessTokenSilently);

  const accessToken = useTypedSelector(state => state.auth.accessToken);
  const contextToken = useTypedSelector(state => state.auth.contextToken);
  const organizationId = useTypedSelector(state => state.auth.organizationId);

  const dispatch = useAppDispatch();
  const [getMe, me] = useLazyMePrivateQuery({
    pollingInterval: 60 * 1000,
    refetchOnReconnect: true,
  });

  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      loginWithRedirect();
    }
  }, [isLoading, isAuthenticated, loginWithRedirect]);

  useEffect(() => {
    if (contextToken && accessToken) {
      getMe({}, false);
    }
  }, [organizationId, accessToken, contextToken, getMe]);

  // Reset API state when organizationId is changed
  useDidUpdateEffect(() => {
    dispatch(JobPrivateApiUtil.resetApiState());
  }, [organizationId]);

  useEffect(() => {
    let unsubscribe: () => void | undefined;

    const initialize = async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: environment.audience,
            scope: environment.scope,
          },
        });

        if (!user) {
          throw new Error('Not found user from auth!');
        }

        if (!user.sub) {
          throw new Error('Not found user id from auth!');
        }

        const contextToken = createContextToken({
          userId: user.sub,
          organizationId,
        });

        dispatch(setUserAuthAndAccessToken({ accessToken, contextToken, userAuth: user }));
      } catch (e) {
        console.error('Handle error when initializing accessToken', e);
        dispatch(resetAuth());
        logout();
      } finally {
        setIsLoadingAccessToken(false);
      }

      const request = getMe({}, false);

      unsubscribe = () => {
        request.unsubscribe();
      };
    };

    if (user?.sub) {
      initialize();
    }

    return () => unsubscribe?.();
  }, [getAccessTokenSilently, dispatch, getMe, user?.sub]);

  return {
    isLoading: isLoading || isLoadingAccessToken || me.isLoading,
    isAuthenticated: contextToken && accessToken && isAuthenticated,
  };
};
