import React from 'react';
import { Box } from '@chakra-ui/react';
import { LocalizationMap, Viewer } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import { toolbarPlugin } from '@react-pdf-viewer/toolbar';
import type { ToolbarSlot, TransformToolbarSlot } from '@react-pdf-viewer/toolbar';
import { useColorMode, useColorModeValue } from '@chakra-ui/system';

import en_US from '@react-pdf-viewer/locales/lib/en_US.json';

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

import './ViewerPdf.css';

interface Props {
  fileUrl: string;
}

const ViewerPDF: React.FC<Props> = ({ fileUrl }) => {
  const { colorMode } = useColorMode();

  const bg = useColorModeValue('white', 'navy.800');

  const toolbarPluginInstance = toolbarPlugin();
  const { renderDefaultToolbar } = toolbarPluginInstance;

  const transform: TransformToolbarSlot = (slot: ToolbarSlot) => ({
    ...slot,
    // These slots will be empty
    Download: () => <></>,
    Open: () => <></>,
    EnterFullScreen: () => <></>,
    SwitchTheme: () => <></>,
    GoToPreviousPage: () => <></>,
    GoToNextPage: () => <></>,
    Print: () => <></>,
  });

  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    sidebarTabs: () => [],
    renderToolbar: Toolbar => <Toolbar>{renderDefaultToolbar(transform)}</Toolbar>,
  });

  return (
    <Box h="inherit" p="16px" borderRadius="16px" bg={bg}>
      <Viewer
        theme={colorMode}
        fileUrl={fileUrl}
        localization={en_US as unknown as LocalizationMap}
        plugins={[defaultLayoutPluginInstance]}
      />
    </Box>
  );
};

export default ViewerPDF;
