import { useTranslation } from 'react-i18next';
import { Flex, Grid, Icon, useColorModeValue } from '@chakra-ui/react';

import { MdOutlineCheckBoxOutlineBlank } from 'react-icons/md';
import { PillButton } from 'libs/ui/atoms/src/lib/button/PillButton';
import { Close, InvertCheckboxes, RecycleBing } from 'libs/ui/atoms/src';

export interface CheckboxActionsWidgetProps {
  isActive: boolean;
  onDeleteUnselected: () => void;
  onDelete: () => void;
  onInvertCheckboxes: () => void;
  onReset: () => void;
}

export function CheckboxActionsWidget({
  isActive,
  onDeleteUnselected,
  onDelete,
  onInvertCheckboxes,
  onReset,
}: CheckboxActionsWidgetProps) {
  const { t } = useTranslation();

  const textColor = useColorModeValue('gray.700', 'white');

  return (
    <Grid
      gridTemplateRows={isActive ? '1fr' : '0fr'}
      transition="all .2s"
      p="12px 32px 12px 32px"
      pb={isActive ? '12px' : 0}
    >
      <Flex alignItems={'center'} gap="16px" overflow={'hidden'}>
        <PillButton
          leftIconChildren={<RecycleBing color={textColor} w="18px" h="18px" />}
          text={t('delete')}
          onClick={onDelete}
        />

        <PillButton
          leftIconChildren={<InvertCheckboxes color={textColor} w="15px" h="15px" />}
          text={t('invertCheckboxes')}
          onClick={onInvertCheckboxes}
        />

        <PillButton
          leftIconChildren={<Icon as={MdOutlineCheckBoxOutlineBlank} color={textColor} w="18px" h="18px" />}
          text={t('deleteUnselected')}
          onClick={onDeleteUnselected}
        />

        <PillButton
          leftIconChildren={<Close color={textColor} w="15px" h="15px" />}
          text={t('reset')}
          onClick={onReset}
        />
      </Flex>
    </Grid>
  );
}
