import { JobWithDocumentsAndResult } from 'api';
import { useMemo } from 'react';
import { JobProblemItemProps } from './JobProblemItem';
import { useTypedSelector } from 'store';

export enum ResolvableErrorId {
  // Certidao permanente expired
  'R98P01' = 'R98P01',
}

export interface UseJobProblemsProps {
  jobId: string;
}

export function useJobProblems({ jobId }: UseJobProblemsProps) {
  const jobProblems = useTypedSelector(
    state => (state.job.jobs.find(i => i.id === jobId) as JobWithDocumentsAndResult)?.problems ?? [],
  );

  const problems = useMemo<JobProblemItemProps[]>(() => {
    return jobProblems.map(problem => ({
      data: problem,
    }));
  }, [jobProblems]);

  return { problems };
}
