import { Flex, useColorModeValue } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { SigningSingleResponse, SigningType } from '../../../../api/signing';
import Card from '../../../../components/card/Card';
import { VCSelectButton } from '../../../../libs/ui/atoms/src/lib/button/SelectButton';
import { SigningDetailsDocumentsWidget } from './SigningDocumentsWidget';
import { useSigningType } from './useSigningType';

const signingTypeLabelTranslate: Record<SigningType, string> = {
  [SigningType.SMS]: 'pages.signing.types.sms',
  [SigningType.MOBILE_KEY_OR_CITIZEN_CARD]: 'pages.signing.types.mobileKeyOrCitizenCard',
};

export interface SigningDetailsWidgetProps {
  signing: SigningSingleResponse;
  onDocumentsChanged: () => void;
}

export const SigningDetailsWidget = ({ signing, onDocumentsChanged }: SigningDetailsWidgetProps) => {
  const { t } = useTranslation();

  const cardBackgroundColor = useColorModeValue('_gray.200', 'navy.900');
  const widgetBackgroundColor = useColorModeValue('white', 'navy.800');

  const { signingType, setSigningType } = useSigningType(signing);

  return (
    <Flex direction="column" gap="24px" p="24px">
      <Card
        flexDirection="column"
        w="100%"
        p={0}
        bg={cardBackgroundColor}
        overflowX={{ sm: 'scroll', lg: 'hidden' }}
        position="static"
      >
        <Flex direction="column" borderRadius={'20px'} bg={widgetBackgroundColor}>
          <VCSelectButton<SigningType>
            value={signingType}
            items={Object.values(SigningType).map(type => ({
              label: t(signingTypeLabelTranslate[type]),
              value: type,
              boxItemProps: { width: '294px' },
              boxItemTextProps: { height: '34px', textAlign: 'center', lineHeight: '24px' },
            }))}
            onSelect={value => {
              setSigningType(value);
            }}
            selectButtonProps={{
              paddingTop: '24px',
              paddingBottom: '24px',
            }}
          />
          <SigningDetailsDocumentsWidget signing={signing} onDocumentsChanged={onDocumentsChanged} />
        </Flex>
      </Card>
    </Flex>
  );
};
