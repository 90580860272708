import { useTranslation } from 'react-i18next';
import {
  Box,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { Cancel, Magnifier } from 'libs/ui/atoms/src';

import { useState } from 'react';
import { InviteUserButton } from '../invite/InviteUserButton';
import { useOrgUsers } from './use-org-users';
import { UserAccordionItem } from './UserAccordionItem';
import Card from '../../../../../../components/card/Card';
import { DeleteUserConfirmModal } from './DeleteUserConfirmModal';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface UsersWidgetProps {}

export function UsersWidgetWidget({}: UsersWidgetProps) {
  const { t } = useTranslation();

  const cardBackgroundColor = useColorModeValue('white', 'navy.800');
  const textColor = useColorModeValue('brand.800', 'white');

  const [isSearchFocused, setIsSearchFocused] = useState(false);
  const [userIdToDelete, setUserIdToDelete] = useState<string | null>(null);

  const { isLoading, isDisabled, users, onLoad, onUpdate, onDelete, search, onSearch, onResendInvite, onRolesChange } =
    useOrgUsers();

  return (
    <>
      <Card
        flexDirection="column"
        w="100%"
        p={0}
        bg={cardBackgroundColor}
        overflowX={{ sm: 'auto', lg: 'hidden' }}
        position="relative"
        pb={'16px'}
      >
        <Flex
          justifyContent={'space-between'}
          alignItems={'center'}
          gap="16px"
          p={'24px 24px 16px 24px'}
          flexDirection={{ base: 'column', md: 'row' }}
        >
          {isSearchFocused ? (
            <></>
          ) : (
            <Text color={textColor} fontSize={20} fontWeight={'bold'}>
              {t('users')}
            </Text>
          )}

          <Flex flex={1} flexDirection="row-reverse" justifyContent={'flex-start'} alignItems={'center'} gap="12px">
            <InviteUserButton onInvited={onLoad} />

            <Flex flex={isSearchFocused ? 1 : undefined} justifyContent={'flex-end'}>
              <InputGroup borderRadius={'10px'}>
                <InputLeftElement pointerEvents="none">
                  <Magnifier color={textColor} />
                </InputLeftElement>
                <Input
                  w={isSearchFocused || search ? '100%' : '130px'}
                  borderRadius={'10px'}
                  overflow={'hidden'}
                  textOverflow={'ellipsis'}
                  placeholder={t('search')}
                  color={textColor}
                  value={search}
                  onFocus={() => setIsSearchFocused(true)}
                  onBlur={() => setIsSearchFocused(false)}
                  onChange={e => onSearch(e.target.value)}
                />
                {search && (
                  <InputRightElement cursor={'pointer'} onClick={() => onSearch('')}>
                    <Cancel color={textColor} w="12px" h="12px" />
                  </InputRightElement>
                )}
              </InputGroup>
            </Flex>
          </Flex>
        </Flex>

        <Box
          overflow={'auto'}
          style={{
            scrollbarTrackColor: 'white',
          }}
          p="0px 8px 8px 8px"
        >
          <Flex flexDirection={'column'} p={'0 16px'}>
            {users?.map((user, idx) => (
              <UserAccordionItem
                key={idx}
                isDisabled={isDisabled || isLoading}
                user={user}
                onUpdate={onUpdate}
                onSetRoles={onRolesChange}
                onDelete={() => setUserIdToDelete(user.id)}
                onResendInvite={() => onResendInvite(user.id)}
              />
            ))}
          </Flex>
        </Box>
      </Card>

      <DeleteUserConfirmModal
        isOpen={!!userIdToDelete}
        onClose={() => setUserIdToDelete(null)}
        onConfirm={() => {
          if (!userIdToDelete) {
            return;
          }
          onDelete(userIdToDelete);
          setUserIdToDelete(null);
        }}
      />
    </>
  );
}
