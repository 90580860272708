import { useCallback, useEffect } from 'react';

export interface VCTableColumnWidth {
  id: string;
  widthPercents: number;
  maxWidthPx?: number;
}

export const useColumnWidth = (columns: VCTableColumnWidth[]) => {
  useEffect(() => {
    if (columns.reduce((acc, i) => acc + i.widthPercents, 0) !== 100) {
      throw new Error('Sum of column widths must be equal to 100');
    }
  }, [columns]);

  const getWidth = useCallback(
    (id: string) => {
      return columns.find(i => i.id === id)?.widthPercents ?? 0;
    },
    [columns],
  );

  const getMaxWidth = useCallback(
    (id: string) => {
      return columns.find(i => i.id === id)?.maxWidthPx;
    },
    [columns],
  );

  return { getWidth, getMaxWidth };
};
