import { createAsyncThunk, AsyncThunkPayloadCreator, AsyncThunk } from '@reduxjs/toolkit';

type DebounceSettings = {
  /**
   * The maximum time `payloadCreator` is allowed to be delayed before
   * it's invoked.
   * @defaultValue `0`
   */
  maxWait?: number;
  /**
   * Specify invoking on the leading edge of the timeout.
   * @defaultValue `false`
   */
  leading?: boolean;
};

/**
 * A debounced analogue of the `createAsyncThunk` from `@reduxjs/toolkit`
 * @param typePrefix - a string action type value
 * @param payloadCreator - a callback function that should return a promise containing the result
 *   of some asynchronous logic
 * @param wait - the number of milliseconds to delay.
 * @param options - the options object
 */
export const createDebouncedAsyncThunk = <Returned, ThunkArg = void>(
  typePrefix: string,
  payloadCreator: AsyncThunkPayloadCreator<Returned, ThunkArg>,
  wait: number,
  options?: DebounceSettings,
): AsyncThunk<Returned, ThunkArg, any> => {
  const { maxWait = 0, leading = false } = options ?? {};

  let timer = 0;
  let maxTimer = 0;
  let resolve: ((value: boolean) => void) | undefined;

  const invoke = (): void => {
    clearTimeout(maxTimer);
    maxTimer = 0;
    if (resolve) {
      resolve(true);
      resolve = undefined;
    }
  };

  const cancel = (): void => {
    if (resolve) {
      resolve(false);
      resolve = undefined;
    }
  };

  return createAsyncThunk<Returned, ThunkArg>(typePrefix, payloadCreator as never, {
    condition() {
      const immediate = leading && !timer;

      clearTimeout(timer);

      timer = setTimeout(() => {
        invoke();
        timer = 0;
      }, wait) as unknown as number;

      if (immediate) {
        return true;
      }

      cancel();

      if (maxWait && !maxTimer) {
        maxTimer = setTimeout(invoke, maxWait) as unknown as number;
      }

      return new Promise<boolean>(res => (resolve = res));
    },
  });
};
