import { FormControl, FormLabel, Text, useColorModeValue } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { PhoneNumberInput } from './phoneNumberInput';

export interface PhoneFormInputProps {
  isReadonly?: boolean;
  isRequired?: boolean;
  value?: string | null;
  label?: string;
  onChange: (value: string) => void;
}

export const PhoneFormInput = (props: PhoneFormInputProps) => {
  const { label, isReadonly, isRequired, value, onChange } = props;

  const labelColor = useColorModeValue('_gray.500', 'whiteAlpha.700');

  const { t } = useTranslation();

  return (
    <FormControl>
      <FormLabel mb="4px">
        {label ?? t('phone')}
        {isRequired && (
          <Text as={'span'} color={'red'}>
            {' *'}
          </Text>
        )}
      </FormLabel>
      <PhoneNumberInput isReadonly={isReadonly} value={value ?? undefined} onChange={onChange} />
    </FormControl>
  );
};
