import { useTranslation } from 'react-i18next';
import { Button, Tooltip, useColorModeValue } from '@chakra-ui/react';
import { Mortarboard } from 'libs/ui/atoms/src';
import { environment } from '../../environments/environment';

export const VeriKnowsButton = () => {
  const { t } = useTranslation();

  const textColor = useColorModeValue('brand.800', 'brand.800');
  const borderColor = useColorModeValue('yellow.500', 'yellow.500');
  const bgColor = useColorModeValue('yellow.100', 'yellow.100');

  const goToVeriKnows = () => window.open(environment.veriKnowsUrl, '_blank');

  return (
    <Tooltip label={t('veriKnows')}>
      <Button
        variant="outline"
        color={textColor}
        borderColor={'transparent'}
        height="46px"
        bgColor={bgColor}
        _hover={{ borderColor: borderColor }}
        onClick={goToVeriKnows}
        title={t('veriKnows')}
      >
        <Mortarboard w="24px" h="24px" />
      </Button>
    </Tooltip>
  );
};
