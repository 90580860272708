import { api } from './api';
import { EntityType } from './submission';

export enum UniversalDocumentStatus {
  CREATED = 'CREATED',
  UPLOADED = 'UPLOADED',
}

export interface UniversalDocument {
  id: string;

  fileId?: string;
  name: string;
  metadata?: unknown;
  entityType: EntityType;
  entityId: string;
  status: UniversalDocumentStatus;

  createdBy: string;
  createdAt: Date;
  updatedBy?: string;
  updatedAt?: Date;
}

export interface UniversalDocumentCreateDto {
  name: string;
  metadata?: unknown;
  entityType: EntityType;
  entityId: string;
}

export interface UniversalDocumentUploadDto {
  id: string;
  formData?: FormData;
}

const universalDocumentApi = api.injectEndpoints({
  endpoints: build => ({
    create: build.mutation<UniversalDocument, UniversalDocumentCreateDto>({
      query: data => ({ url: `/universal-document`, method: 'POST', body: data }),
    }),
    upload: build.mutation<UniversalDocument, UniversalDocumentUploadDto>({
      query: ({ id, formData }) => ({ url: `/universal-document/${id}/upload`, body: formData, method: 'POST' }),
    }),
    delete: build.mutation<UniversalDocument, { id: string }>({
      query: ({ id }) => ({ url: `/universal-document/${id}`, method: 'DELETE' }),
    }),
  }),
});

export const {
  useCreateMutation: useCreateUniversalDocumentMutation,
  useUploadMutation: useUploadUniversalDocumentMutation,
  useDeleteMutation: useDeleteUniversalDocumentMutation,
  endpoints: UniversalDocumentEndpoints,
} = universalDocumentApi;
