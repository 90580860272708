import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

export enum SupportedTranslations {
  EN = 'EN',
  PT = 'PT',
}

export const useDynamicTranslation = () => {
  const { i18n } = useTranslation();

  const dynamicTranslate = useCallback(
    (strings: Record<SupportedTranslations, string>) => {
      const lang = i18n.language.toLocaleLowerCase().includes('pt')
        ? SupportedTranslations.PT
        : SupportedTranslations.EN;

      return strings[lang];
    },
    [i18n.language],
  );

  return { dynamicTranslate };
};
