import { useEffect } from 'react';
import { Box } from '@chakra-ui/react';

import { useTypedSelector } from 'store';
import { useLazyMePrivateQuery } from 'api';
import Card from 'components/card/Card';

import BannerImg from 'assets/img/company-banner.png';
import Banner from '../../../components/common/profile-banner';
import { useNavigate } from 'react-router-dom';
import { UsersWidgetWidget } from './widgets/users-management/users/UsersWidget';

export default function Company() {
  const [getMe] = useLazyMePrivateQuery();
  const navigate = useNavigate();

  const organizationId = useTypedSelector(state => state.auth.organizationId);
  const auth = useTypedSelector(state => state.auth);

  const currentOrganization = auth.user?.organizations?.find(org => org.id === organizationId);

  useEffect(() => {
    if (!organizationId) {
      navigate('/profile');
    }
  }, [currentOrganization, navigate, organizationId]);

  useEffect(() => {
    getMe({}, false);
  }, [getMe]);

  const getBalance = () => (currentOrganization ? currentOrganization.balanceEur : 0);

  return (
    <Box>
      <Card mb={{ base: '0px', lg: '20px' }} alignItems="stretch" position="static">
        <Banner
          banner={BannerImg}
          mainTitle={currentOrganization?.name || ''}
          jobs={auth.user?.jobCount || 0}
          balance={getBalance()}
          files={auth.user?.documentCount || 0}
        />
      </Card>

      <UsersWidgetWidget />
    </Box>
  );
}
