import { useToast } from '@chakra-ui/react';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Signing, useUpdateSigningMutation } from '../../../../api/signing';

export const useSigningType = (signing: Signing) => {
  const { t } = useTranslation();
  const toast = useToast();

  const [signingType, setSigningType] = useState(signing.signingType);
  const [updateSigning] = useUpdateSigningMutation();

  useEffect(() => {
    setSigningType(signing.signingType);
  }, [signing]);

  useEffect(() => {
    if (signingType !== signing.signingType) {
      updateSigning({ id: signing?.id ?? '', signingType }).catch(e => {
        setSigningType(signing.signingType);
        toast({
          title: t('error'),
          description: t('error'),
          status: 'error',
          duration: 1500,
        });
      });
    }
  }, [signingType]);

  return { signingType, setSigningType };
};
