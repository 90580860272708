import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

export interface FieldState {
  value: string;
  error: string;
}

function isNotEmpty(value: string) {
  return value.trim().length > 0;
}

export function useJobForm(name: string, description: string) {
  const { t } = useTranslation();

  const [nameField, setNameField] = useState<FieldState>({
    value: name || '',
    error: '',
  });

  const [descriptionField, setDescriptionField] = useState<FieldState>({
    value: description || '',
    error: '',
  });

  const setName = (value: string) => {
    setNameField({ value, error: isNotEmpty(value) ? '' : t('pages.details.nameIsRequired') });
  };

  const setDescription = (value: string) => {
    setDescriptionField({ ...descriptionField, value });
  };

  const validateForm = () => {
    let isValid = true;

    if (!isNotEmpty(nameField.value)) {
      setNameField({ ...nameField, error: t('pages.details.nameIsRequired') });
      isValid = false;
    }

    return isValid;
  };

  return { nameField, descriptionField, setName, setDescription, validateForm };
}
