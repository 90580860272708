import { Flex, useColorModeValue } from '@chakra-ui/react';
import { useEffect, useRef } from 'react';

interface OverlayProps {
  isUseOverlay: boolean;
  isVisible: boolean;
  onClose: () => void;
  children: React.ReactNode;
}

export const OverlayMaybe = ({ isUseOverlay, isVisible, onClose, children }: OverlayProps): JSX.Element | null => {
  const bgColor = useColorModeValue('blackAlpha.600', 'blackAlpha.600');

  const overlayRef = useRef<HTMLDivElement>(null);

  // Close overlay on pressing 'Escape' key
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };

    if (isVisible) {
      window.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [isVisible, onClose]);

  // Close overlay on clicking outside of the content (misclick)
  const handleOverlayClick = (event: React.MouseEvent) => {
    if (overlayRef.current && overlayRef.current === event.target) {
      onClose();
    }
  };

  if (!isVisible) return null;

  return isUseOverlay ? (
    <Flex
      position="fixed"
      left={0}
      top={0}
      w="100vw"
      h="100vh"
      bg={bgColor}
      align="center"
      justify="center"
      zIndex={1000}
      onClick={handleOverlayClick}
    >
      {children}
    </Flex>
  ) : (
    <>{children}</>
  );
};
