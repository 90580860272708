import { Flex, Text, useColorModeValue } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { UserRole } from '../../../api';
import { userRoleTKeyMap } from './roles-utils';

interface RolePillProps {
  roles: UserRole[];
}

export const UserRolePills = (props: RolePillProps) => {
  const { roles } = props;

  const { t } = useTranslation();

  const textColor = useColorModeValue('_gray.700', '_gray.700');
  const bgColor = useColorModeValue('purple.100', 'purple.100');

  return (
    <Flex gap={'4px'} flexWrap="wrap" flexDir={'row-reverse'} maxW="600px">
      {[...roles].sort().map((role, idx) => (
        <Text
          height={'22px'}
          textAlign={'center'}
          key={idx}
          borderRadius="16px"
          p="0 8px"
          bg={bgColor}
          color={textColor}
          fontSize={'14px'}
          lineHeight={'22px'}
          fontWeight="500"
        >
          {t(userRoleTKeyMap[role])}
        </Text>
      ))}
    </Flex>
  );
};
