import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Button,
  Flex,
  Icon,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';

import { RelatedPartySelectItemType, SelectItem } from './SelectItem';
import { RelatedParty, RelatedPartyCreateDto, RelatedPartyRelationType } from 'api/related-parties';
import { CreateRelatedPartyButton, CreateRelatedPartyButtonType } from './mutateRelatedParty/CreateRelatedPartyButton';
import { useEffect, useState } from 'react';
import { RecycleBing } from 'libs/ui/atoms/src';
import { RelatedPartyTooltip } from './RelatedPartyTooltip';
import { useTranslation } from 'react-i18next';
import { MdOutlineError, MdOutlineErrorOutline, MdUpload } from 'react-icons/md';
import { SubmissionRelatedPartyStatus } from '../../../../../api';
import { VcTooltip } from '../../../../../libs/ui/atoms/src/lib/tooltip/Tooltip';

export interface SelectAccordionItemProps {
  relatedParty: RelatedParty;
  items: RelatedParty[];

  isChecked: boolean;
  onCheck: () => void;

  rightActionChildren?: React.ReactNode;

  onCLick: (entity: RelatedParty) => void;

  onCreate: (data: RelatedPartyCreateDto) => Promise<void>;
  onDelete: (id: string) => void;
  onImportChild: () => void;
  onImportUpdate: (relatedParty: RelatedParty) => void;
  onLoad: () => void;
  onOpenDropzone?: (relatedParty?: RelatedParty) => void;
}

export const SelectAccordionItem = ({
  relatedParty,
  items,
  isChecked,
  onCheck,
  rightActionChildren,
  onCLick,
  onCreate,
  onDelete,
  onImportChild: onImport,
  onImportUpdate,
  onLoad,
  onOpenDropzone,
}: SelectAccordionItemProps) => {
  const { t } = useTranslation();

  const buttonBackground = useColorModeValue('gray.360', 'whiteAlpha.200');
  const textColor = useColorModeValue('navy.750', 'white');
  const borderColor = useColorModeValue('red.500', 'red.500');
  const primaryBgHover = useColorModeValue('blue.50', 'navy.750');
  const pillColor = useColorModeValue('gray.700', 'white');

  const [isExpanded, setIsExpanded] = useState(false);
  const [isInitedExpanded, setIsInitedExpanded] = useState(false);

  useEffect(() => {
    if (!isInitedExpanded) {
      setIsInitedExpanded(true);
      return;
    }
    setIsExpanded(true);
  }, [relatedParty.nestedRelatedParties?.length]);

  return (
    <Accordion
      index={isExpanded ? 0 : -1}
      allowMultiple
      allowToggle
      borderBottom={'1px solid'}
      borderLeft="none"
      borderRight="none"
      borderColor={'gray.400'}
    >
      <AccordionItem color={textColor} border={'none'}>
        <AccordionButton
          p="0"
          _focus={{ boxShadow: 'none' }}
          _hover={{ bg: primaryBgHover }}
          borderBottom={isExpanded ? '1px solid' : undefined}
          borderColor={isExpanded ? 'gray.400' : undefined}
          onClick={e => e.preventDefault()}
          maxW="100%"
          overflow={'hidden'}
        >
          <Flex flex="1" gap={'0'} alignItems={'center'} maxW="100%" overflow={'hidden'}>
            <SelectItem
              isHoverEffectDisabled
              styles={{ flex: '1' }}
              type={RelatedPartySelectItemType.PRIMARY}
              isChecked={isChecked}
              relatedParty={relatedParty}
              onCheck={onCheck}
              onCLick={() => onCLick(relatedParty)}
              rightActionChildren={
                <Flex gap={'8px'}>
                  {!relatedParty.hasCpcCode && relatedParty.relationType === RelatedPartyRelationType.COMPANY && (
                    <VcTooltip
                      iconChildren={<Icon w="19px" h="19px" color={'orange.500'} as={MdOutlineErrorOutline} />}
                      contentChildren={
                        <Flex flexDirection={'column'} gap="12px" p="16px">
                          <Text fontSize={'14px'} fontWeight="bold" color={'orange.500'}>
                            {t('relatedParties.companyHasNoCpcCode')}
                          </Text>
                        </Flex>
                      }
                    />
                  )}

                  <Button
                    size="xs"
                    color={pillColor}
                    bg={buttonBackground}
                    border={`1px solid`}
                    borderColor={buttonBackground}
                    _hover={{ borderColor: pillColor }}
                    onClick={() => () => onCLick(relatedParty)}
                  >
                    <Text textOverflow={'ellipsis'} overflow={'hidden'} maxW={'100%'}>
                      {t('edit')}
                    </Text>
                  </Button>

                  <RelatedPartyTooltip relatedParty={relatedParty} />
                </Flex>
              }
              thirdRowChildren={
                <Flex gap={'8px'}>
                  <Button
                    size={'xs'}
                    leftIcon={<Icon as={MdUpload} w="12px" h="12px" color={'gray.700'} />}
                    borderRadius={'16px'}
                    color={'gray.700'}
                    p={'0 12px'}
                    bg={'green.200'}
                    _hover={{ bg: 'green.300' }}
                    onClick={e => {
                      e.stopPropagation();
                      onImport();
                    }}
                  >
                    {t('uploadNewPerson')}
                  </Button>

                  <CreateRelatedPartyButton
                    type={CreateRelatedPartyButtonType.SECONDARY}
                    jobId={relatedParty.jobId}
                    entityType={relatedParty.entityType}
                    parentRelatedParty={relatedParty}
                    onSubmit={onCreate}
                    relatedPartyId={relatedParty.id}
                    relationType={RelatedPartyRelationType.PERSON}
                    onCodeSubmit={onLoad}
                    onOpenDropzone={onOpenDropzone}
                  />
                </Flex>
              }
            />
            <AccordionIcon w={'43px'} onClick={() => setIsExpanded(!isExpanded)} />
          </Flex>
        </AccordionButton>

        <AccordionPanel p="0">
          <Flex flexDir={'column'}>
            {items.map(p => (
              <SelectItem<RelatedPartySelectItemType.SECONDARY>
                key={p.id}
                type={RelatedPartySelectItemType.SECONDARY}
                relatedParty={p}
                onCLick={() => onCLick(p)}
                rightActionChildren={
                  <Flex gap={'8px'} pr="7px">
                    {(!p.submission || p.submission?.status === SubmissionRelatedPartyStatus.COMPLETED) && (
                      <>
                        <Button
                          size={'xs'}
                          leftIcon={<Icon as={MdUpload} w="12px" h="12px" color={'gray.700'} />}
                          borderRadius={'16px'}
                          color={'gray.700'}
                          p={'0 12px'}
                          bg={'green.200'}
                          _hover={{ bg: 'green.300' }}
                          onClick={e => {
                            e.stopPropagation();
                            onImportUpdate(p);
                          }}
                        >
                          {t('uploadDetails')}
                        </Button>

                        <Button
                          size="xs"
                          color={pillColor}
                          bg={buttonBackground}
                          border={`1px solid`}
                          borderColor={buttonBackground}
                          _hover={{ borderColor: pillColor }}
                          onClick={() => onCLick(p)}
                        >
                          <Text textOverflow={'ellipsis'} overflow={'hidden'} maxW={'100%'}>
                            {t('edit')}
                          </Text>
                        </Button>

                        <RelatedPartyTooltip relatedParty={p} />
                      </>
                    )}

                    {p.submission?.status !== SubmissionRelatedPartyStatus.SUBMITTED && (
                      <Button
                        size="xs"
                        variant="ghost"
                        color={'red.500'}
                        p="0"
                        borderColor={borderColor}
                        onClick={e => {
                          e.stopPropagation();
                          onDelete(p.id);
                        }}
                      >
                        <RecycleBing boxSize={5} color={'red'} />
                      </Button>
                    )}
                  </Flex>
                }
              />
            ))}
          </Flex>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};
