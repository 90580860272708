import { Box, useColorModeValue, useToast } from '@chakra-ui/react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Job } from '../../api';
import { SupportedTranslations, useDynamicTranslation } from './useDynamicTranslation';
import { useFormattedClipboard } from './useFormattedClipboard';

export interface CopyContractsToolProps {
  job: Job;
}

export const CopyContractsTool = ({ job }: CopyContractsToolProps) => {
  const { t } = useTranslation();
  const toast = useToast();

  const textColor = useColorModeValue('navy.750', 'white');
  const menuItemBg = useColorModeValue('gray.50', 'whiteAlpha.200');
  const menuItemHoveBg = useColorModeValue('gray.150', 'whiteAlpha.300');

  const copyToClipboard = useFormattedClipboard();
  const { dynamicTranslate } = useDynamicTranslation();

  const handleCopy = useCallback(
    (text: string) => {
      const copyResult = copyToClipboard({ text });

      if (copyResult) {
        toast({ title: t('copiedToClipboard'), status: 'info', duration: 1500 });
      } else {
        toast({ title: t('copiedToClipboardError'), status: 'error', duration: 1500 });
      }
    },
    [copyToClipboard, t, toast],
  );

  return (
    <>
      {job.contractTexts?.map((text, idx) => {
        return (
          <Box
            key={idx}
            cursor={'pointer'}
            borderRadius={'8px'}
            _hover={{ bg: menuItemHoveBg }}
            bg={menuItemBg}
            color={textColor}
            fontSize="14px"
            lineHeight={'14px'}
            fontWeight={500}
            p="12px"
            onClick={() => {
              handleCopy(
                dynamicTranslate({
                  [SupportedTranslations.EN]: text.contractTextEn,
                  [SupportedTranslations.PT]: text.contractTextPt,
                }),
              );
            }}
          >
            {`${t('clickToCopy')} ${dynamicTranslate({
              [SupportedTranslations.EN]: text.labelEn,
              [SupportedTranslations.PT]: text.labelPt,
            })}`}
          </Box>
        );
      })}
    </>
  );
};
