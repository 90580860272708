import { useTranslation } from 'react-i18next';
import { Button, useColorModeValue } from '@chakra-ui/react';
import { useTypedSelector } from 'store';
import { Job } from 'api';
import { Link, useMatch } from 'react-router-dom';
import { DocumentOutFilledIcon } from '../icons/Icons';

export const AddContractsButton = () => {
  const { t } = useTranslation();

  const job = useTypedSelector(state => state.job.selectedJob) as Job;

  const addButtonColor = useColorModeValue('blue.600', 'blue.600');
  const isResultPage = useMatch('/job-result/:jobId');

  return (
    <>
      {isResultPage && job?.id && (
        <Link to={`/jobs/${job.id}/contracts-and-parties`}>
          <Button
            borderRadius={'10px'}
            color={'white'}
            p={'0 24px 0 16px'}
            h="40px"
            bg={addButtonColor}
            _hover={{ bg: 'navy.600' }}
            leftIcon={<DocumentOutFilledIcon color={'white'} w="24px" h="24px" />}
          >
            {t('pages.details.contracts&parties')}
          </Button>
        </Link>
      )}
    </>
  );
};
