import { ReactNode } from 'react';
import { DocumentOutFilledIcon, ReloadIcon } from '../../../components/icons/Icons';
import { FileExclamation, HandTruck } from '../../../libs/ui/atoms/src';

export enum HelpTicketType {
  HOW_TO_CREATE_REPORT = 'HOW_TO_CREATE_REPORT',
  HOW_TO_CREATE_CONTRACT = 'HOW_TO_CREATE_CONTRACT',
  HOW_TO_RENEW_CERTIDAO_PERMANENTE = 'HOW_TO_RENEW_CERTIDAO_PERMANENTE',
  HOW_TO_DO_DDP = 'HOW_TO_DO_DDP',
}

export interface HelpTicket {
  type: HelpTicketType;
  title: string;
  icon: ReactNode;
  explanationTitle: string;
  explanationSteps: string[];
}

export const tickets: HelpTicket[] = [
  {
    type: HelpTicketType.HOW_TO_CREATE_REPORT,
    title: 'ticket.howToCreateReportForProperty.title',
    icon: <FileExclamation color="red" w="24px" h="24px" />,
    explanationTitle: 'ticket.howToCreateReportForProperty.explanationTitle',
    explanationSteps: [
      'ticket.howToCreateReportForProperty.explanationSteps.1',
      'ticket.howToCreateReportForProperty.explanationSteps.2',
      'ticket.howToCreateReportForProperty.explanationSteps.3',
      'ticket.howToCreateReportForProperty.explanationSteps.4',
      'ticket.howToCreateReportForProperty.explanationSteps.5',
    ],
  },
  {
    type: HelpTicketType.HOW_TO_CREATE_CONTRACT,
    title: 'ticket.howToCreateContractForProperty.title',
    icon: <DocumentOutFilledIcon color="blue.500" w="28px" h="28px" />,
    explanationTitle: 'ticket.howToCreateContractForProperty.explanationTitle',
    explanationSteps: [
      'ticket.howToCreateContractForProperty.explanationSteps.1',
      'ticket.howToCreateContractForProperty.explanationSteps.2',
      'ticket.howToCreateContractForProperty.explanationSteps.3',
      'ticket.howToCreateContractForProperty.explanationSteps.4',
      'ticket.howToCreateContractForProperty.explanationSteps.5',
      'ticket.howToCreateContractForProperty.explanationSteps.6',
      'ticket.howToCreateContractForProperty.explanationSteps.7',
      'ticket.howToCreateContractForProperty.explanationSteps.8',
      'ticket.howToCreateContractForProperty.explanationSteps.9',
    ],
  },
  {
    type: HelpTicketType.HOW_TO_RENEW_CERTIDAO_PERMANENTE,
    title: 'ticket.howToRenewCertidaoPermanente.title',
    icon: <ReloadIcon w="24px" h="24px" color={'green.400'} />,
    explanationTitle: 'ticket.howToRenewCertidaoPermanente.explanationTitle',
    explanationSteps: [
      'ticket.howToRenewCertidaoPermanente.explanationSteps.1',
      'ticket.howToRenewCertidaoPermanente.explanationSteps.2',
      'ticket.howToRenewCertidaoPermanente.explanationSteps.3',
      'ticket.howToRenewCertidaoPermanente.explanationSteps.4',
      'ticket.howToRenewCertidaoPermanente.explanationSteps.5',
      'ticket.howToRenewCertidaoPermanente.explanationSteps.6',
      'ticket.howToRenewCertidaoPermanente.explanationSteps.7',
      'ticket.howToRenewCertidaoPermanente.explanationSteps.8',
      'ticket.howToRenewCertidaoPermanente.explanationSteps.9',
    ],
  },
  {
    type: HelpTicketType.HOW_TO_DO_DDP,
    title: 'ticket.howToDoDdP.title',
    icon: <HandTruck w="24px" h="24px" color={'_brand.300'} />,
    explanationTitle: 'ticket.howToDoDdP.explanationTitle',
    explanationSteps: [
      'ticket.howToDoDdP.explanationSteps.1',
      'ticket.howToDoDdP.explanationSteps.2',
      'ticket.howToDoDdP.explanationSteps.3',
      'ticket.howToDoDdP.explanationSteps.4',
      'ticket.howToDoDdP.explanationSteps.5',
      'ticket.howToDoDdP.explanationSteps.6',
      'ticket.howToDoDdP.explanationSteps.7',
      'ticket.howToDoDdP.explanationSteps.8',
      'ticket.howToDoDdP.explanationSteps.9',
      'ticket.howToDoDdP.explanationSteps.10',
      'ticket.howToDoDdP.explanationSteps.11',
      'ticket.howToDoDdP.explanationSteps.12',
    ],
  },
];
