import { FormControl, FormLabel, Switch } from '@chakra-ui/react';
import React from 'react';

export interface SwitchInputFieldProps {
  isChecked: boolean;
  isDisabled?: boolean;
  onCheck: () => void;
  label?: string;
}

export function SwitchInputField({ isChecked, isDisabled, onCheck, label }: SwitchInputFieldProps) {
  return (
    <FormControl display="flex" alignItems="center">
      {label ? <FormLabel mb="0">{label}</FormLabel> : <></>}
      <Switch isChecked={isChecked} isDisabled={isDisabled ?? false} onChange={() => onCheck()} />
    </FormControl>
  );
}
