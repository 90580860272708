import React from 'react';
import { Box, Flex, FlexProps, Icon, Text, useColorModeValue } from '@chakra-ui/react';

import { Checkbox } from 'libs/ui/atoms/src/lib/checkbox/Checkbox';
import { Account, ErrorInSync, Heranca, OfficeBag, TextDotCenter } from 'libs/ui/atoms/src';
import { RelatedParty, RelatedPartyRelationType, SubmissionRelatedPartyStatus } from 'api';
import { useTranslation } from 'react-i18next';
import { ReloadIcon } from '../../../../../components/icons/Icons';

export enum RelatedPartySelectItemType {
  PRIMARY = 'PRIMARY',
  SECONDARY = 'SECONDARY',
}

export interface SelectItemBaseProps {
  styles?: FlexProps;
  isHoverEffectDisabled?: boolean;
  isHideLeftPadding?: boolean;

  relatedParty: RelatedParty;

  rightActionChildren?: React.ReactNode;
  thirdRowChildren?: React.ReactNode;

  onCLick?: () => void;
}

export interface SelectItemPrimaryProps extends SelectItemBaseProps {
  type: RelatedPartySelectItemType.PRIMARY;
  isChecked?: boolean;
  onCheck?: () => void;
}

export interface SelectItemSecondaryProps extends SelectItemBaseProps {
  type: RelatedPartySelectItemType.SECONDARY;
}

type SelectItemProps<T extends RelatedPartySelectItemType> = T extends RelatedPartySelectItemType.PRIMARY
  ? SelectItemPrimaryProps
  : SelectItemSecondaryProps;

export function SelectItem<T extends RelatedPartySelectItemType = RelatedPartySelectItemType.PRIMARY>(
  props: SelectItemProps<T>,
) {
  const {
    styles,
    type,
    isHoverEffectDisabled,
    relatedParty,
    rightActionChildren,
    onCLick,
    isHideLeftPadding,
    thirdRowChildren,
  } = props;
  const { isChecked, onCheck } = props as SelectItemProps<RelatedPartySelectItemType.PRIMARY>;

  const textColor = useColorModeValue('gray.600', 'white');
  const secondaryBg = useColorModeValue('gray.50', 'navy.700');
  const submittedBg = useColorModeValue('_brand.50', 'navy.700');
  const errorBg = useColorModeValue('red.50', 'navy.700');
  const primaryBgHover = useColorModeValue('blue.50', 'navy.750');
  const submittedBgHover = useColorModeValue('_brand.100', 'navy.750');
  const errorBgHover = useColorModeValue('red.100', 'navy.750');
  const secondaryBgHover = useColorModeValue('blue.50', 'navy.750');
  const subtitleTextColor = useColorModeValue('gray.400', 'gray.300');
  const subtitleMainTextColor = useColorModeValue('gray.600', 'gray.200');
  const textDotColor = useColorModeValue('brand.1050', 'white');
  const relationIconColor = useColorModeValue('brand.800', 'white');

  const { t } = useTranslation();

  const name =
    relatedParty.submission?.status === SubmissionRelatedPartyStatus.SUBMITTED
      ? relatedParty.relationType === RelatedPartyRelationType.COMPANY
        ? t('relatedParties.extract.extractingCompanyFromYourFiles')
        : t('relatedParties.extract.extractingPersonFromYourFiles')
      : relatedParty.submission?.status === SubmissionRelatedPartyStatus.ERROR
        ? t('relatedParties.extract.errorDuringDataExtraction')
        : relatedParty.name;

  const subtitle =
    relatedParty.submission?.status === SubmissionRelatedPartyStatus.SUBMITTED ||
    relatedParty.submission?.status === SubmissionRelatedPartyStatus.ERROR
      ? relatedParty.documents?.map(i => i.name).join(', ')
      : relatedParty.nif ?? '';

  return (
    <Flex
      {...styles}
      alignItems={'center'}
      gap={'10px'}
      borderBottom={'1px'}
      borderColor={'gray.400'}
      p={type === RelatedPartySelectItemType.PRIMARY ? '12px 0 12px 12px' : '6px 0 6px 12px'}
      bg={
        relatedParty.submission?.status === SubmissionRelatedPartyStatus.SUBMITTED
          ? submittedBg
          : relatedParty.submission?.status === SubmissionRelatedPartyStatus.ERROR
            ? errorBg
            : type === RelatedPartySelectItemType.PRIMARY
              ? undefined
              : secondaryBg
      }
      cursor={'pointer'}
      _last={{ border: 'none' }}
      _hover={{
        bg: isHoverEffectDisabled
          ? undefined
          : relatedParty.submission?.status === SubmissionRelatedPartyStatus.SUBMITTED
            ? submittedBgHover
            : relatedParty.submission?.status === SubmissionRelatedPartyStatus.ERROR
              ? errorBgHover
              : type === RelatedPartySelectItemType.PRIMARY
                ? primaryBgHover
                : secondaryBgHover,
      }}
      onClick={onCLick}
      overflow={'hidden'}
    >
      {onCheck && relatedParty.submission?.status !== SubmissionRelatedPartyStatus.SUBMITTED ? (
        <Checkbox isChecked={!!isChecked} onCheck={onCheck} />
      ) : !isHideLeftPadding ? (
        <Box w={'24px'} h={'24px'}></Box>
      ) : undefined}

      {relatedParty.submission?.status === SubmissionRelatedPartyStatus.SUBMITTED ? (
        <Icon
          animation={'rotateinf 1s linear infinite'}
          w="20px"
          h="20px"
          display={'flex'}
          alignItems="center"
          justifyContent={'center'}
          as={ReloadIcon}
        />
      ) : relatedParty.submission?.status === SubmissionRelatedPartyStatus.ERROR ? (
        <ErrorInSync w="20px" h="20px" />
      ) : relatedParty.relationType === RelatedPartyRelationType.COMPANY ? (
        <OfficeBag color={relationIconColor} h={'20px'} w={'20px'} />
      ) : relatedParty.relationType === RelatedPartyRelationType.PERSON ? (
        <Account color={relationIconColor} h={'20px'} w={'20px'} />
      ) : (
        <Heranca color={relationIconColor} h={'20px'} w={'20px'} />
      )}

      <Flex
        minH={'38px'}
        flex={1}
        justifyContent="center"
        alignItems={'baseline'}
        direction={'column'}
        gap={'4px'}
        overflow="hidden"
      >
        <Flex alignItems={'center'} gap={'8px'}>
          <Text
            overflow={'hidden'}
            textOverflow={'ellipsis'}
            flexWrap={'nowrap'}
            fontSize={'md'}
            lineHeight={'20px'}
            fontWeight={'bold'}
            textAlign={'start'}
            color={textColor}
          >
            {name}
          </Text>
        </Flex>

        <Flex alignItems="center" gap="8px" overflow={'hidden'} maxW="100%">
          <Text
            title={subtitle}
            fontSize={'14px'}
            {...(relatedParty.submission?.status === SubmissionRelatedPartyStatus.ERROR ||
            relatedParty.submission?.status === SubmissionRelatedPartyStatus.SUBMITTED
              ? undefined
              : { fontFamily: 'monospace' })}
            lineHeight={'15px'}
            color={subtitleMainTextColor}
            fontWeight={'500'}
            overflow={'hidden'}
            textOverflow={'ellipsis'}
            className="no-text-wrap"
          >
            {subtitle}
          </Text>

          {relatedParty.email && <TextDotCenter color={textDotColor} boxSize={2} />}

          <Text
            title={relatedParty.email ?? ''}
            fontSize={'14px'}
            lineHeight={'15px'}
            color={subtitleTextColor}
            fontWeight={'500'}
            flex={'1'}
            overflow={'hidden'}
            textOverflow={'ellipsis'}
            whiteSpace={'nowrap'}
          >
            {relatedParty.email ?? ''}
          </Text>
        </Flex>

        {thirdRowChildren && thirdRowChildren}
      </Flex>

      {rightActionChildren && rightActionChildren}
    </Flex>
  );
}
