export enum EntityType {
  RELATED_PARTY = 'RELATED_PARTY',
  SIGNING = 'SIGNING',
}

export type SubmissionStatus = SubmissionRelatedPartyStatus | SubmissionSigningStatus;

export enum SubmissionRelatedPartyStatus {
  CREATED = 'CREATED',
  SUBMITTED = 'SUBMITTED',
  COMPLETED = 'COMPLETED',
  ERROR = 'ERROR',
}

export enum SubmissionSigningStatus {
  SENT = 'SENT',
  SUCCESS = 'SUCCESS',
  REJECT = 'REJECT',
  ERROR = 'ERROR',
}

export interface Submission<T extends SubmissionStatus> {
  status: T;
  updatedAt?: Date;
}
