import { useTranslation } from 'react-i18next';
import {
  Box,
  Checkbox,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  Input,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useColorModeValue,
} from '@chakra-ui/react';
import { RelatedPartyFormAction, RelatedPartyFormState } from './useRelatedPartyForm';
import {
  CivilStatus,
  MarriageRegime,
  RelatedPartyAge,
  RelatedPartyEntityType,
  RelatedPartyIDDocumentType,
  RelatedPartyRelationType,
} from 'api/related-parties';
import { RadioButton } from 'libs/ui/atoms/src/lib/radiobutton/RadioButton';

import 'react-phone-input-2/lib/style.css';
import { PhoneNumberInput } from 'libs/ui/atoms/src/lib/input-field/phoneNumberInput';
import { SelectInputInputField } from 'libs/ui/atoms/src/lib/input-field/selectField';
import {
  isShowMarriageRegime,
  tByCivilStatus,
  tByMarriageRegime,
  tByRelatedPartyAge,
  tByRelatedPartyIDDocumentType,
  valueByRelatedPartyIDDocumentType,
} from '../utils';
import { FormInputField } from '../../../../../../libs/ui/atoms/src/lib/input-field/form-input-field';
import {
  getDistrictCode,
  getMunicipalityCode,
  VC_DB_PREDIAL_DISTRICTS,
  VC_DB_PREDIAL_MUNICIPALITIES,
  VC_DB_PREDIAL_PARISHS,
} from '../../../../../../utils/db-predial';
import { COUNTRIES_WITH_CODES_PREPARED } from '../../../../../../libs/ui/atoms/src/lib/input-field/countries';

export interface MutateRelatedPartyFormProps {
  form: RelatedPartyFormState;
  dispatch: (action: RelatedPartyFormAction) => void;

  isHideRelationRadioButton?: boolean;
  isShowCorpAuthority?: boolean;
  entityType: RelatedPartyEntityType;
  isShowCompanyImportByCode?: boolean;

  certidaoAccessCode?: string;
  onCertidaoCodeChange?: (code: string) => void;
  onTabIndexChange?: (index: number) => void;
}

const MainForm = ({ form, dispatch, isShowCorpAuthority }: MutateRelatedPartyFormProps) => {
  const { t } = useTranslation();

  return (
    <>
      <Grid columnGap={'24px'} rowGap={'16px'} gridTemplateColumns={{ sm: '1fr', md: '1fr 1fr' }}>
        <FormInputField
          label={t('certificateRenewal.form.name')}
          value={form.name}
          onChange={e => dispatch({ type: 'setName', value: e })}
        />

        {form.relationType === RelatedPartyRelationType.PERSON && (
          <FormInputField
            label={t('certificateRenewal.form.email')}
            value={form.email}
            onChange={e => dispatch({ type: 'setEmail', value: e })}
          />
        )}

        {form.relationType === RelatedPartyRelationType.PERSON && (
          <FormControl>
            <FormLabel>{t('phone')}</FormLabel>
            <PhoneNumberInput value={form.phone} onChange={e => dispatch({ type: 'setPhone', value: e })} />
          </FormControl>
        )}

        <FormInputField
          type={'number'}
          label={t('NIF')}
          value={form.nif}
          onChange={e => dispatch({ type: 'setNifNips', value: e })}
        />
        {form.relationType === RelatedPartyRelationType.PERSON && (
          <FormInputField
            label={t('address')}
            value={form.address}
            onChange={e => dispatch({ type: 'setAddress', value: e })}
          />
        )}

        {form.relationType === RelatedPartyRelationType.PERSON && (
          <FormControl>
            <FormLabel>{t('relatedParties.nationality')}</FormLabel>
            <SelectInputInputField
              placeholder={t('relatedParties.nationality')}
              items={COUNTRIES_WITH_CODES_PREPARED.map(i => ({
                value: i.alpha3,
                label: i.labelWithoutFlag + ' ' + i.flag,
              }))}
              value={form.nacionalidade}
              onChange={e => dispatch({ type: 'setNacionalidade', value: e })}
            />
          </FormControl>
        )}

        {form.relationType === RelatedPartyRelationType.PERSON && (
          <>
            {form.nacionalidade === 'PRT' && (
              <>
                <FormInputField
                  label={t('relatedParties.placeOfBirthFreguesia')}
                  value={form.placeOfBirthFreguesia}
                  onChange={e => dispatch({ type: 'setPlaceOfBirthFreguesia', value: e })}
                />

                <FormInputField
                  label={t('relatedParties.placeOfBirthConcelho')}
                  value={form.placeOfBirthConcelho}
                  onChange={e => dispatch({ type: 'setPlaceOfBirthConcelho', value: e })}
                />
              </>
            )}

            <FormControl>
              <FormLabel>{t('relatedParties.civilStatus')}</FormLabel>
              <SelectInputInputField<CivilStatus>
                placeholder={t('relatedParties.civilStatus')}
                items={Object.values(CivilStatus).map(i => ({
                  value: i,
                  label: t(tByCivilStatus[i]),
                }))}
                value={form.civilStatus}
                onChange={e => dispatch({ type: 'setCivilStatus', value: e })}
              />
            </FormControl>

            {form.civilStatus === CivilStatus.SINGLE && (
              <FormControl>
                <FormLabel>{t('relatedParties.age')}</FormLabel>
                <SelectInputInputField<RelatedPartyAge>
                  placeholder={t('relatedParties.age')}
                  items={Object.values(RelatedPartyAge).map(i => ({
                    value: i,
                    label: t(tByRelatedPartyAge[i]),
                  }))}
                  value={form.age}
                  onChange={e => dispatch({ type: 'setAge', value: e })}
                />
              </FormControl>
            )}

            {isShowMarriageRegime(form.civilStatus) && (
              <>
                <FormControl>
                  <FormLabel>{t('relatedParties.marriageRegime')}</FormLabel>
                  <SelectInputInputField<MarriageRegime>
                    placeholder={t('relatedParties.marriageRegime')}
                    items={Object.values(MarriageRegime).map(i => ({
                      value: i,
                      label: t(tByMarriageRegime[i]),
                    }))}
                    value={form.marriageRegime}
                    onChange={e => dispatch({ type: 'setMarriageRegime', value: e })}
                  />
                </FormControl>

                <FormInputField
                  label={t('relatedParties.marriedTo')}
                  value={form.marriedTo}
                  onChange={e => dispatch({ type: 'setMarriedTo', value: e })}
                />
              </>
            )}
          </>
        )}
      </Grid>

      {form.relationType === RelatedPartyRelationType.PERSON && (
        <Flex direction={'column'} gap="12px">
          <Flex direction="row" gap="12px" alignItems={'center'}>
            <Divider orientation="horizontal" mt={'24px'} opacity={1} />
          </Flex>

          <Grid templateColumns={{ sm: '1fr', md: '1fr 1fr' }} columnGap="24px" rowGap={'16px'}>
            <FormControl>
              <FormLabel>{t('relatedParties.idDocumentType')}</FormLabel>
              <SelectInputInputField<RelatedPartyIDDocumentType>
                placeholder={t('relatedParties.idDocumentType')}
                items={Object.values(RelatedPartyIDDocumentType).map(i => ({
                  value: valueByRelatedPartyIDDocumentType[i],
                  label: t(tByRelatedPartyIDDocumentType[i]),
                }))}
                value={form.idDocumentType}
                onChange={e => dispatch({ type: 'setIdDocumentType', value: e })}
              />
            </FormControl>

            <FormInputField
              label={t('relatedParties.idDocumentNumber')}
              value={form.idDocumentNumber}
              onChange={e => dispatch({ type: 'setIdDocumentNumber', value: e })}
            />

            <FormInputField
              label={t('relatedParties.idDocumentIssuranceAuthority')}
              value={form.idDocumentIssuanceAuthority}
              onChange={e => dispatch({ type: 'setIdDocumentIssuanceAuthority', value: e })}
            />

            <FormInputField
              type="date"
              label={t('relatedParties.idDocumentIssueDate')}
              value={form.idDocumentIssueDate}
              onChange={e => dispatch({ type: 'setIdDocumentIssueDate', value: new Date(e) })}
            />

            <FormInputField
              type="date"
              label={t('relatedParties.idDocumentExpiryDate')}
              value={form.idDocumentExpiryDate}
              onChange={e => dispatch({ type: 'setIdDocumentExpiryDate', value: new Date(e) })}
            />

            {isShowCorpAuthority && (
              <FormInputField
                label={t('relatedParties.corpRepAuthority')}
                value={form.corpRepAuthority}
                onChange={e => dispatch({ type: 'setCorpRepAuthority', value: e })}
              />
            )}
          </Grid>
        </Flex>
      )}

      {form.relationType === RelatedPartyRelationType.COMPANY && (
        <Flex direction={'column'} gap="12px">
          <Flex direction="row" gap="12px" alignItems={'center'}>
            <Divider orientation="horizontal" mt={'24px'} opacity={1} />
          </Flex>

          <Grid templateColumns={{ sm: '1fr', md: '1fr 1fr' }} columnGap="24px" rowGap={'16px'}>
            <FormInputField
              label={t('relatedParties.corpAddress')}
              value={form.corpAddress}
              onChange={e => dispatch({ type: 'setCorpAddress', value: e })}
            />

            <FormControl>
              <FormLabel>{t('relatedParties.corpAddressDistrict')}</FormLabel>
              <SelectInputInputField
                placeholder={t('relatedParties.corpAddressDistrict')}
                items={VC_DB_PREDIAL_DISTRICTS.map(i => ({ value: i[0], label: i[1] }))}
                value={form.corpAddressDistrict}
                onChange={e => dispatch({ type: 'setCorpAddressDistrict', value: e })}
              />
            </FormControl>

            <FormControl>
              <FormLabel>{t('relatedParties.corpAddressMunicipality')}</FormLabel>
              <SelectInputInputField
                placeholder={t('relatedParties.corpAddressMunicipality')}
                items={VC_DB_PREDIAL_MUNICIPALITIES.filter(
                  i =>
                    form.corpAddressDistrict &&
                    i[0].startsWith(getDistrictCode(form.corpAddressDistrict) ?? form.corpAddressDistrict),
                ).map(i => ({ value: i[0], label: i[1] }))}
                value={form.corpAddressMunicipality}
                onChange={e => dispatch({ type: 'setCorpAddressMunicipality', value: e })}
              />
            </FormControl>

            <FormControl>
              <FormLabel>{t('relatedParties.corpAddressParish')}</FormLabel>
              <SelectInputInputField
                placeholder={t('relatedParties.corpAddressParish')}
                items={VC_DB_PREDIAL_PARISHS.filter(
                  i =>
                    form.corpAddressMunicipality &&
                    i[0].startsWith(getMunicipalityCode(form.corpAddressMunicipality) ?? form.corpAddressMunicipality),
                ).map(i => ({ value: i[0], label: i[1] }))}
                value={form.corpAddressParish}
                onChange={e => dispatch({ type: 'setCorpAddressParish', value: e })}
              />
            </FormControl>

            <FormInputField.PostalCode
              label={t('relatedParties.corpAddressPostalCode')}
              value={form.corpAddressPostalCode}
              onChange={e => dispatch({ type: 'setCorpAddressPostalCode', value: e })}
            />

            <FormInputField
              type={'number'}
              label={t('relatedParties.corpCapital')}
              value={form.corpCapital && parseFloat(form.corpCapital.replace('.', '').replace(',', '.'))}
              onChange={e => dispatch({ type: 'setCorpCapital', value: e })}
              leftChildren={'€'}
            />
          </Grid>
        </Flex>
      )}
    </>
  );
};

export const MutateRelatedPartyForm = ({
  form,
  dispatch,
  isHideRelationRadioButton,
  entityType,
  isShowCorpAuthority,
  certidaoAccessCode,
  isShowCompanyImportByCode,
  onCertidaoCodeChange,
  onTabIndexChange,
}: MutateRelatedPartyFormProps) => {
  const { t } = useTranslation();

  const tabBorderBottomActiveColor = useColorModeValue('white', 'gray.700');
  const tabBorderActiveColor = useColorModeValue('blue.300', 'blue.300');
  const tabTextActiveColor = useColorModeValue('_brand.800', 'white');

  return (
    <Flex flexDirection={'column'} gap={'24px'}>
      {(entityType === RelatedPartyEntityType.BUYER || !isHideRelationRadioButton) && (
        <Grid columnGap={'24px'} rowGap={'16px'} gridTemplateColumns={{ sm: '1fr', md: '1fr 1fr' }}>
          {!isHideRelationRadioButton && (
            <RadioButton<RelatedPartyRelationType>
              items={[
                { value: RelatedPartyRelationType.PERSON, label: t('relatedParties.person') },
                { value: RelatedPartyRelationType.COMPANY, label: t('relatedParties.company') },
                ...(entityType === RelatedPartyEntityType.SELLER
                  ? [{ value: RelatedPartyRelationType.HEIR, label: t('relatedParties.heir') }]
                  : []),
              ]}
              value={form.relationType}
              onChange={e => dispatch({ type: 'setRelationType', value: e })}
            />
          )}

          {entityType === RelatedPartyEntityType.BUYER && (
            <Checkbox
              isChecked={form.isFiador}
              onChange={e => dispatch({ type: 'setIsFiador', value: !form.isFiador })}
            >
              {t('relatedParties.isFiador')}
            </Checkbox>
          )}
        </Grid>
      )}

      {form.relationType === RelatedPartyRelationType.COMPANY && isShowCompanyImportByCode ? (
        <Tabs size="md" variant="enclosed" onChange={i => onTabIndexChange?.(i)}>
          <TabList>
            <Tab
              fontSize={'14px'}
              _selected={{
                color: tabTextActiveColor,
                borderColor: tabBorderActiveColor,
                borderBottomColor: tabBorderBottomActiveColor,
              }}
            >
              {t('relatedParties.addPartyDataByCertidaoCommercial')}
            </Tab>
            <Tab
              fontSize={'14px'}
              _selected={{
                color: tabTextActiveColor,
                borderColor: tabBorderActiveColor,
                borderBottomColor: tabBorderBottomActiveColor,
              }}
            >
              {t('relatedParties.addPartyByAllDataManually')}
            </Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <FormControl isRequired={true}>
                <FormLabel>{t('pages.details.registoComercialAccessCode')}</FormLabel>
                <Input
                  placeholder={t('code')}
                  value={certidaoAccessCode}
                  onChange={event => onCertidaoCodeChange?.(event.target.value ?? '')}
                />
              </FormControl>
            </TabPanel>

            <TabPanel>
              <MainForm form={form} dispatch={dispatch} entityType={entityType} />
            </TabPanel>
          </TabPanels>
        </Tabs>
      ) : (
        <Box>
          <MainForm form={form} dispatch={dispatch} entityType={entityType} />
        </Box>
      )}
    </Flex>
  );
};
