// Chakra imports
import { Button, Flex, useColorModeValue } from '@chakra-ui/react';
// Assets
import { DropzoneOptions, useDropzone } from 'react-dropzone';

interface DropzoneProps {
  content: JSX.Element | string;
  onDrop?: DropzoneOptions['onDrop'];

  [x: string]: any;
}

function Dropzone(props: DropzoneProps) {
  const { content, onDrop, ...rest } = props;
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
  const bg = useColorModeValue('gray.100', 'navy.700');
  const borderColor = useColorModeValue('secondaryGray.100', 'whiteAlpha.100');
  const borderColorActive = useColorModeValue('brand.500', 'white');

  return (
    <Flex
      align="center"
      justify="center"
      bg={bg}
      border={isDragActive ? '1px solid' : '1px dashed'}
      borderColor={isDragActive ? borderColorActive : borderColor}
      borderRadius="16px"
      w="100%"
      h="max-content"
      minH="100%"
      cursor="pointer"
      {...getRootProps({ className: 'dropzone' })}
      {...rest}
    >
      <input {...getInputProps()} />
      <Button variant="no-effects">{content}</Button>
    </Flex>
  );
}

export default Dropzone;
