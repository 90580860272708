import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Flex,
  Text,
  Icon,
} from '@chakra-ui/react';
import { useColorModeValue } from '@chakra-ui/system';
import { useTranslation } from 'react-i18next';
import { ErrorInSync } from '../icons/icons';

export interface ErrorModalProps {
  isOpen: boolean;
  onClose: () => void;
  error: string;
}

export const ErrorModal = ({ isOpen, onClose, error }: ErrorModalProps) => {
  const { t } = useTranslation();

  const textColor = useColorModeValue('navy.750', 'white');

  return (
    <>
      <Modal size="lg" isOpen={isOpen} onClose={onClose} isCentered scrollBehavior="inside">
        <ModalOverlay />
        <ModalContent color={textColor} p="24px" borderRadius="16px">
          <ModalHeader p="0" fontSize="24px">
            {t('error')}
          </ModalHeader>
          <ModalCloseButton right="24px" top="none" />
          <ModalBody p="24px 1px">
            <Flex flexDirection="column" align="center" gap={'24px'}>
              <Icon as={ErrorInSync} color="brand.500" boxSize="64px" />
              <Text textAlign={'center'} fontWeight={'bold'} fontSize={'20px'}>
                {error}
              </Text>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
