import { Avatar, Box, Flex, Image, Text, useColorModeValue } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import './profile-banner.css';

interface BannerProps {
  banner: string;
  avatar?: string;
  mainTitle: string;
  subtitle?: string;
  jobs: number | string;
  balance: number;
  files: number | string;
  [x: string]: any;
}

export default function Banner({ banner, avatar, mainTitle, subtitle, jobs, balance, files, ...rest }: BannerProps) {
  const { t } = useTranslation();

  // Chakra Color Mode
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
  const textColorSecondary = 'gray.400';
  const borderColor = useColorModeValue('white !important', '#111C44 !important');

  const stats = [
    {
      label: t('pages.profile.jobs'),
      value: jobs,
    },
    {
      label: t('pages.profile.currentBalance'),
      value: `${balance.toFixed(2)} \u20AC`,
    },
    {
      label: t('pages.profile.files'),
      value: files,
    },
  ];

  return (
    <Flex direction="column" mb="20px" w="100%" alignItems="center" {...rest}>
      <Box borderRadius="16px" h="131px" w="100%">
        <Image src={banner} alt="banner" w="100%" h="100%" borderRadius="16px" objectFit="cover" />
      </Box>

      <Avatar
        mx="auto"
        src={avatar}
        name={mainTitle}
        h="87px"
        w="87px"
        mt="-43px"
        border="4px solid"
        borderColor={borderColor}
      />

      <Text color={textColorPrimary} fontWeight="bold" fontSize="xl" mt="10px">
        {mainTitle}
      </Text>

      {subtitle && (
        <Text color={textColorSecondary} fontSize="sm">
          {subtitle}
        </Text>
      )}

      <Flex mx="auto" gap={{ base: '12px', md: '60px' }} mt="26px" flexWrap="wrap">
        {stats.map((stat, index) => (
          <Flex key={index} alignItems="center" flexDirection="column" maxW="100px" textAlign="center">
            <Text color={textColorPrimary} fontSize="2xl" fontWeight="700">
              {stat.value}
            </Text>
            <Text color={textColorSecondary} fontSize="sm" fontWeight="400">
              {stat.label}
            </Text>
          </Flex>
        ))}
      </Flex>
    </Flex>
  );
}
