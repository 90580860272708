import { RelatedParty, RelatedPartyEntityType, RelatedPartyRelationType } from 'api';
import { useCallback, useMemo, useState } from 'react';
import { useRelatedParties } from '../../views/admin/job/widgets/AddContractsWidget/useRelatedParties';

export interface RelatedPartyOverride {
  id: string;
  nif?: string;
}

export const useRightOfFirstRefusalRelatedParties = (jobId: string) => {
  const { buyers: buyersRaw, sellers: sellersRaw, loadBuyers, loadSellers } = useRelatedParties(jobId, true);
  const [buyersOverride, setBuyersOverride] = useState<Record<string, RelatedPartyOverride>>({});
  const [sellersOverride, setSellersOverride] = useState<Record<string, RelatedPartyOverride>>({});

  const buyers = useMemo(
    () =>
      buyersRaw
        .reduce((acc, i) => {
          const items = [...acc];

          if (
            !i.relatedPartyId ||
            (i.relatedPartyId &&
              buyersRaw.find(j => j.id === i.relatedPartyId)?.relationType === RelatedPartyRelationType.COMPANY)
          ) {
            items.push({ ...i, nif: buyersOverride[i.id]?.nif ?? i.nif });
          }

          return items;
        }, [] as RelatedParty[])
        .slice(0, 11),
    [buyersRaw, buyersOverride],
  );
  const sellers = useMemo(() => {
    return sellersRaw
      .reduce((acc, i) => {
        const items = [...acc];

        // if it's a company or a person without a related party
        if (
          (!i.relatedPartyId && i.relationType === RelatedPartyRelationType.PERSON) ||
          (!i.relatedPartyId && i.relationType === RelatedPartyRelationType.COMPANY)
        ) {
          items.push({ ...i, nif: sellersOverride[i.id]?.nif ?? i.nif });
        }

        return items;
      }, [] as RelatedParty[])
      .slice(0, 11);
  }, [sellersOverride, sellersRaw]);

  const heirs = useMemo(() => {
    // contains all the herdeiros from all the heranças
    return sellersRaw
      .reduce((acc, i) => {
        const items = [...acc];

        // if it's a heir, get all the nested related parties
        if (i.relationType === RelatedPartyRelationType.HEIR) {
          for (const j of i.nestedRelatedParties ?? []) {
            items.push({ ...j, nif: sellersOverride[j.id]?.nif ?? j.nif });
          }
        }
        return items;
      }, [] as RelatedParty[])
      .slice(0, 11);
  }, [sellersOverride, sellersRaw]);

  const heranças = useMemo(() => {
    // contains all the heranças only
    return sellersRaw
      .reduce((acc, i) => {
        const items = [...acc];
        if (!i.relatedPartyId && i.relationType === RelatedPartyRelationType.HEIR) {
          items.push({ ...i, nif: sellersOverride[i.id]?.nif ?? i.nif });
        }
        return items;
      }, [] as RelatedParty[])
      .slice(0, 11);
  }, [sellersOverride, sellersRaw]);

  const load = useCallback(async () => await Promise.all([loadBuyers(), loadSellers()]), [loadBuyers, loadSellers]);

  const onNifChange = useCallback(
    (entityType: RelatedPartyEntityType, override: RelatedPartyOverride) => {
      if (entityType === RelatedPartyEntityType.BUYER) {
        setBuyersOverride({ ...buyersOverride, [override.id]: override });
      } else {
        setSellersOverride({ ...sellersOverride, [override.id]: override });
      }
    },
    [buyersOverride, sellersOverride],
  );

  return {
    buyers,
    sellers,
    heirs,
    heranças,
    load,
    onNifChange,
  };
};
