import { CivilStatus, MarriageRegime, RelatedPartyAge, RelatedPartyIDDocumentType } from 'api/related-parties';

export const tByCivilStatus: Record<CivilStatus, string> = {
  [CivilStatus.SINGLE]: 'relatedParties.civilStatuses.single',
  [CivilStatus.MARRIED]: 'relatedParties.civilStatuses.married',
  [CivilStatus.DIVORCED]: 'relatedParties.civilStatuses.divorced',
  [CivilStatus.WIDOWED]: 'relatedParties.civilStatuses.widowed',
};

export const tByMarriageRegime: Record<MarriageRegime, string> = {
  [MarriageRegime.SEPARATION_OF_ASSETS]: 'relatedParties.marriageRegimes.separationOfAssets',
  [MarriageRegime.COMMUNION_OF_ACQUIRED]: 'relatedParties.marriageRegimes.communionOfAcquired',
  [MarriageRegime.GENERAL_COMMUNION]: 'relatedParties.marriageRegimes.generalCommunion',
};

export const tByRelatedPartyIDDocumentType: Record<RelatedPartyIDDocumentType, string> = {
  [RelatedPartyIDDocumentType.ID_CARD]: 'relatedParties.documentTypes.idCard',
  [RelatedPartyIDDocumentType.PASSPORT]: 'relatedParties.documentTypes.passport',
  [RelatedPartyIDDocumentType.RESIDENCE_PERMIT]: 'relatedParties.documentTypes.residencePermit',
};

export const relatedPartyIDDocumentTypeByValue: Record<string, RelatedPartyIDDocumentType> = {
  'Cartão de Cidadão': RelatedPartyIDDocumentType.ID_CARD,
  Passaporte: RelatedPartyIDDocumentType.PASSPORT,
  'Cartão de Residência': RelatedPartyIDDocumentType.RESIDENCE_PERMIT,
};

export const valueByRelatedPartyIDDocumentType: Record<RelatedPartyIDDocumentType, string> = {
  [RelatedPartyIDDocumentType.ID_CARD]: 'Cartão de Cidadão',
  [RelatedPartyIDDocumentType.PASSPORT]: 'Passaporte',
  [RelatedPartyIDDocumentType.RESIDENCE_PERMIT]: 'Cartão de Residência',
};

export const tByRelatedPartyAge: Record<RelatedPartyAge, string> = {
  [RelatedPartyAge.MAIOR]: 'relatedParties.ages.maior',
  [RelatedPartyAge.MENOR]: 'relatedParties.ages.menor',
};

export const isShowMarriageRegime = (civilStatus?: CivilStatus) => civilStatus && civilStatus === CivilStatus.MARRIED;
