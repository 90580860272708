import { SelectionMenu, SelectionMenuOption } from 'libs/ui/atoms/src';
import { useTranslation } from 'react-i18next';

const LANGUAGES_OPTIONS: SelectionMenuOption[] = [
  {
    title: 'EN',
    value: 'en',
  },
  {
    title: 'PT',
    value: 'pt',
  },
];

export const LanguageSelector = () => {
  const { i18n } = useTranslation();

  const changeLanguage = (lang: string) => {
    i18n.changeLanguage(lang);
  };

  return <SelectionMenu value={i18n.resolvedLanguage || ''} options={LANGUAGES_OPTIONS} onChange={changeLanguage} />;
};
