import { Box, CloseButton, Flex, Link, Text, useColorModeValue, useDisclosure } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { useCallback, useState } from 'react';
import { Trans } from 'react-i18next';
import { useVersionCheck } from './useVersionCheck';
import { Information } from '../../libs/ui/atoms/src';

const ANIMATION_DURATION = 200;

export const RefreshOnNewChangesToast = () => {
  const [isShowRefreshPageToast, setIsShowRefreshPageToast] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();

  useVersionCheck({
    onVersionChange: () => {
      setIsShowRefreshPageToast(true);
      onOpen();
    },
  });

  const close = useCallback(() => {
    onClose();
    setTimeout(() => setIsShowRefreshPageToast(false), ANIMATION_DURATION);
  }, [onClose]);

  const textColor = useColorModeValue('brand.800', 'white');
  const linkTextColor = useColorModeValue('navy.900', 'white');
  const bg = useColorModeValue('blue.50', 'navy.750');
  const shadow = useColorModeValue(
    '14px 17px 40px 8px rgba(112, 144, 176, 0.32)',
    '14px 17px 40px 8px rgba(112, 144, 176, 0.06)',
  );

  return isShowRefreshPageToast ? (
    <motion.div
      initial={{ opacity: 0, y: 50 }}
      animate={isOpen ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
      transition={{ duration: ANIMATION_DURATION / 1000 }}
    >
      <Box position={'absolute'} bottom={'16px'} left={'16px'}>
        <Flex boxShadow={shadow} bg={bg} borderRadius={'16px'} alignItems={'center'} gap={'16px'} p="12px 16px">
          <Information w="24px" h="24px" color={textColor} />
          <Text fontSize="14px" textAlign="center">
            <Trans
              i18nKey="nevVersionAvailableRefreshPageLink"
              components={{
                refreshAction: (
                  <Link fontWeight={'bold'} textDecoration={'underline'} href={`.`} textColor={linkTextColor}></Link>
                ),
              }}
            ></Trans>
          </Text>
          <CloseButton onClick={close} />
        </Flex>
      </Box>
    </motion.div>
  ) : null;
};
