import { useTypedSelector } from 'store';
import { useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import { Box, Flex, useToast } from '@chakra-ui/react';
import { JobTable, TabKind, tabKinds } from './components/job-table';
import {
  Job,
  JobStatus,
  useArchiveJobMutation,
  useDeleteJobMutation,
  useLazyGetJobsPrivateQuery,
  UserRole,
  useUnarchiveJobMutation,
} from '../../../api';
import { SortingState } from '@tanstack/react-table';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useDidUpdateEffect } from 'hooks/useDidUpdateEffect';
import { jobArchived, jobDeleted, jobUnarchived, organizationChanged, sortChanged, tabChanged } from 'store/job';

const take = 20;

interface JobsListFilter {
  sorting: SortingState;
  skip: number;
  status?: JobStatus | null;
  lastUpdatedAt?: Date | null;
}

const filterActionByTab: Record<TabKind, () => JobsListFilterActions> = {
  [TabKind.RECENT]: () => {
    const d = new Date();
    d.setDate(d.getDate() - 3);
    return { lastUpdatedAt: d };
  },
  [TabKind.THIS_WEEK]: () => {
    const d = new Date();
    d.setDate(d.getDate() - 7);
    return { lastUpdatedAt: d };
  },
  [TabKind.ALL]: () => ({ lastUpdatedAt: null }),
  [TabKind.ARCHIVED]: () => ({ status: JobStatus['job is archived'] }),
};

type JobsListFilterActions =
  | { sorting: SortingState }
  | { isNextPage: true }
  | { skip: number }
  | { status?: JobStatus | null }
  | { lastUpdatedAt?: Date | null };

export function JobListPage() {
  const { t } = useTranslation();
  const toast = useToast();
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const jobs = useTypedSelector(state => state.job.jobs ?? []);
  const jobsTotalCount = useTypedSelector(state => state.job.jobsTotalCount ?? []);
  const user = useTypedSelector(state => state.auth.user);

  const isShowOwnerColumn = useMemo(() => !!user?.roles?.includes(UserRole.MASTER_USER), [user]);

  const [jobsFilter, dispatchJobsFilter] = useReducer(
    (state: JobsListFilter, action: JobsListFilterActions) => {
      if ('isNextPage' in action) {
        return { ...state, skip: jobs.length };
      }
      if ('sorting' in action) {
        return { ...state, skip: 0, sorting: action.sorting };
      }
      if ('lastUpdatedAt' in action) {
        return { ...state, skip: 0, status: undefined, lastUpdatedAt: action.lastUpdatedAt };
      }
      if ('status' in action) {
        return { ...state, skip: 0, status: action.status, lastUpdatedAt: undefined };
      }
      return state;
    },
    { sorting: [], skip: 0, status: undefined, lastUpdatedAt: undefined },
  );
  const [isNeedToFetchNextPage, setIsNeedToFetchNextPage] = useState(false);
  const jobsSorting = jobsFilter.sorting;
  const skip = jobsFilter.skip;
  const status = jobsFilter.status;
  const lastUpdatedAt = jobsFilter.lastUpdatedAt;

  const jobsColumnSort = jobsSorting[0];
  const orderBy = jobsColumnSort?.id;
  const orderDirection = jobsColumnSort ? (jobsColumnSort?.desc ? 'DESC' : 'ASC') : undefined;

  const [archiveJob, {}] = useArchiveJobMutation();
  const [deleteJob, {}] = useDeleteJobMutation();
  const [unarchiveJob, {}] = useUnarchiveJobMutation();
  const [fetchJobs, { isLoading, isFetching }] = useLazyGetJobsPrivateQuery({
    refetchOnReconnect: true,
    refetchOnFocus: true,
  });

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    if (searchParams.get('tab')) {
      return;
    }
    searchParams.set('tab', TabKind.RECENT);
    navigate({ pathname: window.location.pathname, search: searchParams.toString() });
  }, [navigate, location.search]);

  useEffect(() => {
    if (status === undefined && lastUpdatedAt === undefined) {
      return;
    }

    fetchJobs({
      skip,
      take,
      orderBy,
      orderDirection,
      status: status ?? undefined,
      lastUpdatedAt: lastUpdatedAt ?? undefined,
    });
  }, [skip, orderBy, orderDirection, status, lastUpdatedAt, fetchJobs]);

  const organizationId = useTypedSelector(state => state.auth.organizationId);

  // Reset fetchJobs when organizationId changed
  useDidUpdateEffect(() => {
    // Use setTimeout, because it needs to refetch after JobPrivateApiUtil.resetApiState() in auth0.initializer.ts
    dispatchJobsFilter({ skip: 0, sorting: [] });
    setTimeout(() => {
      dispatch(organizationChanged());
      fetchJobs({
        skip: 0,
        take,
        orderBy,
        orderDirection,
        status: status ?? undefined,
        lastUpdatedAt: lastUpdatedAt ?? undefined,
      });
    }, 0);
  }, [organizationId]);

  const [tabIndex, setTabIndex] = useState(0);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const currentTab = searchParams.get('tab') as TabKind;
    const currentTabIndex = tabKinds.findIndex(i => i === currentTab);
    setTabIndex(typeof currentTabIndex === 'number' && currentTabIndex !== -1 ? currentTabIndex : 0);
    dispatch(tabChanged());
    if (currentTab) {
      dispatchJobsFilter(filterActionByTab[currentTab]());
    }
  }, [dispatch, location]);

  const onChangeTab = useCallback(
    (index: number) => {
      const searchParams = new URLSearchParams(location.search);
      searchParams.set('tab', tabKinds[index]);
      navigate({ pathname: window.location.pathname, search: searchParams.toString() });
    },
    [location, navigate],
  );

  const fetchNextPage = useCallback(() => {
    console.log('fetchNextPage');
    if (isFetching || isLoading) {
      setIsNeedToFetchNextPage(true);
      return;
    }
    dispatchJobsFilter({ isNextPage: true });
  }, [isLoading, isFetching]);

  const onClickJob = useCallback(
    (job: Job) => {
      if (!job || !job.id) {
        console.error('Invalid application state! Not found jobId.');
        return;
      }
      navigate(`/jobs/${job.id}`);
    },
    [navigate],
  );

  const onJobArchive = useCallback(
    (job: Job) => {
      if (!job || !job.id) {
        console.error('Invalid application state! Not found jobId.');
        return;
      }

      const isArchived = job.status === JobStatus['job is archived'];
      const isDraft = job.status === JobStatus.Draft;
      const resultPromise = isArchived
        ? unarchiveJob({ jobId: job.id })
        : isDraft
          ? deleteJob({ jobId: job.id })
          : archiveJob({ jobId: job.id });
      resultPromise
        .then(async data => {
          toast({
            title: t(
              `pages.jobs.actions.${
                isArchived ? 'unarchivedSuccessfully' : isDraft ? 'deletedSuccessfully' : 'archivedSuccessfully'
              }`,
            ),
            status: 'success',
            duration: 1000,
          });
          dispatch(isArchived ? jobUnarchived({ job }) : isDraft ? jobDeleted({ job }) : jobArchived({ job }));
        })
        .catch(e => toast({ title: `Error: ${e}`, status: 'error', duration: 1000 }));
    },
    [skip, take, orderBy, orderDirection, status],
  );

  if (isNeedToFetchNextPage) {
    dispatchJobsFilter({ isNextPage: true });
    setIsNeedToFetchNextPage(false);
  }

  if (isLoading) {
    return <Box>...{t('loading')}</Box>;
  }

  return (
    <Flex direction="column" gap="24px">
      <JobTable
        tabIndex={tabIndex}
        jobs={jobs}
        sorting={jobsSorting}
        onChangeTab={onChangeTab}
        onSortingChange={updaterOrValue => {
          if (typeof updaterOrValue === 'function') {
            updaterOrValue = updaterOrValue(jobsSorting);
          }
          dispatchJobsFilter({ sorting: updaterOrValue });
          setTimeout(() => dispatch(sortChanged()));
        }}
        fetchNextPage={fetchNextPage}
        isFetching={isFetching}
        totalCount={jobsTotalCount}
        onClickJob={onClickJob}
        onArchiveClick={onJobArchive}
        isShowOwnerColumn={isShowOwnerColumn}
      />
    </Flex>
  );
}
