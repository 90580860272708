import { useEffect } from 'react';
import { Flex, Text, useBreakpointValue, useColorModeValue } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { useLazyGetJobPrivateQuery, useLazyGetJobPublicQuery } from '../../api';
import { useMemo, useState } from 'react';
import { ArrowRightIcon, CubeIcon } from '../../components/icons/Icons';
import { Loading } from 'components/loading/Loading';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'store';
import { jobSelected, jobUnselected } from 'store/job';
import { JobShareButton } from 'components/jobShareButton/JobShareButton';
import { DownloadAllFilesButton } from 'components/downloadAllFilesButton/DownloadAllFilesButton';
import { DocumentsViewer } from 'components/documentsViewer/DocumentsViewer';
import { DocumentCard, ResultDocumentType } from '../../components/card/DocumentCard';

const DOCUMENT_SKIP_PREVIEW = ['certidão_permanente_registo_comercial'];

interface JobResultPageErrorProps {
  error: any;
}
function JobResultPageError({ error }: JobResultPageErrorProps) {
  return (
    <Flex gap="16px" align="center">
      <Text color="#2B3674" fontStyle="normal" fontWeight={700} lineHeight="42px">
        {error ? 'Something went wrong: ' + error.data?.message : 'Not found job result'}
      </Text>
    </Flex>
  );
}

interface JobResultPageProps {
  isPrivate?: boolean;
}

function JobResultPage({ isPrivate }: JobResultPageProps) {
  const { t, i18n } = useTranslation();

  const { jobId, language: urlLanguage } = useParams<{ jobId: string; language?: string }>();

  const dispatch = useAppDispatch();

  const textColor = useColorModeValue('navy.750', 'white');
  const cardBackground = useColorModeValue('white', 'navy.800');
  const cardHighlightedBackground = useColorModeValue('orange.200', 'whiteAlpha.300');
  const fileCardButtonSize = useBreakpointValue({ base: 'xs', md: 'sm' });

  const [currentUrl, setCurrentUrl] = useState<string>('');

  const [getPublicJob, { isLoading: isGettingPublicJob, error: errorGetPublicJob, data: publicJob }] =
    useLazyGetJobPublicQuery({});

  const [getPrivateJob, { isLoading: isGettingPrivateJob, error: errorGetPrivateJob, data: privateJob }] =
    useLazyGetJobPrivateQuery({});

  useEffect(() => {
    if (!jobId) {
      return;
    }
    if (isPrivate) {
      getPrivateJob({ jobId });
    } else {
      getPublicJob({ jobId });
    }
  }, []);

  useEffect(() => {
    if (urlLanguage) {
      i18n.changeLanguage(urlLanguage === 'pt' ? 'pt' : 'en');
    }
  }, [urlLanguage]);

  const isLoading = () => isGettingPrivateJob || isGettingPublicJob;

  const isError = () => {
    if (isPrivate) {
      return errorGetPrivateJob || !privateJob;
    }

    return errorGetPublicJob || !publicJob;
  };

  const getError = () => {
    if (isPrivate) {
      return errorGetPrivateJob;
    }

    return errorGetPublicJob;
  };

  const handleChangePDFfile = (url: string) => {
    setCurrentUrl(url);
  };

  useEffect(() => {
    const job = isPrivate ? privateJob : publicJob;
    if (!job) {
      return;
    }

    dispatch(jobSelected({ job }));

    return () => {
      dispatch(jobUnselected());
    };
  }, [privateJob, publicJob]);

  const docs = useMemo(() => {
    const data = isPrivate ? privateJob : publicJob;

    if (!data) {
      return [];
    }

    const documents =
      data.documents
        ?.filter(i => !DOCUMENT_SKIP_PREVIEW.includes(i.type))
        ?.map(i => ({
          name: i.name,
          url: i.url,
          type: ResultDocumentType.INPUT,
        })) || [];

    const jobResultFiles = isPrivate ? privateJob?.jobResult?.jobResultFiles : publicJob?.jobResult;

    const jobResults =
      jobResultFiles
        ?.map(i => ({
          name: i.name,
          url: i.url,
          type: ResultDocumentType.OUTPUT,
        }))
        ?.sort(a => (a.name?.includes('VeriCasa Property Report') ? -1 : 1)) || [];

    return [...jobResults, ...documents];
  }, [privateJob, publicJob]);

  useEffect(() => {
    if (docs.length > 0) {
      setCurrentUrl(docs[0].url);
    }
  }, [docs]);

  if (isLoading()) {
    return <Loading />;
  }

  return (
    <Flex direction="column" gap="24px" w="100%">
      {isError() ? (
        <JobResultPageError error={getError()} />
      ) : (
        <>
          {/* Public Result Header */}
          {publicJob && (
            <Flex gap="16px" align="center" flexWrap="wrap" justifyContent={'space-between'}>
              <Flex align="center">
                <CubeIcon color={textColor} w="24px" h="24px" />
                <Text color={textColor} fontStyle="normal" fontWeight={700} lineHeight="42px">
                  {publicJob?.name}
                </Text>
                <ArrowRightIcon w="24px" h="24px" />
                <Text color={textColor} fontStyle="normal" fontWeight={700} lineHeight="42px">
                  {t('result')}
                </Text>
              </Flex>

              <Flex align="center" gap="16px">
                <DownloadAllFilesButton />
                <JobShareButton />
              </Flex>
            </Flex>
          )}

          <DocumentsViewer
            previewUrl={currentUrl}
            documentCards={docs.map(document => (
              <DocumentCard
                key={document.name}
                name={document.name}
                url={document.url}
                type={document.type}
                onClick={() => handleChangePDFfile(document.url)}
              />
            ))}
          />
        </>
      )}
    </Flex>
  );
}

export function JobResultPublicPage() {
  return <JobResultPage isPrivate={false} />;
}

export function JobResultPrivatePage() {
  return <JobResultPage isPrivate={true} />;
}
