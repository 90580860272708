import { Button, Text, useColorModeValue } from '@chakra-ui/react';
import { ReactElement } from 'react';

export interface PillButtonProps {
  leftIconChildren?: ReactElement;
  text: string;
  onClick: () => void;
}

export function PillButton({ leftIconChildren, text, onClick }: PillButtonProps) {
  const textColor = useColorModeValue('gray.700', 'white');
  const buttonBackground = useColorModeValue('gray.360', 'whiteAlpha.200');

  return (
    <Button
      title={text}
      pe={'12px'}
      size="xs"
      leftIcon={leftIconChildren}
      color={textColor}
      bg={buttonBackground}
      border={`1px solid`}
      borderColor={buttonBackground}
      _hover={{ borderColor: textColor }}
      onClick={onClick}
    >
      <Text textOverflow={'ellipsis'} overflow={'hidden'} maxW={'100%'}>
        {text}
      </Text>
    </Button>
  );
}
