import {
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { HelpTicket } from '../tickets';
import { useTranslation } from 'react-i18next';

export interface HelpCardProps {
  ticket: HelpTicket;
  isSelected: boolean;
  onClick?: () => void;
}

export const HelpCard = ({ ticket, isSelected, onClick }: HelpCardProps) => {
  const { title, icon, explanationTitle, explanationSteps } = ticket;

  const { t } = useTranslation();

  const bgColor = useColorModeValue('white', 'navy.750');
  const textColor = useColorModeValue('blue.800', 'gray.200');
  const textColorHover = useColorModeValue('blue.500', 'gray.200');
  const cardBorderColor = useColorModeValue('_gray.200', 'navy.800');
  const cardBorderHighlighColor = useColorModeValue('blue.500', 'gray.200');

  const modalBg = useColorModeValue('white', 'navy.800');
  const stepBgSecondary = useColorModeValue('gray.50', 'navy.700');
  const stepBg = useColorModeValue('white', 'navy.800');
  const stepsNumberColor = useColorModeValue('_gray.500', 'gray.200');
  const stepsColor = useColorModeValue('_gray.600', 'gray.200');

  return (
    <Flex
      bg={bgColor}
      padding={'24px'}
      gap="16px"
      alignItems={'center'}
      border="1px solid"
      borderRadius={'16px'}
      borderColor={isSelected ? cardBorderHighlighColor : cardBorderColor}
      textColor={textColor}
      cursor="pointer"
      _hover={{ borderColor: cardBorderHighlighColor, textColor: textColorHover }}
      transition={'all 0.2s'}
      onClick={onClick}
    >
      {icon}
      <Text fontWeight={'bold'} fontSize={'16px'} lineHeight={'21px'}>
        {t(title)}
      </Text>
    </Flex>
  );
};
