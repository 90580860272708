import { Box, BoxProps, useColorModeValue, useRadio, UseRadioProps } from '@chakra-ui/react';

export interface VCSelectButtonItemProps extends UseRadioProps {
  isFirst?: boolean;
  isLast?: boolean;

  text: string;
  boxItemProps?: BoxProps;
  boxItemTextProps?: BoxProps;
}

export const VCSelectButtonItem = (props: VCSelectButtonItemProps) => {
  const { isFirst, isLast, text, boxItemProps, boxItemTextProps } = props;
  const { getInputProps, getCheckboxProps } = useRadio(props);

  const input = getInputProps();
  const checkbox = getCheckboxProps();

  const borderColor = useColorModeValue('gray.400', 'white');
  const textColor = useColorModeValue('gray.600', 'white');
  const colorChecked = useColorModeValue('_brand.550', '_brand.550');

  return (
    <Box as="label" m="0" marginInline="0 !important" {...boxItemProps}>
      <input {...input} />
      <Box
        {...checkbox}
        height="26px"
        cursor="pointer"
        borderWidth="1px"
        borderColor={borderColor}
        color={textColor}
        _checked={{
          bg: colorChecked,
          color: 'white',
          borderColor: colorChecked,
        }}
        _focus={{
          boxShadow: 'outline',
        }}
        fontSize="14px"
        lineHeight="18px"
        fontWeight="bold"
        p="4px 16px"
        m="0"
        borderLeftRadius="0"
        borderRightRadius="0"
        {...(!isFirst && { borderLeft: 'none' })}
        {...(isLast && { borderTopRightRadius: '16px', borderBottomRightRadius: '16px' })}
        {...(isFirst && { borderTopLeftRadius: '16px', borderBottomLeftRadius: '16px' })}
        {...boxItemTextProps}
      >
        {text}
      </Box>
    </Box>
  );
};
