import { Trans, useTranslation } from 'react-i18next';
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useBreakpointValue,
  useColorModeValue,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { useCallback, useEffect, useState } from 'react';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { initCreateRelatedPartyData, useRelatedPartyForm } from './useRelatedPartyForm';
import {
  RelatedParty,
  RelatedPartyCreateDto,
  RelatedPartyEntityType,
  RelatedPartyRelationType,
  useCreateRelatedPartyMutation,
  useRelatedPartySubmitByCodeMutation,
} from 'api/related-parties';
import { MutateRelatedPartyForm } from './MutateRelatedPartyForm';
import { PersonAdd } from 'libs/ui/atoms/src';
import {
  CertidaoRegistoComercialRelationType,
  useAddCertidaoRegistoComercialWithAccessCodeMutation,
} from '../../../../../../api';
import {
  DocumentsValidationErrorTypeEnum,
  ModalDocumentsValidationError,
} from '../../JobDocumentsWidget/DocumentValidationModals';

export enum CreateRelatedPartyButtonType {
  PRIMARY = 'PRIMARY',
  SECONDARY = 'SECONDARY',
}

const relationByEntityType: Record<RelatedPartyEntityType, CertidaoRegistoComercialRelationType> = {
  [RelatedPartyEntityType.BUYER]: CertidaoRegistoComercialRelationType.BUYER,
  [RelatedPartyEntityType.SELLER]: CertidaoRegistoComercialRelationType.SELLER,
};

export interface CreateRelatedPartyButtonProps {
  type?: CreateRelatedPartyButtonType;
  jobId: string;
  entityType: RelatedPartyEntityType;
  parentRelatedParty?: RelatedParty;

  relatedPartyId?: string;
  relationType?: RelatedPartyRelationType;

  onSubmit: (data: RelatedPartyCreateDto) => Promise<void>;
  onCodeSubmit?: () => void;

  isModalOpen?: boolean; // External prop for modal open/close
  onModalClose?: React.Dispatch<React.SetStateAction<boolean>>;

  onOpenDropzone?: (relatedParty?: RelatedParty) => void;
}

export const CreateRelatedPartyButton = ({
  type = CreateRelatedPartyButtonType.PRIMARY,
  jobId,
  entityType,
  relatedPartyId,
  relationType,
  parentRelatedParty,
  onSubmit,
  onCodeSubmit,

  isModalOpen,
  onModalClose,
  onOpenDropzone,
}: CreateRelatedPartyButtonProps) => {
  const { t } = useTranslation();
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const isShowButtonText = useBreakpointValue({ base: true, sm: false, md: true, lg: true, xl: true });

  const { form, dispatch, validate } = useRelatedPartyForm();
  const [isLoading, setIsLoading] = useState(false);

  const [certidaoAccessCode, setCertidaoAccessCode] = useState<string>('');
  const [errorModalAdditionalText, setErrorModalAdditionalText] = useState<string | undefined>();
  const [isShowErrorModal, setIsShowErrorModal] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);

  const [submitByCode, { isLoading: isLoadingSubmitByCode }] = useRelatedPartySubmitByCodeMutation();
  const [addCertidaoRegistoComercialWithAccessCode, { isLoading: isLoadingCertidaoRegistoComercialWithAccessCode }] =
    useAddCertidaoRegistoComercialWithAccessCodeMutation({});
  const [createRelatedParty] = useCreateRelatedPartyMutation();

  const close = useCallback(() => {
    dispatch({
      type: 'setInitialState',
      value: { ...initCreateRelatedPartyData, relatedPartyId, ...(relationType ? { relationType } : {}) },
    });
    if (onModalClose) {
      onModalClose(false);
    }
    onClose();
    setTabIndex(0);
    setCertidaoAccessCode('');
  }, [dispatch, relatedPartyId, relationType, onModalClose, onClose, setCertidaoAccessCode]);

  useEffect(() => {
    dispatch({
      type: 'setInitialState',
      value: { ...initCreateRelatedPartyData, relatedPartyId, ...(relationType ? { relationType } : {}) },
    });
  }, [dispatch, relatedPartyId, relationType]);

  const submitCertidaoCode = useCallback(
    async (code: string) => {
      try {
        setErrorModalAdditionalText(undefined);
        const addedDocuments = await addCertidaoRegistoComercialWithAccessCode({
          jobId,
          dto: { code, relationType: relationByEntityType[entityType] },
        }).unwrap();
        return addedDocuments[0];
      } catch (e: any) {
        console.error('Failed to add Certidao Registo Comercial with access code', code, e);
        if (
          e.data?.message?.includes('format is incorrect') ||
          e.data?.message?.includes('Não existe qualquer certidão activa com esse número.') ||
          e.data?.message?.includes('Não existe qualquer certidão com esse número.')
        ) {
          setErrorModalAdditionalText(e.data?.message);
        }

        setIsShowErrorModal(true);
      }
    },
    [addCertidaoRegistoComercialWithAccessCode, entityType, jobId],
  );

  const onSubmitByCode = useCallback(async () => {
    const document = await submitCertidaoCode(certidaoAccessCode);
    if (!document) return;

    const newParty = await createRelatedParty({
      entityType,
      jobId,
      relationType: RelatedPartyRelationType.COMPANY,
      relatedPartyId,
    }).unwrap();

    await submitByCode({ id: newParty.id, fileId: (document as any)?.inputFile?.id, code: certidaoAccessCode });
    onCodeSubmit?.();
    close();
  }, [
    certidaoAccessCode,
    close,
    createRelatedParty,
    entityType,
    jobId,
    onCodeSubmit,
    relatedPartyId,
    submitByCode,
    submitCertidaoCode,
  ]);

  const onSubmitClick = useCallback(async () => {
    if (tabIndex === 0 && form.relationType === RelatedPartyRelationType.COMPANY) {
      if (isLoadingCertidaoRegistoComercialWithAccessCode) return;
      try {
        setIsLoading(true);
        await onSubmitByCode();
      } finally {
        setIsLoading(false);
      }
      return;
    }

    const validationMessages = validate();
    if (validationMessages.length > 0) {
      toast({
        title: t('validationError'),
        description: validationMessages.map(i => t(i)).join(', \n'),
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    try {
      setIsLoading(true);
      await onSubmit({
        ...(form as RelatedPartyCreateDto),
        phone: form?.phone ? '+' + form.phone : undefined,
        jobId,
        entityType,
      });

      close();
    } catch (error) {
      toast({
        title: t('relatedParties.unableToCreateRelatedParty'),
        description: ((error as FetchBaseQueryError).data as Error)?.message,
        status: 'error',
        duration: 5000,
      });
    } finally {
      setIsLoading(false);
    }
  }, [
    tabIndex,
    validate,
    isLoadingCertidaoRegistoComercialWithAccessCode,
    onSubmitByCode,
    toast,
    t,
    onSubmit,
    form,
    jobId,
    entityType,
    close,
  ]);

  const buttonTextColor = useColorModeValue('white', 'white');
  const textColor = useColorModeValue('navy.750', 'white');
  const addButtonColor = useColorModeValue('brand.500', 'brand.500');
  const actionTextColor = useColorModeValue('navy.900', 'white');

  return (
    <>
      {type !== CreateRelatedPartyButtonType.PRIMARY ? (
        <Button
          size={'xs'}
          leftIcon={<PersonAdd w="12px" h="12px" />}
          borderRadius={'16px'}
          color={'gray.700'}
          p={'0 12px'}
          bg={type === CreateRelatedPartyButtonType.SECONDARY ? 'green.200' : addButtonColor}
          _hover={{ bg: type === CreateRelatedPartyButtonType.SECONDARY ? 'green.300' : 'navy.600' }}
          onClick={e => {
            e.stopPropagation();
            onOpen();
          }}
        >
          {t('add')}
        </Button>
      ) : (
        <></>
      )}

      <Modal
        size="xl"
        isOpen={
          type === CreateRelatedPartyButtonType.SECONDARY ? isOpen : isModalOpen !== undefined ? isModalOpen : false
        }
        onClose={close}
        isCentered
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent color={textColor} maxH={'calc(100% - 0rem)'} maxW={'900px'} p="24px" borderRadius="16px">
          <ModalHeader p="0" fontSize="24px">
            {t(entityType === RelatedPartyEntityType.BUYER ? 'relatedParties.addBuyer' : 'relatedParties.addSeller')}
          </ModalHeader>
          <ModalCloseButton right="24px" top="none" />
          <ModalBody onClick={e => e.stopPropagation()} p=" 0 1px 24px 1px">
            <Text mb={'16px'} fontSize="14px">
              <Trans
                i18nKey="relatedParties.clickHereToImportDataFromIDsInsteadOfFillingEverythingInManually"
                components={{
                  action: (
                    <Text
                      as={'span'}
                      fontWeight={'bold'}
                      textDecoration={'underline'}
                      cursor={'pointer'}
                      textColor={actionTextColor}
                      onClick={() => {
                        onOpenDropzone?.(parentRelatedParty);
                        close();
                      }}
                    ></Text>
                  ),
                }}
              ></Trans>
            </Text>

            <MutateRelatedPartyForm
              entityType={entityType}
              isHideRelationRadioButton={!!relatedPartyId}
              isShowCorpAuthority={
                relationType === RelatedPartyRelationType.PERSON &&
                parentRelatedParty?.relationType === RelatedPartyRelationType.COMPANY
              }
              form={form}
              dispatch={dispatch}
              certidaoAccessCode={certidaoAccessCode}
              onCertidaoCodeChange={setCertidaoAccessCode}
              onTabIndexChange={setTabIndex}
              isShowCompanyImportByCode
            />
          </ModalBody>

          <ModalFooter justifyContent="center" gap="24px" p="0">
            <Button
              isLoading={isLoading || isLoadingSubmitByCode}
              colorScheme="brand"
              onClick={onSubmitClick}
              color={buttonTextColor}
              maxWidth="200px"
              w="100%"
              h="46px"
              fontSize="14px"
              borderRadius="100px"
            >
              {t('add')}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <ModalDocumentsValidationError
        jobId={jobId}
        errorType={isShowErrorModal ? DocumentsValidationErrorTypeEnum.CertidaoRegistoComercialAccessCodeInvalid : null}
        errorModalAdditionalText={errorModalAdditionalText}
        onClose={() => setIsShowErrorModal(false)}
      />
    </>
  );
};
