import { Flex, Text } from '@chakra-ui/react';
import { useColorModeValue } from '@chakra-ui/system';

export interface TooltipItem {
  isSecondaryColor?: boolean;
  label: string;
  value?: string;
  valueIconChildren?: React.ReactNode;
}

export const TooltipRow = ({ label, value, valueIconChildren, isSecondaryColor }: TooltipItem) => {
  const labelColor = useColorModeValue('gray.500', 'white');
  const valueColor = useColorModeValue('gray.600', 'white');
  const bgSecondary = useColorModeValue('gray.50', 'navy.700');
  const bg = useColorModeValue('white', 'navy.800');

  return (
    <Flex gap={'12px'} bg={isSecondaryColor ? bgSecondary : bg} p="4px 8px" alignItems={'center'}>
      <Text w={'152px'} fontSize={'12px'} color={labelColor}>
        {label}
      </Text>

      {valueIconChildren ? (
        <Flex w={'100%'} flex={1} alignItems={'center'} gap={'4px'}>
          {valueIconChildren}
          <Text
            title={value}
            fontSize={'12px'}
            fontWeight={500}
            color={valueColor}
            textOverflow="ellipsis"
            overflow="hidden"
            whiteSpace="nowrap"
            flex={1}
          >
            {value}
          </Text>
        </Flex>
      ) : (
        <Text
          title={value}
          fontSize={'14px'}
          fontWeight={500}
          color={valueColor}
          textOverflow="ellipsis"
          overflow="hidden"
          whiteSpace="nowrap"
          flex={1}
        >
          {value}
        </Text>
      )}
    </Flex>
  );
};
