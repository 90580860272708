import React from 'react';
import { Button, Flex, Icon, Text } from '@chakra-ui/react';
import { useColorModeValue } from '@chakra-ui/system';
import { Job, JobStatus } from 'api';
import { ApproveTick } from 'libs/ui/atoms/src';
import { Link, useMatch } from 'react-router-dom';
import { formatDate } from 'utils/date';
import { iconByJobStatus, iconColorByJobStatus, jobStatusTranslateByJobStatus } from '../../variables/job-status';
import { useTranslation } from 'react-i18next';
import { DocumentOutFilledIcon } from '../../../../../components/icons/Icons';

interface JobDetailsExtraDataProps {
  job: Job;
}
export function JobDetailsExtraData({ job }: JobDetailsExtraDataProps) {
  const { t } = useTranslation();

  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
  const dateTextColor = useColorModeValue('navy.750', 'white');
  const buttonColor = useColorModeValue('navy.750', 'navy.750');

  const isJobPage = useMatch('/jobs/:jobId');

  return (
    <Flex direction="column" gap="8px">
      <Flex justify="space-between" gap="24px">
        {job.status && (
          <Flex justify="space-between" align="center">
            <Icon
              w="24px"
              h="24px"
              me="5px"
              color={iconColorByJobStatus[job.status]}
              as={iconByJobStatus[job.status]}
            />

            <Text color={textColor} fontSize="sm" fontWeight="700">
              {t(jobStatusTranslateByJobStatus[job.status])}
            </Text>
          </Flex>
        )}
        {(job.updatedAt || job.createdAt) && (
          <Text color={dateTextColor}>{formatDate(job.updatedAt || job.createdAt)}</Text>
        )}
      </Flex>

      <Flex justify="flex-end">
        {isJobPage && (
          <Link to={`/jobs/${job.id}/contracts-and-parties`}>
            <Button
              leftIcon={<DocumentOutFilledIcon color={'white'} w="24px" h="24px" />}
              borderRadius={'100px'}
              color={'white'}
              h="40px"
              borderEndRadius={'10px'}
              borderStartRadius={'10px'}
              bg={buttonColor}
              _hover={{ bg: 'navy.600' }}
              isDisabled={job.status !== JobStatus['Job is completed']}
              marginRight="10px"
            >
              {t('routes.contracts&parties')}
            </Button>
          </Link>
        )}

        <Link to={`/job-result/${job.id}`}>
          <Button
            leftIcon={<ApproveTick />}
            borderRadius={'100px'}
            color={'white'}
            h="40px"
            borderEndRadius={'10px'}
            borderStartRadius={'10px'}
            bg={buttonColor}
            _hover={{ bg: 'navy.600' }}
            isDisabled={job.status !== JobStatus['Job is completed']}
          >
            {t('result')}
          </Button>
        </Link>
      </Flex>
    </Flex>
  );
}
