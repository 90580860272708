import { useToast } from '@chakra-ui/react';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useDeleteUserMutation,
  useLazyOrganizationUsersQuery,
  UserSetRolesDto,
  UserUpdateDto,
  useSetUserRolesMutation,
  useUpdateUserMutation,
  useUserResendInviteMutation,
} from '../../../../../../api';
import { useTypedSelector } from '../../../../../../store';

export const useOrgUsers = () => {
  const toast = useToast();
  const { i18n } = useTranslation();

  const [search, setSearch] = useState('');
  const [isDisabled, setIsDisabled] = useState(false);

  const organizationId = useTypedSelector(state => state.auth.organizationId);

  const [getUsers, { data: users, isLoading }] = useLazyOrganizationUsersQuery();
  const [updateUser] = useUpdateUserMutation();
  const [deleteUser] = useDeleteUserMutation();
  const [resendInvite] = useUserResendInviteMutation();
  const [setRoles] = useSetUserRolesMutation();

  const onLoad = useCallback(
    async (term?: string) => {
      try {
        await getUsers({ search: term ?? search });
      } catch (error) {
        toast({
          title: 'Failed to load users',
          description: ((error as any).data as Error)?.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    },
    [getUsers, search, toast],
  );

  useEffect(() => {
    onLoad();
  }, []);

  const onUpdate = useCallback(
    async (data: UserUpdateDto) => {
      try {
        setIsDisabled(true);
        await updateUser(data);
        onLoad();
      } catch (error) {
        toast({
          title: 'Failed to update users',
          description: ((error as any).data as Error)?.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      } finally {
        setIsDisabled(false);
      }
    },
    [onLoad, toast, updateUser],
  );

  const onDelete = useCallback(
    async (id: string) => {
      try {
        setIsDisabled(true);
        await deleteUser({ id });
        onLoad();
      } catch (error) {
        toast({
          title: 'Failed to update users',
          description: ((error as any).data as Error)?.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      } finally {
        setIsDisabled(false);
      }
    },
    [deleteUser, onLoad, toast],
  );

  const onRolesChange = useCallback(
    async (data: UserSetRolesDto) => {
      try {
        setIsDisabled(true);
        await setRoles(data);
        onLoad();
      } catch (error) {
        toast({
          title: 'Failed to update user roles',
          description: ((error as any).data as Error)?.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      } finally {
        setIsDisabled(false);
      }
    },
    [onLoad, setRoles, toast],
  );

  const onSearch = (term: string) => {
    setSearch(term);
    onLoad(term);
  };

  const onResendInvite = useCallback(
    async (id: string) => {
      try {
        setIsDisabled(true);
        await resendInvite({ userId: id, locale: i18n.language.toUpperCase() });
      } catch (error) {
        toast({
          title: 'Failed to resend invite users',
          description: ((error as any).data as Error)?.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      } finally {
        setIsDisabled(false);
      }
    },
    [i18n.language, resendInvite, toast],
  );

  return { isLoading, isDisabled, users, onLoad, onUpdate, onDelete, search, onSearch, onResendInvite, onRolesChange };
};
