import { api } from './api';

export interface Template {
  id: string;
  url: string;
  exampleUrl: string;
  nameEn: string;
  namePt: string;
  descriptionEn: string;
  descriptionPt: string;
  changeHistoryEn: string;
  changeHistoryPt: string;
}

const templatesApi = api.injectEndpoints({
  endpoints: build => ({
    getTemplates: build.query<Template[], unknown>({ query: () => `document-template` }),
  }),
});

export const { useGetTemplatesQuery, useLazyGetTemplatesQuery, endpoints: TemplateEndpoints } = templatesApi;
