import * as React from 'react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button as ButtonChakra,
  Flex,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Table,
  Tag,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import Card from 'components/card/Card';
import Dropzone from 'views/admin/job/widgets/JobDocumentsWidget/Dropzone';
import { MdUpload } from 'react-icons/md';
import { ApproveTick, ExclamationMark, RecycleBing } from 'libs/ui/atoms/src/lib/icons/icons';
import { useTypedSelector } from '../../../../../store';
import {
  CertidaoRegistoComercialRelationType,
  DocumentStatus,
  DocumentTranslatedCategoryByType,
  DocumentType,
  DocumentTypesOrdered,
  JobDocument,
  JobDocumentUpdateDto,
  useAddCadernetaWithAccessCodeMutation,
  useAddCertidaoRegistoComercialWithAccessCodeMutation,
  useAddCertidaoWithAccessCodeMutation,
  useCreateJobDocumentMutation,
  useDeleteJobDocumentMutation,
  useLazyGetJobDocumentsQuery,
  useUpdateJobDocumentMutation,
  useUploadJobDocumentMutation,
} from '../../../../../api';
import { selectJobDocuments } from '../../../../../store/job';
import { ReloadIcon } from 'components/icons/Icons';
import InputCell from 'components/table/InputCell';
import {
  CadernetaValidationData,
  DocumentsValidationErrorTypeEnum,
  ModalCadernetaValidation,
  ModalCertidaoPerdialValidation,
  ModalDocumentsValidationError,
  ModalDocumentTypeValidationError,
} from './DocumentValidationModals';
import { ModalCertidaoRegistoComercialValidation } from './DocumentValidationModals/ModalCertidaoRegistoComercialValidation';

const columnHelper = createColumnHelper<JobDocument>();

const ColumnsWidth = ['5%', '55%', '20%', '20%'];
const ColumnsMinWidth = ['0', '250px', '0', '0'];

const IconByStatus: Record<DocumentStatus, JSX.Element> = {
  [DocumentStatus.INITIALIZED]: <ApproveTick color="green.200" boxSize={6} />,
  [DocumentStatus.CREATED]: <ReloadIcon />,
  [DocumentStatus.ERRORED]: <ExclamationMark color="red.500" boxSize={6} />,
  [DocumentStatus.FATAL]: <ExclamationMark color="red.500" boxSize={6} />,
  [DocumentStatus.UNACCEPTABLE]: <ExclamationMark color="orange.500" boxSize={6} />,
  [DocumentStatus.PROPERTY_ID_MISMATCH]: <ExclamationMark color="orange.500" boxSize={6} />,
};

interface JobDocumentsWidgetProps {
  jobId: string;
}

export function JobDocumentsWidget({ jobId }: JobDocumentsWidgetProps) {
  const { t } = useTranslation();

  const [isCertidaoModalOpen, setIsCertidaoModalOpen] = React.useState(false);
  const [isCadernetaModalOpen, setIsCadernetaModalOpen] = React.useState(false);
  const [isCertidaoRegistoComercialModalOpen, setIsCertidaoRegistoComercialModalOpen] = React.useState(false);
  const [documentsValidationError, setDocumentsValidationError] =
    React.useState<DocumentsValidationErrorTypeEnum | null>(null);
  const [errorModalAdditionalText, setErrorModalAdditionalText] = React.useState<string | undefined>();
  const [isErrorDocumentTypeOpen, setIsErrorDocumentTypeOpen] = React.useState(false);

  const jobDocuments = useTypedSelector(state => selectJobDocuments(state, jobId));
  const [createJobDocument] = useCreateJobDocumentMutation({});
  const [uploadJobDocument] = useUploadJobDocumentMutation({});
  const [deleteJobDocument] = useDeleteJobDocumentMutation({});
  const [updateJobDocument] = useUpdateJobDocumentMutation({});
  const [getJobDocuments] = useLazyGetJobDocumentsQuery();
  const [addCertidaoWithAccessCode, { isLoading: isLoadingCertidaoWithAccessCode }] =
    useAddCertidaoWithAccessCodeMutation({});
  const [addCadernetaWithAccessCode, { isLoading: isLoadingCadernetaWithAccessCode }] =
    useAddCadernetaWithAccessCodeMutation({});
  const [addCertidaoRegistoComercialWithAccessCode, { isLoading: isLoadingCertidaoRegistoComercialWithAccessCode }] =
    useAddCertidaoRegistoComercialWithAccessCodeMutation({});

  const pollDocuments = async (pollingDocuments: JobDocument[]) => {
    if (pollingDocuments?.length === 0) {
      return;
    }

    const documents = await getJobDocuments({ ids: pollingDocuments.map(d => d.id) }).unwrap();

    if (documents.some(d => d.status === DocumentStatus.FATAL)) {
      setDocumentsValidationError(DocumentsValidationErrorTypeEnum.FatalError);
    }

    if (documents.some(d => d.status === DocumentStatus.UNACCEPTABLE)) {
      setDocumentsValidationError(DocumentsValidationErrorTypeEnum.CertidaoIsUnacceptable);
    }

    if (documents.some(d => d.status === DocumentStatus.PROPERTY_ID_MISMATCH)) {
      setDocumentsValidationError(DocumentsValidationErrorTypeEnum.CertidaoPropertyIdMismatch);
    }

    const newPollingDocuments = documents.filter(d => d.status === DocumentStatus.CREATED && !d.type);

    if (newPollingDocuments.length > 0) {
      setTimeout(() => pollDocuments(newPollingDocuments), 700);
    }
  };

  const [sorting, setSorting] = React.useState<SortingState>([]);
  const grayTextColor = useColorModeValue('gray.500', 'white');
  const textColor = useColorModeValue('navy.750', 'white');
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
  const brandColor = useColorModeValue('brand.500', 'white');
  const contextMenuTextColor = useColorModeValue('gray.600', 'white');

  const uploadFile = useCallback(
    async (acceptedFiles: File[]) => {
      console.debug('Handle files', acceptedFiles);
      const newDocuments: JobDocument[] = [];

      await Promise.all(
        acceptedFiles.map(async file => {
          try {
            const jobDocumentResult = await createJobDocument({ name: file.name, jobId }).unwrap();
            const formData = new FormData();
            formData.set('inputFile', file);
            const uploadedDocument = await uploadJobDocument({ id: jobDocumentResult.id, formData }).unwrap();
            newDocuments.push(uploadedDocument);
          } catch (e) {
            console.error('Fail upload file', file, e);
          }
        }),
      );

      pollDocuments(newDocuments);
    },
    [createJobDocument, uploadJobDocument],
  );

  const deleteFile = async (id: string) => {
    try {
      await deleteJobDocument({ id });
    } catch (e) {
      console.error('Fail delete file', id, e);
    }
  };

  const updateDocument = async (dto: Partial<JobDocumentUpdateDto>, document: JobDocument) => {
    try {
      if (dto.type && Object.values(DocumentType).includes(dto.type as DocumentType)) {
        setIsErrorDocumentTypeOpen(true);
      } else {
        await updateJobDocument({
          jobId,
          id: document.id,
          name: dto.name ?? document.name,
          type: dto.type ?? document.type,
        });
      }
    } catch (e) {
      console.error('Fail update file', document.id, e);
    }
  };

  const addCertidaoWithAccessCodeHandler = async (accessCode: string) => {
    try {
      setErrorModalAdditionalText(undefined);
      const addedDocuments = await addCertidaoWithAccessCode({
        jobId,
        dto: {
          code: accessCode,
        },
      }).unwrap();
      pollDocuments(addedDocuments);
    } catch (e: any) {
      console.error('Failed to add Certidao with access code', accessCode, e);
      if (
        e.data?.message?.includes('format is incorrect') ||
        e.data?.message?.includes('A certidão permanente indicada expirou o prazo de validade.')
      ) {
        setErrorModalAdditionalText(e.data?.message);
      }

      setDocumentsValidationError(DocumentsValidationErrorTypeEnum.CertidaoAccessCodeInvalid);
    }
  };

  const addCertidaoRegistoComercialWithAccessCodeHandler = async (
    accessCode: string,
    relationType: CertidaoRegistoComercialRelationType,
  ) => {
    try {
      setErrorModalAdditionalText(undefined);
      const addedDocuments = await addCertidaoRegistoComercialWithAccessCode({
        jobId,
        dto: { code: accessCode, relationType },
      }).unwrap();
      pollDocuments(addedDocuments);
    } catch (e: any) {
      console.error('Failed to add Certidao Registo Comercial with access code', accessCode, e);
      if (
        e.data?.message?.includes('format is incorrect') ||
        e.data?.message?.includes('Não existe qualquer certidão activa com esse número.') ||
        e.data?.message?.includes('Não existe qualquer certidão com esse número.')
      ) {
        setErrorModalAdditionalText(e.data?.message);
      }

      setDocumentsValidationError(DocumentsValidationErrorTypeEnum.CertidaoRegistoComercialAccessCodeInvalid);
    }
  };

  const addCadernetaWithAccessCodeHandler = async (data: CadernetaValidationData) => {
    try {
      const addedDocuments = await addCadernetaWithAccessCode({
        jobId,
        dto: {
          codeIssuerTIN: data.issuingNIF,
          codeValidation: data.validationCode,
        },
      }).unwrap();
      pollDocuments(addedDocuments);
    } catch (e) {
      console.error('Failed to add Caderneta with access code', data, e);
      setDocumentsValidationError(DocumentsValidationErrorTypeEnum.CadernetaAccessCodeInvalid);
    }
  };

  const columns = [
    columnHelper.accessor('status', {
      id: 'status',
      header: () => null,
      cell: info => <Flex align="center">{IconByStatus[info.getValue()]}</Flex>,
    }),
    columnHelper.accessor('name', {
      id: 'name',
      header: () => (
        <Flex justifyContent="center" alignItems="center" gap="4px">
          <Text justifyContent="space-between" align="center" fontSize={{ sm: '10px', lg: '12px' }} color="gray.400">
            {t('pages.details.fileName')}
          </Text>
          <ChevronDownIcon w="12px" h="24px" />
        </Flex>
      ),
      cell: info => {
        const document = info.table.getRow(info.row.id).original;

        const isFatalError = document.status === DocumentStatus.FATAL;
        const isUnacceptable = document.status === DocumentStatus.UNACCEPTABLE;
        const isChangeable = !document.isInitialized && !isFatalError && !isUnacceptable;

        if (!isChangeable) {
          return (
            <Flex alignItems="center">
              {isFatalError && (
                <Tag
                  color="red.400"
                  borderRadius="100px"
                  borderWidth="1px"
                  fontSize="10px"
                  borderColor="red.400"
                  bg="transparent"
                  p="5px"
                >
                  {t('pages.details.brokenFile')}
                </Tag>
              )}
              {isUnacceptable && (
                <Tag
                  color="orange.500"
                  borderRadius="100px"
                  borderWidth="1px"
                  fontSize="10px"
                  borderColor="orange.500"
                  bg="transparent"
                  p="5px"
                >
                  {t('pages.details.unacceptable')}
                </Tag>
              )}
              <Text p="16px" fontSize="14px" color={grayTextColor}>
                {info.getValue()}
              </Text>
            </Flex>
          );
        }

        return (
          <InputCell
            placeholder="Document name"
            initialValue={info.getValue()}
            onChange={value => updateDocument({ name: value }, document)}
          />
        );
      },
    }),
    columnHelper.accessor('id', {
      id: 'type',
      header: () => (
        <Flex justifyContent="center" alignItems="center" gap="4px">
          <Text justifyContent="space-between" align="center" fontSize={{ sm: '10px', lg: '12px' }} color="gray.400">
            {t('pages.details.category')}
          </Text>
          <ChevronDownIcon w="12px" h="24px" />
        </Flex>
      ),
      cell: info => {
        const document = info.table.getRow(info.row.id).original;

        const isChangeable =
          !document.isInitialized &&
          document.status !== DocumentStatus.FATAL &&
          document.status !== DocumentStatus.UNACCEPTABLE;

        const categoryTitle =
          t(DocumentTranslatedCategoryByType[document.type as DocumentType]) || t('pages.details.categories.documents');

        if (!isChangeable) {
          return (
            <ButtonChakra color={textColor} variant="outline" size="xs">
              {categoryTitle}
            </ButtonChakra>
          );
        }

        return (
          <Menu>
            <MenuButton as={ButtonChakra} size="xs" rightIcon={<ChevronDownIcon />} color={textColor} variant="outline">
              {categoryTitle}
            </MenuButton>

            <MenuList borderRadius="8px" minW="70px" maxH={'350px'} overflowY="auto" color={contextMenuTextColor}>
              {Object.values(DocumentTypesOrdered)
                .filter(v => v !== document.type)
                .map(documentType => (
                  <MenuItem
                    key={`category-${documentType}`}
                    borderRadius="8px"
                    fontSize="14px"
                    onClick={() => updateDocument({ type: documentType }, document)}
                  >
                    {t(DocumentTranslatedCategoryByType[documentType])}
                  </MenuItem>
                ))}
            </MenuList>
          </Menu>
        );
      },
    }),
    columnHelper.accessor('id', {
      id: 'action',
      header: () => (
        <Flex justifyContent="center" alignItems="center" gap="4px">
          <Text justifyContent="space-between" align="center" fontSize={{ sm: '10px', lg: '12px' }} color="gray.400">
            {t('pages.details.action')}
          </Text>
          <ChevronDownIcon w="12px" h="24px" />
        </Flex>
      ),
      cell: info => (
        <Flex align="center" gap="16px">
          <ButtonChakra
            size="xs"
            leftIcon={<RecycleBing boxSize={4} color={'red'} />}
            variant="outline"
            color={textColor}
            borderColor={borderColor}
            _hover={{ bg: 'red.400', color: 'white' }}
            onClick={() => deleteFile(info.getValue())}
          >
            {t('pages.details.delete')}
          </ButtonChakra>
        </Flex>
      ),
    }),
  ];

  const table = useReactTable({
    data: jobDocuments,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  return (
    <>
      <Card flexDirection="column" w="100%" p="0">
        <Flex direction="column" gap="24px">
          <Text color={textColor} fontWeight="700">
            {t('pages.details.step2Title')}
          </Text>

          <Flex gap="24px" direction={{ base: 'column', md: 'row' }}>
            <Dropzone
              w="100%"
              h="auto"
              maxH={{ base: '60%', lg: '50%', '2xl': '100%' }}
              minH={{ base: '125px', lg: '50%', '2xl': '100%' }}
              onDrop={uploadFile}
              content={
                <Box>
                  <Icon as={MdUpload} w="32px" h="32px" color={brandColor} />
                  <Flex direction="column" justify="center" mx="auto" mb="12px">
                    <Text fontSize="xl" fontWeight="700" color={brandColor}>
                      {t('pages.details.uploadFiles')}
                    </Text>
                    <Text color="secondaryGray.500" fontSize="12px" fontWeight="500" lineHeight="20px">
                      {t('pages.details.youCanDragAndDrop')}
                    </Text>
                  </Flex>
                </Box>
              }
            />
            <Flex direction="column" justifyContent="space-between" gap="12px">
              <ModalCadernetaValidation
                callback={addCadernetaWithAccessCodeHandler}
                isLoading={isLoadingCadernetaWithAccessCode}
                isOpen={isCadernetaModalOpen}
                onOpen={() => setIsCadernetaModalOpen(true)}
                onClose={() => setIsCadernetaModalOpen(false)}
              />
              <ModalCertidaoPerdialValidation
                callback={addCertidaoWithAccessCodeHandler}
                isLoading={isLoadingCertidaoWithAccessCode}
                isOpen={isCertidaoModalOpen}
                onOpen={() => setIsCertidaoModalOpen(true)}
                onClose={() => setIsCertidaoModalOpen(false)}
              />
              <ModalCertidaoRegistoComercialValidation
                callback={addCertidaoRegistoComercialWithAccessCodeHandler}
                isLoading={isLoadingCertidaoRegistoComercialWithAccessCode}
                isOpen={isCertidaoRegistoComercialModalOpen}
                onOpen={() => setIsCertidaoRegistoComercialModalOpen(true)}
                onClose={() => setIsCertidaoRegistoComercialModalOpen(false)}
              />
              <ModalDocumentTypeValidationError
                isOpen={isErrorDocumentTypeOpen}
                onClose={() => setIsErrorDocumentTypeOpen(false)}
              />
            </Flex>
          </Flex>
        </Flex>

        <Box position="relative" w="100%" minH="200px" mt="24px">
          <Table variant="simple" color="gray.500">
            <Thead>
              {table.getHeaderGroups().map(headerGroup => (
                <Tr key={headerGroup.id}>
                  {headerGroup.headers.map((header, idx) => {
                    return (
                      <Th
                        key={header.id}
                        colSpan={header.colSpan}
                        p="0 8px 4px 8px"
                        w={ColumnsWidth[idx]}
                        minW={ColumnsMinWidth[idx]}
                        borderColor={borderColor}
                        cursor="pointer"
                        textTransform="capitalize"
                        onClick={header.column.getToggleSortingHandler()}
                      >
                        <Flex
                          justifyContent="space-between"
                          align="center"
                          fontSize={{ sm: '10px', lg: '12px' }}
                          color="gray.400"
                        >
                          {flexRender(header.column.columnDef.header, header.getContext())}
                          {{
                            asc: '',
                            desc: '',
                          }[header.column.getIsSorted() as string] ?? null}
                        </Flex>
                      </Th>
                    );
                  })}
                </Tr>
              ))}
            </Thead>

            <Tbody>
              {table.getRowModel().rows.map(row => {
                return (
                  <Tr key={row.id} h="48px">
                    {row.getVisibleCells().map((cell, idx) => {
                      return (
                        <Td
                          key={cell.id}
                          fontSize={{ sm: '14px' }}
                          borderColor="transparent"
                          p="0"
                          paddingRight="16px"
                          w={ColumnsWidth[idx]}
                          minW={ColumnsMinWidth[idx]}
                        >
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </Td>
                      );
                    })}
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </Box>
      </Card>

      <ModalDocumentsValidationError
        jobId={jobId}
        errorType={documentsValidationError}
        errorModalAdditionalText={errorModalAdditionalText}
        onCadernetaButtonClick={() => setIsCadernetaModalOpen(true)}
        onCertidaoButtonClick={() => setIsCertidaoModalOpen(true)}
        onClose={() => setDocumentsValidationError(null)}
      />
    </>
  );
}
