import { Select, useColorModeValue } from '@chakra-ui/react';

export interface SelectInputItem<T = string> {
  value: T;
  label: string;
}

export interface SelectInputProps<T = string> {
  items: SelectInputItem[];
  value?: T;
  placeholder?: string;
  isReadonly?: boolean;
  isDisabled?: boolean;
  onChange: (value: T) => void;
  onBlur?: () => void;
}

export function SelectInputInputField<T>({
  items,
  isReadonly,
  isDisabled,
  value,
  placeholder,
  onChange,
  onBlur,
}: SelectInputProps<T>) {
  const textColor = useColorModeValue('navy.750', 'white');

  return (
    <Select
      height={'50px'}
      borderRadius={'10px'}
      isReadOnly={isReadonly}
      isDisabled={isDisabled}
      placeholder={`-- ${placeholder} --`}
      color={textColor}
      value={(value ?? '') as string}
      fontFamily="DM Sans"
      onChange={e => onChange(e.target.value as T)}
      onBlur={onBlur}
    >
      {items.map(item => (
        <option style={{ fontFamily: 'DM Sans' }} key={item.value} value={item.value}>
          {item.label}
        </option>
      ))}

      {value && !items.map(i => i.value).includes(value as string) && (
        <option style={{ fontFamily: 'DM Sans' }} key={value as string} value={value as string} disabled>
          {value as string}
        </option>
      )}
    </Select>
  );
}
