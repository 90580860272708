import { Auth0Provider, CacheLocation } from '@auth0/auth0-react';
import { environment } from 'environments/environment';
import { ReactNode } from 'react';

interface Props {
  children: ReactNode;
}

export function AuthProvider({ children }: Props) {
  return (
    <Auth0Provider
      domain={environment.domain}
      clientId={environment.clientId}
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: environment.audience,
        scope: environment.scope,
      }}
      cacheLocation={'localstorage' as CacheLocation}
      useRefreshTokens={true}
    >
      {children}
    </Auth0Provider>
  );
}
