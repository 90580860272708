import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, useColorModeValue } from '@chakra-ui/react';
import { Download } from 'libs/ui/atoms/src';
import { useTypedSelector } from 'store';
import { useMatch } from 'react-router-dom';

export const DownloadAllFilesButton = () => {
  const { t } = useTranslation();

  const selectedJob = useTypedSelector(state => state.job.selectedJob);
  const accessToken = useTypedSelector(state => state.auth.accessToken);
  const contextToken = useTypedSelector(state => state.auth.contextToken);

  const textColor = useColorModeValue('navy.750', 'white');
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
  const bgColor = useColorModeValue('#F4F7FE', 'whiteAlpha.200');
  const hoverBgColor = useColorModeValue('gray.200', 'whiteAlpha.300');

  const downloadAll = useCallback(async () => {
    if (!selectedJob || !selectedJob.publicId) {
      return;
    }

    const response = await fetch(selectedJob?.filesArchiveUrl, {
      method: 'GET',
    });

    const blobUrl = URL.createObjectURL(await response.blob());
    const link = document.createElement('a');
    link.href = blobUrl;
    link.setAttribute('download', `${selectedJob.name ?? 'vericasareport'}.zip`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(blobUrl);
  }, [selectedJob]);

  const isResultPage = useMatch('/job-result/:jobId');
  const isPublicPage = useMatch('/r/:jobId/:language?');

  return selectedJob?.filesArchiveUrl && (!!isResultPage || !!isPublicPage) ? (
    <Button
      size="xs"
      leftIcon={<Download boxSize={4} />}
      variant="outline"
      color={textColor}
      borderColor={borderColor}
      bgColor={bgColor}
      _hover={{ bg: hoverBgColor }}
      onClick={downloadAll}
    >
      {t('pages.details.downloadAll')}
    </Button>
  ) : (
    <></>
  );
};
