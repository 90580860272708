import { api } from './api';

export interface JobPublic {
  publicId: string;
  name: string;
  description: string;
  documents?: JobDocumentPublic[] | null;
  jobResult?: JobResultPublic[] | null;
  filesArchiveUrl: string;
}

export interface JobDocumentPublic {
  name: string;
  type: string;
  url: string;
}

export interface JobResultPublic {
  name: string;
  url: string;
}

export interface JobResultSendEmailDto {
  email: string;
  link: string;
  locale: string;
}

const jobPublicApi = api.injectEndpoints({
  endpoints: build => ({
    getJobPublic: build.query<JobPublic, { jobId: string }>({
      query: ({ jobId }) => `job/public/${jobId}`,
    }),
    sendJobResultEmail: build.mutation<unknown, JobResultSendEmailDto>({
      query: dto => ({
        url: 'job/public/sendResultEmail',
        body: dto,
        method: 'POST',
      }),
    }),
  }),
});

export const {
  useGetJobPublicQuery,
  useLazyGetJobPublicQuery,
  useSendJobResultEmailMutation,
  endpoints: JobPublicEndpoints,
} = jobPublicApi;
