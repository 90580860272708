import { useTranslation } from 'react-i18next';
import { Download } from '../../libs/ui/atoms/src';
import { DocumentOutFilledIcon } from '../icons/Icons';
import Card from './Card';
import { Badge, Button, Flex, Text, useBreakpointValue, useColorModeValue } from '@chakra-ui/react';

export enum ResultDocumentType {
  OUTPUT = 'OUTPUT',
  INPUT = 'INPUT',
  REPORT = 'REPORT',
}

export interface DocumentCardProps {
  name: string;
  url: string;
  type?: ResultDocumentType;
  /**
   * True by default
   */
  truncateText?: boolean;
  onClick?: () => void;
}

export function DocumentCard({ name, url, type, truncateText = true, onClick }: DocumentCardProps) {
  const { t } = useTranslation();

  const bgColor = useColorModeValue('white', 'whiteAlpha.300');
  const textColor = useColorModeValue('navy.750', 'white');
  const cardBackground = useColorModeValue('white', 'navy.800');
  const cardHighlightedBackground = useColorModeValue('orange.200', 'whiteAlpha.300');
  const fileCardButtonSize = useBreakpointValue({ base: 'xs', md: 'sm' });

  return (
    <Card
      position="static"
      bg={(name?.includes('VeriCasa Property Report') ? cardHighlightedBackground : cardBackground) ?? bgColor}
      p={{ base: '8px', md: '16px' }}
      display="flex"
      flexDirection="column"
      gap={{ base: '8px', md: '16px' }}
      borderWidth="1px"
      borderColor="#a3aed0"
      onClick={onClick}
    >
      <Flex align="center" justify="space-between" gap="8px">
        <Flex alignItems={'flex-end'} width={'50px'}>
          <Badge
            fontSize="10px"
            fontWeight="700"
            lineHeight="14px"
            variant="solid"
            colorScheme={type === ResultDocumentType.INPUT ? 'red' : 'green'}
            pl="8px"
            pr="8px"
            pt="0"
            pb="0"
            textTransform="none"
          >
            {t(type?.toLowerCase() ?? '')}
          </Badge>
        </Flex>

        <DocumentOutFilledIcon color={textColor} w="24px" h="24px" />

        <Text
          flex={1}
          fontSize={{ base: 'xs', md: 'md' }}
          fontWeight="700"
          lineHeight="normal"
          color={textColor}
          noOfLines={truncateText ? 2 : { base: 2, md: 0 }}
          title={name}
        >
          {name}
        </Text>

        <Button
          as="a"
          size={fileCardButtonSize}
          fontSize={{ base: 'xs', md: 'sm' }}
          borderRadius={'10px'}
          bg={'blue.500'}
          href={url}
          download={name}
          _hover={{ bg: 'blue.600' }}
          target="_blank"
          rel="noopener noreferrer"
          color={'white'}
          leftIcon={<Download boxSize={4} />}
        >
          {t('download')}
        </Button>
      </Flex>
    </Card>
  );
}
