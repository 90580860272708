import React from 'react';
import { Button as ButtonChakra, ButtonProps } from '@chakra-ui/react';

interface Props extends ButtonProps {
  children: React.ReactNode;
}

export const Button: React.FC<Props> = ({ children, ...rest }: Props) => {
  return (
    <ButtonChakra borderRadius="100px" colorScheme="brand" color="white" h="46px" size="sm" p="10px 75px" {...rest}>
      {children}
    </ButtonChakra>
  );
};
