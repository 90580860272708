import { useTranslation } from 'react-i18next';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Flex,
  Icon,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { JobProblemItem } from './JobProblemItem';
import { MdOutlineErrorOutline } from 'react-icons/md';
import { ResolvableErrorId, useJobProblems } from './useJobsProblems';
import { CertificateRenewalAction } from 'components/certificateRenewal/CertificateRenewalModal';

export interface JobProblemsAccordionProps {
  index?: number;
  jobId: string;
  onIndexChange: () => void;
}

export const JobProblemsAccordion = ({ index, jobId, onIndexChange }: JobProblemsAccordionProps) => {
  const { t } = useTranslation();

  const { problems } = useJobProblems({ jobId });

  const textColor = useColorModeValue('navy.750', 'white');
  const borderColor = useColorModeValue('red.500', 'red.500');
  const bgColor = useColorModeValue('white', 'navy.800');

  return !problems.length ? (
    <></>
  ) : (
    <Accordion
      index={index}
      allowMultiple
      allowToggle
      border={'1px solid'}
      borderColor={borderColor}
      borderRadius={'16px'}
      bg={bgColor}
    >
      <AccordionItem color={textColor} border={'none'}>
        <AccordionButton onClick={onIndexChange} p={'16px 24px 16px 24px'} _focus={{ boxShadow: 'none' }} _hover={{}}>
          <Flex flex="1" gap={'16px'} height={'50px'} alignItems={'center'}>
            <Icon w="24px" h="24px" me="5px" color={'red.500'} as={MdOutlineErrorOutline} />
            <Flex alignItems={'start'} flexDirection={'column'}>
              <Text fontSize={'14px'} fontWeight="bold">
                {t('pages.details.problemsThatNeedToBeSolved')}
              </Text>
              <Text fontSize={'13px'} opacity={0.6}>
                {t('pages.details.YouCanStillViewThePreviousReportByClickingTheResultButtonAbove')}
              </Text>
            </Flex>
          </Flex>
          <AccordionIcon />
        </AccordionButton>

        <AccordionPanel p="0 24px 16px 24px">
          <Flex flexDir={'column'} gap={'16px'}>
            {problems.map(p => (
              <JobProblemItem {...p} key={p.data.id}>
                {p.data.errorId === ResolvableErrorId.R98P01 && (
                  <CertificateRenewalAction jobId={jobId} problemId={p.data.id} />
                )}
              </JobProblemItem>
            ))}
          </Flex>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};
