import { useTranslation } from 'react-i18next';
import { useColorModeValue, Text, Flex } from '@chakra-ui/react';
import { JobProblem } from 'api';
import { ReactNode } from 'react';

export interface JobProblemItemProps {
  data: JobProblem;
  children?: ReactNode;
}

export const JobProblemItem = ({ data, children }: JobProblemItemProps) => {
  const { i18n } = useTranslation();

  const textColor = useColorModeValue('navy.750', 'navy.750');
  const bgColor = useColorModeValue('orange.600', 'orange.600');

  return (
    <Flex
      flexDir={{ sm: 'column', md: 'row' }}
      alignItems={'center'}
      bg={bgColor}
      w="100%"
      p={'16px'}
      gap={'16px'}
      borderRadius={'16px'}
      color={textColor}
    >
      <Flex flexDir={{ sm: 'column', md: 'row' }} flex={1} gap={'16px'}>
        <Text fontSize={'14px'} fontWeight="bold">
          {data.errorId}
        </Text>
        <Text
          dangerouslySetInnerHTML={{ __html: i18n.language?.toLowerCase().includes('pt') ? data.textPt : data.textEn }}
          flex={1}
          fontSize={'14px'}
        ></Text>
      </Flex>

      {children}
    </Flex>
  );
};
