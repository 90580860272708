import { Navigate, Route } from 'react-router-dom';
import { Loading } from 'components/loading/Loading';
import { useAuthInitializer } from './auth0.initializer';
import { AdminLayout } from '../layouts/admin';

const ProtectedRoute = () => {
  const { isLoading, isAuthenticated } = useAuthInitializer();

  if (isLoading && !isAuthenticated) {
    return <Loading />;
  }

  return isAuthenticated ? <AdminLayout /> : <Navigate to="/login" />;
};

export default ProtectedRoute;
