import { Input } from '@chakra-ui/react';

interface DetailInputFieldProps {
  isReadOnly?: boolean;
  value: string;
  placeholder: string;
  isInvalid?: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}
export function DetailInputField({ isReadOnly, value, placeholder, isInvalid, onChange }: DetailInputFieldProps) {
  return (
    <Input
      readOnly={isReadOnly}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      isInvalid={isInvalid}
      _invalid={{ borderColor: 'red.400' }}
      variant="auth"
      fontSize="sm"
      ms={{ base: '0px', md: '0px' }}
      type="text"
      fontWeight="500"
      size="lg"
    />
  );
}
