import { Box, Flex, FormControl, FormLabel, Text } from '@chakra-ui/react';
import { useColorModeValue } from '@chakra-ui/system';
import { useTranslation } from 'react-i18next';
import { Job } from 'api';

import { JobDetailsExtraData } from '../widgets/JobDetailsWidget/JobDetailsExtraData';
import { DetailInputField } from 'libs/ui/atoms/src/lib/input-field/InputField';
import { FieldState } from '../widgets/JobDetailsWidget/useJobForm';

export interface JobDetailsWidgetProps {
  isReadOnly?: boolean;
  job: Job;
  hideExtraData?: boolean;
  nameField: FieldState;
  descriptionField: FieldState;
  onNameUpdate: (value: string) => void;
  onDescriptionUpdate: (value: string) => void;
}

export function JobMainInfoWidget({
  isReadOnly,
  job,
  nameField,
  descriptionField,
  hideExtraData = false,
  onNameUpdate,
  onDescriptionUpdate,
}: JobDetailsWidgetProps) {
  const { t } = useTranslation();

  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const brandStars = useColorModeValue('brand.500', 'white');
  const widgetBackgroundColor = useColorModeValue('white', 'navy.800');

  return (
    <Flex direction="column" gap="24px" p={'24px'} bg={widgetBackgroundColor} borderRadius={'20px'}>
      <FormControl
        gap="24px"
        display="flex"
        justifyContent="space-between"
        position="static"
        flexDirection={{ base: 'column', md: 'row' }}
      >
        <Box flex={1}>
          <FormLabel display="flex" ms="4px" fontSize="sm" fontWeight="500" color={textColor} mb="8px">
            {t('pages.details.description')}
            <Text color={brandStars}>*</Text>
          </FormLabel>
          <DetailInputField
            isReadOnly={isReadOnly}
            value={nameField.value}
            onChange={event => onNameUpdate(event.target.value)}
            isInvalid={!!nameField.error}
            placeholder={t('pages.details.description')}
          />
          {nameField.error && <Text color="red.400">{nameField.error}</Text>}
        </Box>

        {!hideExtraData && (
          <Box order={{ base: -1, md: 0 }}>
            <JobDetailsExtraData job={job} />
          </Box>
        )}
      </FormControl>
    </Flex>
  );
}
