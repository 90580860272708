import { api } from './api';

export interface CertificateRenewalData {
  jobId: string;
  name: string;
  email: string;
  nif: string;
  location: string;
  address: string;
  postalCode: string;

  certidaoIdentifier: string;
  identifier_conservatória: string;
  identifier_parish: string;
  identifier_parish_id: string;
}

export interface CertificateRenewalResult {
  success: boolean;
}

export interface CertificateRenewalDto {
  jobId: string;
  problemId: string;

  name: string;
  email: string;
  nif: string;
  location: string;
  address: string;
  postalCode: string;

  certidaoIdentifier: string;
  identifier_parish_id: string;

  simulateRequest?: boolean;
}

const certificateRenewalApi = api.injectEndpoints({
  endpoints: build => ({
    getCertificateRenewalData: build.query<CertificateRenewalData, { jobId: string; problemId: string }>({
      query: ({ jobId, problemId }) => ({ url: `/job/${jobId}/getCertificateRenewalData/${problemId}` }),
    }),
    renewCertificate: build.mutation<CertificateRenewalResult, CertificateRenewalDto>({
      query: dto => ({ url: `/job/${dto.jobId}/renewCertificate`, method: 'POST', body: dto }),
    }),
  }),
});

export const {
  useLazyGetCertificateRenewalDataQuery,
  useRenewCertificateMutation,
  endpoints: CertificateRenewalEndpoints,
} = certificateRenewalApi;
