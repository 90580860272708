import { BoxProps, HStack, StackProps, useRadioGroup } from '@chakra-ui/react';
import { VCSelectButtonItem } from './SelectButtonItem';

interface SelectButtonItem<T> {
  value: T;
  label: string;
  boxItemProps?: BoxProps;
  boxItemTextProps?: BoxProps;
}

export interface VCSelectButtonProps<T> {
  value: T;
  items: SelectButtonItem<T>[];

  onSelect: (value: T) => void;
  selectButtonProps?: StackProps;
}

export const VCSelectButton = <T,>({ value, items, onSelect, selectButtonProps }: VCSelectButtonProps<T>) => {
  const { getRootProps, getRadioProps } = useRadioGroup({
    value: value as string,
    onChange: onSelect as (value: string) => void,
  });

  const group = getRootProps();

  return (
    <HStack {...group} gap="0" justifyContent={'center'} onClick={e => e.stopPropagation()} {...selectButtonProps}>
      {items.map((option, idx) => {
        const radio = getRadioProps({ value: option.value as string });
        return (
          <VCSelectButtonItem
            key={option.value as string}
            {...radio}
            text={option.label}
            isFirst={idx === 0}
            isLast={idx === items.length - 1}
            boxItemProps={option.boxItemProps}
            boxItemTextProps={option.boxItemTextProps}
          />
        );
      })}
    </HStack>
  );
};
