import { FormInputField, FormInputFieldProps } from './form-input-field';
import { isPostalCodeValid } from '../../../../../../utils/validators';
import { useTranslation } from 'react-i18next';
import { useToast } from '@chakra-ui/react';
import { useState } from 'react';

export type PostalCodeInputFieldProps = FormInputFieldProps;

function PostalCodeInputField(props: PostalCodeInputFieldProps) {
  const { t } = useTranslation();
  const [valueFormatted, setValueFormatted] = useState(props.value);
  const [isInvalid, setIsInvalid] = useState(false);
  const toast = useToast();

  const checkIsValidFormat = (value: string) => {
    if (isPostalCodeValid(value) && value.length === 8) return true;

    toast({
      title: t('invalidPostalCode'),
      status: 'error',
      duration: 5000,
      isClosable: true,
    });
    return false;
  };

  const handleBlur = (value: string) => {
    const inputValue = value;
    if (props.isReadonly) return;

    setIsInvalid(!checkIsValidFormat(inputValue));

    if (props.onBlur) props.onBlur(inputValue);
  };

  const handleChange = (value: string) => {
    const inputValue = value;

    const digits = inputValue.replace(/\D/g, '').slice(0, 7);
    let formattedValue = digits;
    if (digits.length > 4) {
      formattedValue = `${digits.slice(0, 4)}-${digits.slice(4)}`;
    }

    setValueFormatted(formattedValue);
    props.onChange?.(formattedValue);
  };

  return (
    <FormInputField
      {...props}
      value={valueFormatted}
      isInvalid={isInvalid}
      onChange={handleChange}
      onBlur={handleBlur}
    />
  );
}

export { PostalCodeInputField };
