import { Input, useColorModeValue } from '@chakra-ui/react';
import React from 'react';

export interface InputCellProps {
  onChange: (value: string) => void;
  initialValue?: string;
  placeholder?: string;
}
export default function InputCell({ initialValue = '', placeholder, onChange }: InputCellProps) {
  const [value, setValue] = React.useState(initialValue);

  const borderColor = useColorModeValue('navy.500', 'white');
  const grayTextColor = useColorModeValue('gray.500', 'white');
  const bgColor = useColorModeValue('secondaryGray.300', 'whiteAlpha.300');

  const sendChange = () => {
    if (value !== initialValue) {
      onChange(value);
    }
  };

  const onBlurHandler = () => {
    sendChange();
  };

  const onKeyDownHandler = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      sendChange();
    }
  };

  return (
    <Input
      placeholder={placeholder}
      _placeholder={{ color: 'gray.400' }}
      _focus={{ borderColor, borderWidth: '1px', bg: bgColor }}
      color={grayTextColor}
      p="16px"
      fontSize="14px"
      borderRadius="16px"
      value={value}
      onChange={e => setValue(e.target.value)}
      onBlur={onBlurHandler}
      onKeyDown={onKeyDownHandler}
    />
  );
}
