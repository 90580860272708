import { Box, Button, Flex, FlexProps, Icon, Text, useColorModeValue } from '@chakra-ui/react';
import { DropzoneOptions, useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { MdUpload } from 'react-icons/md';
import { CloseCircle } from '../icons/icons';

export enum VCMimeTypes {
  JPEG = 'image/jpeg',
  JPG = 'image/jpg',
  PNG = 'image/png',
  PDF = 'application/pdf',
}

export interface VCDropzoneProps {
  title: string;
  allowedFileTypes?: VCMimeTypes[];
  files?: File[];
  onDrop: DropzoneOptions['onDrop'];
  onDeleteFile?: (file: File) => void;
  isSmall?: boolean;

  secondHeaderChildren?: React.ReactNode;
  topChildren?: React.ReactNode;
  bottomChildren?: React.ReactNode;
  dropzoneFlexProps?: FlexProps;
}

export const VCDropzone = ({
  title,
  allowedFileTypes,
  files,
  onDrop,
  onDeleteFile,
  isSmall,

  secondHeaderChildren,
  topChildren,
  bottomChildren,
  dropzoneFlexProps,
}: VCDropzoneProps) => {
  const { t } = useTranslation();

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: allowedFileTypes ?? [],
  });

  const bg = useColorModeValue('_gray.100', 'navy.700');
  const borderColor = useColorModeValue('secondaryGray.100', 'whiteAlpha.100');

  const pillTextColor = useColorModeValue('_gray.600', 'white');
  const pillBg = useColorModeValue('_gray.300', '_brand.550');

  const textColor = useColorModeValue('_brand.550', 'white');

  return (
    <Flex
      w="100%"
      // h="max-content"
      // minH={{ base: '125px', lg: '50%', '2xl': '100%' }}
      align="center"
      justify="center"
      bg={bg}
      border={isDragActive ? '1px solid' : '1px dashed'}
      borderColor={textColor}
      borderRadius="22px"
      p={isSmall ? '0 24px' : '40px'}
      cursor="pointer"
      {...getRootProps({ className: 'dropzone' })}
      {...dropzoneFlexProps}
      h="100%"
    >
      <input {...getInputProps()} />

      <Button variant="no-effects" height={'unset'} boxShadow="none" onDragLeave={e => e.stopPropagation()}>
        {
          <Flex
            maxW={'100%'}
            flexDirection={isSmall ? 'row' : 'column'}
            alignItems="center"
            gap={isSmall ? '12px' : '0'}
          >
            <Icon as={MdUpload} w={isSmall ? '18px' : '32px'} h={isSmall ? '18px' : '32px'} color={textColor} />
            <Flex
              alignItems="center"
              direction={isSmall ? 'row' : 'column'}
              justify="center"
              mx="auto"
              mb={isSmall ? '0' : '12px'}
              overflow={'hidden'}
            >
              <Text
                fontSize={isSmall ? '16px' : '20px'}
                lineHeight={isSmall ? '18px' : '22px'}
                fontWeight="700"
                color={textColor}
                className={isSmall ? '' : 'text-wrap'}
                textOverflow="ellipsis"
                overflow={'hidden'}
              >
                {title}
              </Text>
              {secondHeaderChildren && secondHeaderChildren}
              {!isSmall && (
                <Text color="secondaryGray.500" fontSize="12px" fontWeight="500" lineHeight="20px">
                  {t('filesAreAllowed') +
                    ' ' +
                    Object.entries(VCMimeTypes)
                      .reduce(
                        (acc, i) => (allowedFileTypes?.includes(i[1] as VCMimeTypes) ? [...acc, i[0]] : acc),
                        [] as string[],
                      )
                      .join(', ')
                      .replace(/,([^,]*)$/, ' ' + t('and') + '$1')}
                </Text>
              )}

              {topChildren && topChildren}

              {!isSmall && files && !!files?.length && (
                <Flex
                  mt="12px"
                  mb="24px"
                  flexWrap={'wrap'}
                  justifyContent={'center'}
                  alignItems={'center'}
                  gap="12px"
                  onClick={e => e.stopPropagation()}
                >
                  {files?.map(i => (
                    <Flex
                      h="24px"
                      alignItems={'center'}
                      gap="4px"
                      p="4px 16px 4px 1px"
                      borderRadius="16px"
                      bg={pillBg}
                      maxW="100%"
                    >
                      <Button
                        size="xs"
                        variant="ghost"
                        color={pillTextColor}
                        _hover={{ color: 'red.500' }}
                        p="0"
                        borderColor={borderColor}
                        onClick={() => onDeleteFile?.(i)}
                      >
                        <CloseCircle boxSize={4} />
                      </Button>
                      <Text
                        color={pillTextColor}
                        fontWeight={'500'}
                        fontSize="14px"
                        lineHeight={'15px'}
                        overflow="hidden"
                        textOverflow={'ellipsis'}
                      >
                        {i.name}
                      </Text>
                    </Flex>
                  ))}
                </Flex>
              )}

              {bottomChildren && bottomChildren}
            </Flex>
          </Flex>
        }
      </Button>
    </Flex>
  );
};
