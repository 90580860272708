import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  UnorderedList,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import { Button } from 'libs/ui/atoms/src';

export const ReleaseNotesPopup = () => {
  const { t, i18n } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [newFeatures, setNewFeatures] = useState<string[]>([]);
  const [generalUpdates, setGeneralUpdates] = useState<string[]>([]);
  const [versionDate, setVersionDate] = useState<string>('');

  const [needShow, setNeedShow] = useState(true);
  const close = useCallback(() => {
    onClose();
    localStorage.setItem(versionDate, 'true');
  }, [onClose, versionDate]);

  useEffect(() => {
    if (!needShow) {
      return;
    }

    fetch('CHANGELOG.md')
      .then(response => response.text())
      .then(text => {
        const lines = text.split(/\r?\n/);
        const regex = /## \[\d+\.\d+\.\d+\] \d{4}-\d{2}-\d{2}/;
        const featuresRegex = /### New features([\s\S]*?)### General updates/;
        const updatesRegex = /### General updates([\s\S]*?)(?=###|$)/;

        let lastIndex = -1;
        lines.forEach((line, index) => {
          if (regex.test(line)) {
            lastIndex = index;
          }
        });

        if (lastIndex !== -1 && lastIndex + 1 < lines.length) {
          const identifier = lines.slice(lastIndex).join('\n');
          const input = identifier.split('\n')[0];
          const versionDateStr = 'vc_release_notes_' + input.replace('## [', '').replace('] ', '-');
          setVersionDate(versionDateStr);

          if (localStorage.getItem(versionDateStr) === 'true') {
            return;
          }

          const textAfterLastMatch = lines.slice(lastIndex + 1).join('\n');

          const featuresMatch = featuresRegex.exec(textAfterLastMatch);
          const updatesMatch = updatesRegex.exec(textAfterLastMatch);

          const currentLocale = i18n.resolvedLanguage?.toUpperCase() ?? 'EN';

          if (featuresMatch) {
            setNewFeatures(
              featuresMatch[1]
                .trim()
                .split('\n')
                .filter(line => line.startsWith(`- [${currentLocale}] `))
                .map(i => i.replace(`- [${currentLocale}] `, '')),
            );
          }
          if (updatesMatch) {
            setGeneralUpdates(
              updatesMatch[1]
                .trim()
                .split('\n')
                .filter(line => line.startsWith(`- [${currentLocale}] `))
                .map(i => i.replace(`- [${currentLocale}] `, '')),
            );
          }

          onOpen();
        } else {
          console.log('No match found or no text after the last match.');
        }
      });
  }, [i18n, needShow]);

  const textColor = useColorModeValue('navy.750', 'white');
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');

  const getListElement = useCallback(
    (titleKey: string, items: string[]) => (
      <>
        <Text fontWeight="bold">{t(titleKey)}</Text>
        <UnorderedList>
          {items.map((item, idx) => (
            <ListItem key={idx}>{item}</ListItem>
          ))}
        </UnorderedList>
      </>
    ),
    [newFeatures, generalUpdates, i18n],
  );

  return (
    <Modal size={'lg'} onClose={close} isOpen={isOpen} isCentered>
      <ModalOverlay />
      <ModalContent borderRadius="16" padding={'2'} paddingBottom="6">
        <ModalHeader color={textColor}>{t('releaseNotes')}</ModalHeader>
        <ModalCloseButton top={'6'} right="4" />
        <ModalBody>
          <Box color={textColor} gap={'4'} display="flex" flexDirection="column">
            {newFeatures.length && getListElement('newFeatures', newFeatures)}
            {generalUpdates.length && getListElement('generalUpdates', generalUpdates)}

            <Button p="10px 24px" onClick={close}>
              {t('ok')}
            </Button>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
