import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export const ModalDocumentTypeValidationError = ({ isOpen, onClose }: Props) => {
  const { t } = useTranslation();

  return (
    <Modal size="lg" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay>
        <ModalContent p="24px" borderRadius="16px">
          <ModalHeader p="0" fontSize="24px">
            {t('error')}
          </ModalHeader>
          <ModalBody p="24px 0">
            <Text fontSize="20px">{t('pages.details.unsupportedFile')}</Text>
          </ModalBody>
          <ModalFooter justifyContent="center" gap="24px" p="0">
            <Button
              colorScheme="brand"
              onClick={onClose}
              maxWidth="200px"
              w="100%"
              h="46px"
              fontSize="14px"
              borderRadius="100px"
            >
              {t('close')}
            </Button>
          </ModalFooter>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  );
};
