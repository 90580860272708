import React, { useState } from 'react';

// chakra imports
import {
  Box,
  Flex,
  Drawer,
  DrawerBody,
  Icon,
  useColorModeValue,
  DrawerOverlay,
  useDisclosure,
  DrawerContent,
  DrawerCloseButton,
} from '@chakra-ui/react';
import { SidebarContent } from 'components/sidebar/components/Content';
import { renderThumb, renderTrack, renderView } from 'components/scrollbar/Scrollbar';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { IoMenuOutline } from 'react-icons/io5';
import { ReleaseNotesPopup } from 'components/releaseNotesPopup/ReleaseNotesPopup';
import { useTranslation } from 'react-i18next';

export interface SidebarProps {
  routes: RoutesType[];
  onClickButton?: React.MouseEventHandler<HTMLButtonElement>;

  isFloatingAnchorIcon?: boolean;

  [x: string]: any;
}

export function Sidebar(props: SidebarProps) {
  const { routes, onClickButton } = props;
  const { t } = useTranslation();

  const variantChange = '0.2s linear';
  const shadow = useColorModeValue('14px 17px 40px 4px rgba(112, 144, 176, 0.08)', 'unset');
  // Chakra Color Mode
  const sidebarBg = useColorModeValue('white', 'navy.800');
  const sidebarMargins = '0px';

  const [isCollapsed, setIsCollapsed] = useState(true);

  // SIDEBAR
  return (
    <>
      <ReleaseNotesPopup />

      <Box minH="100%">
        <Box
          bg={sidebarBg}
          transition={variantChange}
          w={isCollapsed ? '97px' : '300px'}
          transitionProperty={'width'}
          transitionDuration={'0.2s'}
          h="100vh"
          m={sidebarMargins}
          minH="100%"
          overflowX="hidden"
          boxShadow={shadow}
        >
          <Scrollbars
            autoHide
            renderTrackVertical={renderTrack}
            renderThumbVertical={renderThumb}
            renderView={renderView}
          >
            <SidebarContent
              routes={routes}
              onClickButton={onClickButton}
              isCollapsed={isCollapsed}
              isCollapsable={true}
              onCollapseClick={() => setIsCollapsed(!isCollapsed)}
            />
          </Scrollbars>
        </Box>
      </Box>
    </>
  );
}

export function SidebarResponsive(props: SidebarProps) {
  const { isFloatingAnchorIcon } = props;

  const sidebarBackgroundColor = useColorModeValue('white', 'navy.800');
  const menuColor = useColorModeValue('gray.400', 'white');
  const burgerBg = useColorModeValue('gray.100', 'navy.700');
  const shadow = useColorModeValue(
    '14px 17px 40px 8px rgba(112, 144, 176, 0.32)',
    '14px 17px 40px 8px rgba(112, 144, 176, 0.06)',
  );

  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef<HTMLDivElement>(null);

  const { routes, onClickButton } = props;

  return (
    <>
      <ReleaseNotesPopup />

      <Flex display={{ base: 'flex', xl: 'none' }} alignItems="center">
        <Flex
          ref={btnRef}
          w="max-content"
          h="max-content"
          onClick={onOpen}
          p={isFloatingAnchorIcon ? '12px' : undefined}
          borderRadius={isFloatingAnchorIcon ? '50%' : undefined}
          boxShadow={isFloatingAnchorIcon ? shadow : undefined}
          bg={isFloatingAnchorIcon ? burgerBg : undefined}
        >
          <Icon as={IoMenuOutline} color={menuColor} my="auto" w="20px" h="20px" _hover={{ cursor: 'pointer' }} />
        </Flex>

        <Drawer
          isOpen={isOpen}
          onClose={onClose}
          placement={document.documentElement.dir === 'rtl' ? 'right' : 'left'}
          finalFocusRef={btnRef}
        >
          <DrawerOverlay />

          <DrawerContent w="285px" maxW="285px" bg={sidebarBackgroundColor}>
            <DrawerCloseButton
              zIndex="3"
              onClick={onClose}
              _focus={{ boxShadow: 'none' }}
              _hover={{ boxShadow: 'none' }}
            />

            <DrawerBody maxW="285px" px="0rem" pb="0">
              <Scrollbars
                autoHide
                renderTrackVertical={renderTrack}
                renderThumbVertical={renderThumb}
                renderView={renderView}
              >
                <SidebarContent
                  routes={routes}
                  onClickButton={onClickButton}
                  isCollapsed={false}
                  isCollapsable={false}
                  onCollapseClick={() => null}
                />
              </Scrollbars>
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      </Flex>
    </>
  );
}
