import { defaultSerializeQueryArgs } from '@reduxjs/toolkit/query';
import { api } from './api';
import { Job } from './job.private';
import { Signer } from './signer';
import { Submission, SubmissionSigningStatus } from './submission';
import { UniversalDocument } from './universal-document';

export enum SigningType {
  SMS = 'SMS',
  MOBILE_KEY_OR_CITIZEN_CARD = 'MOBILE_KEY_OR_CITIZEN_CARD',
}

export interface Signing {
  id: string;
  signingType: SigningType;
  resultArchiveFile?: File;
  jobId: string;
  organizationId?: string;
  submission?: Submission<SubmissionSigningStatus>;

  createdAt: Date;
  createdBy: string;
  updatedAt: Date;
}

export type SigningListResponse = Signing & {
  job: Job;
};

export type SigningSingleResponse = Signing & {
  signers: Signer[];
  documents: UniversalDocument[];
};

export interface SigningPagedListResponse {
  data: SigningListResponse[];
  totalCount: number;
  pageCount: number;
}

export interface SigningGetListDto {
  skip?: number;
  take?: number;
  orderBy?: string;
  search?: string;
  orderDirection?: 'ASC' | 'DESC';
  signingType?: SigningType;
  lastUpdatedAt?: Date;
  status?: SubmissionSigningStatus;
}

export interface SigningCreateDto {
  signingType: SigningType;
  jobId: string;
}

export interface SigningUpdateDto {
  id: string;
  signingType: SigningType;
}

export const signingApi = api.injectEndpoints({
  endpoints: build => ({
    getSignings: build.query<SigningPagedListResponse, SigningGetListDto>({
      query: ({ skip, take, orderBy, orderDirection, search, signingType, lastUpdatedAt, status }) => ({
        url: '/signing',
        params: { skip, take, orderBy, orderDirection, search, signingType, lastUpdatedAt, status },
      }),
      serializeQueryArgs: ({ queryArgs: { skip, take, ...queryArgs }, endpointName, endpointDefinition }) =>
        defaultSerializeQueryArgs({
          queryArgs,
          endpointName,
          endpointDefinition,
        }),
    }),
    getSigning: build.query<SigningSingleResponse, { signingId: string }>({
      query: ({ signingId }) => ({ url: `/signing/${signingId}` }),
    }),
    createSigning: build.mutation<SigningSingleResponse, SigningCreateDto>({
      query: dto => ({
        url: '/signing',
        body: dto,
        method: 'POST',
      }),
    }),
    updateSigning: build.mutation<SigningSingleResponse, SigningUpdateDto>({
      query: dto => ({
        url: '/signing',
        body: dto,
        method: 'PUT',
      }),
    }),
  }),
});

export const {
  useGetSigningsQuery,
  useLazyGetSigningsQuery,
  useGetSigningQuery,
  useLazyGetSigningQuery,
  useCreateSigningMutation,
  useUpdateSigningMutation,
} = signingApi;
