import { Box, Flex } from '@chakra-ui/layout';
import { Image } from '@chakra-ui/react';
import logo from '../../assets/img/vc-logo.png';

export const Loading: React.FC = () => {
  return (
    <Flex h="100vh" justifyContent="center" alignItems="center">
      <Box>
        <Image ml="32px" mt="24px" mb="10px" src={logo} alt={'Logo'} />
      </Box>
    </Flex>
  );
};
