import { useTranslation } from 'react-i18next';
import { Grid, Flex } from '@chakra-ui/react';

import 'react-phone-input-2/lib/style.css';
import { FormInputField } from '../../../../../../libs/ui/atoms/src/lib/input-field/form-input-field';
import { UserFormAction, UserFormState } from './use-user-form';
import { UserRolesWidget } from '../../../../../../components/user/roles-management/RolesAccordion';
import { UserRole } from '../../../../../../api';

export interface MutateUserFormProps {
  form: UserFormState;
  dispatch: (action: UserFormAction) => void;

  isReadonly?: boolean;
  onBlur?: () => void;
  onRolesChange?: (roles: UserRole[]) => void;

  isSowRolesInAccordionButton?: boolean;
  isEmailDisabled?: boolean;
}

export const UserMutateForm = (props: MutateUserFormProps) => {
  const { form, dispatch, onBlur, onRolesChange, isReadonly, isSowRolesInAccordionButton, isEmailDisabled } = props;

  const { t } = useTranslation();

  return (
    <Flex direction={'column'} gap="24px">
      <Grid columnGap={'24px'} rowGap={'16px'} gridTemplateColumns={{ sm: '1fr', md: '1fr 1fr' }}>
        <FormInputField
          isRequired
          isReadonly={isReadonly}
          label={t('certificateRenewal.form.name')}
          value={form.name}
          onChange={e => dispatch({ type: 'setName', value: e })}
          onBlur={onBlur}
        />
        <FormInputField
          isRequired
          isReadonly={isReadonly || isEmailDisabled}
          label={t('certificateRenewal.form.email')}
          value={form.email}
          onChange={e => dispatch({ type: 'setEmail', value: e })}
          onBlur={onBlur}
        />

        <FormInputField
          isReadonly={isReadonly}
          type={'number'}
          label={t('NIF')}
          value={form.nif}
          onChange={e => dispatch({ type: 'setNifNips', value: e })}
          onBlur={onBlur}
        />

        <FormInputField
          isReadonly={isReadonly}
          label={t('address')}
          value={form.address}
          onChange={e => dispatch({ type: 'setAddress', value: e })}
          onBlur={onBlur}
        />

        <FormInputField
          isReadonly={isReadonly}
          label={t('postalCode')}
          value={form.postalCode}
          onChange={e => dispatch({ type: 'setPostalCode', value: e })}
          onBlur={onBlur}
        />

        <FormInputField
          isReadonly={isReadonly}
          label={t('location')}
          value={form.location}
          onChange={e => dispatch({ type: 'setLocation', value: e })}
          onBlur={onBlur}
        />
      </Grid>

      <UserRolesWidget
        isReadonly={isReadonly}
        isShowRolesInButton={isSowRolesInAccordionButton}
        selectedRoles={form.roles}
        onToggle={async e => {
          dispatch({ type: 'toggleRole', value: e });

          const roles = form.roles.includes(e) ? form.roles.filter(r => r !== e) : [...form.roles, e];

          onRolesChange?.(roles);
        }}
      />
    </Flex>
  );
};
