import Card from './Card';
import { Flex, Text, useColorModeValue } from '@chakra-ui/react';
import { CustomCardProps } from '../../theme/theme';

export interface FileCardProps {
  title: string;
  description?: string;
  header?: JSX.Element;
  footer?: JSX.Element;
  icon: JSX.Element | string;
  bg?: string;
  truncateText?: boolean;
  onClick?: () => void;
  cardProps?: CustomCardProps;
}

export function FileCard({
  title,
  header,
  footer,
  icon,
  description,
  truncateText,
  bg,
  onClick,
  cardProps,
}: FileCardProps) {
  const bgColor = useColorModeValue('white', 'whiteAlpha.300');
  const textColor = useColorModeValue('navy.750', 'white');

  return (
    <Card
      position="static"
      bg={bg ?? bgColor}
      p={{ base: '8px', md: '16px' }}
      display="flex"
      flexDirection="column"
      gap={{ base: '8px', md: '16px' }}
      borderWidth="1px"
      borderColor="#a3aed0"
      onClick={onClick}
      {...cardProps}
    >
      <Flex align="center" justify="space-between" gap="8px">
        {icon}
        <Text
          flex={1}
          fontSize={{ base: 'xs', md: 'md' }}
          fontWeight="700"
          lineHeight="normal"
          color={textColor}
          noOfLines={truncateText ? 2 : { base: 2, md: 0 }}
          title={title}
        >
          {title}
        </Text>
        {header}
      </Flex>

      {description && (
        <Text
          fontSize={{ base: 'xs', md: 'md' }}
          noOfLines={truncateText ? 2 : { base: 2, md: 0 }}
          fontWeight="400"
          lineHeight="normal"
          color="secondaryGray.600"
        >
          {description}
        </Text>
      )}

      {footer}
    </Card>
  );
}
