import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { HelpTicketType } from '../views/admin/home/tickets';

export interface HelpToolsState {
  selectedHelpTicketType: HelpTicketType | null;
  isHelpTicketPanelCollapsed: boolean;
}

const initialHelpToolsState: HelpToolsState = {
  selectedHelpTicketType: null,
  isHelpTicketPanelCollapsed: false,
};

const helpToolsSlice = createSlice({
  name: 'helpTools',
  initialState: initialHelpToolsState,
  reducers: {
    helpTicketSelected: (state, action: PayloadAction<HelpTicketType>) => ({
      ...state,
      selectedHelpTicketType: action.payload,
      isHelpTicketPanelCollapsed: false,
    }),
    helpPanelCollapsed: state => ({ ...state, isHelpTicketPanelCollapsed: true }),
    helpPanelExpanded: state => ({ ...state, isHelpTicketPanelCollapsed: false }),
    helpPanelClosed: state => ({ ...state, selectedHelpTicketType: null, isHelpTicketPanelCollapsed: false }),
  },
});

export const { helpTicketSelected, helpPanelCollapsed, helpPanelExpanded, helpPanelClosed } = helpToolsSlice.actions;

export const { reducer: helpToolsReducer, reducerPath: helpToolsReducerPath } = helpToolsSlice;
