import { useTranslation } from 'react-i18next';
import {
  Button,
  Flex,
  Icon,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import { ReloadIcon } from 'components/icons/Icons';
import { Job, JobStatus } from 'api';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { shuffle } from 'lodash';

const WAIT_PLEASE_QUOTES_KEYS: string[] = [
  'pages.details.quote.quote1',
  'pages.details.quote.quote2',
  'pages.details.quote.quote3',
  'pages.details.quote.quote4',
  'pages.details.quote.quote5',
  'pages.details.quote.quote6',
  'pages.details.quote.quote7',
];

const shuffledQuotesKeys: string[] = shuffle([...WAIT_PLEASE_QUOTES_KEYS]);

interface WaitPleasePopupProps {
  job: Job;
}

export const WaitPleasePopup = ({ job }: WaitPleasePopupProps) => {
  const { t } = useTranslation();

  const { isOpen, onOpen, onClose } = useDisclosure({});
  const [wasModalOpened, setWasModalOpened] = useState<boolean>(false);

  useEffect(() => {
    const isLoading = job.status === JobStatus.Submitted;

    if (isLoading) {
      if (!wasModalOpened) {
        setWasModalOpened(true);
        onOpen();
      }
    } else {
      onClose();
      setWasModalOpened(false);
    }
  }, [job, wasModalOpened]);

  const textColor = useColorModeValue('navy.750', 'white');
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
  const bgColor = useColorModeValue('white', 'navy.800');

  return (
    <>
      <Modal size={'4xl'} onClose={onClose} isOpen={isOpen} isCentered>
        <ModalOverlay />
        <ModalContent bg={bgColor} borderRadius="16" padding={'2'} paddingBottom="6">
          <ModalBody overflow={'hidden'}>
            <Flex
              h={'400px'}
              flexDirection={'column'}
              p="24px"
              gap="24px"
              alignItems={'center'}
              justifyContent="center"
            >
              <Icon
                animation={'rotateinf 1s linear infinite'}
                w="24px"
                h="24px"
                display={'flex'}
                alignItems="center"
                justifyContent={'center'}
                as={ReloadIcon}
              />

              <Carousel
                interval={5000}
                autoPlay
                infiniteLoop
                showIndicators={false}
                showStatus={false}
                showArrows={false}
                showThumbs={false}
              >
                {shuffledQuotesKeys.map((quoteKey, i) => (
                  <Text fontSize="xl" overflowWrap={'anywhere'} fontWeight={'bold'} key={i} color={textColor}>
                    {t(quoteKey)}
                  </Text>
                ))}
              </Carousel>

              <Link to={`/job`}>
                <Button
                  colorScheme="brand"
                  onClick={() => null}
                  maxWidth="200px"
                  ps="40px"
                  pe="40px"
                  w="100%"
                  h="46px"
                  fontSize="14px"
                  borderRadius="100px"
                >
                  {t('pages.details.GoToJobList')}
                </Button>
              </Link>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
