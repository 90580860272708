import { useCallback } from 'react';

export interface UseFormattedClipboardProps {
  text: string;
}

export const useFormattedClipboard = () => {
  const copyToClipboard = useCallback(({ text }: UseFormattedClipboardProps) => {
    const tempElement = document.createElement('div');
    tempElement.style.position = 'absolute';
    tempElement.style.left = '-9999px';
    tempElement.style.background = 'white';
    tempElement.innerHTML = text;
    document.body.appendChild(tempElement);

    const range = document.createRange();
    range.selectNodeContents(tempElement);
    const selection = window.getSelection();
    if (selection) {
      selection.removeAllRanges();
      selection.addRange(range);
    }

    let result: boolean;
    try {
      document.execCommand('copy');
      result = true;
    } catch (err) {
      result = false;
    } finally {
      if (selection) {
        selection.removeAllRanges();
      }
      document.body.removeChild(tempElement);
    }
    return { result };
  }, []);

  return copyToClipboard;
};
