import {
  useDisclosure,
  Modal,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  FormControl,
  FormLabel,
  Input,
  ModalFooter,
  ModalOverlay,
  Flex,
} from '@chakra-ui/react';
import { Button } from 'libs/ui/atoms/src';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CertidaoRegistoComercialRelationType } from '../../../../../../api';
import { RadioButton } from '../../../../../../libs/ui/atoms/src/lib/radiobutton/RadioButton';

interface Props {
  isLoading?: boolean;
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  callback: (accessCode: string, relationType: CertidaoRegistoComercialRelationType) => void;
}

export const ModalCertidaoRegistoComercialValidation: React.FC<Props> = ({
  callback,
  isLoading,
  isOpen,
  onOpen,
  onClose,
}) => {
  const { t } = useTranslation();

  const { onClose: onCloseHandler } = useDisclosure({
    isOpen,
    onOpen,
    onClose: () => {
      handleReset();
      onClose();
    },
  });

  const [accessCode, setAccessCode] = useState<string>('');
  const [relationType, setRelationType] = useState(CertidaoRegistoComercialRelationType.SELLER);
  const [isRequired, setIsRequired] = useState<boolean>(false);

  const initialRef = React.useRef<any>();
  const finalRef = React.useRef<any>();

  const handleReset = () => {
    setAccessCode('');
    setIsRequired(false);
  };

  const isValid = () => {
    return Boolean(accessCode);
  };

  const submitHandler = async () => {
    if (!isValid()) {
      setIsRequired(true);
      return;
    }

    callback(accessCode.trim(), relationType);

    onCloseHandler();
    handleReset();
  };

  return (
    <>
      <Button isLoading={isLoading} onClick={onOpen} w={{ base: 'unset', lg: '400px' }}>
        {t('pages.details.addCertidaoRermananteRegistoComercialWithAccessCode')}
      </Button>

      <Modal size="lg" initialFocusRef={initialRef} finalFocusRef={finalRef} isOpen={isOpen} onClose={onCloseHandler}>
        <ModalOverlay />
        <ModalContent p="24px" borderRadius="16px">
          <ModalHeader p="0" fontSize="24px">
            {t('pages.details.certidaoRegistoComercialValidation')}
          </ModalHeader>
          <ModalCloseButton right="24px" top="none" />
          <ModalBody p="24px 0">
            <Flex flexDirection={'column'} gap="24px">
              <FormControl isRequired={isRequired}>
                <FormLabel>{t('pages.details.registoComercialAccessCode')}</FormLabel>
                <Input
                  ref={initialRef}
                  placeholder={t('code')}
                  value={accessCode}
                  onChange={event => setAccessCode(event.target.value)}
                />
              </FormControl>

              <FormControl isRequired={isRequired}>
                <FormLabel>{t('pages.details.relationshipOfParty')}</FormLabel>
                <RadioButton<CertidaoRegistoComercialRelationType>
                  items={[
                    {
                      value: CertidaoRegistoComercialRelationType.SELLER,
                      label: t('pages.details.registoComercialSeller'),
                    },
                    {
                      value: CertidaoRegistoComercialRelationType.BUYER,
                      label: t('pages.details.registoComercialBuyer'),
                    },
                  ]}
                  value={relationType}
                  onChange={e => setRelationType(e)}
                />
              </FormControl>
            </Flex>
          </ModalBody>

          <ModalFooter justifyContent="center" gap="24px" p="0">
            <Button
              colorScheme="brand"
              onClick={submitHandler}
              maxWidth="200px"
              w="100%"
              h="46px"
              fontSize="14px"
              borderRadius="100px"
            >
              {t('pages.details.submit')}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
