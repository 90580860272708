export interface HasId {
  id: string;
}

export interface ItemInsertAction<T extends HasId> {
  items: T[];
}

export interface ItemAddManyAction<T extends HasId> {
  items: T[];
  atEnd?: boolean;
  atIndex?: number;
}

export interface ItemRemoveManyAction {
  ids: string[];
}

export interface ItemUpdateAction<T> {
  item: T;
}

export interface ItemUpdateManyAction<T> {
  items: T[];
}

export interface ItemUpsertManyAction<T> {
  items: T[];
  changePositionForUpdated?: boolean;
  replaceOldWithNew?: boolean;
  addAtEnd?: boolean;
  addAtIndex?: number;
}

export const getArrayWithInserted = <T>(array: T[], index: number, ...items: T[]) => {
  array.splice(index, 0, ...items);
  return array;
};

type Filter<T, A = T> = (e: T, index: number, array: A[]) => boolean;
type FilterNonNullable<T, A = T> = (e: T, index: number, array: A[]) => e is NonNullable<T>;
type Mapper<T, R> = (e: T, index: number, array: T[]) => R;

export const filterAndMap = <T, R>(arr: T[], filter: Filter<T>, map: Mapper<T, R>) =>
  arr.reduce((acc: R[], e: T, i) => (filter(e, i, arr) ? [...acc, map(e, i, arr)] : acc), [] as R[]);

export const mapAndFilter = <T, R>(arr: T[], map: Mapper<T, R>, filter: Filter<R, T>) =>
  arr.reduce((acc: R[], e: T, i) => {
    const res = map(e, i, arr);
    return filter(res, i, arr) ? [...acc, res] : acc;
  }, [] as R[]);

export const collectToArray = <T, R>(array: T[], valueMapper: Mapper<T, R>) =>
  array.reduce((acc: R[], e, i) => [...acc, valueMapper(e, i, array)], [] as R[]);

export const mapAndFilterNonNullable = <T, R>(
  arr: T[],
  map: Mapper<T, R>,
  filter: FilterNonNullable<R, T> = (e: R): e is NonNullable<R> => !!e,
) =>
  arr.reduce((acc: NonNullable<R>[], e: T, i) => {
    const res = map(e, i, arr);
    return filter(res, i, arr) ? [...acc, res] : acc;
  }, [] as NonNullable<R>[]);

export const itemInsertManyReducer = <T extends HasId, A extends ItemInsertAction<T>>(
  state: T[] | undefined,
  action: A,
): T[] => action.items;

export const itemAddManyReducer = <T extends HasId, A extends ItemAddManyAction<T>>(
  state: T[] | undefined,
  action: A,
): T[] =>
  action.atEnd
    ? [...(state ?? []), ...action.items]
    : action.atIndex
      ? [...(state ?? [])].splice(action.atIndex, 0, ...action.items)
      : [...action.items, ...(state ?? [])];

export const itemUpsertManyReducer = <T extends HasId, A extends ItemUpsertManyAction<T>>(
  stateF: T[] | undefined,
  action: A,
): T[] => {
  let stateUpdated: T[];
  let itemsToAdd: T[];

  const state = stateF || [];
  if (!state || !state.length) {
    stateUpdated = [];
    itemsToAdd = action.items;
  } else if (action.replaceOldWithNew) {
    // stateUpdated is old state with updated items from action.items
    stateUpdated = state.map(value => {
      const thisItemInNewList = action.items.findIndex(item => item.id === value.id);

      // If item exists in new list, put it to stateUpdated instead of old item
      if (thisItemInNewList >= 0) {
        return action.items[thisItemInNewList];
      }

      // If item does not exist in new list, keep old item
      return value;
    });

    // Add items that are not in stateUpdated
    itemsToAdd = action.items.filter(item => stateUpdated.every(value => value.id !== item.id));
  } else if (action.changePositionForUpdated) {
    // stateUpdated is old state without items that are in action.items
    stateUpdated = state.filter(value => action.items.every(item => item.id !== value.id));
    itemsToAdd = action.items;
  } else {
    const foundIndexed: number[] = [];
    stateUpdated = mapAndFilterNonNullable(state, e => {
      const index = action.items.findIndex(item => item.id === e.id);
      if (index >= 0) {
        foundIndexed.push(index);
        return null;
      }
      return e;
    });
    itemsToAdd = Array.from({ length: foundIndexed.length }, (_, i) => action.items[foundIndexed[i]]);
  }

  return action.addAtEnd
    ? [...stateUpdated, ...itemsToAdd]
    : action.addAtIndex
      ? getArrayWithInserted(stateUpdated, action.addAtIndex, ...itemsToAdd)
      : [...itemsToAdd, ...stateUpdated];
};

export const itemRemoveManyReducer = <T extends HasId, A extends ItemRemoveManyAction>(
  state: T[] | undefined,
  action: A,
): T[] => (state && state.length ? state.filter(item => !action.ids.includes(item.id)) : []);

export const itemUpdateReducer = <T extends HasId, A extends ItemUpdateAction<T>>(
  state: T[] | undefined,
  { item }: A,
): T[] => {
  if (!state || !state.length) {
    return [item as unknown as T];
  }
  return state.map(i => (i.id === item.id ? item : i));
};

export const itemUpdateManyReducer = <T extends HasId, A extends ItemUpdateManyAction<T>>(
  state: T[] | undefined,
  { items }: A,
): T[] => {
  if (!state || !state.length) {
    return [...(items as unknown as T[])];
  }

  return items.reduce(
    (acc, item) => {
      const index = acc.findIndex(i => i.id === item.id);
      if (index >= 0) {
        acc[index] = item;
      }
      return acc;
    },
    [...state],
  );
};
