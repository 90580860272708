import { ChevronDownIcon } from '@chakra-ui/icons';
import {
  Menu,
  MenuButton,
  Button,
  MenuList,
  MenuItem,
  Box,
  Flex,
  IconButton,
  Icon,
  useColorModeValue,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';

interface DropdownButtonProps {
  actions: DropdownAction[];
}

interface DropdownAction {
  title: string;
  clickOnSelect: boolean;
  onClick: () => void;
  rightIcon?: React.ReactNode;
}

export const DropdownButton = ({ actions }: DropdownButtonProps) => {
  const [itemIndex, setItemIndex] = useState<number | undefined>(undefined);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    const firstKey = Object.keys(actions)[0];
    if (firstKey && !itemIndex) {
      setItemIndex(0);
    }
  }, [actions, itemIndex]);

  const buttonTextColor = useColorModeValue('white', 'white');
  const textColor = useColorModeValue('brand.800', 'white');
  const addButtonColor = useColorModeValue('brand.500', 'brand.500');
  const menuItemHoveBg = useColorModeValue('gray.50', 'brand.600');
  const cardBackgroundColor = useColorModeValue('white', 'navy.800');
  const borderColor = useColorModeValue('rgba(255, 255, 255, 0.32)', 'rgba(255, 255, 255, 0.32)');

  const handleClose = () => {
    setIsMenuOpen(false);
  };

  const handleOpen = () => {
    setIsMenuOpen(true);
  };

  return (
    <Flex>
      <Box flex="1">
        <Button
          color={buttonTextColor}
          backgroundColor={addButtonColor}
          width="100%"
          borderTopRightRadius="0"
          borderBottomRightRadius="0"
          borderTopLeftRadius="10px"
          borderBottomLeftRadius={isMenuOpen ? '0' : '10px'}
          _hover={{ bg: 'navy.600' }}
          fontFamily="DM Sans"
          fontSize="14px"
          onClick={() => {
            if (itemIndex !== undefined) {
              actions[itemIndex].onClick();
            }
          }}
        >
          {actions[itemIndex !== undefined ? itemIndex : 0].title}
          <Box
            position="absolute"
            top="10px"
            right="-0.5px"
            borderRadius="1px"
            height="50%"
            width="1px"
            backgroundColor={borderColor}
            zIndex="100"
          />
        </Button>
      </Box>
      <Box flex="auto" minWidth="40px">
        <Menu isOpen={isMenuOpen} onClose={handleClose}>
          <MenuButton
            color={buttonTextColor}
            backgroundColor={addButtonColor}
            as={IconButton}
            icon={<Icon as={ChevronDownIcon} />}
            width="100%"
            borderTopLeftRadius="0"
            borderBottomLeftRadius="0"
            borderTopRightRadius="10px"
            borderBottomRightRadius={isMenuOpen ? '0' : '10px'}
            _hover={{ bg: 'navy.600' }}
            onClick={handleOpen}
          ></MenuButton>
          <MenuList
            bg={cardBackgroundColor}
            p="8px"
            boxShadow={'0px 18px 40px 0px rgba(112, 144, 176, 0.12)'}
            border="none"
            borderRadius={'10px'}
            zIndex={11}
            rootProps={{ style: { right: 0, transform: 'scale(0)' } }}
          >
            {actions.map((item, idx) => (
              <MenuItem
                key={idx}
                borderRadius={'8px'}
                _hover={{ bg: menuItemHoveBg }}
                color={textColor}
                fontSize="12px"
                lineHeight={'12px'}
                fontWeight={500}
                p="8px 12px"
                onClick={() => {
                  if (!item.clickOnSelect) {
                    setItemIndex(idx);
                  } else {
                    item.onClick();
                  }
                }}
              >
                <Flex w="100%" gap={'8px'} alignItems={'center'} justifyContent="space-between">
                  {item.title} {item.rightIcon && item.rightIcon}
                </Flex>
                {/* {item.title} */}
              </MenuItem>
            ))}
          </MenuList>
        </Menu>
      </Box>
    </Flex>
  );
};
