import { Button, Icon } from '@chakra-ui/react';
import { MdOutlineCheckBox, MdOutlineCheckBoxOutlineBlank } from 'react-icons/md';

export interface CheckboxProps {
  isChecked: boolean;
  isDisabled?: boolean;
  onCheck: () => void;
}

export function Checkbox({ isChecked, isDisabled, onCheck }: CheckboxProps) {
  return (
    <Button
      size="xs"
      variant="unstyled"
      disabled={isDisabled}
      onClick={e => {
        e.stopPropagation();
        e.preventDefault();
        onCheck();
      }}
    >
      <Icon
        as={isChecked ? MdOutlineCheckBox : MdOutlineCheckBoxOutlineBlank}
        color={isChecked ? 'green.300' : 'secondaryGray.600'}
        w="24px"
        h="24px"
      />
    </Button>
  );
}
