import {
  RelatedPartyCreateDto,
  RelatedPartyEntityType,
  RelatedPartyUpdateDto,
  useCreateRelatedPartyMutation,
  useDeleteRelatedPartyMutation,
  useLazyGetJobPartiesQuery,
  useResetRelatedPartyMutation,
  useUpdateRelatedPartyMutation,
} from 'api/related-parties';
import { useCallback, useEffect, useState } from 'react';
import { SubmissionRelatedPartyStatus } from '../../../../../api';
import { getDistrict, getMunicipality, getParish } from '../../../../../utils/db-predial';

export const useRelatedParties = (jobId: string, isInited: boolean) => {
  const [buyersSearch, setBuyersSearch] = useState('');
  const [sellersSearch, setSellersSearch] = useState('');
  const [buyersRefetchTimer, setBuyersRefetchTimer] = useState<NodeJS.Timeout>();
  const [sellersRefetchTimer, setSellersRefetchTimer] = useState<NodeJS.Timeout>();

  const [getBuyers, { data: buyers, isLoading: isLoadingBuyers }] = useLazyGetJobPartiesQuery();
  const [getSellers, { data: sellers, isLoading: isLoadingSellers }] = useLazyGetJobPartiesQuery();
  const [create, { isLoading: isLoadingCreate, error: createError }] = useCreateRelatedPartyMutation();
  const [update, { isLoading: isLoadingUpdate, error: updateError }] = useUpdateRelatedPartyMutation();
  const [deleteParty, { isLoading: isLoadingDelete, error: deleteError }] = useDeleteRelatedPartyMutation();
  const [reset, { isLoading: isLoadingReset, error: resetError }] = useResetRelatedPartyMutation();

  useEffect(() => {
    if (!isInited) return;
    getBuyers({ jobId, filter: { entityType: RelatedPartyEntityType.BUYER, search: buyersSearch } });
  }, [buyersSearch, getBuyers, isInited, jobId]);

  useEffect(() => {
    if (!isInited) return;
    getSellers({ jobId, filter: { entityType: RelatedPartyEntityType.SELLER, search: sellersSearch } });
  }, [getSellers, isInited, jobId, sellersSearch]);

  const loadBuyers = useCallback(async () => {
    const result = await getBuyers({
      jobId,
      filter: { entityType: RelatedPartyEntityType.BUYER, search: buyersSearch },
    });

    if (
      result.data?.some(
        buyer =>
          buyer.submission?.status === SubmissionRelatedPartyStatus.SUBMITTED ||
          buyer.nestedRelatedParties?.some(n => n.submission?.status === SubmissionRelatedPartyStatus.SUBMITTED),
      )
    ) {
      if (buyersRefetchTimer) return;
      const timer = setTimeout(() => loadBuyers(), 3000);
      setBuyersRefetchTimer(timer);
    } else {
      clearTimeout(buyersRefetchTimer);
      setBuyersRefetchTimer(undefined);
    }
  }, [getBuyers, jobId, buyersSearch, buyersRefetchTimer]);

  const loadSellers = useCallback(async () => {
    const result = await getSellers({
      jobId,
      filter: { entityType: RelatedPartyEntityType.SELLER, search: sellersSearch },
    });

    if (
      result.data?.some(
        seller =>
          seller.submission?.status === SubmissionRelatedPartyStatus.SUBMITTED ||
          seller.nestedRelatedParties?.some(n => n.submission?.status === SubmissionRelatedPartyStatus.SUBMITTED),
      )
    ) {
      if (sellersRefetchTimer) return;
      const timer = setTimeout(() => loadSellers(), 3000);
      setSellersRefetchTimer(timer);
    } else {
      clearTimeout(sellersRefetchTimer);
      setSellersRefetchTimer(undefined);
    }
  }, [getSellers, jobId, sellersRefetchTimer, sellersSearch]);

  const onCreate = useCallback(
    async (dto: RelatedPartyCreateDto) => {
      await create({
        ...dto,
        corpAddressDistrict: getDistrict(dto.corpAddressDistrict),
        corpAddressMunicipality: getMunicipality(dto.corpAddressMunicipality),
        corpAddressParish: getParish(dto.corpAddressParish),
      }).unwrap();
      dto.entityType === RelatedPartyEntityType.BUYER ? loadBuyers() : loadSellers();
    },
    [create, loadBuyers, loadSellers],
  );

  const onUpdate = useCallback(
    async (dto: RelatedPartyUpdateDto) => {
      await update({
        ...dto,
        corpAddressDistrict: getDistrict(dto.corpAddressDistrict),
        corpAddressMunicipality: getMunicipality(dto.corpAddressMunicipality),
        corpAddressParish: getParish(dto.corpAddressParish),
      }).unwrap();
      loadBuyers();
      loadSellers();
    },
    [update, loadBuyers, loadSellers],
  );

  const onDeleteItems = useCallback(
    async (ids: string[]) => {
      await Promise.all(ids.map(id => deleteParty({ id }).unwrap()));
      loadBuyers();
      loadSellers();
    },
    [deleteParty, loadBuyers, loadSellers],
  );

  const onResetToDefault = useCallback(
    async (entityType: RelatedPartyEntityType) => {
      await reset({ jobId, entityType }).unwrap();
      entityType === RelatedPartyEntityType.BUYER ? loadBuyers() : loadSellers();
    },
    [jobId, loadBuyers, loadSellers, reset],
  );

  return {
    isLoadingBuyers,
    isLoadingSellers,
    buyers: buyers || [],
    sellers: sellers || [],
    onBuyerSearchChange: (value: string) => setBuyersSearch(value),
    onSellerSearchChange: (value: string) => setSellersSearch(value),
    onDeleteItems,
    onResetToDefault,
    onCreate,
    onUpdate,
    loadBuyers,
    loadSellers,
  };
};
