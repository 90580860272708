import { Flex, Text, useColorModeValue } from '@chakra-ui/react';
import { Checkbox } from '../../../libs/ui/atoms/src/lib/checkbox/Checkbox';

interface RoleListItemProps {
  title: string;
  isChecked: boolean;
  isReadonly?: boolean;

  onCheck: () => void;
}

export const UserRoleListItem = (props: RoleListItemProps) => {
  const { title, isChecked, onCheck, isReadonly } = props;

  const textColor = useColorModeValue('_gray.600', 'white');

  return (
    <Flex gap={'24px'} alignItems="center">
      <Checkbox isDisabled={isReadonly} isChecked={isChecked} onCheck={onCheck} />

      <Text color={textColor} fontSize={'14px'}>
        {title}
      </Text>
    </Flex>
  );
};
