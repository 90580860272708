export const formatDate = (date: Date | string) => {
  return new Intl.DateTimeFormat('en-EN', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour12: false,
    hour: '2-digit',
    minute: '2-digit',
  }).format(new Date(date));
};

export function formatDateToYYYYMMDD(date: Date) {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  return `${year}-${month}-${day}`;
}
