import { Suspense, useEffect } from 'react';
import './assets/css/App.css';
import { Route, BrowserRouter, Navigate, Routes } from 'react-router-dom';
import { Provider as ReduxProvider } from 'react-redux';
import { AuthLayout } from './layouts/auth';
import { PublicLayout } from './layouts/public';
import { ChakraProvider } from '@chakra-ui/react';
import theme from './theme/theme';
import { store } from './store';
import { AuthProvider } from 'auth0/AuthProvider';
import ProtectedRoute from 'auth0/ProtectedRoute';
import { createRoot } from 'react-dom/client';
import { Loading } from 'components/loading/Loading';

import './i18n';
import { RefreshOnNewChangesToast } from './components/RefreshOnNewChangesToast/RefreshOnNewChangesToast';
import { environment } from './environments/environment';

const element = document.getElementById('root');
if (!element) {
  throw new Error('Invalid application state! Not found root element.');
}
const root = createRoot(element);

const DocumentTitleHandler = () => {
  useEffect(() => {
    let title = 'VeriCasa';

    if (environment.stage === 'local' || environment.stage === 'dev') {
      title = 'VeriCasa - DEV';
    } else if (environment.stage === 'prod') {
      title = 'VeriCasa';
    }

    document.title = title;
  }, []);

  return null;
};

root.render(
  <Suspense fallback={<Loading />}>
    <AuthProvider>
      <ChakraProvider theme={theme}>
        <ReduxProvider store={store}>
          <BrowserRouter>
            <DocumentTitleHandler />
            <Routes>
              <Route path={`/auth/*`} element={<AuthLayout />} />
              <Route path={`/r/*`} element={<PublicLayout />} />
              <Route path={'/*'} element={<ProtectedRoute />} />
              <Route element={<Navigate to="/login" />} />
            </Routes>
          </BrowserRouter>
          <RefreshOnNewChangesToast />
        </ReduxProvider>
      </ChakraProvider>
    </AuthProvider>
  </Suspense>,
);
