export const getDistrict = (value?: string) => VC_DB_PREDIAL_DISTRICTS.find(district => district[0] === value)?.[1];
export const getMunicipality = (value?: string) =>
  VC_DB_PREDIAL_MUNICIPALITIES.find(municipality => municipality[0] === value)?.[1];
export const getParish = (value?: string) => VC_DB_PREDIAL_PARISHS.find(parish => parish[0] === value)?.[1];

export const getDistrictCode = (value?: string) => VC_DB_PREDIAL_DISTRICTS.find(district => district[1] === value)?.[0];
export const getMunicipalityCode = (value?: string) =>
  VC_DB_PREDIAL_MUNICIPALITIES.find(municipality => municipality[1] === value)?.[0];
export const getParishCode = (value?: string) => VC_DB_PREDIAL_PARISHS.find(parish => parish[1] === value)?.[0];

export const VC_DB_PREDIAL_DISTRICTS = [
  ['01', 'Aveiro'],
  ['02', 'Beja'],
  ['03', 'Braga'],
  ['04', 'Bragança'],
  ['05', 'Castelo Branco'],
  ['06', 'Coimbra'],
  ['08', 'Faro'],
  ['09', 'Guarda'],
  ['44', 'Ilha Graciosa'],
  ['43', 'Ilha Terceira'],
  ['31', 'Ilha da Madeira'],
  ['48', 'Ilha das Flores'],
  ['32', 'Ilha de Porto Santo'],
  ['41', 'Ilha de Santa Maria'],
  ['45', 'Ilha de São Jorge'],
  ['42', 'Ilha de São Miguel'],
  ['49', 'Ilha do Corvo'],
  ['47', 'Ilha do Faial'],
  ['46', 'Ilha do Pico'],
  ['10', 'Leiria'],
  ['11', 'Lisboa'],
  ['12', 'Portalegre'],
  ['13', 'Porto'],
  ['14', 'Santarém'],
  ['15', 'Setúbal'],
  ['16', 'Viana do Castelo'],
  ['17', 'Vila Real'],
  ['18', 'Viseu'],
  ['07', 'Évora'],
].sort((a, b) => a[1].localeCompare(b[1], 'en', { sensitivity: 'base' }));

export const VC_DB_PREDIAL_MUNICIPALITIES = [
  ['0101', 'Águeda'],
  ['0102', 'Albergaria-a-Velha'],
  ['0103', 'Anadia'],
  ['0104', 'Arouca'],
  ['0105', 'Aveiro'],
  ['0106', 'Castelo de Paiva'],
  ['0107', 'Espinho'],
  ['0108', 'Estarreja'],
  ['0109', 'Santa Maria da Feira'],
  ['0110', 'Ílhavo'],
  ['0111', 'Mealhada'],
  ['0112', 'Murtosa'],
  ['0113', 'Oliveira de Azeméis'],
  ['0114', 'Oliveira do Bairro'],
  ['0115', 'Ovar'],
  ['0116', 'São João da Madeira'],
  ['0117', 'Sever do Vouga'],
  ['0118', 'Vagos'],
  ['0119', 'Vale de Cambra'],
  ['0201', 'Aljustrel'],
  ['0202', 'Almodôvar'],
  ['0203', 'Alvito'],
  ['0204', 'Barrancos'],
  ['0205', 'Beja'],
  ['0206', 'Castro Verde'],
  ['0207', 'Cuba'],
  ['0208', 'Ferreira do Alentejo'],
  ['0209', 'Mértola'],
  ['0210', 'Moura'],
  ['0211', 'Odemira'],
  ['0212', 'Ourique'],
  ['0213', 'Serpa'],
  ['0214', 'Vidigueira'],
  ['0301', 'Amares'],
  ['0302', 'Barcelos'],
  ['0303', 'Braga'],
  ['0304', 'Cabeceiras de Basto'],
  ['0305', 'Celorico de Basto'],
  ['0306', 'Esposende'],
  ['0307', 'Fafe'],
  ['0308', 'Guimarães'],
  ['0309', 'Póvoa de Lanhoso'],
  ['0310', 'Terras de Bouro'],
  ['0311', 'Vieira do Minho'],
  ['0312', 'Vila Nova de Famalicão'],
  ['0313', 'Vila Verde'],
  ['0314', 'Vizela'],
  ['0401', 'Alfândega da Fé'],
  ['0402', 'Bragança'],
  ['0403', 'Carrazeda de Ansiães'],
  ['0404', 'Freixo de Espada à Cinta'],
  ['0405', 'Macedo de Cavaleiros'],
  ['0406', 'Miranda do Douro'],
  ['0407', 'Mirandela'],
  ['0408', 'Mogadouro'],
  ['0409', 'Torre de Moncorvo'],
  ['0410', 'Vila Flor'],
  ['0411', 'Vimioso'],
  ['0412', 'Vinhais'],
  ['0501', 'Belmonte'],
  ['0502', 'Castelo Branco'],
  ['0503', 'Covilhã'],
  ['0504', 'Fundão'],
  ['0505', 'Idanha-a-Nova'],
  ['0506', 'Oleiros'],
  ['0507', 'Penamacor'],
  ['0508', 'Proença-a-Nova'],
  ['0509', 'Sertã'],
  ['0510', 'Vila de Rei'],
  ['0511', 'Vila Velha de Ródão'],
  ['0601', 'Arganil'],
  ['0602', 'Cantanhede'],
  ['0603', 'Coimbra'],
  ['0604', 'Condeixa-a-Nova'],
  ['0605', 'Figueira da Foz'],
  ['0606', 'Góis'],
  ['0607', 'Lousã'],
  ['0608', 'Mira'],
  ['0609', 'Miranda do Corvo'],
  ['0610', 'Montemor-o-Velho'],
  ['0611', 'Oliveira do Hospital'],
  ['0612', 'Pampilhosa da Serra'],
  ['0613', 'Penacova'],
  ['0614', 'Penela'],
  ['0615', 'Soure'],
  ['0616', 'Tábua'],
  ['0617', 'Vila Nova de Poiares'],
  ['0701', 'Alandroal'],
  ['0702', 'Arraiolos'],
  ['0703', 'Borba'],
  ['0704', 'Estremoz'],
  ['0705', 'Évora'],
  ['0706', 'Montemor-o-Novo'],
  ['0707', 'Mora'],
  ['0708', 'Mourão'],
  ['0709', 'Portel'],
  ['0710', 'Redondo'],
  ['0711', 'Reguengos de Monsaraz'],
  ['0712', 'Vendas Novas'],
  ['0713', 'Viana do Alentejo'],
  ['0714', 'Vila Viçosa'],
  ['0801', 'Albufeira'],
  ['0802', 'Alcoutim'],
  ['0803', 'Aljezur'],
  ['0804', 'Castro Marim'],
  ['0805', 'Faro'],
  ['0806', 'Lagoa'],
  ['0807', 'Lagos'],
  ['0808', 'Loulé'],
  ['0809', 'Monchique'],
  ['0810', 'Olhão'],
  ['0811', 'Portimão'],
  ['0812', 'São Brás de Alportel'],
  ['0813', 'Silves'],
  ['0814', 'Tavira'],
  ['0815', 'Vila do Bispo'],
  ['0816', 'Vila Real de Santo António'],
  ['0901', 'Aguiar da Beira'],
  ['0902', 'Almeida'],
  ['0903', 'Celorico da Beira'],
  ['0904', 'Figueira de Castelo Rodrigo'],
  ['0905', 'Fornos de Algodres'],
  ['0906', 'Gouveia'],
  ['0907', 'Guarda'],
  ['0908', 'Manteigas'],
  ['0909', 'Mêda'],
  ['0910', 'Pinhel'],
  ['0911', 'Sabugal'],
  ['0912', 'Seia'],
  ['0913', 'Trancoso'],
  ['0914', 'Vila Nova de Foz Côa'],
  ['1001', 'Alcobaça'],
  ['1002', 'Alvaiázere'],
  ['1003', 'Ansião'],
  ['1004', 'Batalha'],
  ['1005', 'Bombarral'],
  ['1006', 'Caldas da Rainha'],
  ['1007', 'Castanheira de Pêra'],
  ['1008', 'Figueiró dos Vinhos'],
  ['1009', 'Leiria'],
  ['1010', 'Marinha Grande'],
  ['1011', 'Nazaré'],
  ['1012', 'Óbidos'],
  ['1013', 'Pedrógão Grande'],
  ['1014', 'Peniche'],
  ['1015', 'Pombal'],
  ['1016', 'Porto de Mós'],
  ['1101', 'Alenquer'],
  ['1102', 'Arruda dos Vinhos'],
  ['1103', 'Azambuja'],
  ['1104', 'Cadaval'],
  ['1105', 'Cascais'],
  ['1106', 'Lisboa'],
  ['1107', 'Loures'],
  ['1108', 'Lourinhã'],
  ['1109', 'Mafra'],
  ['1110', 'Oeiras'],
  ['1111', 'Sintra'],
  ['1112', 'Sobral de Monte Agraço'],
  ['1113', 'Torres Vedras'],
  ['1114', 'Vila Franca de Xira'],
  ['1115', 'Amadora'],
  ['1116', 'Odivelas'],
  ['1201', 'Alter do Chão'],
  ['1202', 'Arronches'],
  ['1203', 'Avis'],
  ['1204', 'Campo Maior'],
  ['1205', 'Castelo de Vide'],
  ['1206', 'Crato'],
  ['1207', 'Elvas'],
  ['1208', 'Fronteira'],
  ['1209', 'Gavião'],
  ['1210', 'Marvão'],
  ['1211', 'Monforte'],
  ['1212', 'Nisa'],
  ['1213', 'Ponte de Sor'],
  ['1214', 'Portalegre'],
  ['1215', 'Sousel'],
  ['1301', 'Amarante'],
  ['1302', 'Baião'],
  ['1303', 'Felgueiras'],
  ['1304', 'Gondomar'],
  ['1305', 'Lousada'],
  ['1306', 'Maia'],
  ['1307', 'Marco de Canaveses'],
  ['1308', 'Matosinhos'],
  ['1309', 'Paços de Ferreira'],
  ['1310', 'Paredes'],
  ['1311', 'Penafiel'],
  ['1312', 'Porto'],
  ['1313', 'Póvoa de Varzim'],
  ['1314', 'Santo Tirso'],
  ['1315', 'Valongo'],
  ['1316', 'Vila do Conde'],
  ['1317', 'Vila Nova de Gaia'],
  ['1318', 'Trofa'],
  ['1401', 'Abrantes'],
  ['1402', 'Alcanena'],
  ['1403', 'Almeirim'],
  ['1404', 'Alpiarça'],
  ['1405', 'Benavente'],
  ['1406', 'Cartaxo'],
  ['1407', 'Chamusca'],
  ['1408', 'Constância'],
  ['1409', 'Coruche'],
  ['1410', 'Entroncamento'],
  ['1411', 'Ferreira do Zêzere'],
  ['1412', 'Golegã'],
  ['1413', 'Mação'],
  ['1414', 'Rio Maior'],
  ['1415', 'Salvaterra de Magos'],
  ['1416', 'Santarém'],
  ['1417', 'Sardoal'],
  ['1418', 'Tomar'],
  ['1419', 'Torres Novas'],
  ['1420', 'Vila Nova da Barquinha'],
  ['1421', 'Ourém'],
  ['1501', 'Alcácer do Sal'],
  ['1502', 'Alcochete'],
  ['1503', 'Almada'],
  ['1504', 'Barreiro'],
  ['1505', 'Grândola'],
  ['1506', 'Moita'],
  ['1507', 'Montijo'],
  ['1508', 'Palmela'],
  ['1509', 'Santiago do Cacém'],
  ['1510', 'Seixal'],
  ['1511', 'Sesimbra'],
  ['1512', 'Setúbal'],
  ['1513', 'Sines'],
  ['1601', 'Arcos de Valdevez'],
  ['1602', 'Caminha'],
  ['1603', 'Melgaço'],
  ['1604', 'Monção'],
  ['1605', 'Paredes de Coura'],
  ['1606', 'Ponte da Barca'],
  ['1607', 'Ponte de Lima'],
  ['1608', 'Valença'],
  ['1609', 'Viana do Castelo'],
  ['1610', 'Vila Nova de Cerveira'],
  ['1701', 'Alijó'],
  ['1702', 'Boticas'],
  ['1703', 'Chaves'],
  ['1704', 'Mesão Frio'],
  ['1705', 'Mondim de Basto'],
  ['1706', 'Montalegre'],
  ['1707', 'Murça'],
  ['1708', 'Peso da Régua'],
  ['1709', 'Ribeira de Pena'],
  ['1710', 'Sabrosa'],
  ['1711', 'Santa Marta de Penaguião'],
  ['1712', 'Valpaços'],
  ['1713', 'Vila Pouca de Aguiar'],
  ['1714', 'Vila Real'],
  ['1801', 'Armamar'],
  ['1802', 'Carregal do Sal'],
  ['1803', 'Castro Daire'],
  ['1804', 'Cinfães'],
  ['1805', 'Lamego'],
  ['1806', 'Mangualde'],
  ['1807', 'Moimenta da Beira'],
  ['1808', 'Mortágua'],
  ['1809', 'Nelas'],
  ['1810', 'Oliveira de Frades'],
  ['1811', 'Penalva do Castelo'],
  ['1812', 'Penedono'],
  ['1813', 'Resende'],
  ['1814', 'Santa Comba Dão'],
  ['1815', 'São João da Pesqueira'],
  ['1816', 'São Pedro do Sul'],
  ['1817', 'Sátão'],
  ['1818', 'Sernancelhe'],
  ['1819', 'Tabuaço'],
  ['1820', 'Tarouca'],
  ['1821', 'Tondela'],
  ['1822', 'Vila Nova de Paiva'],
  ['1823', 'Viseu'],
  ['1824', 'Vouzela'],
  ['3101', 'Calheta'],
  ['3102', 'Câmara de Lobos'],
  ['3103', 'Funchal'],
  ['3104', 'Machico'],
  ['3105', 'Ponta do Sol'],
  ['3106', 'Porto Moniz'],
  ['3107', 'Ribeira Brava'],
  ['3108', 'Santa Cruz'],
  ['3109', 'Santana'],
  ['3110', 'São Vicente'],
  ['3201', 'Porto Santo'],
  ['4101', 'Vila do Porto'],
  ['4201', 'Lagoa'],
  ['4202', 'Nordeste'],
  ['4203', 'Ponta Delgada'],
  ['4204', 'Povoação'],
  ['4205', 'Ribeira Grande'],
  ['4206', 'Vila Franca do Campo'],
  ['4301', 'Angra do Heroísmo'],
  ['4302', 'Vila da Praia da Vitória'],
  ['4401', 'Santa Cruz da Graciosa'],
  ['4501', 'Calheta'],
  ['4502', 'Velas'],
  ['4601', 'Lajes do Pico'],
  ['4602', 'Madalena'],
  ['4603', 'São Roque do Pico'],
  ['4701', 'Horta'],
  ['4801', 'Lajes das Flores'],
  ['4802', 'Santa Cruz das Flores'],
  ['4901', 'Corvo'],
].sort((a, b) => a[1].localeCompare(b[1], 'en', { sensitivity: 'base' }));

export const VC_DB_PREDIAL_PARISHS = [
  ['010101', 'Agadão'],
  ['010102', 'Aguada de Baixo'],
  ['010103', 'Aguada de Cima'],
  ['010104', 'Águeda'],
  ['010105', 'Barrô'],
  ['010106', 'Belazaima do Chão'],
  ['010107', 'Castanheira do Vouga'],
  ['010108', 'Espinhel'],
  ['010109', 'Fermentelos'],
  ['010110', 'Lamas do Vouga'],
  ['010111', 'Macieira de Alcoba'],
  ['010112', 'Macinhata do Vouga'],
  ['010113', 'Óis da Ribeira'],
  ['010114', 'Préstimo'],
  ['010115', 'Recardães'],
  ['010116', 'Segadães'],
  ['010117', 'Travassô'],
  ['010118', 'Trofa'],
  ['010119', 'Valongo do Vouga'],
  ['010120', 'Borralha'],
  ['010201', 'Albergaria-a-Velha'],
  ['010202', 'Alquerubim'],
  ['010203', 'Angeja'],
  ['010204', 'Branca'],
  ['010205', 'Frossos'],
  ['010206', 'Ribeira de Fráguas'],
  ['010207', 'São João de Loure'],
  ['010208', 'Valmaior'],
  ['010301', 'Amoreira da Gândara'],
  ['010302', 'Ancas'],
  ['010303', 'Arcos'],
  ['010304', 'Avelãs de Caminho'],
  ['010305', 'Avelãs de Cima'],
  ['010306', 'Mogofores'],
  ['010307', 'Moita'],
  ['010308', 'Óis do Bairro'],
  ['010309', 'Sangalhos'],
  ['010310', 'São Lourenço do Bairro'],
  ['010311', 'Tamengos'],
  ['010312', 'Vila Nova de Monsarros'],
  ['010313', 'Vilarinho do Bairro'],
  ['010401', 'Albergaria da Serra'],
  ['010402', 'Alvarenga'],
  ['010403', 'Arouca'],
  ['010404', 'Burgo'],
  ['010405', 'Cabreiros'],
  ['010406', 'Canelas'],
  ['010407', 'Chave'],
  ['010408', 'Covelo de Paivó'],
  ['010409', 'Escariz'],
  ['010410', 'Espiunca'],
  ['010411', 'Fermêdo'],
  ['010412', 'Janarde'],
  ['010413', 'Mansores'],
  ['010414', 'Moldes'],
  ['010415', 'Rossas'],
  ['010416', 'Santa Eulália'],
  ['010417', 'São Miguel do Mato'],
  ['010418', 'Tropêço'],
  ['010419', 'Urrô'],
  ['010420', 'Várzea'],
  ['010501', 'Aradas'],
  ['010502', 'Cacia'],
  ['010503', 'Eirol'],
  ['010504', 'Eixo'],
  ['010505', 'Esgueira'],
  ['010506', 'Glória'],
  ['010507', 'Nariz'],
  ['010508', 'Oliveirinha'],
  ['010509', 'Requeixo'],
  ['010510', 'S. Bernardo'],
  ['010511', 'S. Jacinto'],
  ['010512', 'Vera Cruz'],
  ['010513', 'Santa Joana'],
  ['010514', 'Nossa Senhora de Fátima'],
  ['010601', 'Bairros'],
  ['010602', 'Fornos'],
  ['010603', 'Paraíso'],
  ['010604', 'Pedorido'],
  ['010605', 'Raiva'],
  ['010606', 'Real'],
  ['010607', 'Santa Maria de Sardoura'],
  ['010608', 'São Martinho de Sardoura'],
  ['010609', 'Sobrado'],
  ['010701', 'Anta'],
  ['010702', 'Espinho'],
  ['010703', 'Guetim'],
  ['010704', 'Paramos'],
  ['010705', 'Silvalde'],
  ['010801', 'Avanca'],
  ['010802', 'Beduido'],
  ['010803', 'Canelas'],
  ['010804', 'Fermelã'],
  ['010805', 'Pardilhó'],
  ['010806', 'Salreu'],
  ['010807', 'Veiros'],
  ['010901', 'Argoncilhe'],
  ['010902', 'Arrifana'],
  ['010903', 'Canedo'],
  ['010904', 'Escapães'],
  ['010905', 'Espargo'],
  ['010906', 'Feira'],
  ['010907', 'Fiães'],
  ['010908', 'Fornos'],
  ['010909', 'Gião'],
  ['010910', 'Guisande'],
  ['010911', 'Lobão'],
  ['010912', 'Louredo'],
  ['010913', 'Lourosa'],
  ['010914', 'Milheirós de Poiares'],
  ['010915', 'Mosteiró'],
  ['010916', 'Mozelos'],
  ['010917', 'Nogueira da Regedoura'],
  ['010918', 'São Paio de Oleiros'],
  ['010919', 'Paços de Brandão'],
  ['010920', 'Pigeiros'],
  ['010921', 'Rio Meão'],
  ['010922', 'Romariz'],
  ['010923', 'Sanfins'],
  ['010924', 'Sanguedo'],
  ['010925', 'Santa Maria de Lamas'],
  ['010926', 'São João de Ver'],
  ['010927', 'Caldas de São Jorge'],
  ['010928', 'Souto'],
  ['010929', 'Travanca'],
  ['010930', 'Vale'],
  ['010931', 'Vila Maior'],
  ['011001', 'Gafanha do Carmo'],
  ['011002', 'Gafanha da Encarnação'],
  ['011003', 'Gafanha da Nazaré'],
  ['011004', 'Ílhavo (São Salvador)'],
  ['011101', 'Antes'],
  ['011102', 'Barcouço'],
  ['011103', 'Casal Comba'],
  ['011104', 'Luso'],
  ['011105', 'Mealhada'],
  ['011106', 'Pampilhosa'],
  ['011107', 'Vacariça'],
  ['011108', 'Ventosa do Bairro'],
  ['011201', 'Bunheiro'],
  ['011202', 'Monte'],
  ['011203', 'Murtosa'],
  ['011204', 'Torreira'],
  ['011301', 'Carregosa'],
  ['011302', 'Cesar'],
  ['011303', 'Fajões'],
  ['011304', 'Loureiro'],
  ['011305', 'Macieira de Sarnes'],
  ['011306', 'Macinhata da Seixa'],
  ['011307', 'Madaíl'],
  ['011308', 'Nogueira do Cravo'],
  ['011309', 'Oliveira de Azeméis'],
  ['011310', 'Ossela'],
  ['011311', 'Palmaz'],
  ['011312', 'Pindelo'],
  ['011313', 'Pinheiro da Bemposta'],
  ['011314', 'São Tiago de Riba-Ul'],
  ['011315', 'São Martinho da Gandâra'],
  ['011316', 'Travanca'],
  ['011317', 'Ul'],
  ['011318', 'São Roque'],
  ['011319', 'Cucujães'],
  ['011401', 'Bustos'],
  ['011402', 'Mamarrosa'],
  ['011403', 'Oiã'],
  ['011404', 'Oliveira do Bairro'],
  ['011405', 'Palhaça'],
  ['011406', 'Troviscal'],
  ['011501', 'Arada'],
  ['011502', 'Cortegaça'],
  ['011503', 'Esmoriz'],
  ['011504', 'Maceda'],
  ['011505', 'Ovar'],
  ['011506', 'S. Vicente de Pereira'],
  ['011507', 'Válega'],
  ['011508', 'S. João'],
  ['011601', 'São João da Madeira'],
  ['011701', 'Cedrim'],
  ['011702', 'Couto de Esteves'],
  ['011703', 'Paradela'],
  ['011704', 'Pessegueiro do Vouga'],
  ['011705', 'Rocas do Vouga'],
  ['011706', 'Sever do Vouga'],
  ['011707', 'Silva Escura'],
  ['011708', 'Talhadas'],
  ['011709', 'Dornelas'],
  ['011801', 'Calvão'],
  ['011802', 'Covão do Lobo'],
  ['011803', 'Fonte de Angeão'],
  ['011804', 'Gafanha da Boa Hora'],
  ['011805', 'Ouca'],
  ['011806', 'Ponte de Vagos'],
  ['011807', 'Sosa'],
  ['011808', 'Vagos'],
  ['011809', 'Santo António de Vagos'],
  ['011810', 'Santo André de Vagos'],
  ['011811', 'Santa Catarina'],
  ['011901', 'Arões'],
  ['011902', 'São Pedro de Castelões'],
  ['011903', 'Cepelos'],
  ['011904', 'Codal'],
  ['011905', 'Junqueira'],
  ['011906', 'Macieira de Cambra'],
  ['011907', 'Rôge'],
  ['011908', 'Vila Chã'],
  ['011909', 'Vila Cova de Perrinho'],
  ['020101', 'Aljustrel'],
  ['020102', 'Ervidel'],
  ['020103', 'Messejana'],
  ['020104', 'São João de Negrilhos'],
  ['020105', 'Rio de Moinhos'],
  ['020201', 'Almodôvar'],
  ['020202', 'Gomes Aires'],
  ['020203', 'Rosário'],
  ['020204', 'Santa Clara-a-Nova'],
  ['020205', 'Santa Cruz'],
  ['020206', 'São Barnabé'],
  ['020207', 'Graça de Padrões'],
  ['020208', 'Aldeia dos Fernandes'],
  ['020301', 'Alvito'],
  ['020302', 'Vila Nova da Baronia'],
  ['020401', 'Barrancos'],
  ['020501', 'Albernoa'],
  ['020502', 'Baleizão'],
  ['020503', 'Beringel'],
  ['020504', 'Cabeça Gorda'],
  ['020505', 'Mombeja'],
  ['020506', 'Nossa Senhora das Neves'],
  ['020507', 'Quintos'],
  ['020508', 'Salvada'],
  ['020509', 'Beja (Salvador)'],
  ['020510', 'Santa Clara de Louredo'],
  ['020511', 'Beja (Santa Maria da Feira)'],
  ['020512', 'Santa Vitória'],
  ['020513', 'Beja (Santiago Maior)'],
  ['020514', 'São Brissos'],
  ['020515', 'Beja (São João Baptista)'],
  ['020516', 'São Matias'],
  ['020517', 'Trindade'],
  ['020518', 'Trigaches'],
  ['020601', 'Casével'],
  ['020602', 'Castro Verde'],
  ['020603', 'Entradas'],
  ['020604', 'Santa Bárbara de Padrões'],
  ['020605', 'S. Marcos da Ataboeira'],
  ['020701', 'Cuba'],
  ['020702', 'Faro do Alentejo'],
  ['020703', 'Vila Alva'],
  ['020704', 'Vila Ruiva'],
  ['020801', 'Alfundão'],
  ['020802', 'Ferreira do Alentejo'],
  ['020803', 'Figueira dos Cavaleiros'],
  ['020804', 'Odivelas'],
  ['020805', 'Peroguarda'],
  ['020806', 'Canhestros'],
  ['020901', 'Alcaria Ruiva'],
  ['020902', 'Corte do Pinto'],
  ['020903', 'Espírito Santo'],
  ['020904', 'Mértola'],
  ['020905', 'Santana de Cambas'],
  ['020906', 'São João dos Caldeireiros'],
  ['020907', 'São Miguel do Pinheiro'],
  ['020908', 'São Pedro de Sólis'],
  ['020909', 'São Sebastião dos Carros'],
  ['021001', 'Amareleja'],
  ['021002', 'Póvoa de São Miguel'],
  ['021003', 'Safara'],
  ['021004', 'Moura (Santo Agostinho)'],
  ['021005', 'Santo Aleixo da Restauração'],
  ['021006', 'Santo Amador'],
  ['021007', 'Moura (S. João Baptista)'],
  ['021008', 'Sobral da Adiça'],
  ['021101', 'Colos'],
  ['021102', 'Relíquias'],
  ['021103', 'Sabóia'],
  ['021104', 'Santa Clara-a-Velha'],
  ['021105', 'Santa Maria'],
  ['021106', 'São Luís'],
  ['021107', 'São Martinho das Amoreiras'],
  ['021108', 'São Salvador'],
  ['021109', 'São Teotónio'],
  ['021110', 'Vale de Santiago'],
  ['021111', 'Vila Nova de Milfontes'],
  ['021112', 'Pereiras-Gare'],
  ['021113', 'Bicos'],
  ['021114', 'Zambujeira do Mar'],
  ['021115', 'Luzianes-Gare'],
  ['021116', 'Boavista dos Pinheiros'],
  ['021117', 'Longueira/Almograve'],
  ['021201', 'Conceição'],
  ['021202', 'Garvão'],
  ['021203', 'Ourique'],
  ['021204', 'Panoias'],
  ['021205', 'Santa Luzia'],
  ['021206', 'Santana da Serra'],
  ['021301', 'Vila Nova de São Bento'],
  ['021302', 'Brinches'],
  ['021303', 'Pias'],
  ['021304', 'Serpa (Salvador)'],
  ['021305', 'Serpa (Santa Maria)'],
  ['021306', 'Vale de Vargo'],
  ['021307', 'Vila Verde de Ficalho'],
  ['021401', 'Pedrógão'],
  ['021402', 'Selmes'],
  ['021403', 'Vidigueira'],
  ['021404', 'Vila de Frades'],
  ['030101', 'Amares'],
  ['030102', 'Barreiros'],
  ['030103', 'Besteiros'],
  ['030104', 'Bico'],
  ['030105', 'Caires'],
  ['030106', 'Caldelas'],
  ['030107', 'Carrazedo'],
  ['030108', 'Dornelas'],
  ['030109', 'Ferreiros'],
  ['030110', 'Figueiredo'],
  ['030111', 'Fiscal'],
  ['030112', 'Goães'],
  ['030113', 'Lago'],
  ['030114', 'Paranhos'],
  ['030115', 'Paredes Secas'],
  ['030116', 'Portela'],
  ['030117', 'Prozelo'],
  ['030118', 'Rendufe'],
  ['030119', 'Bouro (Santa Maria)'],
  ['030120', 'Bouro (Santa Marta)'],
  ['030121', 'Sequeiros'],
  ['030122', 'Seramil'],
  ['030123', 'Torre'],
  ['030124', 'Vilela'],
  ['030201', 'Abade de Neiva'],
  ['030202', 'Aborim'],
  ['030203', 'Adães'],
  ['030204', 'Aguiar'],
  ['030205', 'Airó'],
  ['030206', 'Aldreu'],
  ['030207', 'Alheira'],
  ['030208', 'Alvelos'],
  ['030209', 'Arcozelo'],
  ['030210', 'Areias'],
  ['030211', 'Areias de Vilar'],
  ['030212', 'Balugães'],
  ['030213', 'Barcelinhos'],
  ['030214', 'Barcelos'],
  ['030215', 'Barqueiros'],
  ['030216', 'Cambeses'],
  ['030217', 'Campo'],
  ['030218', 'Carapeços'],
  ['030219', 'Carreira'],
  ['030220', 'Carvalhal'],
  ['030221', 'Carvalhos'],
  ['030222', 'Chavão'],
  ['030223', 'Chorente'],
  ['030224', 'Cossourado'],
  ['030225', 'Courel'],
  ['030226', 'Couto'],
  ['030227', 'Creixomil'],
  ['030228', 'Cristelo'],
  ['030229', 'Durrães'],
  ['030230', 'Encourados'],
  ['030231', 'Faria'],
  ['030232', 'Feitos'],
  ['030233', 'Fonte Coberta'],
  ['030234', 'Fornelos'],
  ['030235', 'Fragoso'],
  ['030236', 'Gamil'],
  ['030237', 'Gilmonde'],
  ['030238', 'Góios'],
  ['030239', 'Grimancelos'],
  ['030240', 'Gueral'],
  ['030241', 'Igreja Nova'],
  ['030242', 'Lama'],
  ['030243', 'Lijó'],
  ['030244', 'Macieira'],
  ['030245', 'Manhente'],
  ['030246', 'Mariz'],
  ['030247', 'Martim'],
  ['030248', 'Midões'],
  ['030249', 'Milhazes'],
  ['030250', 'Minhotães'],
  ['030251', 'Monte de Fralães'],
  ['030252', 'Moure'],
  ['030253', 'Negreiros'],
  ['030254', 'Oliveira'],
  ['030255', 'Palme'],
  ['030256', 'Panque'],
  ['030257', 'Paradela'],
  ['030258', 'Pedra Furada'],
  ['030259', 'Pereira'],
  ['030260', 'Perelhal'],
  ['030261', 'Pousa'],
  ['030262', 'Quintiães'],
  ['030263', 'Remelhe'],
  ['030264', 'Roriz'],
  ['030265', 'Rio Covo (Santa Eugénia)'],
  ['030266', 'Rio Covo (Santa Eulália)'],
  ['030267', 'Tamel (Santa Leocádia)'],
  ['030268', 'Galegos (Santa Maria)'],
  ['030269', 'Bastuço (Santo Estêvão)'],
  ['030270', 'Bastuço (S. João)'],
  ['030271', 'Alvito (S. Martinho)'],
  ['030272', 'Galegos (S. Martinho)'],
  ['030273', 'Vila Frescainha (S. Martinho)'],
  ['030274', 'Alvito (S. Pedro)'],
  ['030275', 'Vila Frescainha (S. Pedro)'],
  ['030276', 'Tamel (S.  Fins)'],
  ['030277', 'Tamel (S. Veríssimo)'],
  ['030278', 'Sequeade'],
  ['030279', 'Silva'],
  ['030280', 'Silveiros'],
  ['030281', 'Tregosa'],
  ['030282', 'Ucha'],
  ['030283', 'Várzea'],
  ['030284', 'Viatodos'],
  ['030285', 'Vila Boa'],
  ['030286', 'Vila Cova'],
  ['030287', 'Vila Seca'],
  ['030288', 'Vilar de Figos'],
  ['030289', 'Vilar do Monte'],
  ['030301', 'Adaúfe'],
  ['030302', 'Arcos'],
  ['030303', 'Arentim'],
  ['030304', 'Aveleda'],
  ['030305', 'Cabreiros'],
  ['030306', 'Celeirós'],
  ['030307', 'Braga (Cividade)'],
  ['030308', 'Crespos'],
  ['030309', 'Cunha'],
  ['030310', 'Dume'],
  ['030311', 'Escudeiros'],
  ['030312', 'Espinho'],
  ['030313', 'Esporões'],
  ['030314', 'Ferreiros'],
  ['030315', 'Figueiredo'],
  ['030316', 'Fraião'],
  ['030317', 'Frossos'],
  ['030318', 'Gondizalves'],
  ['030319', 'Gualtar'],
  ['030320', 'Guisande'],
  ['030321', 'Lamaçães'],
  ['030322', 'Lamas'],
  ['030323', 'Lomar'],
  ['030324', 'Braga (Maximinos)'],
  ['030325', 'Mire de Tibães'],
  ['030326', 'Morreira'],
  ['030327', 'Navarra'],
  ['030328', 'Nogueira'],
  ['030329', 'Nogueiró'],
  ['030330', 'Padim da Graça'],
  ['030331', 'Palmeira'],
  ['030332', 'Panoias'],
  ['030333', 'Parada de Tibães'],
  ['030334', 'Pedralva'],
  ['030335', 'Pousada'],
  ['030336', 'Priscos'],
  ['030337', 'Real'],
  ['030338', 'Ruilhe'],
  ['030339', 'Santa Lucrécia de Algeriz'],
  ['030340', 'Penso (Santo Estêvão)'],
  ['030341', 'Braga (São João do Souto)'],
  ['030342', 'Braga (S. José de S. Lázaro)'],
  ['030343', 'Passos (São Julião)'],
  ['030344', 'Este (São Mamede)'],
  ['030345', 'Merelim (São Paio)'],
  ['030346', 'Este (São Pedro)'],
  ['030347', 'Merelim (São Pedro)'],
  ['030348', 'Oliveira (São Pedro)'],
  ['030349', 'Braga (São Vicente)'],
  ['030350', 'Penso (São Vicente)'],
  ['030351', 'Braga (São Vítor)'],
  ['030352', 'Braga (Sé)'],
  ['030353', 'Semelhe'],
  ['030354', 'Sequeira'],
  ['030355', 'Sobreposta'],
  ['030356', 'Tadim'],
  ['030357', 'Tebosa'],
  ['030358', 'Tenões'],
  ['030359', 'Trandeiras'],
  ['030360', 'Vilaça'],
  ['030361', 'Vimieiro'],
  ['030362', 'Fradelos'],
  ['030401', 'Abadim'],
  ['030402', 'Alvite'],
  ['030403', 'Arco de Baúlhe'],
  ['030404', 'Basto'],
  ['030405', 'Bucos'],
  ['030406', 'Cabeceiras de Basto'],
  ['030407', 'Cavez'],
  ['030408', 'Faia'],
  ['030409', 'Gondiães'],
  ['030410', 'Outeiro'],
  ['030411', 'Painzela'],
  ['030412', 'Passos'],
  ['030413', 'Pedraça'],
  ['030414', 'Refojos de Basto'],
  ['030415', 'Rio Douro'],
  ['030416', 'Vila Nune'],
  ['030417', 'Vilar de Cunhas'],
  ['030501', 'Agilde'],
  ['030502', 'Arnoia'],
  ['030503', 'Borba da Montanha'],
  ['030504', 'Britelo'],
  ['030505', 'Caçarilhe'],
  ['030506', 'Canedo de Basto'],
  ['030507', 'Carvalho'],
  ['030508', 'Codeçoso'],
  ['030509', 'Corgo'],
  ['030510', 'Fervença'],
  ['030511', 'Gagos'],
  ['030512', 'Gémeos'],
  ['030513', 'Infesta'],
  ['030514', 'Molares'],
  ['030515', 'Moreira do Castelo'],
  ['030516', 'Ourilhe'],
  ['030517', 'Rego'],
  ['030518', 'Ribas'],
  ['030519', 'Basto (Santa Tecla)'],
  ['030520', 'Basto (São Clemente)'],
  ['030521', 'Vale de Bouro'],
  ['030522', 'Veade'],
  ['030601', 'Antas'],
  ['030602', 'Apúlia'],
  ['030603', 'Belinho'],
  ['030604', 'Curvos'],
  ['030605', 'Esposende'],
  ['030606', 'Fão'],
  ['030607', 'Fonte Boa'],
  ['030608', 'Forjães'],
  ['030609', 'Gandra'],
  ['030610', 'Gemeses'],
  ['030611', 'Mar'],
  ['030612', 'Marinhas'],
  ['030613', 'Palmeira de Faro'],
  ['030614', 'Rio Tinto'],
  ['030615', 'Vila Chã'],
  ['030701', 'Aboim'],
  ['030702', 'Agrela'],
  ['030703', 'Antime'],
  ['030704', 'Ardegão'],
  ['030705', 'Armil'],
  ['030706', 'Arnozela'],
  ['030707', 'Cepães'],
  ['030708', 'Estorãos'],
  ['030709', 'Fafe'],
  ['030710', 'Fareja'],
  ['030711', 'Felgueiras'],
  ['030712', 'Fornelos'],
  ['030713', 'Freitas'],
  ['030714', 'Golães'],
  ['030715', 'Gontim'],
  ['030716', 'Medelo'],
  ['030717', 'Monte'],
  ['030718', 'Moreira do Rei'],
  ['030719', 'Passos'],
  ['030720', 'Pedraído'],
  ['030721', 'Queimadela'],
  ['030722', 'Quinchães'],
  ['030723', 'Regadas'],
  ['030724', 'Revelhe'],
  ['030725', 'Ribeiros'],
  ['030726', 'Santa Cristina de Arões'],
  ['030727', 'São Clemente de Silvares'],
  ['030728', 'São Gens'],
  ['030729', 'São Martinho de Silvares'],
  ['030730', 'São Romão de Arões'],
  ['030731', 'Seidões'],
  ['030732', 'Serafão'],
  ['030733', 'Travassós'],
  ['030734', 'Várzea Cova'],
  ['030735', 'Vila Cova'],
  ['030736', 'Vinhós'],
  ['030801', 'Aldão'],
  ['030802', 'Arosa'],
  ['030803', 'Atães'],
  ['030804', 'Azurém'],
  ['030805', 'Balasar'],
  ['030806', 'Barco'],
  ['030807', 'Brito'],
  ['030808', 'Caldelas'],
  ['030809', 'Calvos'],
  ['030810', 'Castelões'],
  ['030811', 'Conde'],
  ['030812', 'Costa'],
  ['030813', 'Creixomil'],
  ['030814', 'Donim'],
  ['030815', 'Fermentões'],
  ['030816', 'Figueiredo'],
  ['030817', 'Gandarela'],
  ['030818', 'Gémeos'],
  ['030819', 'Gominhães'],
  ['030820', 'Gonça'],
  ['030821', 'Gondar'],
  ['030822', 'Gondomar'],
  ['030823', 'Guardizela'],
  ['030824', 'Infantas'],
  ['030826', 'Leitões'],
  ['030827', 'Longos'],
  ['030828', 'Lordelo'],
  ['030829', 'Mascotelos'],
  ['030830', 'Mesão Frio'],
  ['030831', 'Moreira de Cónegos'],
  ['030832', 'Nespereira'],
  ['030833', 'Oleiros'],
  ['030834', 'Guimarães (Oliveira do Castelo)'],
  ['030835', 'Pencelo'],
  ['030836', 'Pinheiro'],
  ['030837', 'Polvoreira'],
  ['030838', 'Ponte'],
  ['030839', 'Rendufe'],
  ['030840', 'Ronfe'],
  ['030841', 'Briteiros (São Salvador)'],
  ['030842', 'Prazins (Santa Eufémia)'],
  ['030843', 'Briteiros (Santa Leocádia)'],
  ['030844', 'Airão (Santa Maria)'],
  ['030845', 'Souto (Santa Maria)'],
  ['030846', 'Candoso (São Tiago)'],
  ['030847', 'Briteiros (Santo Estevão)'],
  ['030848', 'Prazins (Santo Tirso)'],
  ['030849', 'Sande (São Clemente)'],
  ['030850', 'Selho (São Cristóvão)'],
  ['030851', 'São Faustino'],
  ['030853', 'Airão (São João Baptista)'],
  ['030854', 'Selho (São Jorge)'],
  ['030855', 'Sande (São Lourenço)'],
  ['030856', 'Selho (São Lourenço)'],
  ['030857', 'Candoso (São Martinho)'],
  ['030858', 'Sande (São Martinho)'],
  ['030860', 'Guimarães (São Paio)'],
  ['030862', 'Souto (São Salvador)'],
  ['030863', 'Guimarães (São Sebastião)'],
  ['030864', 'Abação (São Tomé)'],
  ['030865', 'São Torcato'],
  ['030866', 'Serzedelo'],
  ['030867', 'Serzedo'],
  ['030868', 'Silvares'],
  ['030869', 'Tabuadelo'],
  ['030871', 'Urgezes'],
  ['030872', 'Vermil'],
  ['030873', 'Sande (Vila Nova)'],
  ['030874', 'Corvite'],
  ['030901', 'Águas Santas'],
  ['030902', 'Ajude'],
  ['030903', 'Brunhais'],
  ['030904', 'Calvos'],
  ['030905', 'Campos'],
  ['030906', 'Covelas'],
  ['030907', 'Esperança'],
  ['030908', 'Ferreiros'],
  ['030909', 'Fonte Arcada'],
  ['030910', 'Frades'],
  ['030911', 'Friande'],
  ['030912', 'Galegos'],
  ['030913', 'Garfe'],
  ['030914', 'Geraz do Minho'],
  ['030915', 'Lanhoso'],
  ['030916', 'Louredo'],
  ['030917', 'Monsul'],
  ['030918', 'Moure'],
  ['030919', 'Póvoa de Lanhoso (Nossa Senhora do Amparo)'],
  ['030920', 'Oliveira'],
  ['030921', 'Rendufinho'],
  ['030922', 'Santo Emilião'],
  ['030923', 'São João de Rei'],
  ['030924', 'Serzedelo'],
  ['030925', 'Sobradelo da Goma'],
  ['030926', 'Taíde'],
  ['030927', 'Travassos'],
  ['030928', 'Verim'],
  ['030929', 'Vilela'],
  ['031001', 'Balança'],
  ['031002', 'Brufe'],
  ['031003', 'Campo do Gerês'],
  ['031004', 'Carvalheira'],
  ['031005', 'Chamoim'],
  ['031006', 'Chorense'],
  ['031007', 'Cibões'],
  ['031008', 'Covide'],
  ['031009', 'Gondoriz'],
  ['031010', 'Moimenta'],
  ['031011', 'Monte'],
  ['031012', 'Ribeira'],
  ['031013', 'Rio Caldo'],
  ['031014', 'Souto'],
  ['031015', 'Valdosende'],
  ['031016', 'Vilar'],
  ['031017', 'Vilar da Veiga'],
  ['031101', 'Anissó'],
  ['031102', 'Anjos'],
  ['031103', 'Campos'],
  ['031104', 'Caniçada'],
  ['031105', 'Cantelães'],
  ['031106', 'Cova'],
  ['031107', 'Eira Vedra'],
  ['031108', 'Guilhofrei'],
  ['031109', 'Louredo'],
  ['031110', 'Mosteiro'],
  ['031111', 'Parada do Bouro'],
  ['031112', 'Pinheiro'],
  ['031113', 'Rossas'],
  ['031114', 'Ruivães'],
  ['031115', 'Salamonde'],
  ['031116', 'Soengas'],
  ['031117', 'Soutelo'],
  ['031118', 'Tabuaças'],
  ['031119', 'Ventosa'],
  ['031120', 'Vieira do Minho'],
  ['031121', 'Vilar do Chão'],
  ['031201', 'Abade de Vermoim'],
  ['031202', 'Antas'],
  ['031203', 'Avidos'],
  ['031204', 'Bairro'],
  ['031205', 'Bente'],
  ['031206', 'Brufe'],
  ['031207', 'Cabeçudos'],
  ['031208', 'Calendário'],
  ['031209', 'Carreira'],
  ['031210', 'Castelões'],
  ['031211', 'Cavalões'],
  ['031212', 'Cruz'],
  ['031213', 'Delães'],
  ['031214', 'Esmeriz'],
  ['031215', 'Fradelos'],
  ['031216', 'Gavião'],
  ['031217', 'Gondifelos'],
  ['031218', 'Jesufrei'],
  ['031219', 'Joane'],
  ['031220', 'Lagoa'],
  ['031221', 'Landim'],
  ['031222', 'Lemenhe'],
  ['031223', 'Louro'],
  ['031224', 'Lousado'],
  ['031225', 'Mogege'],
  ['031226', 'Mouquim'],
  ['031227', 'Nine'],
  ['031228', 'Novais'],
  ['031229', 'Outiz'],
  ['031230', 'Pedome'],
  ['031231', 'Portela'],
  ['031232', 'Pousada de Saramagos'],
  ['031233', 'Requião'],
  ['031234', 'Riba de Ave'],
  ['031235', 'Ribeirão'],
  ['031236', 'Ruivães'],
  ['031237', 'Arnoso (Santa Eulália)'],
  ['031238', 'Arnoso (Santa Maria)'],
  ['031239', 'Oliveira (Santa Maria)'],
  ['031240', 'Vale (S. Cosme)'],
  ['031241', 'Vale (S. Martinho)'],
  ['031242', 'Oliveira (S. Mateus)'],
  ['031243', 'Seide (S. Miguel)'],
  ['031244', 'Seide (S. Paio)'],
  ['031245', 'Sezures'],
  ['031246', 'Telhado'],
  ['031247', 'Vermoim'],
  ['031248', 'Vila Nova de Famalicão'],
  ['031249', 'Vilarinho das Cambas'],
  ['031301', 'Aboim da Nóbrega'],
  ['031302', 'Arcozelo'],
  ['031303', 'Atães'],
  ['031304', 'Atiães'],
  ['031305', 'Azões'],
  ['031306', 'Barbudo'],
  ['031307', 'Barros'],
  ['031308', 'Cabanelas'],
  ['031309', 'Cervães'],
  ['031310', 'Codeceda'],
  ['031311', 'Coucieiro'],
  ['031312', 'Covas'],
  ['031313', 'Dossãos'],
  ['031314', 'Duas Igrejas'],
  ['031315', 'Esqueiros'],
  ['031316', 'Freiriz'],
  ['031317', 'Geme'],
  ['031318', 'Goães'],
  ['031319', 'Godinhaços'],
  ['031320', 'Gomide'],
  ['031321', 'Gondiães'],
  ['031322', 'Gondomar'],
  ['031323', 'Laje'],
  ['031324', 'Lanhas'],
  ['031325', 'Loureira'],
  ['031326', 'Marrancos'],
  ['031327', 'Mós'],
  ['031328', 'Moure'],
  ['031329', 'Nevogilde'],
  ['031330', 'Oleiros'],
  ['031331', 'Parada de Gatim'],
  ['031332', 'Passó'],
  ['031333', 'Pedregais'],
  ['031334', 'Penascais'],
  ['031335', 'Pico'],
  ['031336', 'Pico de Regalados'],
  ['031337', 'Ponte'],
  ['031338', 'Portela das Cabras'],
  ['031339', 'Rio Mau'],
  ['031340', 'Sabariz'],
  ['031341', 'Sande'],
  ['031342', 'Vila de Prado'],
  ['031343', 'Oriz (Santa Marinha)'],
  ['031344', 'Carreiras (Santiago)'],
  ['031345', 'Escariz (S. Mamede)'],
  ['031346', 'Escariz (S. Martinho)'],
  ['031347', 'Valbom (S. Martinho)'],
  ['031348', 'Carreiras (S. Miguel)'],
  ['031349', 'Oriz (S. Miguel)'],
  ['031350', 'Prado (S. Miguel)'],
  ['031351', 'Valbom (S. Pedro)'],
  ['031352', 'Soutelo'],
  ['031353', 'Travassós'],
  ['031354', 'Turiz'],
  ['031355', 'Valdreu'],
  ['031356', 'Valões'],
  ['031357', 'Vila Verde'],
  ['031358', 'Vilarinho'],
  ['031401', 'Santa Eulália'],
  ['031402', 'São João das Caldas de Vizela'],
  ['031403', 'São Miguel das Caldas de Vizela'],
  ['031404', 'Infias'],
  ['031405', 'Tagilde'],
  ['031406', 'Santo Adrião de Vizela'],
  ['031407', 'São Paio de Vizela'],
  ['040101', 'Agrobom'],
  ['040102', 'Alfândega da Fé'],
  ['040103', 'Cerejais'],
  ['040104', 'Eucísia'],
  ['040105', 'Ferradosa'],
  ['040106', 'Gebelim'],
  ['040107', 'Gouveia'],
  ['040108', 'Parada'],
  ['040109', 'Pombal'],
  ['040110', 'Saldonha'],
  ['040111', 'Sambade'],
  ['040112', 'Sendim da Ribeira'],
  ['040113', 'Sendim da Serra'],
  ['040114', 'Soeima'],
  ['040115', 'Valpereiro'],
  ['040116', 'Vales'],
  ['040117', 'Valverde'],
  ['040118', 'Vilar Chão'],
  ['040119', 'Vilarelhos'],
  ['040120', 'Vilares da Vilariça'],
  ['040201', 'Alfaião'],
  ['040202', 'Aveleda'],
  ['040203', 'Babe'],
  ['040204', 'Baçal'],
  ['040205', 'Calvelhe'],
  ['040206', 'Carragosa'],
  ['040207', 'Carrazedo'],
  ['040208', 'Castrelos'],
  ['040209', 'Castro de Avelãs'],
  ['040210', 'Coelhoso'],
  ['040211', 'Deilão'],
  ['040212', 'Donai'],
  ['040213', 'Espinhosela'],
  ['040214', 'Failde'],
  ['040215', 'França'],
  ['040216', 'Gimonde'],
  ['040217', 'Gondesende'],
  ['040218', 'Gostei'],
  ['040219', 'Grijó de Parada'],
  ['040220', 'Izeda'],
  ['040221', 'Macedo do Mato'],
  ['040222', 'Meixedo'],
  ['040223', 'Milhão'],
  ['040224', 'Mós'],
  ['040225', 'Nogueira'],
  ['040226', 'Outeiro'],
  ['040227', 'Parada'],
  ['040228', 'Paradinha Nova'],
  ['040229', 'Parâmio'],
  ['040230', 'Pinela'],
  ['040231', 'Pombares'],
  ['040232', 'Quintanilha'],
  ['040233', 'Quintela de Lampaças'],
  ['040234', 'Rabal'],
  ['040235', 'Rebordainhos'],
  ['040236', 'Rebordãos'],
  ['040237', 'Rio Frio'],
  ['040238', 'Rio de Onor'],
  ['040239', 'Salsas'],
  ['040240', 'Samil'],
  ['040241', 'Santa Comba de Rossas'],
  ['040242', 'Santa Maria'],
  ['040243', 'São Julião de Palácios'],
  ['040244', 'São Pedro de Serracenos'],
  ['040245', 'Sé'],
  ['040246', 'Sendas'],
  ['040247', 'Serapicos'],
  ['040248', 'Sortes'],
  ['040249', 'Zoio'],
  ['040301', 'Amedo'],
  ['040302', 'Beira Grande'],
  ['040303', 'Belver'],
  ['040304', 'Carrazeda de Ansiães'],
  ['040305', 'Castanheiro'],
  ['040306', 'Fonte Longa'],
  ['040307', 'Lavandeira'],
  ['040308', 'Linhares'],
  ['040309', 'Marzagão'],
  ['040310', 'Mogo de Malta'],
  ['040311', 'Parambos'],
  ['040312', 'Pereiros'],
  ['040313', 'Pinhal do Norte'],
  ['040314', 'Pombal'],
  ['040315', 'Ribalonga'],
  ['040316', 'Seixo de Ansiães'],
  ['040317', 'Selores'],
  ['040318', 'Vilarinho da Castanheira'],
  ['040319', 'Zedes'],
  ['040401', 'Fornos'],
  ['040402', 'Freixo de Espada à Cinta'],
  ['040403', 'Lagoaça'],
  ['040404', 'Ligares'],
  ['040405', 'Mazouco'],
  ['040406', 'Poiares'],
  ['040501', 'Ala'],
  ['040502', 'Amendoeira'],
  ['040503', 'Arcas'],
  ['040504', 'Bagueixe'],
  ['040505', 'Bornes'],
  ['040506', 'Burga'],
  ['040507', 'Carrapatas'],
  ['040508', 'Castelãos'],
  ['040509', 'Chacim'],
  ['040510', 'Cortiços'],
  ['040511', 'Corujas'],
  ['040512', 'Edroso'],
  ['040513', 'Espadanedo'],
  ['040514', 'Ferreira'],
  ['040515', 'Grijó'],
  ['040516', 'Lagoa'],
  ['040517', 'Lamalonga'],
  ['040518', 'Lamas'],
  ['040519', 'Lombo'],
  ['040520', 'Macedo de Cavaleiros'],
  ['040521', 'Morais'],
  ['040522', 'Murçós'],
  ['040523', 'Olmos'],
  ['040524', 'Peredo'],
  ['040525', 'Podence'],
  ['040526', 'Salselas'],
  ['040527', 'Santa Combinha'],
  ['040528', 'Sezulfe'],
  ['040529', 'Soutelo Mourisco'],
  ['040530', 'Talhas'],
  ['040531', 'Talhinhas'],
  ['040532', 'Vale Benfeito'],
  ['040533', 'Vale da Porca'],
  ['040534', 'Vale de Prados'],
  ['040535', 'Vilar do Monte'],
  ['040536', 'Vilarinho de Agrochão'],
  ['040537', 'Vilarinho do Monte'],
  ['040538', 'Vinhas'],
  ['040601', 'Atenor'],
  ['040602', 'Cicouro'],
  ['040603', 'Constantim'],
  ['040604', 'Duas Igrejas'],
  ['040605', 'Genísio'],
  ['040606', 'Ifanes'],
  ['040607', 'Malhadas'],
  ['040608', 'Miranda do Douro'],
  ['040609', 'Palaçoulo'],
  ['040610', 'Paradela'],
  ['040611', 'Picote'],
  ['040612', 'Póvoa'],
  ['040613', 'São Martinho de Angueira'],
  ['040614', 'Sendim'],
  ['040615', 'Silva'],
  ['040616', 'Vila Chã de Braciosa'],
  ['040617', 'Águas Vivas'],
  ['040701', 'Abambres'],
  ['040702', 'Abreiro'],
  ['040703', 'Aguieiras'],
  ['040704', 'Alvites'],
  ['040705', 'Avantos'],
  ['040706', 'Avidagos'],
  ['040707', 'Barcel'],
  ['040708', 'Bouça'],
  ['040709', 'Cabanelas'],
  ['040710', 'Caravelas'],
  ['040711', 'Carvalhais'],
  ['040712', 'Cedães'],
  ['040713', 'Cobro'],
  ['040714', 'Fradizela'],
  ['040715', 'Franco'],
  ['040716', 'Frechas'],
  ['040717', 'Freixeda'],
  ['040718', 'Lamas de Orelhão'],
  ['040719', 'Marmelos'],
  ['040720', 'Mascarenhas'],
  ['040721', 'Mirandela'],
  ['040722', 'Múrias'],
  ['040723', 'Navalho'],
  ['040724', 'Passos'],
  ['040725', 'Pereira'],
  ['040726', 'Romeu'],
  ['040727', 'São Pedro Velho'],
  ['040728', 'São Salvador'],
  ['040729', 'Suçães'],
  ['040730', 'Torre de Dona Chama'],
  ['040731', 'Vale de Asnes'],
  ['040732', 'Vale de Gouvinhas'],
  ['040733', 'Vale de Salgueiro'],
  ['040734', 'Vale de Telhas'],
  ['040735', 'Valverde'],
  ['040736', 'Vila Boa'],
  ['040737', 'Vila Verde'],
  ['040801', 'Azinhoso'],
  ['040802', 'Bemposta'],
  ['040803', 'Bruçó'],
  ['040804', 'Brunhoso'],
  ['040805', 'Brunhosinho'],
  ['040806', 'Castanheira'],
  ['040807', 'Castelo Branco'],
  ['040808', 'Castro Vicente'],
  ['040809', 'Meirinhos'],
  ['040810', 'Mogadouro'],
  ['040811', 'Paradela'],
  ['040812', 'Penas Roias'],
  ['040813', 'Peredo da Bemposta'],
  ['040814', 'Remondes'],
  ['040815', 'Saldanha'],
  ['040816', 'Sanhoane'],
  ['040817', 'São Martinho do Peso'],
  ['040818', 'Soutelo'],
  ['040819', 'Tó'],
  ['040820', 'Travanca'],
  ['040821', 'Urrós'],
  ['040822', 'Vale da Madre'],
  ['040823', 'Vale de Porco'],
  ['040824', 'Valverde'],
  ['040825', 'Ventozelo'],
  ['040826', 'Vila de Ala'],
  ['040827', 'Vilar de Rei'],
  ['040828', 'Vilarinho dos Galegos'],
  ['040901', 'Açoreira'],
  ['040902', 'Adeganha'],
  ['040903', 'Cabeça Boa'],
  ['040904', 'Cardanha'],
  ['040905', 'Carviçais'],
  ['040906', 'Castedo'],
  ['040907', 'Felgar'],
  ['040908', 'Felgueiras'],
  ['040909', 'Horta da Vilariça'],
  ['040910', 'Larinho'],
  ['040911', 'Lousa'],
  ['040912', 'Maçores'],
  ['040913', 'Mos'],
  ['040914', 'Peredo dos Castelhanos'],
  ['040915', 'Souto da Velha'],
  ['040916', 'Torre de Moncorvo'],
  ['040917', 'Urros'],
  ['041001', 'Assares'],
  ['041002', 'Benlhevai'],
  ['041003', 'Candoso'],
  ['041004', 'Carvalho de Egas'],
  ['041005', 'Freixiel'],
  ['041006', 'Lodões'],
  ['041007', 'Mourão'],
  ['041008', 'Nabo'],
  ['041009', 'Roios'],
  ['041010', 'Samões'],
  ['041011', 'Sampaio'],
  ['041012', 'Santa Comba de Vilariça'],
  ['041013', 'Seixo de Manhoses'],
  ['041014', 'Trindade'],
  ['041015', 'Vale Frechoso'],
  ['041016', 'Valtorno'],
  ['041017', 'Vila Flor'],
  ['041018', 'Vilarinho das Azenhas'],
  ['041019', 'Vilas Boas'],
  ['041101', 'Algoso'],
  ['041102', 'Angueira'],
  ['041103', 'Argozelo'],
  ['041104', 'Avelanoso'],
  ['041105', 'Caçarelhos'],
  ['041106', 'Campo de Víboras'],
  ['041107', 'Carção'],
  ['041108', 'Matela'],
  ['041109', 'Pinelo'],
  ['041110', 'Santulhão'],
  ['041111', 'Uva'],
  ['041112', 'Vale de Frades'],
  ['041113', 'Vilar Seco'],
  ['041114', 'Vimioso'],
  ['041201', 'Agrochão'],
  ['041202', 'Alvaredos'],
  ['041203', 'Candedo'],
  ['041204', 'Celas'],
  ['041205', 'Curopos'],
  ['041206', 'Edral'],
  ['041207', 'Edrosa'],
  ['041208', 'Ervedosa'],
  ['041209', 'Fresulfe'],
  ['041210', 'Mofreita'],
  ['041211', 'Moimenta'],
  ['041212', 'Montouto'],
  ['041213', 'Nunes'],
  ['041214', 'Ousilhão'],
  ['041215', 'Paço'],
  ['041216', 'Penhas Juntas'],
  ['041217', 'Pinheiro Novo'],
  ['041218', 'Quirás'],
  ['041219', 'Rebordelo'],
  ['041220', 'Santa Cruz'],
  ['041221', 'Santalha'],
  ['041222', 'São Jumil'],
  ['041223', 'Sobreiró de Baixo'],
  ['041224', 'Soeira'],
  ['041225', 'Travanca'],
  ['041226', 'Tuizelo'],
  ['041227', 'Vale das Fontes'],
  ['041228', 'Vale de Janeiro'],
  ['041229', 'Vila Boa de Ousilhão'],
  ['041230', 'Vila Verde'],
  ['041231', 'Vilar de Lomba'],
  ['041232', 'Vilar de Ossos'],
  ['041233', 'Vilar de Peregrinos'],
  ['041234', 'Vilar Seco de Lomba'],
  ['041235', 'Vinhais'],
  ['050101', 'Belmonte'],
  ['050102', 'Caria'],
  ['050103', 'Colmeal da Torre'],
  ['050104', 'Inguias'],
  ['050105', 'Maçainhas'],
  ['050201', 'Alcains'],
  ['050202', 'Almaceda'],
  ['050203', 'Benquerenças'],
  ['050204', 'Cafede'],
  ['050205', 'Castelo Branco'],
  ['050206', 'Cebolais de Cima'],
  ['050207', 'Escalos de Baixo'],
  ['050208', 'Escalos de Cima'],
  ['050209', 'Freixial do Campo'],
  ['050210', 'Juncal do Campo'],
  ['050211', 'Lardosa'],
  ['050212', 'Louriçal do Campo'],
  ['050213', 'Lousa'],
  ['050214', 'Malpica do Tejo'],
  ['050215', 'Mata'],
  ['050216', 'Monforte da Beira'],
  ['050217', 'Ninho do Açor'],
  ['050218', 'Póvoa de Rio de Moinhos'],
  ['050219', 'Retaxo'],
  ['050220', 'Salgueiro do Campo'],
  ['050221', 'Santo André das Tojeiras'],
  ['050222', 'S. Vicente da Beira'],
  ['050223', 'Sarzedas'],
  ['050224', 'Sobral do Campo'],
  ['050225', 'Tinalhas'],
  ['050301', 'Vila do Carvalho'],
  ['050302', 'Aldeia de São Francisco de Assis'],
  ['050303', 'Aldeia do Souto'],
  ['050304', 'Barco'],
  ['050305', 'Boidobra'],
  ['050306', 'Casegas'],
  ['050307', 'Conceição'],
  ['050308', 'Cortes'],
  ['050309', 'Dominguiso'],
  ['050310', 'Erada'],
  ['050311', 'Ferro'],
  ['050312', 'Orjais'],
  ['050313', 'Ourondo'],
  ['050314', 'Paúl'],
  ['050315', 'Peraboa'],
  ['050316', 'Peso'],
  ['050317', 'Santa Maria'],
  ['050318', 'São Jorge da Beira'],
  ['050319', 'São Martinho'],
  ['050320', 'São Pedro'],
  ['050321', 'Sarzedo'],
  ['050322', 'Sobral de São Miguel'],
  ['050323', 'Teixoso'],
  ['050324', 'Tortosendo'],
  ['050325', 'Unhais da Serra'],
  ['050326', 'Vale Formoso'],
  ['050327', 'Verdelhos'],
  ['050328', 'Vales do Rio'],
  ['050329', 'Coutada'],
  ['050330', 'Cantar-Galo'],
  ['050331', 'Canhoso'],
  ['050401', 'Alcaide'],
  ['050402', 'Alcaria'],
  ['050403', 'Alcongosta'],
  ['050404', 'Aldeia de Joanes'],
  ['050405', 'Aldeia Nova do Cabo'],
  ['050406', 'Alpedrinha'],
  ['050407', 'Atalaia do Campo'],
  ['050408', 'Barroca'],
  ['050409', 'Bogas de Baixo'],
  ['050410', 'Bogas de Cima'],
  ['050411', 'Capinha'],
  ['050412', 'Castelejo'],
  ['050413', 'Castelo Novo'],
  ['050414', 'Donas'],
  ['050415', 'Escarigo'],
  ['050416', 'Fatela'],
  ['050417', 'Fundão'],
  ['050418', 'Janeiro de Cima'],
  ['050419', 'Lavacolhos'],
  ['050420', 'Orca'],
  ['050421', 'Peroviseu'],
  ['050422', 'Póvoa de Atalaia'],
  ['050423', 'Salgueiro'],
  ['050424', 'Silvares'],
  ['050425', 'Soalheira'],
  ['050426', 'Souto da Casa'],
  ['050427', 'Telhado'],
  ['050428', 'Vale de Prazeres'],
  ['050429', 'Valverde'],
  ['050430', 'Mata da Rainha'],
  ['050431', 'Enxames'],
  ['050501', 'Alcafozes'],
  ['050502', 'Aldeia de Santa Margarida'],
  ['050503', 'Idanha-a-Nova'],
  ['050504', 'Idanha-a-Velha'],
  ['050505', 'Ladoeiro'],
  ['050506', 'Medelim'],
  ['050507', 'Monfortinho'],
  ['050508', 'Monsanto'],
  ['050509', 'Olêdo'],
  ['050510', 'Penha Garcia'],
  ['050511', 'Proença-a-Velha'],
  ['050512', 'Rosmaninhal'],
  ['050513', 'Salvaterra do Extremo'],
  ['050514', 'São Miguel de Acha'],
  ['050515', 'Segura'],
  ['050516', 'Toulões'],
  ['050517', 'Zebreira'],
  ['050601', 'Álvaro'],
  ['050602', 'Amieira'],
  ['050603', 'Cambas'],
  ['050604', 'Estreito'],
  ['050605', 'Isna'],
  ['050606', 'Madeirã'],
  ['050607', 'Mosteiro'],
  ['050608', 'Oleiros'],
  ['050609', 'Orvalho'],
  ['050610', 'Sarnadas de São Simão'],
  ['050611', 'Sobral'],
  ['050612', 'Vilar Barroco'],
  ['050701', 'Águas'],
  ['050702', 'Aldeia do Bispo'],
  ['050703', 'Aldeia de João Pires'],
  ['050704', 'Aranhas'],
  ['050705', 'Bemposta'],
  ['050706', 'Benquerença'],
  ['050707', 'Meimão'],
  ['050708', 'Meimoa'],
  ['050709', 'Pedrogão de São Pedro'],
  ['050710', 'Penamacor'],
  ['050711', 'Salvador'],
  ['050712', 'Vale da Senhora da Póvoa'],
  ['050801', 'Alvito da Beira'],
  ['050802', 'Montes da Senhora'],
  ['050803', 'Peral'],
  ['050804', 'Proença-a-Nova'],
  ['050805', 'São Pedro do Esteval'],
  ['050806', 'Sobreira Formosa'],
  ['050901', 'Cabeçudo'],
  ['050902', 'Carvalhal'],
  ['050903', 'Castelo'],
  ['050904', 'Cernache do Bonjardim'],
  ['050905', 'Cumeada'],
  ['050906', 'Ermida'],
  ['050907', 'Figueiredo'],
  ['050908', 'Marmeleiro'],
  ['050909', 'Nesperal'],
  ['050910', 'Palhais'],
  ['050911', 'Pedrógão Pequeno'],
  ['050912', 'Sertã'],
  ['050913', 'Troviscal'],
  ['050914', 'Várzea dos Cavaleiros'],
  ['051001', 'Fundada'],
  ['051002', 'São João do Peso'],
  ['051003', 'Vila de Rei'],
  ['051101', 'Fratel'],
  ['051102', 'Perais'],
  ['051103', 'Sarnadas de Ródão'],
  ['051104', 'Vila Velha de Ródão'],
  ['060101', 'Anceriz'],
  ['060102', 'Arganil'],
  ['060103', 'Barril de Alva'],
  ['060104', 'Benfeita'],
  ['060105', 'Celavisa'],
  ['060106', 'Cepos'],
  ['060107', 'Cerdeira'],
  ['060108', 'Coja'],
  ['060109', 'Folques'],
  ['060110', 'Moura da Serra'],
  ['060111', 'Piódão'],
  ['060112', 'Pomares'],
  ['060113', 'Pombeiro da Beira'],
  ['060114', 'São Martinho da Cortiça'],
  ['060115', 'Sarzedo'],
  ['060116', 'Secarias'],
  ['060117', 'Teixeira'],
  ['060118', 'Vila Cova de Alva'],
  ['060201', 'Ançã'],
  ['060202', 'Bolho'],
  ['060203', 'Cadima'],
  ['060204', 'Cantanhede'],
  ['060205', 'Cordinhã'],
  ['060206', 'Covões'],
  ['060207', 'Febres'],
  ['060208', 'Murtede'],
  ['060209', 'Ourentã'],
  ['060210', 'Outil'],
  ['060211', 'Pocariça'],
  ['060212', 'Portunhos'],
  ['060213', 'Sepins'],
  ['060214', 'Tocha'],
  ['060215', 'São Caetano'],
  ['060216', 'Corticeiro de Cima'],
  ['060217', 'Vilamar'],
  ['060218', 'Sanguinheira'],
  ['060219', 'Camarneira'],
  ['060301', 'Almalaguês'],
  ['060302', 'Coimbra (Almedina - Sé Velha)'],
  ['060303', 'Ameal'],
  ['060304', 'Antanhol'],
  ['060305', 'Antuzede'],
  ['060306', 'Arzila'],
  ['060307', 'Assafarge'],
  ['060308', 'Botão'],
  ['060309', 'Brasfemes'],
  ['060310', 'Castelo Viegas'],
  ['060311', 'Ceira'],
  ['060312', 'Cernache'],
  ['060313', 'Eiras'],
  ['060314', 'Lamarosa'],
  ['060315', 'Ribeira de Frades'],
  ['060316', 'Santa Clara'],
  ['060317', 'Coimbra (Santa Cruz)'],
  ['060318', 'Santo António dos Olivais'],
  ['060319', 'Coimbra (S. Bartolomeu)'],
  ['060320', 'S. João do Campo'],
  ['060321', 'S. Martinho de Árvore'],
  ['060322', 'São Martinho do Bispo'],
  ['060323', 'S. Paulo de Frades'],
  ['060324', 'S. Silvestre'],
  ['060325', 'Coimbra (Sé Nova)'],
  ['060326', 'Souselas'],
  ['060327', 'Taveiro'],
  ['060328', 'Torre de Vilela'],
  ['060329', 'Torres do Mondego'],
  ['060330', 'Trouxemil'],
  ['060331', 'Vil de Matos'],
  ['060401', 'Anobra'],
  ['060402', 'Belide'],
  ['060403', 'Bem da Fé'],
  ['060404', 'Condeixa-a-Nova'],
  ['060405', 'Condeixa-a-Velha'],
  ['060406', 'Ega'],
  ['060407', 'Furadouro'],
  ['060408', 'Sebal Grande'],
  ['060409', 'Vila Sêca'],
  ['060410', 'Zambujal'],
  ['060501', 'Alhadas'],
  ['060502', 'Alqueidão'],
  ['060503', 'Brenha'],
  ['060504', 'Buarcos'],
  ['060505', 'Ferreira-a-Nova'],
  ['060506', 'Lavos'],
  ['060507', 'Maiorca'],
  ['060508', 'Marinha das Ondas'],
  ['060509', 'Paião'],
  ['060510', 'Quiaios'],
  ['060511', 'São Julião'],
  ['060512', 'Tavarede'],
  ['060513', 'Vila Verde'],
  ['060514', 'São Pedro'],
  ['060515', 'Bom Sucesso'],
  ['060516', 'Santana'],
  ['060517', 'Borda do Campo'],
  ['060518', 'Moinhos da Gândara'],
  ['060601', 'Alvares'],
  ['060602', 'Cadafaz'],
  ['060603', 'Colmeal'],
  ['060604', 'Góis'],
  ['060605', 'Vila Nova do Ceira'],
  ['060701', 'Casal de Ermio'],
  ['060702', 'Foz de Arouce'],
  ['060703', 'Lousã'],
  ['060704', 'Serpins'],
  ['060705', 'Vilarinho'],
  ['060706', 'Gândaras'],
  ['060801', 'Mira'],
  ['060802', 'Seixo'],
  ['060803', 'Carapelhos'],
  ['060804', 'Praia de Mira'],
  ['060901', 'Lamas'],
  ['060902', 'Miranda do Corvo'],
  ['060903', 'Rio de Vide'],
  ['060904', 'Semide'],
  ['060905', 'Vila Nova'],
  ['061001', 'Abrunheira'],
  ['061002', 'Arazede'],
  ['061003', 'Carapinheira'],
  ['061004', 'Gatões'],
  ['061005', 'Liceia'],
  ['061006', 'Meãs'],
  ['061007', 'Montemor-o-Velho'],
  ['061008', 'Pereira'],
  ['061009', 'Santo Varão'],
  ['061010', 'Seixo'],
  ['061011', 'Tentúgal'],
  ['061012', 'Verride'],
  ['061013', 'Vila Nova da Barca'],
  ['061014', 'Ereira'],
  ['061101', 'Aldeia das Dez'],
  ['061102', 'Alvoco das Várzeas'],
  ['061103', 'Avô'],
  ['061104', 'Bobadela'],
  ['061105', 'Ervedal'],
  ['061106', 'Lagares'],
  ['061107', 'Lagos da Beira'],
  ['061108', 'Lajeosa'],
  ['061109', 'Lourosa'],
  ['061110', 'Meruge'],
  ['061111', 'Nogueira do Cravo'],
  ['061112', 'Oliveira do Hospital'],
  ['061113', 'Penalva de Alva'],
  ['061114', 'Santa Ovaia'],
  ['061115', 'São Gião'],
  ['061116', 'São Paio de Gramaços'],
  ['061117', 'São Sebastião da Feira'],
  ['061118', 'Seixo da Beira'],
  ['061119', 'Travanca de Lagos'],
  ['061120', 'Vila Pouca da Beira'],
  ['061121', 'Vila Franca da Beira'],
  ['061201', 'Cabril'],
  ['061202', 'Dornelas do Zêzere'],
  ['061203', 'Fajão'],
  ['061204', 'Janeiro de Baixo'],
  ['061205', 'Machio'],
  ['061206', 'Pampilhosa da Serra'],
  ['061207', 'Pessegueiro'],
  ['061208', 'Portela do Fojo'],
  ['061209', 'Unhais-O-Velho'],
  ['061210', 'Vidual'],
  ['061301', 'Carvalho'],
  ['061302', 'Figueira de Lorvão'],
  ['061303', 'Friúmes'],
  ['061304', 'Lorvão'],
  ['061305', 'Oliveira do Mondego'],
  ['061306', 'Paradela'],
  ['061307', 'Penacova'],
  ['061308', 'São Paio de Mondego'],
  ['061309', 'São Pedro de Alva'],
  ['061310', 'Sazes do Lorvão'],
  ['061311', 'Travanca do Mondego'],
  ['061401', 'Cumeeira'],
  ['061402', 'Espinhal'],
  ['061403', 'Podentes'],
  ['061404', 'Rabaçal'],
  ['061405', 'Penela (Santa Eufémia)'],
  ['061406', 'Penela (São Miguel)'],
  ['061501', 'Alfarelos'],
  ['061502', 'Brunhós'],
  ['061503', 'Degracias'],
  ['061504', 'Figueiró do Campo'],
  ['061505', 'Gesteira'],
  ['061506', 'Granja do Ulmeiro'],
  ['061507', 'Pombalinho'],
  ['061508', 'Samuel'],
  ['061509', 'Soure'],
  ['061510', 'Tapéus'],
  ['061511', 'Vila Nova de Anços'],
  ['061512', 'Vinha da Rainha'],
  ['061601', 'Ázere'],
  ['061602', 'Candosa'],
  ['061603', 'Carapinha'],
  ['061604', 'Covas'],
  ['061605', 'Covelo'],
  ['061606', 'Espariz'],
  ['061607', 'Meda de Mouros'],
  ['061608', 'Midões'],
  ['061609', 'Mouronho'],
  ['061610', 'Pinheiro de Coja'],
  ['061611', 'Póvoa de Midões'],
  ['061612', 'São João da Boa Vista'],
  ['061613', 'Sinde'],
  ['061614', 'Tábua'],
  ['061615', 'Vila Nova de Oliveirinha'],
  ['061701', 'Arrifana'],
  ['061702', 'Lavegadas'],
  ['061703', 'Poiares (Santo André)'],
  ['061704', 'São Miguel de Poiares'],
  ['070101', 'Alandroal (Nossa Senhora da Conceição)'],
  ['070102', 'Juromenha (Nossa Senhora do Loreto)'],
  ['070103', 'Santiago Maior'],
  ['070104', 'Capelins (Santo António)'],
  ['070105', 'Terena (São Pedro)'],
  ['070106', 'São Brás dos Matos (Mina do Bugalho)'],
  ['070201', 'Arraiolos'],
  ['070202', 'Igrejinha'],
  ['070203', 'Santa Justa'],
  ['070204', 'São Gregório'],
  ['070205', 'Gafanhoeira (São Pedro)'],
  ['070206', 'Vimieiro'],
  ['070301', 'Borba (Matriz)'],
  ['070302', 'Orada'],
  ['070303', 'Rio de Moinhos'],
  ['070304', 'Borba (S. Bartolomeu)'],
  ['070401', 'Arcos'],
  ['070402', 'Glória'],
  ['070403', 'Santa Maria'],
  ['070404', 'Évoramonte'],
  ['070405', 'Santa Vitória do Ameixial'],
  ['070406', 'Santo André'],
  ['070407', 'Santo Estêvão'],
  ['070408', 'São Bento do Ameixial'],
  ['070409', 'São Bento de Ana Loura'],
  ['070410', 'São Bento do Cortiço'],
  ['070411', 'São Domingos de Ana Loura'],
  ['070412', 'São Lourenço de Mamporcão'],
  ['070413', 'Veiros'],
  ['070501', 'Nossa Senhora da Boa Fé'],
  ['070502', 'Nossa Senhora da Graça do Divor'],
  ['070503', 'Nossa Senhora de Machede'],
  ['070504', 'Nossa Senhora da Tourega'],
  ['070505', 'Évora (Santo Antão)'],
  ['070506', 'S. Bento do Mato'],
  ['070507', 'Évora (S. Mamede)'],
  ['070508', 'S. Manços'],
  ['070509', 'S. Miguel de Machede'],
  ['070510', 'Évora (S. Pedro)'],
  ['070511', 'S. Vicente do Pigeiro'],
  ['070512', 'Évora (Sé)'],
  ['070513', 'Torre de Coelheiros'],
  ['070514', 'S. Sebastião da Giesteira'],
  ['070515', 'Canaviais'],
  ['070516', 'Nossa Senhora de Guadalupe'],
  ['070517', 'Bacelo'],
  ['070518', 'Horta das Figueiras'],
  ['070519', 'Malagueira'],
  ['070520', 'Sé e São Pedro'],
  ['070521', 'Senhora da Saúde'],
  ['070601', 'Cabrela'],
  ['070602', 'Lavre'],
  ['070603', 'Nossa Senhora do Bispo'],
  ['070604', 'Nossa Senhora da Vila'],
  ['070605', 'Santiago do Escoural'],
  ['070606', 'São Cristóvão'],
  ['070607', 'Ciborro'],
  ['070608', 'Cortiçadas de Lavre'],
  ['070609', 'Silveiras'],
  ['070610', 'Foros de Vale de Figueira'],
  ['070701', 'Brotas'],
  ['070702', 'Cabeção'],
  ['070703', 'Mora'],
  ['070704', 'Pavia'],
  ['070801', 'Granja'],
  ['070802', 'Luz'],
  ['070803', 'Mourão'],
  ['070901', 'Alqueva'],
  ['070902', 'Amieira'],
  ['070903', 'Monte do Trigo'],
  ['070904', 'Oriola'],
  ['070905', 'Portel'],
  ['070906', 'Santana'],
  ['070907', 'São Bartolomeu do Outeiro'],
  ['070908', 'Vera Cruz'],
  ['071001', 'Montoito'],
  ['071002', 'Redondo'],
  ['071101', 'Campo'],
  ['071102', 'Corval'],
  ['071103', 'Monsaraz'],
  ['071104', 'Reguengos de Monsaraz'],
  ['071105', 'Campinho'],
  ['071201', 'Vendas Novas'],
  ['071202', 'Landeira'],
  ['071301', 'Alcáçovas'],
  ['071302', 'Viana do Alentejo'],
  ['071303', 'Aguiar'],
  ['071401', 'Bencatel'],
  ['071402', 'Ciladas'],
  ['071403', 'Vila Viçosa (Conceição)'],
  ['071404', 'Pardais'],
  ['071405', 'Vila Viçosa (S. Bartolomeu)'],
  ['080101', 'Albufeira'],
  ['080102', 'Guia'],
  ['080103', 'Paderne'],
  ['080104', 'Ferreiras'],
  ['080105', 'Olhos de Água'],
  ['080201', 'Alcoutim'],
  ['080202', 'Giões'],
  ['080203', 'Martim Longo'],
  ['080204', 'Pereiro'],
  ['080205', 'Vaqueiros'],
  ['080301', 'Aljezur'],
  ['080302', 'Bordeira'],
  ['080303', 'Odeceixe'],
  ['080304', 'Rogil'],
  ['080401', 'Azinhal'],
  ['080402', 'Castro Marim'],
  ['080403', 'Odeleite'],
  ['080404', 'Altura'],
  ['080501', 'Conceição'],
  ['080502', 'Estói'],
  ['080503', 'Santa Bárbara de Nexe'],
  ['080504', 'Faro (S. Pedro)'],
  ['080505', 'Faro (Sé)'],
  ['080506', 'Montenegro'],
  ['080601', 'Estômbar'],
  ['080602', 'Ferragudo'],
  ['080603', 'Lagoa'],
  ['080604', 'Porches'],
  ['080605', 'Carvoeiro'],
  ['080606', 'Parchal'],
  ['080701', 'Barão de S. João'],
  ['080702', 'Bensafrim'],
  ['080703', 'Luz'],
  ['080704', 'Odiáxere'],
  ['080705', 'Lagos (Santa Maria)'],
  ['080706', 'Lagos (S. Sebastião)'],
  ['080801', 'Almancil'],
  ['080802', 'Alte'],
  ['080803', 'Ameixial'],
  ['080804', 'Boliqueime'],
  ['080805', 'Quarteira'],
  ['080806', 'Querença'],
  ['080807', 'Salir'],
  ['080808', 'Loulé (S. Clemente)'],
  ['080809', 'Loulé (S. Sebastião)'],
  ['080810', 'Benafim'],
  ['080811', 'Tôr'],
  ['080901', 'Alferce'],
  ['080902', 'Marmelete'],
  ['080903', 'Monchique'],
  ['081001', 'Fuseta'],
  ['081002', 'Moncarapacho'],
  ['081003', 'Olhão'],
  ['081004', 'Pechão'],
  ['081005', 'Quelfes'],
  ['081101', 'Alvor'],
  ['081102', 'Mexilhoeira Grande'],
  ['081103', 'Portimão'],
  ['081201', 'S. Brás de Alportel'],
  ['081301', 'Alcantarilha'],
  ['081302', 'Algoz'],
  ['081303', 'Armação de Pêra'],
  ['081304', 'Pêra'],
  ['081305', 'S. Bartolomeu de Messines'],
  ['081306', 'S. Marcos da Serra'],
  ['081307', 'Silves'],
  ['081308', 'Tunes'],
  ['081401', 'Cachopo'],
  ['081402', 'Conceição'],
  ['081403', 'Luz'],
  ['081404', 'Santa Catarina da Fonte do Bispo'],
  ['081405', 'Tavira (Santa Maria)'],
  ['081406', 'Tavira (Santiago)'],
  ['081407', 'Santo Estêvão'],
  ['081408', 'Santa Luzia'],
  ['081409', 'Cabanas de Tavira'],
  ['081501', 'Barão de São Miguel'],
  ['081502', 'Budens'],
  ['081503', 'Raposeira'],
  ['081504', 'Sagres'],
  ['081505', 'Vila do Bispo'],
  ['081601', 'Vila Nova de Cacela'],
  ['081602', 'Vila Real de Santo António'],
  ['081603', 'Monte Gordo'],
  ['090101', 'Aguiar da Beira'],
  ['090102', 'Carapito'],
  ['090103', 'Cortiçada'],
  ['090104', 'Coruche'],
  ['090105', 'Dornelas'],
  ['090106', 'Eirado'],
  ['090107', 'Forninhos'],
  ['090108', 'Gradiz'],
  ['090109', 'Pena Verde'],
  ['090110', 'Pinheiro'],
  ['090111', 'Sequeiros'],
  ['090112', 'Souto de Aguiar da Beira'],
  ['090113', 'Valverde'],
  ['090201', 'Ade'],
  ['090202', 'Aldeia Nova'],
  ['090203', 'Almeida'],
  ['090204', 'Amoreira'],
  ['090205', 'Azinhal'],
  ['090206', 'Cabreira'],
  ['090207', 'Castelo Bom'],
  ['090208', 'Castelo Mendo'],
  ['090209', 'Freineda'],
  ['090210', 'Freixo'],
  ['090211', 'Junça'],
  ['090212', 'Leomil'],
  ['090213', 'Malhada Sorda'],
  ['090214', 'Malpartida'],
  ['090215', 'Mesquitela'],
  ['090216', 'Mido'],
  ['090217', 'Miuzela'],
  ['090218', 'Monteperobolso'],
  ['090219', 'Nave de Haver'],
  ['090220', 'Naves'],
  ['090221', 'Parada'],
  ['090222', 'Peva'],
  ['090223', 'Porto de Ovelha'],
  ['090224', 'São Pedro de Rio Seco'],
  ['090225', 'Senouras'],
  ['090226', 'Vale de Coelha'],
  ['090227', 'Vale da Mula'],
  ['090228', 'Valeverde'],
  ['090229', 'Vilar Formoso'],
  ['090301', 'Açores'],
  ['090302', 'Baraçal'],
  ['090303', 'Cadafaz'],
  ['090304', 'Carrapichana'],
  ['090305', 'Cortiçô da Serra'],
  ['090306', 'Forno Telheiro'],
  ['090307', 'Lageosa do Mondego'],
  ['090308', 'Linhares'],
  ['090309', 'Maçal do Chão'],
  ['090310', 'Mesquitela'],
  ['090311', 'Minhocal'],
  ['090312', 'Prados'],
  ['090313', 'Rapa'],
  ['090314', 'Ratoeira'],
  ['090315', 'Salgueirais'],
  ['090316', 'Celorico (Santa Maria)'],
  ['090317', 'Celorico (São Pedro)'],
  ['090318', 'Vale de Azares'],
  ['090319', 'Velosa'],
  ['090320', 'Vide Entre Vinhas'],
  ['090321', 'Vila Boa do Mondego'],
  ['090322', 'Casas do Soeiro'],
  ['090401', 'Algodres'],
  ['090402', 'Almofala'],
  ['090403', 'Castelo Rodrigo'],
  ['090404', 'Cinco Vilas'],
  ['090405', 'Colmeal'],
  ['090406', 'Escalhão'],
  ['090407', 'Escarigo'],
  ['090408', 'Figueira de Castelo Rodrigo'],
  ['090409', 'Freixeda do Torrão'],
  ['090410', 'Mata de Lobos'],
  ['090411', 'Penha de Águia'],
  ['090412', 'Quintã de Pêro Martins'],
  ['090413', 'Reigada'],
  ['090414', 'Vale de Afonsinho'],
  ['090415', 'Vermiosa'],
  ['090416', 'Vilar de Amargo'],
  ['090417', 'Vilar Torpim'],
  ['090501', 'Algodres'],
  ['090502', 'Casal Vasco'],
  ['090503', 'Cortiçô'],
  ['090504', 'Figueiró da Granja'],
  ['090505', 'Fornos de Algodres'],
  ['090506', 'Fuinhas'],
  ['090507', 'Infias'],
  ['090508', 'Juncais'],
  ['090509', 'Maceira'],
  ['090510', 'Matança'],
  ['090511', 'Muxagata'],
  ['090512', 'Queiriz'],
  ['090513', 'Sobral Pichorro'],
  ['090514', 'Vila Chã'],
  ['090515', 'Vila Ruiva'],
  ['090516', 'Vila Soeiro do Chão'],
  ['090601', 'Aldeias'],
  ['090602', 'Arcozelo'],
  ['090603', 'Cativelos'],
  ['090604', 'Figueiró da Serra'],
  ['090605', 'Folgosinho'],
  ['090606', 'Freixo da Serra'],
  ['090607', 'Lagarinhos'],
  ['090608', 'Mangualde da Serra'],
  ['090609', 'Melo'],
  ['090610', 'Moimenta da Serra'],
  ['090611', 'Nabais'],
  ['090612', 'Nespereira'],
  ['090613', 'Paços da Serra'],
  ['090614', 'Ribamondego'],
  ['090615', 'Rio Torto'],
  ['090616', 'Gouveia (São Julião)'],
  ['090617', 'São Paio'],
  ['090618', 'Gouveia (São Pedro)'],
  ['090619', 'Vila Cortês da Serra'],
  ['090620', 'Vila Franca da Serra'],
  ['090621', 'Vila Nova de Tazem'],
  ['090622', 'Vinhó'],
  ['090701', 'Adão'],
  ['090702', 'Albardo'],
  ['090703', 'Aldeia do Bispo'],
  ['090704', 'Aldeia Viçosa'],
  ['090705', 'Alvendre'],
  ['090706', 'Arrifana'],
  ['090707', 'Avelãs de Ambom'],
  ['090708', 'Avelãs da Ribeira'],
  ['090709', 'Benespera'],
  ['090710', 'Carvalhal Meão'],
  ['090711', 'Casal de Cinza'],
  ['090712', 'Castanheira'],
  ['090713', 'Cavadoude'],
  ['090714', 'Codesseiro'],
  ['090715', 'Corujeira'],
  ['090716', 'Faia'],
  ['090717', 'Famalicão'],
  ['090718', 'Fernão Joanes'],
  ['090719', 'Gagos'],
  ['090720', 'Gonçalo'],
  ['090721', 'Gonçalo Bocas'],
  ['090722', 'João Antão'],
  ['090723', 'Maçainhas'],
  ['090724', 'Marmeleiro'],
  ['090725', 'Meios'],
  ['090726', 'Mizarela'],
  ['090727', 'Monte Margarida'],
  ['090728', 'Panoias de Cima'],
  ['090729', 'Pega'],
  ['090730', 'Pêra do Moço'],
  ['090731', 'Pêro Soares'],
  ['090732', 'Porto da Carne'],
  ['090733', 'Pousade'],
  ['090734', 'Ramela'],
  ['090735', 'Ribeira dos Carinhos'],
  ['090736', 'Rocamondo'],
  ['090737', 'Rochoso'],
  ['090738', 'Santana da Azinha'],
  ['090739', 'Jarmelo (São Miguel)'],
  ['090740', 'Jarmelo (São Pedro)'],
  ['090741', 'Guarda (São Vicente)'],
  ['090742', 'Guarda (Sé)'],
  ['090743', 'Seixo Amarelo'],
  ['090744', 'Sobral da Serra'],
  ['090745', 'Trinta'],
  ['090746', 'Vale de Estrela'],
  ['090747', 'Valhelhas'],
  ['090748', 'Vela'],
  ['090749', 'Videmonte'],
  ['090750', 'Vila Cortês do Mondego'],
  ['090751', 'Vila Fernando'],
  ['090752', 'Vila Franca do Deão'],
  ['090753', 'Vila Garcia'],
  ['090754', 'Vila Soeiro'],
  ['090755', 'S. Miguel da Guarda'],
  ['090756', 'Vale de Amoreira'],
  ['090801', 'Sameiro'],
  ['090802', 'Manteigas (Santa Maria)'],
  ['090803', 'Manteigas (São Pedro)'],
  ['090804', 'Vale de Amoreira'],
  ['090901', 'Aveloso'],
  ['090902', 'Barreira'],
  ['090903', 'Carvalhal'],
  ['090904', 'Casteição'],
  ['090905', 'Coriscada'],
  ['090906', 'Fontelonga'],
  ['090907', 'Longroiva'],
  ['090908', 'Marialva'],
  ['090909', 'Meda'],
  ['090910', 'Outeiro de Gatos'],
  ['090911', 'Paipenela'],
  ['090912', 'Poço do Canto'],
  ['090913', 'Prova'],
  ['090914', 'Rabaçal'],
  ['090915', 'Ranhados'],
  ['090916', 'Valflor'],
  ['091001', 'Alverca da Beira'],
  ['091002', 'Atalaia'],
  ['091003', 'Azevo'],
  ['091004', 'Bogalhal'],
  ['091005', 'Bouça Cova'],
  ['091006', 'Cerejo'],
  ['091007', 'Cidadelhe'],
  ['091008', 'Ervas Tenras'],
  ['091009', 'Ervedosa'],
  ['091010', 'Freixedas'],
  ['091011', 'Gouveias'],
  ['091012', 'Lamegal'],
  ['091013', 'Lameiras'],
  ['091014', 'Manigoto'],
  ['091015', 'Pala'],
  ['091016', 'Pereiro'],
  ['091017', 'Pinhel'],
  ['091018', 'Pínzio'],
  ['091019', 'Pomares'],
  ['091020', 'Póvoa de El-Rei'],
  ['091021', 'Safurdão'],
  ['091022', 'Santa Eufémia'],
  ['091023', 'Sorval'],
  ['091024', 'Souro Pires'],
  ['091025', 'Valbom'],
  ['091026', 'Vale de Madeira'],
  ['091027', 'Vascoveiro'],
  ['091101', 'Águas Belas'],
  ['091102', 'Aldeia do Bispo'],
  ['091103', 'Aldeia da Ponte'],
  ['091104', 'Aldeia da Ribeira'],
  ['091105', 'Aldeia de Santo António'],
  ['091106', 'Aldeia Velha'],
  ['091107', 'Alfaiates'],
  ['091108', 'Badamalos'],
  ['091109', 'Baraçal'],
  ['091110', 'Bendada'],
  ['091111', 'Bismula'],
  ['091112', 'Casteleiro'],
  ['091113', 'Cerdeira'],
  ['091114', 'Foios'],
  ['091115', 'Forcalhos'],
  ['091116', 'Lajeosa'],
  ['091117', 'Lomba'],
  ['091118', 'Malcata'],
  ['091119', 'Moita'],
  ['091120', 'Nave'],
  ['091121', 'Pena Lobo'],
  ['091122', 'Pousafoles do Bispo'],
  ['091123', 'Quadrazais'],
  ['091124', 'Quintas de São Bartolomeu'],
  ['091125', 'Rapoula do Côa'],
  ['091126', 'Rebolosa'],
  ['091127', 'Rendo'],
  ['091128', 'Ruivós'],
  ['091129', 'Ruvina'],
  ['091130', 'Sabugal'],
  ['091131', 'Santo Estêvão'],
  ['091132', 'Seixo do Côa'],
  ['091133', 'Sortelha'],
  ['091134', 'Souto'],
  ['091135', 'Vale das Éguas'],
  ['091136', 'Vale de Espinho'],
  ['091137', 'Vale Longo'],
  ['091138', 'Vila Boa'],
  ['091139', 'Vila do Touro'],
  ['091140', 'Vilar Maior'],
  ['091201', 'Alvoco da Serra'],
  ['091202', 'Cabeça'],
  ['091203', 'Carragosela'],
  ['091204', 'Folhadosa'],
  ['091205', 'Girabolhos'],
  ['091206', 'Lages'],
  ['091207', 'Loriga'],
  ['091208', 'Paranhos'],
  ['091209', 'Pinhanços'],
  ['091210', 'Sabugueiro'],
  ['091211', 'Sameice'],
  ['091212', 'Sandomil'],
  ['091213', 'Santa Comba'],
  ['091214', 'Santa Eulália'],
  ['091215', 'Santa Marinha'],
  ['091216', 'Santiago'],
  ['091217', 'São Martinho'],
  ['091218', 'São Romão'],
  ['091219', 'Sazes da Beira'],
  ['091220', 'Seia'],
  ['091221', 'Teixeira'],
  ['091222', 'Torrozelo'],
  ['091223', 'Tourais'],
  ['091224', 'Travancinha'],
  ['091225', 'Valesim'],
  ['091226', 'Várzea de Meruge'],
  ['091227', 'Vide'],
  ['091228', 'Vila Cova à Coelheira'],
  ['091229', 'Lapa dos Dinheiros'],
  ['091301', 'Aldeia Nova'],
  ['091302', 'Carnicães'],
  ['091303', 'Castanheira'],
  ['091304', 'Cogula'],
  ['091305', 'Cótimos'],
  ['091306', 'Feital'],
  ['091307', 'Fiães'],
  ['091308', 'Freches'],
  ['091309', 'Granja'],
  ['091310', 'Guilheiro'],
  ['091311', 'Moimentinha'],
  ['091312', 'Moreira de Rei'],
  ['091313', 'Palhais'],
  ['091314', 'Póvoa do Concelho'],
  ['091315', 'Reboleiro'],
  ['091316', 'Rio de Mel'],
  ['091317', 'Trancoso (Santa Maria)'],
  ['091318', 'Trancoso (São Pedro)'],
  ['091319', 'Sebadelhe da Serra'],
  ['091320', 'Souto Maior'],
  ['091321', 'Tamanhos'],
  ['091322', 'Terrenho'],
  ['091323', 'Torre do Terrenho'],
  ['091324', 'Torres'],
  ['091325', 'Valdujo'],
  ['091326', 'Vale do Seixo'],
  ['091327', 'Vila Franca das Naves'],
  ['091328', 'Vila Garcia'],
  ['091329', 'Vilares'],
  ['091401', 'Almendra'],
  ['091402', 'Castelo Melhor'],
  ['091403', 'Cedovim'],
  ['091404', 'Chãs'],
  ['091405', 'Custóias'],
  ['091406', 'Freixo de Numão'],
  ['091407', 'Horta'],
  ['091408', 'Mós'],
  ['091409', 'Murça'],
  ['091410', 'Muxagata'],
  ['091411', 'Numão'],
  ['091412', 'Santa Comba'],
  ['091413', 'Santo Amaro'],
  ['091414', 'Sebadelhe'],
  ['091415', 'Seixas'],
  ['091416', 'Touça'],
  ['091417', 'Vila Nova de Foz Côa'],
  ['100101', 'Alcobaça'],
  ['100102', 'Alfeizerão'],
  ['100103', 'Alpedriz'],
  ['100104', 'Bárrio'],
  ['100105', 'Benedita'],
  ['100106', 'Cela'],
  ['100107', 'Coz'],
  ['100108', 'Évora de Alcobaça'],
  ['100109', 'Maiorga'],
  ['100110', 'Pataias'],
  ['100111', 'Aljubarrota (Prazeres)'],
  ['100112', 'S. Martinho do Porto'],
  ['100113', 'Aljubarrota (S. Vicente)'],
  ['100114', 'Turquel'],
  ['100115', 'Vestiaria'],
  ['100116', 'Vimeiro'],
  ['100117', 'Moita'],
  ['100118', 'Martingança'],
  ['100119', 'Montes'],
  ['100201', 'Almoster'],
  ['100202', 'Alvaiázere'],
  ['100203', 'Maçãs de Caminho'],
  ['100204', 'Maçãs de Dona Maria'],
  ['100205', 'Pelmá'],
  ['100206', 'Pussos'],
  ['100207', 'Rego da Murta'],
  ['100301', 'Alvorge'],
  ['100302', 'Ansião'],
  ['100303', 'Avelar'],
  ['100304', 'Chão de Couce'],
  ['100305', 'Lagarteira'],
  ['100306', 'Pousaflores'],
  ['100307', 'Santiago da Guarda'],
  ['100308', 'Torre de Vale de Todos'],
  ['100401', 'Batalha'],
  ['100402', 'Reguengo do Fetal'],
  ['100403', 'São Mamede'],
  ['100404', 'Golpilheira'],
  ['100501', 'Bombarral'],
  ['100502', 'Carvalhal'],
  ['100503', 'Roliça'],
  ['100504', 'Vale Covo'],
  ['100505', 'Pó'],
  ['100601', 'A-dos-Francos'],
  ['100602', 'Alvorninha'],
  ['100603', 'Caldas da Rainha - Nossa Senhora do Pópulo'],
  ['100604', 'Carvalhal Benfeito'],
  ['100605', 'Couto'],
  ['100606', 'Foz do Arelho'],
  ['100607', 'Landal'],
  ['100608', 'Nadadouro'],
  ['100609', 'Salir de Matos'],
  ['100610', 'Salir do Porto'],
  ['100611', 'Santa Catarina'],
  ['100612', 'São Gregório'],
  ['100613', 'Serra do Bouro'],
  ['100614', 'Tornada'],
  ['100615', 'Vidais'],
  ['100616', 'Caldas da Rainha - Santo Onofre'],
  ['100701', 'Castanheira de Pêra'],
  ['100702', 'Coentral'],
  ['100801', 'Aguda'],
  ['100802', 'Arega'],
  ['100803', 'Campelo'],
  ['100804', 'Figueiró dos Vinhos'],
  ['100805', 'Bairradas'],
  ['100901', 'Amor'],
  ['100902', 'Arrabal'],
  ['100903', 'Azoia'],
  ['100904', 'Barosa'],
  ['100905', 'Barreira'],
  ['100906', 'Boa Vista'],
  ['100907', 'Caranguejeira'],
  ['100908', 'Carvide'],
  ['100909', 'Coimbrão'],
  ['100910', 'Colmeias'],
  ['100911', 'Cortes'],
  ['100912', 'Leiria'],
  ['100913', 'Maceira'],
  ['100914', 'Marrazes'],
  ['100915', 'Milagres'],
  ['100916', 'Monte Real'],
  ['100917', 'Monte Redondo'],
  ['100918', 'Ortigosa'],
  ['100919', 'Parceiros'],
  ['100920', 'Pousos'],
  ['100921', 'Regueira de Pontes'],
  ['100922', 'Santa Catarina da Serra'],
  ['100923', 'Santa Eufémia'],
  ['100924', 'Souto da Carpalhosa'],
  ['100925', 'Bajouca'],
  ['100926', 'Bidoeira de Cima'],
  ['100927', 'Memória'],
  ['100930', 'Carreira'],
  ['100931', 'Chainça'],
  ['101001', 'Marinha Grande'],
  ['101002', 'Vieira de Leiria'],
  ['101003', 'Moita'],
  ['101101', 'Famalicão'],
  ['101102', 'Nazaré'],
  ['101103', 'Valado dos Frades'],
  ['101201', 'A dos Negros'],
  ['101202', 'Amoreira'],
  ['101203', 'Olho Marinho'],
  ['101204', 'Óbidos (Santa Maria)'],
  ['101205', 'Óbidos (S. Pedro)'],
  ['101206', 'Sobral da Lagoa'],
  ['101207', 'Vau'],
  ['101208', 'Gaeiras'],
  ['101209', 'Usseira'],
  ['101301', 'Graça'],
  ['101302', 'Pedrógão Grande'],
  ['101303', 'Vila Facaia'],
  ['101401', 'Ajuda'],
  ['101402', 'Atouguia da Baleia'],
  ['101403', 'Conceição'],
  ['101404', 'São Pedro'],
  ['101405', "Serra d'el Rei"],
  ['101406', 'Ferrel'],
  ['101501', 'Abiul'],
  ['101502', 'Albergaria dos Doze'],
  ['101503', 'Almagreira'],
  ['101504', 'Carnide'],
  ['101505', 'Carriço'],
  ['101506', 'Louriçal'],
  ['101507', 'Mata Mourisca'],
  ['101508', 'Pelariga'],
  ['101509', 'Pombal'],
  ['101510', 'Redinha'],
  ['101511', 'Santiago de Litém'],
  ['101512', 'São Simão de Litém'],
  ['101513', 'Vermoil'],
  ['101514', 'Vila Cã'],
  ['101515', 'Meirinhas'],
  ['101516', 'Guia'],
  ['101517', 'Ilha'],
  ['101601', 'Alcaria'],
  ['101602', 'Alqueidão da Serra'],
  ['101603', 'Alvados'],
  ['101604', 'Arrimal'],
  ['101605', 'Calvaria de Cima'],
  ['101606', 'Juncal'],
  ['101607', 'Mendiga'],
  ['101608', 'Mira de Aire'],
  ['101609', 'Pedreiras'],
  ['101610', 'São Bento'],
  ['101611', 'Porto de Mós (São João Baptista)'],
  ['101612', 'Porto de Mós (São Pedro)'],
  ['101613', 'Serro Ventoso'],
  ['110101', 'Abrigada'],
  ['110102', 'Aldeia Galega da Merceana'],
  ['110103', 'Aldeia Gavinha'],
  ['110104', 'Cabanas de Torres'],
  ['110105', 'Cadafais'],
  ['110106', 'Carnota'],
  ['110107', 'Meca'],
  ['110108', 'Olhalvo'],
  ['110109', 'Ota'],
  ['110110', 'Pereiro de Palhacana'],
  ['110111', 'Santo Estêvão'],
  ['110112', 'Triana'],
  ['110113', 'Ventosa'],
  ['110114', 'Vila Verde dos Francos'],
  ['110115', 'Carregado'],
  ['110116', 'Ribafria'],
  ['110201', 'Arranhó'],
  ['110202', 'Arruda dos Vinhos'],
  ['110203', 'Cardosas'],
  ['110204', 'Santiago dos Velhos'],
  ['110301', 'Alcoentre'],
  ['110302', 'Aveiras de Baixo'],
  ['110303', 'Aveiras de Cima'],
  ['110304', 'Azambuja'],
  ['110305', 'Manique do Intendente'],
  ['110306', 'Vale do Paraíso'],
  ['110307', 'Vila Nova da Rainha'],
  ['110308', 'Vila Nova de São Pedro'],
  ['110309', 'Maçussa'],
  ['110401', 'Alguber'],
  ['110402', 'Cadaval'],
  ['110403', 'Cercal'],
  ['110404', 'Figueiros'],
  ['110405', 'Lamas'],
  ['110406', 'Painho'],
  ['110407', 'Peral'],
  ['110408', 'Pêro Moniz'],
  ['110409', 'Vermelha'],
  ['110410', 'Vilar'],
  ['110501', 'Alcabideche'],
  ['110502', 'Carcavelos'],
  ['110503', 'Cascais'],
  ['110504', 'Estoril'],
  ['110505', 'Parede'],
  ['110506', 'S. Domingos de Rana'],
  ['1106AA', 'Conceição'],
  ['1106AB', 'São Julião'],
  ['110601', 'Ajuda'],
  ['110602', 'Alcântara'],
  ['110603', 'Alto do Pina'],
  ['110604', 'Alvalade'],
  ['110605', 'Ameixoeira'],
  ['110606', 'Anjos'],
  ['110607', 'Beato'],
  ['110608', 'Benfica'],
  ['110609', 'Campo Grande'],
  ['110610', 'Campolide'],
  ['110611', 'Carnide'],
  ['110612', 'Castelo'],
  ['110613', 'Charneca'],
  ['110614', 'Coração de Jesus'],
  ['110615', 'Encarnação'],
  ['110616', 'Graça'],
  ['110617', 'Lapa'],
  ['110618', 'Lumiar'],
  ['110619', 'Madalena'],
  ['110620', 'Mártires'],
  ['110621', 'Marvila'],
  ['110622', 'Mercês'],
  ['110623', 'Nossa Senhora de Fátima'],
  ['110624', 'Pena'],
  ['110625', 'Penha de França'],
  ['110626', 'Prazeres'],
  ['110627', 'Sacramento'],
  ['110628', 'Santa Catarina'],
  ['110629', 'Santa Engrácia'],
  ['110630', 'Santa Isabel'],
  ['110631', 'Santa Justa'],
  ['110632', 'Santa Maria de Belém'],
  ['110633', 'Santa Maria dos Olivais'],
  ['110634', 'Santiago'],
  ['110635', 'Santo Condestável'],
  ['110636', 'Santo Estêvão'],
  ['110637', 'Santos-o-Velho'],
  ['110638', 'São Cristóvão e São Lourenço'],
  ['110639', 'São Domingos de Benfica'],
  ['110640', 'São Francisco Xavier'],
  ['110642', 'São João de Brito'],
  ['110643', 'São João de Deus'],
  ['110644', 'São Jorge de Arroios'],
  ['110645', 'São José'],
  ['110646', 'São Mamede'],
  ['110647', 'São Miguel'],
  ['110648', 'São Nicolau'],
  ['110649', 'São Paulo'],
  ['110650', 'São Sebastião da Pedreira'],
  ['110651', 'São Vicente de Fora'],
  ['110652', 'Sé'],
  ['110653', 'Socorro'],
  ['110701', 'Apelação'],
  ['110702', 'Bucelas'],
  ['110703', 'Camarate'],
  ['110705', 'Fanhões'],
  ['110706', 'Frielas'],
  ['110707', 'Loures'],
  ['110708', 'Lousa'],
  ['110709', 'Moscavide'],
  ['110712', 'Sacavém'],
  ['110713', 'Santa Iria de Azóia'],
  ['110714', 'Santo Antão do Tojal'],
  ['110715', 'S. João da Talha'],
  ['110716', 'S. Julião do Tojal'],
  ['110717', 'Unhos'],
  ['110719', 'Portela'],
  ['110722', 'Bobadela'],
  ['110723', 'Prior Velho'],
  ['110724', 'Santo António dos Cavaleiros'],
  ['110801', 'Lourinhã'],
  ['110802', 'Miragaia'],
  ['110803', 'Moita dos Ferreiros'],
  ['110804', 'Moledo'],
  ['110805', 'Reguengo Grande'],
  ['110806', 'Santa Bárbara'],
  ['110807', 'São Bartolomeu dos Galegos'],
  ['110808', 'Vimeiro'],
  ['110809', 'Marteleira'],
  ['110810', 'Ribamar'],
  ['110811', 'Atalaia'],
  ['110901', 'Azueira'],
  ['110902', 'Carvoeira'],
  ['110903', 'Cheleiros'],
  ['110904', 'Encarnação'],
  ['110905', 'Enxara do Bispo'],
  ['110906', 'Ericeira'],
  ['110907', 'Gradil'],
  ['110908', 'Igreja Nova'],
  ['110909', 'Mafra'],
  ['110910', 'Malveira'],
  ['110911', 'Milharado'],
  ['110912', 'Santo Estevão das Galés'],
  ['110913', 'Santo Isidoro'],
  ['110914', 'Sobral da Abelheira'],
  ['110915', 'Vila Franca do Rosário'],
  ['110916', 'Venda do Pinheiro'],
  ['110917', 'São Miguel de Alcainça'],
  ['111002', 'Barcarena'],
  ['111003', 'Carnaxide'],
  ['111004', 'Oeiras e S. Julião da Barra'],
  ['111005', 'Paço de Arcos'],
  ['111006', 'Algés'],
  ['111007', 'Cruz Quebrada-Dafundo'],
  ['111008', 'Linda-A-Velha'],
  ['111009', 'Porto Salvo'],
  ['111010', 'Queijas'],
  ['111011', 'Caxias'],
  ['111101', 'Agualva-Cacém'],
  ['111102', 'Algueirão-Mem Martins'],
  ['111103', 'Almargem do Bispo'],
  ['111104', 'Belas'],
  ['111105', 'Colares'],
  ['111106', 'Montelavar'],
  ['111107', 'Queluz'],
  ['111108', 'Rio de Mouro'],
  ['111109', 'Santa Maria e São Miguel'],
  ['111110', 'S. João das Lampas'],
  ['111111', 'Sintra (S. Martinho)'],
  ['111112', 'Sintra (S. Pedro de Penaferrim)'],
  ['111113', 'Terrugem'],
  ['111114', 'Pêro Pinheiro'],
  ['111115', 'Casal de Cambra'],
  ['111116', 'Massamá'],
  ['111117', 'Monte Abraão'],
  ['111118', 'Agualva'],
  ['111119', 'Cacém'],
  ['111120', 'Mira-Sintra'],
  ['111121', 'São Marcos'],
  ['111201', 'Santo Quintino'],
  ['111202', 'Sapataria'],
  ['111203', 'Sobral de Monte Agraço'],
  ['111301', 'A dos Cunhados'],
  ['111302', 'Campelos'],
  ['111303', 'Carmões'],
  ['111304', 'Carvoeira'],
  ['111305', 'Dois Portos'],
  ['111306', 'Freiria'],
  ['111307', 'Matacães'],
  ['111308', 'Maxial'],
  ['111309', 'Monte Redondo'],
  ['111310', 'Ponte do Rol'],
  ['111311', 'Ramalhal'],
  ['111312', 'Runa'],
  ['111313', 'Torres Vedras (S.Maria do Castelo S.Miguel)'],
  ['111314', 'S. Pedro da Cadeira'],
  ['111315', 'Torres Vedras (S. Pedro e Santiago)'],
  ['111316', 'Silveira'],
  ['111317', 'Turcifal'],
  ['111318', 'Ventosa'],
  ['111319', 'Outeiro da Cabeça'],
  ['111320', 'Maceira'],
  ['111401', 'Alhandra'],
  ['111402', 'Alverca do Ribatejo'],
  ['111403', 'Cachoeiras'],
  ['111404', 'Calhandriz'],
  ['111405', 'Castanheira do Ribatejo'],
  ['111406', 'Póvoa de Santa Iria'],
  ['111407', 'São João dos Montes'],
  ['111408', 'Vialonga'],
  ['111409', 'Vila Franca de Xira'],
  ['111410', 'Sobralinho'],
  ['111411', 'Forte da Casa'],
  ['111501', 'Alfragide'],
  ['111502', 'Brandoa'],
  ['111503', 'Buraca'],
  ['111504', 'Damaia'],
  ['111506', 'Mina'],
  ['111507', 'Reboleira'],
  ['111508', 'Venteira'],
  ['111512', 'Falagueira-Venda Nova'],
  ['111601', 'Caneças'],
  ['111602', 'Famões'],
  ['111603', 'Odivelas'],
  ['111604', 'Olival Basto'],
  ['111605', 'Pontinha'],
  ['111606', 'Póvoa de Santo Adrião'],
  ['111607', 'Ramada'],
  ['120101', 'Alter do Chão'],
  ['120102', 'Chancelaria'],
  ['120103', 'Seda'],
  ['120104', 'Cunheira'],
  ['120201', 'Assunção'],
  ['120202', 'Esperança'],
  ['120203', 'Mosteiros'],
  ['120301', 'Alcôrrego'],
  ['120302', 'Aldeia Velha'],
  ['120303', 'Avis'],
  ['120304', 'Benavila'],
  ['120305', 'Ervedal'],
  ['120306', 'Figueira e Barros'],
  ['120307', 'Maranhão'],
  ['120308', 'Valongo'],
  ['120401', 'Nossa Senhora da Expectação'],
  ['120402', 'N. Sª da Graça dos Degolados'],
  ['120403', 'São João Baptista'],
  ['120501', 'Nossa Senhora da Graça de Póvoa e Meadas'],
  ['120502', 'Santa Maria da Devesa'],
  ['120503', 'Santiago Maior'],
  ['120504', 'São João Baptista'],
  ['120601', 'Aldeia da Mata'],
  ['120602', 'Crato e Mártires'],
  ['120603', 'Flor da Rosa'],
  ['120604', 'Gáfete'],
  ['120605', 'Monte da Pedra'],
  ['120606', 'Vale do Peso'],
  ['120701', 'Ajuda Salvador e Santo Ildefonso'],
  ['120702', 'Alcáçova'],
  ['120703', 'Assunção'],
  ['120704', 'Barbacena'],
  ['120705', 'Caia e São Pedro'],
  ['120706', 'Santa Eulália'],
  ['120707', 'São Brás e São Lourenço'],
  ['120708', 'São Vicente e Ventosa'],
  ['120709', 'Terrugem'],
  ['120710', 'Vila Boim'],
  ['120711', 'Vila Fernando'],
  ['120801', 'Cabeço de Vide'],
  ['120802', 'Fronteira'],
  ['120803', 'São Saturnino'],
  ['120901', 'Atalaia'],
  ['120902', 'Belver'],
  ['120903', 'Comenda'],
  ['120904', 'Gavião'],
  ['120905', 'Margem'],
  ['121001', 'Beirã'],
  ['121002', 'Santa Maria de Marvão'],
  ['121003', 'Santo António das Areias'],
  ['121004', 'São Salvador da Aramenha'],
  ['121101', 'Assumar'],
  ['121102', 'Monforte'],
  ['121103', 'Santo Aleixo'],
  ['121104', 'Vaiamonte'],
  ['121201', 'Alpalhão'],
  ['121202', 'Amieira do Tejo'],
  ['121203', 'Arez'],
  ['121204', 'Espírito Santo'],
  ['121205', 'Montalvão'],
  ['121206', 'Nossa Senhora da Graça'],
  ['121207', 'Santana'],
  ['121208', 'S. Matias'],
  ['121209', 'S. Simão'],
  ['121210', 'Tolosa'],
  ['121301', 'Galveias'],
  ['121302', 'Montargil'],
  ['121303', 'Ponte de Sôr'],
  ['121304', 'Foros de Arrão'],
  ['121305', 'Longomel'],
  ['121306', 'Vale de Açor'],
  ['121307', 'Tramaga'],
  ['121401', 'Alagoa'],
  ['121402', 'Alegrete'],
  ['121403', 'Carreiras'],
  ['121404', 'Fortios'],
  ['121405', 'Reguengo'],
  ['121406', 'Ribeira de Nisa'],
  ['121407', 'São Julião'],
  ['121408', 'São Lourenço'],
  ['121409', 'Sé'],
  ['121410', 'Urra'],
  ['121501', 'Cano'],
  ['121502', 'Casa Branca'],
  ['121503', 'Santo Amaro'],
  ['121504', 'Sousel'],
  ['130101', 'Aboadela'],
  ['130102', 'Aboim'],
  ['130103', 'Ansiães'],
  ['130104', 'Ataíde'],
  ['130105', 'Bustelo'],
  ['130106', 'Canadelo'],
  ['130107', 'Candemil'],
  ['130108', 'Carneiro'],
  ['130109', 'Carvalho de Rei'],
  ['130110', 'Cepelos'],
  ['130111', 'Chapa'],
  ['130112', 'Fregim'],
  ['130113', 'Freixo de Baixo'],
  ['130114', 'Freixo de Cima'],
  ['130115', 'Fridão'],
  ['130116', 'Gatão'],
  ['130117', 'Gondar'],
  ['130118', 'Jazente'],
  ['130119', 'Lomba'],
  ['130120', 'Louredo'],
  ['130121', 'Lufrei'],
  ['130122', 'Madalena'],
  ['130123', 'Mancelos'],
  ['130124', 'Oliveira'],
  ['130125', 'Olo'],
  ['130126', 'Padronelo'],
  ['130127', 'Real'],
  ['130128', 'Rebordelo'],
  ['130129', 'Salvador do Monte'],
  ['130130', 'Sanche'],
  ['130131', 'Figueiró (Santa Cristina)'],
  ['130132', 'Figueiró (Santiago)'],
  ['130133', 'Amarante (São Gonçalo)'],
  ['130134', 'Gouveia (São Simão)'],
  ['130135', 'Telões'],
  ['130136', 'Travanca'],
  ['130137', 'Várzea'],
  ['130138', 'Vila Caiz'],
  ['130139', 'Vila Chã do Marão'],
  ['130140', 'Vila Garcia'],
  ['130201', 'Ancede'],
  ['130202', 'Campelo'],
  ['130203', 'São Tomé de Covelas'],
  ['130204', 'Frende'],
  ['130205', 'Gestaçô'],
  ['130206', 'Gôve'],
  ['130207', 'Grilo'],
  ['130208', 'Loivos do Monte'],
  ['130209', 'Loivos da Ribeira'],
  ['130210', 'Mesquinhata'],
  ['130211', 'Ovil'],
  ['130212', 'Ribadouro'],
  ['130213', 'Santa Cruz do Douro'],
  ['130214', 'Santa Leocádia'],
  ['130215', 'Santa Marinha do Zêzere'],
  ['130216', 'Teixeira'],
  ['130217', 'Teixeiró'],
  ['130218', 'Tresouras'],
  ['130219', 'Valadares'],
  ['130220', 'Viariz'],
  ['130301', 'Aião'],
  ['130302', 'Airães'],
  ['130303', 'Borba de Godim'],
  ['130304', 'Caramos'],
  ['130305', 'Friande'],
  ['130306', 'Idães'],
  ['130307', 'Jugueiros'],
  ['130308', 'Lagares'],
  ['130309', 'Lordelo'],
  ['130310', 'Macieira da Lixa'],
  ['130311', 'Moure'],
  ['130312', 'Pedreira'],
  ['130313', 'Penacova'],
  ['130314', 'Pinheiro'],
  ['130315', 'Pombeiro de Ribavizela'],
  ['130316', 'Rande'],
  ['130317', 'Refontoura'],
  ['130318', 'Regilde'],
  ['130319', 'Revinhade'],
  ['130320', 'Margaride'],
  ['130321', 'Santão'],
  ['130323', 'São Jorge de Vizela'],
  ['130324', 'Sendim'],
  ['130325', 'Sernande'],
  ['130326', 'Sousa'],
  ['130327', 'Torrados'],
  ['130328', 'Unhão'],
  ['130329', 'Várzea'],
  ['130330', 'Varziela'],
  ['130331', 'Vila Cova da Lixa'],
  ['130332', 'Vila Fria'],
  ['130333', 'Vila Verde'],
  ['130401', 'Covelo'],
  ['130402', 'Fânzeres'],
  ['130403', 'Foz do Sousa'],
  ['130404', 'Jovim'],
  ['130405', 'Lomba'],
  ['130406', 'Medas'],
  ['130407', 'Melres'],
  ['130408', 'Rio Tinto'],
  ['130409', 'Gondomar (S. Cosme)'],
  ['130410', 'S. Pedro da Cova'],
  ['130411', 'Valbom'],
  ['130412', 'Baguim do Monte (Rio Tinto)'],
  ['130501', 'Alvarenga'],
  ['130502', 'Aveleda'],
  ['130503', 'Boim'],
  ['130504', 'Caíde de Rei'],
  ['130505', 'Casais'],
  ['130506', 'Cernadelo'],
  ['130507', 'Covas'],
  ['130508', 'Cristelos'],
  ['130509', 'Figueiras'],
  ['130510', 'Lodares'],
  ['130511', 'Lustosa'],
  ['130512', 'Macieira'],
  ['130513', 'Meinedo'],
  ['130514', 'Nespereira'],
  ['130515', 'Nevogilde'],
  ['130516', 'Nogueira'],
  ['130517', 'Ordem'],
  ['130518', 'Pias'],
  ['130520', 'Lousada (Santa Margarida)'],
  ['130521', 'Barrosas (Santo Estêvão)'],
  ['130522', 'Lousada (São Miguel)'],
  ['130523', 'Silvares'],
  ['130524', 'Sousela'],
  ['130525', 'Torno'],
  ['130526', 'Vilar do Torno e Alentém'],
  ['130601', 'Águas Santas'],
  ['130602', 'Barca'],
  ['130603', 'Folgosa'],
  ['130604', 'Gemunde'],
  ['130605', 'Gondim'],
  ['130606', 'Gueifães'],
  ['130607', 'Maia'],
  ['130608', 'Milheirós'],
  ['130609', 'Moreira'],
  ['130610', 'Nogueira'],
  ['130611', 'Avioso (Santa Maria)'],
  ['130612', 'Avioso (São Pedro)'],
  ['130613', 'São Pedro Fins'],
  ['130614', 'Silva Escura'],
  ['130615', 'Vermoim'],
  ['130616', 'Vila Nova da Telha'],
  ['130617', 'Pedrouços'],
  ['130701', 'Alpendorada e Matos'],
  ['130702', 'Ariz'],
  ['130703', 'Avessadas'],
  ['130704', 'Banho e Carvalhosa'],
  ['130705', 'Constance'],
  ['130706', 'Favões'],
  ['130707', 'Folhada'],
  ['130708', 'Fornos'],
  ['130709', 'Freixo'],
  ['130710', 'Magrelos'],
  ['130711', 'Manhuncelos'],
  ['130712', 'Maureles'],
  ['130713', 'Paços de Gaiolo'],
  ['130714', 'Paredes de Viadores'],
  ['130715', 'Penha Longa'],
  ['130716', 'Rio de Galinhas'],
  ['130717', 'Rosem'],
  ['130718', 'Sande'],
  ['130719', 'Santo Isidoro'],
  ['130720', 'São Lourenço do Douro'],
  ['130721', 'São Nicolau'],
  ['130722', 'Soalhães'],
  ['130723', 'Sobretâmega'],
  ['130724', 'Tabuado'],
  ['130725', 'Torrão'],
  ['130726', 'Toutosa'],
  ['130727', 'Tuias'],
  ['130728', 'Várzea do Douro'],
  ['130729', 'Várzea da Ovelha'],
  ['130730', 'Vila Boa do Bispo'],
  ['130731', 'Vila Boa de Quires'],
  ['130801', 'Custóias'],
  ['130802', 'Guifões'],
  ['130803', 'Lavra'],
  ['130804', 'Leça do Balio'],
  ['130805', 'Leça da Palmeira'],
  ['130806', 'Matosinhos'],
  ['130807', 'Perafita'],
  ['130808', 'Santa Cruz do Bispo'],
  ['130809', 'S. Mamede de Infesta'],
  ['130810', 'Senhora da Hora'],
  ['130901', 'Arreigada'],
  ['130902', 'Carvalhosa'],
  ['130903', 'Codessos'],
  ['130904', 'Eiriz'],
  ['130905', 'Ferreira'],
  ['130906', 'Figueiró'],
  ['130907', 'Frazão'],
  ['130908', 'Freamunde'],
  ['130909', 'Lamoso'],
  ['130910', 'Meixomil'],
  ['130911', 'Modelos'],
  ['130912', 'Paços de Ferreira'],
  ['130913', 'Penamaior'],
  ['130914', 'Raimonda'],
  ['130915', 'Sanfins de Ferreira'],
  ['130916', 'Seroa'],
  ['131001', 'Aguiar de Sousa'],
  ['131002', 'Astromil'],
  ['131003', 'Baltar'],
  ['131004', 'Beire'],
  ['131005', 'Besteiros'],
  ['131006', 'Bitarães'],
  ['131007', 'Castelões de Cepeda'],
  ['131008', 'Cete'],
  ['131009', 'Cristelo'],
  ['131010', 'Duas Igrejas'],
  ['131011', 'Gandra'],
  ['131012', 'Gondalães'],
  ['131013', 'Lordelo'],
  ['131014', 'Louredo'],
  ['131015', 'Madalena'],
  ['131016', 'Mouriz'],
  ['131017', 'Parada de Todeia'],
  ['131018', 'Rebordosa'],
  ['131019', 'Recarei'],
  ['131020', 'Sobreira'],
  ['131021', 'Sobrosa'],
  ['131022', 'Vandoma'],
  ['131023', 'Vila Cova de Carros'],
  ['131024', 'Vilela'],
  ['131101', 'Abragão'],
  ['131102', 'Boelhe'],
  ['131103', 'Bustelo'],
  ['131104', 'Cabeça Santa'],
  ['131105', 'Canelas'],
  ['131106', 'Capela'],
  ['131107', 'Castelões'],
  ['131108', 'Croca'],
  ['131109', 'Duas Igrejas'],
  ['131110', 'Eja'],
  ['131111', 'Figueira'],
  ['131112', 'Fonte Arcada'],
  ['131113', 'Galegos'],
  ['131114', 'Guilhufe'],
  ['131115', 'Irivo'],
  ['131116', 'Lagares'],
  ['131117', 'Luzim'],
  ['131118', 'Marecos'],
  ['131119', 'Milhundos'],
  ['131120', 'Novelas'],
  ['131121', 'Oldrões'],
  ['131122', 'Paço de Sousa'],
  ['131123', 'Paredes'],
  ['131124', 'Penafiel'],
  ['131125', 'Perozelo'],
  ['131126', 'Pinheiro'],
  ['131127', 'Portela'],
  ['131128', 'Rans'],
  ['131129', 'Rio de Moinhos'],
  ['131130', 'Santa Marta'],
  ['131131', 'Santiago de Subarrifana'],
  ['131132', 'Recezinhos (S. Mamede)'],
  ['131133', 'Recezinhos (S. Martinho)'],
  ['131134', 'Sebolido'],
  ['131135', 'Urrô'],
  ['131136', 'Valpedre'],
  ['131137', 'Vila Cova'],
  ['131138', 'Rio Mau'],
  ['131201', 'Aldoar'],
  ['131202', 'Bonfim'],
  ['131203', 'Campanhã'],
  ['131204', 'Cedofeita'],
  ['131205', 'Foz do Douro'],
  ['131206', 'Lordelo do Ouro'],
  ['131207', 'Massarelos'],
  ['131208', 'Miragaia'],
  ['131209', 'Nevogilde'],
  ['131210', 'Paranhos'],
  ['131211', 'Ramalde'],
  ['131212', 'Santo Ildefonso'],
  ['131213', 'S. Nicolau'],
  ['131214', 'Sé'],
  ['131215', 'Vitória'],
  ['131301', 'A-Ver-o-Mar'],
  ['131302', 'Aguçadoura'],
  ['131303', 'Amorim'],
  ['131304', 'Argivai'],
  ['131305', 'Balazar'],
  ['131306', 'Beiriz'],
  ['131307', 'Estela'],
  ['131308', 'Laundos'],
  ['131309', 'Navais'],
  ['131310', 'Póvoa de Varzim'],
  ['131311', 'Rates'],
  ['131312', 'Terroso'],
  ['131401', 'Agrela'],
  ['131402', 'Água Longa'],
  ['131404', 'Areias'],
  ['131405', 'Vila das Aves'],
  ['131406', 'Burgães'],
  ['131407', 'Carreira'],
  ['131410', 'Guimarei'],
  ['131411', 'Lama'],
  ['131412', 'Lamelas'],
  ['131413', 'Monte Córdova'],
  ['131415', 'Palmeira'],
  ['131416', 'Rebordões'],
  ['131417', 'Refojos'],
  ['131418', 'Reguenga'],
  ['131419', 'Roriz'],
  ['131420', 'Santa Cristina do Couto'],
  ['131422', 'Santo Tirso'],
  ['131424', 'São Mamede de Negrelos'],
  ['131426', 'São Martinho do Campo'],
  ['131427', 'São Miguel do Couto'],
  ['131429', 'São Salvador do Campo'],
  ['131430', 'São Tomé de Negrelos'],
  ['131431', 'Sequeirô'],
  ['131432', 'Vilarinho'],
  ['131501', 'Alfena'],
  ['131502', 'Campo'],
  ['131503', 'Ermesinde'],
  ['131504', 'Sobrado'],
  ['131505', 'Valongo'],
  ['131601', 'Arcos'],
  ['131602', 'Árvore'],
  ['131603', 'Aveleda'],
  ['131604', 'Azurara'],
  ['131605', 'Bagunte'],
  ['131606', 'Canidelo'],
  ['131607', 'Fajozes'],
  ['131608', 'Ferreiró'],
  ['131609', 'Fornelo'],
  ['131610', 'Gião'],
  ['131611', 'Guilhabreu'],
  ['131612', 'Junqueira'],
  ['131613', 'Labruge'],
  ['131614', 'Macieira da Maia'],
  ['131615', 'Malta'],
  ['131616', 'Mindelo'],
  ['131617', 'Modivas'],
  ['131618', 'Mosteiró'],
  ['131619', 'Outeiro Maior'],
  ['131620', 'Parada'],
  ['131621', 'Retorta'],
  ['131622', 'Rio Mau'],
  ['131623', 'Tougues'],
  ['131624', 'Touguinha'],
  ['131625', 'Touguinhó'],
  ['131626', 'Vairão'],
  ['131627', 'Vila Chã'],
  ['131628', 'Vila do Conde'],
  ['131629', 'Vilar'],
  ['131630', 'Vilar de Pinheiro'],
  ['131701', 'Arcozelo'],
  ['131702', 'Avintes'],
  ['131703', 'Canelas'],
  ['131704', 'Canidelo'],
  ['131705', 'Crestuma'],
  ['131706', 'Grijó'],
  ['131707', 'Gulpilhares'],
  ['131708', 'Lever'],
  ['131709', 'Madalena'],
  ['131710', 'Mafamude'],
  ['131711', 'Olival'],
  ['131712', 'Oliveira do Douro'],
  ['131713', 'Pedroso'],
  ['131714', 'Perozinho'],
  ['131715', 'Sandim'],
  ['131716', 'Vila Nova de Gaia (Santa Marinha)'],
  ['131717', 'S. Félix da Marinha'],
  ['131718', 'S. Pedro da Afurada'],
  ['131719', 'Seixezelo'],
  ['131720', 'Sermonde'],
  ['131721', 'Serzedo'],
  ['131722', 'Valadares'],
  ['131723', 'Vilar de Andorinho'],
  ['131724', 'Vilar do Paraíso'],
  ['131801', 'Alvarelhos'],
  ['131802', 'Santiago do Bougado'],
  ['131803', 'São Martinho do Bougado'],
  ['131804', 'São Mamede do Coronado'],
  ['131805', 'São Romão do Coronado'],
  ['131806', 'Covelas'],
  ['131807', 'Guidões'],
  ['131808', 'Muro'],
  ['140101', 'Aldeia do Mato'],
  ['140102', 'Alferrarede'],
  ['140103', 'Alvega'],
  ['140104', 'Bemposta'],
  ['140105', 'Martinchel'],
  ['140106', 'Mouriscas'],
  ['140107', 'Pego'],
  ['140108', 'Rio de Moinhos'],
  ['140109', 'Rossio ao Sul do Tejo'],
  ['140110', 'S. Facundo'],
  ['140111', 'Abrantes (São João)'],
  ['140112', 'S. Miguel do Rio Torto'],
  ['140113', 'Abrantes (São Vicente)'],
  ['140114', 'Souto'],
  ['140115', 'Tramagal'],
  ['140116', 'Vale das Mós'],
  ['140117', 'Concavada'],
  ['140118', 'Fontes'],
  ['140119', 'Carvalhal'],
  ['140201', 'Alcanena'],
  ['140202', 'Bugalhos'],
  ['140203', 'Espinheiro'],
  ['140204', 'Louriceira'],
  ['140205', 'Malhou'],
  ['140206', 'Minde'],
  ['140207', 'Moitas Venda'],
  ['140208', 'Monsanto'],
  ['140209', 'Serra de Santo António'],
  ['140210', 'Vila Moreira'],
  ['140301', 'Almeirim'],
  ['140302', 'Benfica do Ribatejo'],
  ['140303', 'Fazendas de Almeirim'],
  ['140304', 'Raposa'],
  ['140401', 'Alpiarça'],
  ['140501', 'Benavente'],
  ['140502', 'Samora Correia'],
  ['140503', 'Santo Estêvão'],
  ['140504', 'Barrosa'],
  ['140601', 'Cartaxo'],
  ['140602', 'Ereira'],
  ['140603', 'Lapa'],
  ['140604', 'Pontével'],
  ['140605', 'Valada'],
  ['140606', 'Vale da Pinta'],
  ['140607', 'Vila Chã de Ourique'],
  ['140608', 'Vale da Pedra'],
  ['140701', 'Chamusca'],
  ['140702', 'Chouto'],
  ['140703', 'Pinheiro Grande'],
  ['140704', 'Ulme'],
  ['140705', 'Vale de Cavalos'],
  ['140706', 'Parreira'],
  ['140707', 'Carregueira'],
  ['140801', 'Constância'],
  ['140802', 'Montalvo'],
  ['140803', 'Santa Margarida da Coutada'],
  ['140901', 'Coruche'],
  ['140902', 'Couço'],
  ['140903', 'São José da Lamarosa'],
  ['140904', 'Fajarda'],
  ['140905', 'Branca'],
  ['140906', 'Erra'],
  ['140907', 'Biscainho'],
  ['140908', 'Santana do Mato'],
  ['141001', 'S. João Baptista'],
  ['141002', 'Nª Srª de Fátima'],
  ['141101', 'Águas Belas'],
  ['141102', 'Areias'],
  ['141103', 'Beco'],
  ['141104', 'Chãos'],
  ['141105', 'Dornes'],
  ['141106', 'Ferreira do Zêzere'],
  ['141107', 'Igreja Nova do Sobral'],
  ['141108', 'Paio Mendes'],
  ['141109', 'Pias'],
  ['141201', 'Azinhaga'],
  ['141202', 'Golegã'],
  ['141301', 'Aboboreira'],
  ['141302', 'Amêndoa'],
  ['141303', 'Cardigos'],
  ['141304', 'Carvoeiro'],
  ['141305', 'Envendos'],
  ['141306', 'Mação'],
  ['141307', 'Ortiga'],
  ['141308', 'Penhascoso'],
  ['141401', 'Alcobertas'],
  ['141402', 'Arrouquelas'],
  ['141403', 'Arruda dos Pisões'],
  ['141404', 'Azambujeira'],
  ['141405', 'Fráguas'],
  ['141406', 'Vila da Marmeleira'],
  ['141407', 'Outeiro da Cortiçada'],
  ['141408', 'Rio Maior'],
  ['141409', 'S. João da Ribeira'],
  ['141410', 'Asseiceira'],
  ['141411', 'S. Sebastião'],
  ['141412', 'Ribeira de S. João'],
  ['141413', 'Malaqueijo'],
  ['141414', 'Assentiz'],
  ['141501', 'Glória do Ribatejo'],
  ['141502', 'Marinhais'],
  ['141503', 'Muge'],
  ['141504', 'Salvaterra de Magos'],
  ['141505', 'Foros de Salvaterra'],
  ['141506', 'Granho'],
  ['141601', 'Abitureiras'],
  ['141602', 'Abrã'],
  ['141603', 'Achete'],
  ['141604', 'Alcanede'],
  ['141605', 'Alcanhões'],
  ['141606', 'Almoster'],
  ['141607', 'Amiais de Baixo'],
  ['141608', 'Arneiro das Milhariças'],
  ['141609', 'Azoia de Baixo'],
  ['141610', 'Azoia de Cima'],
  ['141611', 'Casével'],
  ['141612', 'Santarém (Marvila)'],
  ['141613', 'Moçarria'],
  ['141614', 'Pernes'],
  ['141615', 'Pombalinho'],
  ['141616', 'Póvoa da Isenta'],
  ['141617', 'Póvoa de Santarém'],
  ['141618', 'Romeira'],
  ['141619', 'Santa Iria da Ribeira de Santarém'],
  ['141620', 'Santarém (São Nicolau)'],
  ['141621', 'Santarém (Salvador)'],
  ['141622', 'São Vicente do Paúl'],
  ['141623', 'Tremês'],
  ['141624', 'Vale de Figueira'],
  ['141625', 'Vale de Santarém'],
  ['141626', 'Vaqueiros'],
  ['141627', 'Várzea'],
  ['141628', 'Gançaria'],
  ['141701', 'Alcaravela'],
  ['141702', 'Santiago de Montalegre'],
  ['141703', 'Sardoal'],
  ['141704', 'Valhascos'],
  ['141801', 'Alviobeira'],
  ['141802', 'Asseiceira'],
  ['141803', 'Beselga'],
  ['141804', 'Carregueiros'],
  ['141805', 'Casais'],
  ['141806', 'Junceira'],
  ['141807', 'Madalena'],
  ['141808', 'Olalhas'],
  ['141809', 'Paialvo'],
  ['141810', 'Pedreira'],
  ['141811', 'Santa Maria dos Olivais'],
  ['141812', 'São João Baptista'],
  ['141813', 'São Pedro de Tomar'],
  ['141814', 'Sabacheira'],
  ['141815', 'Serra'],
  ['141816', 'Além da Ribeira'],
  ['141901', 'Alcorochel'],
  ['141902', 'Assentiz'],
  ['141903', 'Brogueira'],
  ['141904', 'Chancelaria'],
  ['141905', 'Lapas'],
  ['141906', 'Olaia'],
  ['141907', 'Paço'],
  ['141908', 'Parceiros de Igreja'],
  ['141909', 'Pedrógão'],
  ['141910', 'Riachos'],
  ['141911', 'Ribeira Branca'],
  ['141912', 'Salvador'],
  ['141913', 'Santa Maria'],
  ['141914', 'Santiago'],
  ['141915', 'São Pedro'],
  ['141916', 'Zibreira'],
  ['141917', 'Meia Via'],
  ['142001', 'Atalaia'],
  ['142002', 'Praia do Ribatejo'],
  ['142003', 'Tancos'],
  ['142004', 'Vila Nova da Barquinha'],
  ['142005', 'Moita do Norte'],
  ['142101', 'Alburitel'],
  ['142102', 'Atouguia'],
  ['142103', 'Casal dos Bernardos'],
  ['142104', 'Caxarias'],
  ['142105', 'Espite'],
  ['142106', 'Fátima'],
  ['142107', 'Formigais'],
  ['142108', 'Freixianda'],
  ['142109', 'Gondemaria'],
  ['142110', 'Olival'],
  ['142111', 'Nossa Senhora das Misericórdias'],
  ['142112', 'Rio de Couros'],
  ['142113', 'Seiça'],
  ['142114', 'Urqueira'],
  ['142115', 'Nossa Senhora da Piedade'],
  ['142116', 'Matas'],
  ['142117', 'Cercal'],
  ['142118', 'Ribeira do Fárrio'],
  ['150101', 'Santa Maria'],
  ['150102', 'Santa Susana'],
  ['150103', 'Santiago'],
  ['150104', 'Torrão'],
  ['150105', 'São Martinho'],
  ['150106', 'Comporta'],
  ['150201', 'Alcochete'],
  ['150202', 'Samouco'],
  ['150203', 'São Francisco'],
  ['150301', 'Almada'],
  ['150302', 'Caparica'],
  ['150303', 'Costa da Caparica'],
  ['150304', 'Cova da Piedade'],
  ['150305', 'Trafaria'],
  ['150306', 'Cacilhas'],
  ['150307', 'Pragal'],
  ['150308', 'Sobreda'],
  ['150309', 'Charneca de Caparica'],
  ['150310', 'Laranjeiro'],
  ['150311', 'Feijó'],
  ['150401', 'Barreiro'],
  ['150402', 'Lavradio'],
  ['150403', 'Palhais'],
  ['150404', 'Santo André'],
  ['150405', 'Verderena'],
  ['150406', 'Alto do Seixalinho'],
  ['150407', 'Santo António da Charneca'],
  ['150408', 'Coina'],
  ['150501', 'Azinheira dos Barros e São Mamede do Sadão'],
  ['150502', 'Grândola'],
  ['150503', 'Melides'],
  ['150504', 'Santa Margarida da Serra'],
  ['150505', 'Carvalhal'],
  ['150601', 'Alhos Vedros'],
  ['150602', 'Baixa da Banheira'],
  ['150603', 'Moita'],
  ['150604', 'Gaio-Rosário'],
  ['150605', 'Sarilhos Pequenos'],
  ['150606', 'Vale da Amoreira'],
  ['150701', 'Canha'],
  ['150702', 'Montijo'],
  ['150703', 'Santo Isidro de Pegões'],
  ['150704', 'Sarilhos Grandes'],
  ['150705', 'Alto-Estanqueiro-Jardia'],
  ['150706', 'Pegões'],
  ['150707', 'Atalaia'],
  ['150708', 'Afonsoeiro'],
  ['150801', 'Marateca'],
  ['150802', 'Palmela'],
  ['150803', 'Pinhal Novo'],
  ['150804', 'Quinta do Anjo'],
  ['150805', 'Poceirão'],
  ['150901', 'Abela'],
  ['150902', 'Alvalade-Sado'],
  ['150903', 'Cercal do Alentejo'],
  ['150904', 'Ermidas-Sado'],
  ['150905', 'Santa Cruz'],
  ['150906', 'Santiago do Cacém'],
  ['150907', 'Santo André'],
  ['150908', 'São Bartolomeu da Serra'],
  ['150909', 'São Domingos'],
  ['150910', 'São Francisco da Serra'],
  ['150911', 'Vale de Água'],
  ['151001', 'Paio Pires'],
  ['151002', 'Amora'],
  ['151003', 'Arrentela'],
  ['151004', 'Seixal'],
  ['151005', 'Corroios'],
  ['151006', 'Fernão Ferro (Amora) (C.R.P. Amora)'],
  ['151099', 'Fernão Ferro (Seixal) (C.R.P. Seixal)'],
  ['151101', 'Sesimbra (Castelo)'],
  ['151102', 'Sesimbra (Santiago)'],
  ['151103', 'Quinta do Conde'],
  ['151201', 'Setúbal (Nossa Senhora da Anunciada)'],
  ['151202', 'Setúbal (Santa Maria da Graça)'],
  ['151203', 'Setúbal (S. Julião)'],
  ['151204', 'S. Lourenço'],
  ['151205', 'Setúbal (S. Sebastião)'],
  ['151206', 'S. Simão'],
  ['151207', 'Gâmbia-Pontes-Alto da Guerra'],
  ['151208', 'Sado'],
  ['151301', 'Sines'],
  ['151302', 'Porto Covo'],
  ['160101', 'Aboim das Choças'],
  ['160102', 'Aguiã'],
  ['160103', 'Alvora'],
  ['160104', 'Ázere'],
  ['160105', 'Cabana Maior'],
  ['160106', 'Cabreiro'],
  ['160107', 'Carralcova'],
  ['160108', 'Cendufe'],
  ['160109', 'Couto'],
  ['160110', 'Eiras'],
  ['160111', 'Ermelo'],
  ['160112', 'Extremo'],
  ['160113', 'Gavieira'],
  ['160114', 'Giela'],
  ['160115', 'Gondoriz'],
  ['160116', 'Grade'],
  ['160117', 'Guilhadeses'],
  ['160118', 'Loureda'],
  ['160119', 'Jolda (Madalena)'],
  ['160120', 'Mei'],
  ['160121', 'Miranda'],
  ['160122', 'Monte Redondo'],
  ['160123', 'Oliveira'],
  ['160124', 'Paçô'],
  ['160125', 'Padroso'],
  ['160126', 'Parada'],
  ['160127', 'Portela'],
  ['160128', 'Prozelo'],
  ['160129', 'Rio Cabrão'],
  ['160130', 'Rio Frio'],
  ['160131', 'Rio de Moinhos'],
  ['160132', 'Sá'],
  ['160133', 'Sabadim'],
  ['160134', 'Arcos de Valdevez (Salvador)'],
  ['160135', 'Padreiro (Salvador)'],
  ['160136', 'Padreiro (Santa Cristina)'],
  ['160137', 'Távora (Santa Maria)'],
  ['160138', 'Santar'],
  ['160139', 'S. Cosme e S. Damião'],
  ['160140', 'S. Jorge'],
  ['160141', 'Arcos de Valdevez (S. Paio)'],
  ['160142', 'Jolda (S. Paio)'],
  ['160143', 'Távora (S. Vicente)'],
  ['160144', 'Senharei'],
  ['160145', 'Sistelo'],
  ['160146', 'Soajo'],
  ['160147', 'Souto'],
  ['160148', 'Tabaçô'],
  ['160149', 'Vale'],
  ['160150', 'Vila Fonche'],
  ['160151', 'Vilela'],
  ['160201', 'Âncora'],
  ['160202', 'Arga de Baixo'],
  ['160203', 'Arga de Cima'],
  ['160204', 'Arga de S. João'],
  ['160205', 'Argela'],
  ['160206', 'Azevedo'],
  ['160207', 'Caminha (Matriz)'],
  ['160208', 'Cristelo'],
  ['160209', 'Dém'],
  ['160210', 'Gondar'],
  ['160211', 'Lanhelas'],
  ['160212', 'Moledo'],
  ['160213', 'Orbacém'],
  ['160214', 'Riba de Âncora'],
  ['160215', 'Seixas'],
  ['160216', 'Venade'],
  ['160217', 'Vila Praia de Âncora'],
  ['160218', 'Vilar de Mouros'],
  ['160219', 'Vilarelho'],
  ['160220', 'Vile'],
  ['160301', 'Alvaredo'],
  ['160302', 'Castro Laboreiro'],
  ['160303', 'Chaviães'],
  ['160304', 'Cousso'],
  ['160305', 'Cristoval'],
  ['160306', 'Cubalhão'],
  ['160307', 'Fiães'],
  ['160308', 'Gave'],
  ['160309', 'Lamas de Mouro'],
  ['160310', 'Paços'],
  ['160311', 'Paderne'],
  ['160312', 'Parada do Monte'],
  ['160313', 'Penso'],
  ['160314', 'Prado'],
  ['160315', 'Remoães'],
  ['160316', 'Roussas'],
  ['160317', 'S. Paio'],
  ['160318', 'Vila'],
  ['160401', 'Abedim'],
  ['160402', 'Anhões'],
  ['160403', 'Badim'],
  ['160404', 'Barbeita'],
  ['160405', 'Barroças e Taias'],
  ['160406', 'Bela'],
  ['160407', 'Cambeses'],
  ['160408', 'Ceivães'],
  ['160409', 'Lapela'],
  ['160410', 'Lara'],
  ['160411', 'Longos Vales'],
  ['160412', 'Lordelo'],
  ['160413', 'Luzio'],
  ['160414', 'Mazedo'],
  ['160415', 'Merufe'],
  ['160416', 'Messegães'],
  ['160417', 'Monção'],
  ['160418', 'Moreira'],
  ['160419', 'Parada'],
  ['160420', 'Pias'],
  ['160421', 'Pinheiros'],
  ['160422', 'Podame'],
  ['160423', 'Portela'],
  ['160424', 'Riba de Mouro'],
  ['160425', 'Sá'],
  ['160426', 'Sago'],
  ['160427', 'Segude'],
  ['160428', 'Tangil'],
  ['160429', 'Troporiz'],
  ['160430', 'Troviscoso'],
  ['160431', 'Trute'],
  ['160432', 'Valadares'],
  ['160433', 'Cortes'],
  ['160501', 'Agualonga'],
  ['160502', 'Bico'],
  ['160503', 'Castanheira'],
  ['160504', 'Cossourado'],
  ['160505', 'Coura'],
  ['160506', 'Cristelo'],
  ['160507', 'Cunha'],
  ['160508', 'Ferreira'],
  ['160509', 'Formariz'],
  ['160510', 'Infesta'],
  ['160511', 'Insalde'],
  ['160512', 'Linhares'],
  ['160513', 'Mozelos'],
  ['160514', 'Padornelo'],
  ['160515', 'Parada'],
  ['160516', 'Paredes de Coura'],
  ['160517', 'Porreiras'],
  ['160518', 'Resende'],
  ['160519', 'Romarigães'],
  ['160520', 'Rubiães'],
  ['160521', 'Vascões'],
  ['160601', 'Azias'],
  ['160602', 'Boivães'],
  ['160603', 'Bravães'],
  ['160604', 'Britelo'],
  ['160605', 'Crasto'],
  ['160606', 'Cuide de Vila Verde'],
  ['160607', 'Entre Ambos-os-Rios'],
  ['160608', 'Ermida'],
  ['160609', 'Germil'],
  ['160610', 'Grovelas'],
  ['160611', 'Lavradas'],
  ['160612', 'Lindoso'],
  ['160613', 'Nogueira'],
  ['160614', 'Oleiros'],
  ['160615', 'Paço Vedro de Magalhães'],
  ['160616', 'Ponte da Barca'],
  ['160617', 'Ruivos'],
  ['160618', 'Touvedo (Salvador)'],
  ['160619', 'Sampriz'],
  ['160620', 'Vila Chã (Santiago)'],
  ['160621', 'Vila Chã (S. João Baptista)'],
  ['160622', 'Touvedo (S. Lourenço)'],
  ['160623', 'Vade (S. Pedro)'],
  ['160624', 'Vade (S. Tomé)'],
  ['160625', 'Vila Nova de Muía'],
  ['160701', 'Anais'],
  ['160702', 'Arca'],
  ['160703', "São Pedro d'Arcos"],
  ['160704', 'Arcozelo'],
  ['160705', 'Ardegão'],
  ['160706', 'Bárrio'],
  ['160707', 'Beiral do Lima'],
  ['160708', 'Bertiandos'],
  ['160709', 'Boalhosa'],
  ['160710', 'Brandara'],
  ['160711', 'Cabaços'],
  ['160712', 'Cabração'],
  ['160713', 'Calheiros'],
  ['160714', 'Calvelo'],
  ['160715', 'Cepões'],
  ['160716', 'Correlhã'],
  ['160717', 'Estorãos'],
  ['160718', 'Facha'],
  ['160719', 'Feitosa'],
  ['160720', 'Fojo Lobal'],
  ['160721', 'Fontão'],
  ['160722', 'Fornelos'],
  ['160723', 'Freixo'],
  ['160724', 'Friastelas'],
  ['160725', 'Gaifar'],
  ['160726', 'Gandra'],
  ['160727', 'Gemieira'],
  ['160728', 'Gondufe'],
  ['160729', 'Labruja'],
  ['160730', 'Labrujó'],
  ['160731', 'Mato'],
  ['160732', 'Moreira do Lima'],
  ['160733', 'Navió'],
  ['160734', 'Poiares'],
  ['160735', 'Ponte de Lima'],
  ['160736', 'Queijada'],
  ['160737', 'Refóios do Lima'],
  ['160738', 'Rendufe'],
  ['160739', 'Ribeira'],
  ['160740', 'Sá'],
  ['160741', 'Sandiães'],
  ['160742', 'Santa Comba'],
  ['160743', 'Santa Cruz do Lima'],
  ['160744', 'Rebordões (Santa Maria)'],
  ['160745', 'Seara'],
  ['160746', 'Serdedelo'],
  ['160747', 'Rebordões (Souto)'],
  ['160748', 'Vilar das Almas'],
  ['160749', 'Vilar do Monte'],
  ['160750', 'Vitorino das Donas'],
  ['160751', 'Vitorino dos Piães'],
  ['160801', 'Arão'],
  ['160802', 'Boivão'],
  ['160803', 'Cerdal'],
  ['160804', 'Cristelo Covo'],
  ['160805', 'Fontoura'],
  ['160806', 'Friestas'],
  ['160807', 'Gandra'],
  ['160808', 'Ganfei'],
  ['160809', 'Gondomil'],
  ['160810', 'Sanfins'],
  ['160811', 'S. Julião'],
  ['160812', 'S. Pedro da Torre'],
  ['160813', 'Silva'],
  ['160814', 'Taião'],
  ['160815', 'Valença'],
  ['160816', 'Verdoejo'],
  ['160901', 'Afife'],
  ['160902', 'Alvarães'],
  ['160903', 'Amonde'],
  ['160904', 'Vila Nova de Anha'],
  ['160905', 'Areosa'],
  ['160906', 'Barroselas'],
  ['160907', 'Cardielos'],
  ['160908', 'Carreço'],
  ['160909', 'Carvoeiro'],
  ['160910', 'Castelo do Neiva'],
  ['160911', 'Darque'],
  ['160912', 'Deão'],
  ['160913', 'Deocriste'],
  ['160914', 'Freixieiro de Soutelo'],
  ['160915', 'Lanheses'],
  ['160916', 'Mazarefes'],
  ['160917', 'Meadela'],
  ['160918', 'Meixedo'],
  ['160919', 'Viana do Castelo (Monserrate)'],
  ['160920', 'Montaria'],
  ['160921', 'Moreira de Geraz do Lima'],
  ['160922', 'Mujães'],
  ['160923', 'Neiva'],
  ['160924', 'Nogueira'],
  ['160925', 'Outeiro'],
  ['160926', 'Perre'],
  ['160927', 'Portela Susã'],
  ['160928', 'Santa Marta de Portuzelo'],
  ['160929', 'Geraz do Lima (Santa Leocádia)'],
  ['160930', 'Geraz do Lima (Santa Maria)'],
  ['160931', 'Viana do Castelo (Santa Maria Maior)'],
  ['160932', 'Serreleis'],
  ['160933', 'Subportela'],
  ['160934', 'Torre'],
  ['160935', 'Vila Franca'],
  ['160936', 'Vila Fria'],
  ['160937', 'Vila Mou'],
  ['160938', 'Vila de Punhe'],
  ['160939', 'Vilar de Murteda'],
  ['160940', 'Chafé'],
  ['161001', 'Campos'],
  ['161002', 'Candemil'],
  ['161003', 'Cornes'],
  ['161004', 'Covas'],
  ['161005', 'Gondar'],
  ['161006', 'Gondarém'],
  ['161007', 'Loivo'],
  ['161008', 'Lovelhe'],
  ['161009', 'Mentrestido'],
  ['161010', 'Nogueira'],
  ['161011', 'Reboreda'],
  ['161012', 'Sapardos'],
  ['161013', 'Sopo'],
  ['161014', 'Vila Meã'],
  ['161015', 'Vila Nova de Cerveira'],
  ['170101', 'Alijó'],
  ['170102', 'Amieiro'],
  ['170103', 'Carlão'],
  ['170104', 'Casal de Loivos'],
  ['170105', 'Castedo'],
  ['170106', 'Cotas'],
  ['170107', 'Favaios'],
  ['170108', 'Pegarinhos'],
  ['170109', 'Pinhão'],
  ['170110', 'Pópulo'],
  ['170111', 'Ribalonga'],
  ['170112', 'Sanfins do Douro'],
  ['170113', 'Santa Eugénia'],
  ['170114', 'São Mamede de Ribatua'],
  ['170115', 'Vale de Mendiz'],
  ['170116', 'Vila Chã'],
  ['170117', 'Vila Verde'],
  ['170118', 'Vilar de Maçada'],
  ['170119', 'Vilarinho de Cotas'],
  ['170201', 'Alturas do Barroso'],
  ['170202', 'Ardãos'],
  ['170203', 'Beça'],
  ['170204', 'Bobadela'],
  ['170205', 'Boticas'],
  ['170206', 'Cerdedo'],
  ['170207', 'Codessoso'],
  ['170208', 'Covas do Barroso'],
  ['170209', 'Curros'],
  ['170210', 'Dornelas'],
  ['170211', 'Fiães do Tâmega'],
  ['170212', 'Granja'],
  ['170213', 'Pinho'],
  ['170214', 'São Salvador de Viveiro'],
  ['170215', 'Sapiãos'],
  ['170216', 'Vilar'],
  ['170301', 'Águas Frias'],
  ['170302', 'Anelhe'],
  ['170303', 'Arcossó'],
  ['170304', 'Bobadela'],
  ['170305', 'Bustelo'],
  ['170306', 'Calvão'],
  ['170307', 'Cela'],
  ['170308', 'Chaves'],
  ['170309', 'Cimo de Vila da Castanheira'],
  ['170310', 'Curalha'],
  ['170311', 'Eiras'],
  ['170312', 'Ervededo'],
  ['170313', 'Faiões'],
  ['170314', 'Lama de Arcos'],
  ['170315', 'Loivos'],
  ['170316', 'Mairos'],
  ['170317', 'Moreiras'],
  ['170318', 'Nogueira da Montanha'],
  ['170319', 'Oucidres'],
  ['170320', 'Oura'],
  ['170321', 'Outeiro Seco'],
  ['170322', 'Paradela de Monforte'],
  ['170323', 'Póvoa de Agrações'],
  ['170324', 'Redondelo'],
  ['170325', 'Roriz'],
  ['170326', 'Samaiões'],
  ['170327', 'Sanfins da Castanheira'],
  ['170328', 'Sanjurge'],
  ['170329', 'Santa Leocádia'],
  ['170330', 'Santo António de Monforte'],
  ['170331', 'Santo Estêvão'],
  ['170332', 'São Julião de Montenegro'],
  ['170333', 'São Pedro de Agostém'],
  ['170334', 'São Vicente da Raia'],
  ['170335', 'Seara Velha'],
  ['170336', 'Selhariz'],
  ['170337', 'Soutelinho da Raia'],
  ['170338', 'Soutelo'],
  ['170339', 'Travancas'],
  ['170340', 'Tronco'],
  ['170341', 'Valdanta'],
  ['170342', 'Vidago'],
  ['170343', 'Vila Verde da Raia'],
  ['170344', 'Vilar de Nantes'],
  ['170345', 'Vilarelho da Raia'],
  ['170346', 'Vilarinho das Paranheiras'],
  ['170347', 'Vilas Boas'],
  ['170348', 'Vilela Seca'],
  ['170349', 'Vilela do Tâmega'],
  ['170350', 'Santa Maria Maior'],
  ['170351', 'Madalena'],
  ['170352', 'Santa Cruz / Trindade'],
  ['170401', 'Barqueiros'],
  ['170402', 'Cidadelhe'],
  ['170403', 'Oliveira'],
  ['170404', 'Santa Cristina'],
  ['170405', 'São Nicolau'],
  ['170406', 'Vila Jusã'],
  ['170407', 'Vila Marim'],
  ['170501', 'Atei'],
  ['170502', 'Bilhó'],
  ['170503', 'Campanhó'],
  ['170504', 'Ermelo'],
  ['170505', 'Mondim de Basto'],
  ['170506', 'Paradança'],
  ['170507', 'Pardelhas'],
  ['170508', 'Vilar de Ferreiros'],
  ['170601', 'Cabril'],
  ['170602', 'Cambeses do Rio'],
  ['170603', 'Cervos'],
  ['170604', 'Chã'],
  ['170605', 'Contim'],
  ['170606', 'Covelães'],
  ['170607', 'Covelo do Gerês'],
  ['170608', 'Donões'],
  ['170609', 'Ferral'],
  ['170610', 'Fervidelas'],
  ['170611', 'Fiães do Rio'],
  ['170612', 'Gralhas'],
  ['170613', 'Meixedo'],
  ['170614', 'Meixide'],
  ['170615', 'Montalegre'],
  ['170616', 'Morgade'],
  ['170617', 'Mourilhe'],
  ['170618', 'Negrões'],
  ['170619', 'Outeiro'],
  ['170620', 'Padornelos'],
  ['170621', 'Padroso'],
  ['170622', 'Paradela'],
  ['170623', 'Pitões das Junias'],
  ['170624', 'Pondras'],
  ['170625', 'Reigoso'],
  ['170626', 'Salto'],
  ['170627', 'Santo André'],
  ['170628', 'Vilar de Perdizes (S. Miguel)'],
  ['170629', 'Sarraquinhos'],
  ['170630', 'Sezelhe'],
  ['170631', 'Solveira'],
  ['170632', 'Tourém'],
  ['170633', 'Venda Nova'],
  ['170634', 'Viade de Baixo'],
  ['170635', 'Vila da Ponte'],
  ['170701', 'Candedo'],
  ['170702', 'Carva'],
  ['170703', 'Fiolhoso'],
  ['170704', 'Jou'],
  ['170705', 'Murça'],
  ['170706', 'Noura'],
  ['170707', 'Palheiros'],
  ['170708', 'Valongo de Milhais'],
  ['170709', 'Vilares'],
  ['170801', 'Covelinhas'],
  ['170802', 'Fontelas'],
  ['170803', 'Galafura'],
  ['170804', 'Godim'],
  ['170805', 'Loureiro'],
  ['170806', 'Moura Morta'],
  ['170807', 'Peso da Régua'],
  ['170808', 'Poiares'],
  ['170809', 'Sedielos'],
  ['170810', 'Vilarinho dos Freires'],
  ['170811', 'Vinhós'],
  ['170812', 'Canelas'],
  ['170901', 'Alvadia'],
  ['170902', 'Canedo'],
  ['170903', 'Cerva'],
  ['170904', 'Limões'],
  ['170905', 'Ribeira de Pena (Salvador)'],
  ['170906', 'Santa Marinha'],
  ['170907', 'Santo Aleixo de Além-Tâmega'],
  ['171001', 'Celeirós'],
  ['171002', 'Covas do Douro'],
  ['171003', 'Gouvães do Douro'],
  ['171004', 'Gouvinhas'],
  ['171005', 'Parada de Pinhão'],
  ['171006', 'Paradela de Guiães'],
  ['171007', 'Paços'],
  ['171008', 'Provesende'],
  ['171009', 'Sabrosa'],
  ['171010', 'S. Cristovão do Douro'],
  ['171011', 'S. Lourenço de Ribapinhão'],
  ['171012', 'S. Martinho de Antas'],
  ['171013', 'Souto Maior'],
  ['171014', 'Torre do Pinhão'],
  ['171015', 'Vilarinho de S. Romão'],
  ['171101', 'Alvações do Corgo'],
  ['171102', 'Cumieira'],
  ['171103', 'Fontes'],
  ['171104', 'Fornelos'],
  ['171105', 'Louredo'],
  ['171106', 'Medrões'],
  ['171107', 'Sanhoane'],
  ['171108', 'São João de Lobrigos'],
  ['171109', 'São Miguel de Lobrigos'],
  ['171110', 'Sever'],
  ['171201', 'Água Revés e Crasto'],
  ['171202', 'Alvarelhos'],
  ['171203', 'Argeriz'],
  ['171204', 'Barreiros'],
  ['171205', 'Bouçoães'],
  ['171206', 'Canaveses'],
  ['171207', 'Carrazedo de Montenegro'],
  ['171208', 'Curros'],
  ['171209', 'Ervões'],
  ['171210', 'Fiães'],
  ['171211', 'Fornos do Pinhal'],
  ['171212', 'Friões'],
  ['171213', 'Lebução'],
  ['171214', 'Nozelos'],
  ['171215', 'Padrela e Tazem'],
  ['171216', 'Possacos'],
  ['171217', 'Rio Torto'],
  ['171218', 'Sanfins'],
  ['171219', 'Santa Maria de Emeres'],
  ['171220', 'Santa Valha'],
  ['171221', 'Santiago de Ribeira de Alhariz'],
  ['171222', 'S. João da Corveira'],
  ['171223', 'S. Pedro de Veiga de Lila'],
  ['171224', 'Serapicos'],
  ['171225', 'Sonim'],
  ['171226', 'Tinhela'],
  ['171227', 'Vales'],
  ['171228', 'Valpaços'],
  ['171229', 'Vassal'],
  ['171230', 'Veiga de Lila'],
  ['171231', 'Vilarandelo'],
  ['171301', 'Afonsim'],
  ['171302', 'Alfarela de Jales'],
  ['171303', 'Bornes de Aguiar'],
  ['171304', 'Bragado'],
  ['171305', 'Capeludos'],
  ['171306', 'Gouvães da Serra'],
  ['171307', 'Parada de Monteiros'],
  ['171308', 'Pensalvos'],
  ['171309', 'Santa Marta da Montanha'],
  ['171310', 'Soutelo de Aguiar'],
  ['171311', 'Telões'],
  ['171312', 'Tresminas'],
  ['171313', 'Valoura'],
  ['171314', 'Vila Pouca de Aguiar'],
  ['171315', 'Vreia de Bornes'],
  ['171316', 'Vreia de Jales'],
  ['171317', 'Sabroso de Aguiar'],
  ['171318', 'Lixa do Alvão'],
  ['171401', 'Abaças'],
  ['171402', 'Adoufe'],
  ['171403', 'Andrães'],
  ['171404', 'Arroios'],
  ['171405', 'Borbela'],
  ['171406', 'Campeã'],
  ['171407', 'Constantim'],
  ['171408', 'Ermida'],
  ['171409', 'Folhadela'],
  ['171410', 'Guiães'],
  ['171411', 'Justes'],
  ['171412', 'Lamares'],
  ['171413', 'Lamas de Olo'],
  ['171414', 'Lordelo'],
  ['171415', 'Mateus'],
  ['171416', 'Mondrões'],
  ['171417', 'Mouçós'],
  ['171418', 'Nogueira'],
  ['171419', 'Vila Real (Nossa Senhora da Conceição)'],
  ['171420', 'Parada de Cunhos'],
  ['171421', 'Pena'],
  ['171422', 'Quinta'],
  ['171423', 'Vila Real (S. Dinis)'],
  ['171424', 'Vila Real (S. Pedro)'],
  ['171425', 'S. Tomé do Castelo'],
  ['171426', 'Torgueda'],
  ['171427', 'Vale de Nogueiras'],
  ['171428', 'Vila Cova'],
  ['171429', 'Vila Marim'],
  ['171430', 'Vilarinho de Samardã'],
  ['180101', 'Aldeias'],
  ['180102', 'Aricera'],
  ['180103', 'Armamar'],
  ['180104', 'Cimbres'],
  ['180105', 'Coura'],
  ['180106', 'Folgosa'],
  ['180107', 'Fontelo'],
  ['180108', 'Goujoim'],
  ['180109', 'Queimada'],
  ['180110', 'Queimadela'],
  ['180111', 'Santa Cruz'],
  ['180112', 'Santiago'],
  ['180113', 'Santo Adrião'],
  ['180114', 'São Cosmado'],
  ['180115', 'São Martinho das Chãs'],
  ['180116', 'São Romão'],
  ['180117', 'Tões'],
  ['180118', 'Vacalar'],
  ['180119', 'Vila Seca'],
  ['180201', 'Beijós'],
  ['180202', 'Cabanas de Viriato'],
  ['180203', 'Currelos'],
  ['180204', 'Oliveira do Conde'],
  ['180205', 'Papízios'],
  ['180206', 'Parada'],
  ['180207', 'Sobral'],
  ['180301', 'Almofala'],
  ['180302', 'Alva'],
  ['180303', 'Cabril'],
  ['180304', 'Castro Daire'],
  ['180305', 'Cujó'],
  ['180306', 'Ermida'],
  ['180307', 'Ester'],
  ['180308', 'Gafanhão'],
  ['180309', 'Gosende'],
  ['180310', 'Mamouros'],
  ['180311', 'Mezio'],
  ['180312', 'Mões'],
  ['180313', 'Moledo'],
  ['180314', 'Monteiras'],
  ['180315', 'Moura Morta'],
  ['180316', 'Parada de Ester'],
  ['180317', 'Pepim'],
  ['180318', 'Picão'],
  ['180319', 'Pinheiro'],
  ['180320', 'Reriz'],
  ['180321', 'Ribolhos'],
  ['180322', 'São Joaninho'],
  ['180401', 'Alhões'],
  ['180402', 'Bustelo'],
  ['180403', 'Cinfães'],
  ['180404', 'Espadanedo'],
  ['180405', 'Ferreiros de Tendais'],
  ['180406', 'Fornelos'],
  ['180407', 'Gralheira'],
  ['180408', 'Moimenta'],
  ['180409', 'Nespereira'],
  ['180410', 'Oliveira do Douro'],
  ['180411', 'Ramires'],
  ['180412', 'Santiago de Piães'],
  ['180413', 'São Cristóvão de Nogueira'],
  ['180414', 'Souselo'],
  ['180415', 'Tarouquela'],
  ['180416', 'Tendais'],
  ['180417', 'Travanca'],
  ['180501', 'Almacave'],
  ['180502', 'Avões'],
  ['180503', 'Bigorne'],
  ['180504', 'Britiande'],
  ['180505', 'Cambres'],
  ['180506', 'Cepões'],
  ['180507', 'Ferreirim'],
  ['180508', 'Ferreiros de Avões'],
  ['180509', 'Figueira do Douro'],
  ['180510', 'Lalim'],
  ['180511', 'Lazarim'],
  ['180512', 'Magueija'],
  ['180513', 'Meijinhos'],
  ['180514', 'Melcões'],
  ['180515', 'Parada do Bispo'],
  ['180516', 'Penajóia'],
  ['180517', 'Penude'],
  ['180518', 'Pretarouca'],
  ['180519', 'Samodães'],
  ['180520', 'Sande'],
  ['180521', 'Sé'],
  ['180522', 'Valdigem'],
  ['180523', 'Várzea de Abrunhais'],
  ['180524', 'Vila Nova de Souto d´El Rei'],
  ['180601', 'Abrunhosa-a-Velha'],
  ['180602', 'Alcafache'],
  ['180603', 'Chãs de Tavares'],
  ['180604', 'Cunha Alta'],
  ['180605', 'Cunha Baixa'],
  ['180606', 'Espinho'],
  ['180607', 'Fornos de Maceira Dão'],
  ['180608', 'Freixiosa'],
  ['180609', 'Lobelhe do Mato'],
  ['180610', 'Mangualde'],
  ['180611', 'Mesquitela'],
  ['180612', 'Moimenta de Maceira Dão'],
  ['180613', 'Póvoa de Cervães'],
  ['180614', 'Quintela de Azurara'],
  ['180615', 'Santiago de Cassurrães'],
  ['180616', 'São João da Fresta'],
  ['180617', 'Travanca de Tavares'],
  ['180618', 'Várzea de Tavares'],
  ['180701', 'Aldeia de Nacomba'],
  ['180702', 'Alvite'],
  ['180703', 'Arcozelos'],
  ['180704', 'Ariz'],
  ['180705', 'Baldos'],
  ['180706', 'Cabaços'],
  ['180707', 'Caria'],
  ['180708', 'Castelo'],
  ['180709', 'Leomil'],
  ['180710', 'Moimenta da Beira'],
  ['180711', 'Nagosa'],
  ['180712', 'Paradinha'],
  ['180713', 'Passô'],
  ['180714', 'Peravelha'],
  ['180715', 'Peva'],
  ['180716', 'Rua'],
  ['180717', 'Sarzedo'],
  ['180718', 'Segões'],
  ['180719', 'Sever'],
  ['180720', 'Vilar'],
  ['180801', 'Almaça'],
  ['180802', 'Cercosa'],
  ['180803', 'Cortegaça'],
  ['180804', 'Espinho'],
  ['180805', 'Marmeleira'],
  ['180806', 'Mortágua'],
  ['180807', 'Pala'],
  ['180808', 'Sobral'],
  ['180809', 'Trezói'],
  ['180810', 'Vale de Remígio'],
  ['180901', 'Canas de Senhorim'],
  ['180902', 'Carvalhal Redondo'],
  ['180903', 'Nelas'],
  ['180904', 'Santar'],
  ['180905', 'Senhorim'],
  ['180906', 'Vilar Seco'],
  ['180907', 'Aguieira'],
  ['180908', 'Lapa do Lobo'],
  ['180909', 'Moreira'],
  ['181001', 'Arca'],
  ['181002', 'Arcozelo das Maias'],
  ['181003', 'Destriz'],
  ['181004', 'Oliveira de Frades'],
  ['181005', 'Pinheiro'],
  ['181006', 'Reigoso'],
  ['181007', 'Ribeiradio'],
  ['181008', 'São João da Serra'],
  ['181009', 'São Vicente de Lafões'],
  ['181010', 'Sejães'],
  ['181011', 'Souto de Lafões'],
  ['181012', 'Varzielas'],
  ['181101', 'Antas'],
  ['181102', 'Castelo de Penalva'],
  ['181103', 'Esmolfe'],
  ['181104', 'Germil'],
  ['181105', 'Ínsua'],
  ['181106', 'Lusinde'],
  ['181107', 'Mareco'],
  ['181108', 'Matela'],
  ['181109', 'Pindo'],
  ['181110', 'Real'],
  ['181111', 'Sezures'],
  ['181112', 'Trancozelos'],
  ['181113', 'Vila Cova do Covelo'],
  ['181201', 'Antas'],
  ['181202', 'Beselga'],
  ['181203', 'Castainço'],
  ['181204', 'Granja'],
  ['181205', 'Ourozinho'],
  ['181206', 'Penedono'],
  ['181207', 'Penela da Beira'],
  ['181208', 'Póvoa de Penela'],
  ['181209', 'Souto'],
  ['181301', 'Anreade'],
  ['181302', 'Barrô'],
  ['181303', 'Cárquere'],
  ['181304', 'Feirão'],
  ['181305', 'Felgueiras'],
  ['181306', 'Freigil'],
  ['181307', 'Miomães'],
  ['181308', 'Ovadas'],
  ['181309', 'Panchorra'],
  ['181310', 'Paus'],
  ['181311', 'Resende'],
  ['181312', 'São Cipriano'],
  ['181313', 'São João de Fontoura'],
  ['181314', 'São Martinho de Mouros'],
  ['181315', 'São Romão de Aregos'],
  ['181401', 'Couto do Mosteiro'],
  ['181402', 'Óvoa'],
  ['181403', 'Pinheiro de Ázere'],
  ['181404', 'Santa Comba Dão'],
  ['181405', 'São Joaninho'],
  ['181406', 'São João de Areias'],
  ['181407', 'Treixedo'],
  ['181408', 'Vimieiro'],
  ['181409', 'Nagosela'],
  ['181501', 'Castanheiro do Sul'],
  ['181502', 'Ervedosa do Douro'],
  ['181503', 'Espinhosa'],
  ['181504', 'Nagoselo do Douro'],
  ['181505', 'Paredes da Beira'],
  ['181506', 'Pereiros'],
  ['181507', 'Riodades'],
  ['181508', 'São João da Pesqueira'],
  ['181509', 'Soutelo do Douro'],
  ['181510', 'Trevões'],
  ['181511', 'Vale de Figueira'],
  ['181512', 'Valongo dos Azeites'],
  ['181513', 'Várzea de Trevões'],
  ['181514', 'Vilarouco'],
  ['181601', 'Baiões'],
  ['181602', 'Bordonhos'],
  ['181603', 'Candal'],
  ['181604', 'Carvalhais'],
  ['181605', 'Covas do Rio'],
  ['181606', 'Figueiredo de Alva'],
  ['181607', 'Manhouce'],
  ['181608', 'Pindelo dos Milagres'],
  ['181609', 'Pinho'],
  ['181610', 'Santa Cruz da Trapa'],
  ['181611', 'S. Cristóvão de Lafões'],
  ['181612', 'S. Félix'],
  ['181613', 'S. Martinho das Moitas'],
  ['181614', 'S. Pedro do Sul'],
  ['181615', 'Serrazes'],
  ['181616', 'Sul'],
  ['181617', 'Valadares'],
  ['181618', 'Várzea'],
  ['181619', 'Vila Maior'],
  ['181701', 'Águas Boas'],
  ['181702', 'Avelal'],
  ['181703', 'Decermilo'],
  ['181704', 'Ferreira de Aves'],
  ['181705', 'Forles'],
  ['181706', 'Mioma'],
  ['181707', 'Rio de Moinhos'],
  ['181708', 'Romãs'],
  ['181709', 'S. Miguel de Vila Boa'],
  ['181710', 'Sátão'],
  ['181711', 'Silvã de Cima'],
  ['181712', 'Vila Longa'],
  ['181801', 'Arnas'],
  ['181802', 'Carregal'],
  ['181803', 'Chosendo'],
  ['181804', 'Cunha'],
  ['181805', 'Escurquela'],
  ['181806', 'Faia'],
  ['181807', 'Ferreirim'],
  ['181808', 'Fonte Arcada'],
  ['181809', 'Freixinho'],
  ['181810', 'Granjal'],
  ['181811', 'Lamosa'],
  ['181812', 'Macieira'],
  ['181813', 'Penso'],
  ['181814', 'Quintela'],
  ['181815', 'Sarzeda'],
  ['181816', 'Sernancelhe'],
  ['181817', 'Vila da Ponte'],
  ['181901', 'Adorigo'],
  ['181902', 'Arcos'],
  ['181903', 'Barcos'],
  ['181904', 'Chavães'],
  ['181905', 'Desejosa'],
  ['181906', 'Granja do Tedo'],
  ['181907', 'Granjinha'],
  ['181908', 'Longa'],
  ['181909', 'Paradela'],
  ['181910', 'Pereiro'],
  ['181911', 'Pinheiros'],
  ['181912', 'Santa Leocádia'],
  ['181913', 'Sendim'],
  ['181914', 'Tabuaço'],
  ['181915', 'Távora'],
  ['181916', 'Vale de Figueira'],
  ['181917', 'Valença do Douro'],
  ['182001', 'Dalvares'],
  ['182002', 'Gouviães'],
  ['182003', 'Granja Nova'],
  ['182004', 'Mondim da Beira'],
  ['182005', 'Salzedas'],
  ['182006', 'São João de Tarouca'],
  ['182007', 'Tarouca'],
  ['182008', 'Ucanha'],
  ['182009', 'Várzea da Serra'],
  ['182010', 'Vila Chã da Beira'],
  ['182101', 'Barreiro de Besteiros'],
  ['182102', 'Campo de Besteiros'],
  ['182103', 'Canas de Santa Maria'],
  ['182104', 'Caparrosa'],
  ['182105', 'Castelões'],
  ['182106', 'Dardavaz'],
  ['182107', 'Ferreirós do Dão'],
  ['182108', 'Guardão'],
  ['182109', 'Lajeosa'],
  ['182110', 'Lobão da Beira'],
  ['182111', 'Molelos'],
  ['182112', 'Mosteirinho'],
  ['182113', 'Mosteiro de Fráguas'],
  ['182114', 'Mouraz'],
  ['182115', 'Nandufe'],
  ['182116', 'Parada de Gonta'],
  ['182117', 'Sabugosa'],
  ['182118', 'Santiago de Besteiros'],
  ['182119', 'São João do Monte'],
  ['182120', 'São Miguel do Outeiro'],
  ['182121', 'Silvares'],
  ['182122', 'Tonda'],
  ['182123', 'Tondela'],
  ['182124', 'Vila Nova da Rainha'],
  ['182125', 'Vilar de Besteiros'],
  ['182126', 'Tourigo'],
  ['182201', 'Alhais'],
  ['182202', 'Fráguas'],
  ['182203', 'Pendilhe'],
  ['182204', 'Queiriga'],
  ['182205', 'Touro'],
  ['182206', 'Vila Cova à Coelheira'],
  ['182207', 'Vila Nova de Paiva'],
  ['182301', 'Abraveses'],
  ['182302', 'Barreiros'],
  ['182303', 'Boa Aldeia'],
  ['182304', 'Bodiosa'],
  ['182305', 'Calde'],
  ['182306', 'Campo'],
  ['182307', 'Cavernães'],
  ['182308', 'Cepões'],
  ['182309', 'Viseu (Coração de Jesus)'],
  ['182310', 'Côta'],
  ['182311', 'Couto de Baixo'],
  ['182312', 'Couto de Cima'],
  ['182313', 'Fail'],
  ['182314', 'Farminhão'],
  ['182315', 'Fragosela'],
  ['182316', 'Lordosa'],
  ['182317', 'Silgueiros'],
  ['182318', 'Mundão'],
  ['182319', 'Orgens'],
  ['182320', 'Povolide'],
  ['182321', 'Ranhados'],
  ['182322', 'Ribafeita'],
  ['182323', 'Rio de Loba'],
  ['182324', 'Viseu (Santa Maria de Viseu)'],
  ['182325', 'Santos Evos'],
  ['182326', 'S. Cipriano'],
  ['182327', 'S. João de Lourosa'],
  ['182328', 'Viseu (S. José)'],
  ['182329', 'São Pedro de France'],
  ['182330', 'S. Salvador'],
  ['182331', 'Torredeita'],
  ['182332', 'Vil de Souto'],
  ['182333', 'Vila Chã de Sá'],
  ['182334', 'Repeses'],
  ['182401', 'Alcofra'],
  ['182402', 'Cambra'],
  ['182403', 'Campia'],
  ['182404', 'Carvalhal de Vermilhas'],
  ['182405', 'Fataunços'],
  ['182406', 'Figueiredo das Donas'],
  ['182407', 'Fornelo do Monte'],
  ['182408', 'Paços de Vilharigues'],
  ['182409', 'Queirã'],
  ['182410', 'S. Miguel do Mato'],
  ['182411', 'Ventosa'],
  ['182412', 'Vouzela'],
  ['310101', 'Arco da Calheta'],
  ['310102', 'Calheta'],
  ['310103', 'Estreito da Calheta'],
  ['310104', 'Fajã da Ovelha'],
  ['310105', 'Jardim do Mar'],
  ['310106', 'Paul do Mar'],
  ['310107', 'Ponta do Pargo'],
  ['310108', 'Prazeres'],
  ['310201', 'Câmara de Lobos'],
  ['310202', 'Curral das Freiras'],
  ['310203', 'Estreito de Câmara de Lobos'],
  ['310204', 'Quinta Grande'],
  ['310205', 'Jardim da Serra'],
  ['310301', 'Imaculado Coração de Maria'],
  ['310302', 'Monte'],
  ['310303', 'Funchal (Santa Luzia)'],
  ['310304', 'Funchal (Santa Maria Maior)'],
  ['310305', 'Santo António'],
  ['310306', 'São Gonçalo'],
  ['310307', 'São Martinho'],
  ['310308', 'Funchal (São Pedro)'],
  ['310309', 'São Roque'],
  ['310310', 'Funchal (Sé)'],
  ['310401', 'Água de Pena'],
  ['310402', 'Caniçal'],
  ['310403', 'Machico'],
  ['310404', 'Porto da Cruz'],
  ['310405', 'Santo António da Serra'],
  ['310501', 'Canhas'],
  ['310502', 'Madalena do Mar'],
  ['310503', 'Ponta do Sol'],
  ['310601', 'Achadas da Cruz'],
  ['310602', 'Porto Moniz'],
  ['310603', 'Ribeira da Janela'],
  ['310604', 'Seixal'],
  ['310701', 'Campanário'],
  ['310702', 'Ribeira Brava'],
  ['310703', 'Serra de Água'],
  ['310704', 'Tabua'],
  ['310802', 'Camacha'],
  ['310803', 'Caniço'],
  ['310804', 'Gaula'],
  ['310805', 'Santa Cruz'],
  ['310806', 'Santo António da Serra'],
  ['310901', 'Arco de São Jorge'],
  ['310902', 'Faial'],
  ['310903', 'Santana'],
  ['310904', 'São Jorge'],
  ['310905', 'São Roque do Faial'],
  ['310906', 'Ilha'],
  ['311001', 'Boa Ventura'],
  ['311002', 'Ponta Delgada'],
  ['311003', 'São Vicente'],
  ['320101', 'Porto Santo'],
  ['410101', 'Almagreira'],
  ['410102', 'Santa Bárbara'],
  ['410103', 'Santo Espírito'],
  ['410104', 'São Pedro'],
  ['410105', 'Vila do Porto'],
  ['420101', 'Água de Pau'],
  ['420102', 'Cabouco'],
  ['420103', 'Lagoa (Nossa Senhora do Rosário)'],
  ['420104', 'Lagoa (Santa Cruz)'],
  ['420105', 'Ribeira Chã'],
  ['420201', 'Achada'],
  ['420202', 'Achadinha'],
  ['420203', 'Lomba da Fazenda'],
  ['420204', 'Nordeste'],
  ['420205', 'Nordestinho'],
  ['420206', 'Salga'],
  ['420207', 'Santana'],
  ['420208', 'Algarvia'],
  ['420209', 'Santo António de Nordestinho'],
  ['420210', 'São Pedro de Nordestinho'],
  ['420301', 'Arrifes'],
  ['420302', 'Bretanha'],
  ['420303', 'Candelária'],
  ['420304', 'Capelas'],
  ['420305', 'Covoada'],
  ['420306', 'Fajã de Baixo'],
  ['420307', 'Fajã de Cima'],
  ['420308', 'Fenais da Luz'],
  ['420309', 'Feteiras'],
  ['420310', 'Ginetes'],
  ['420311', 'Mosteiros'],
  ['420312', 'Ponta Delgada (São Sebastião)'],
  ['420313', 'Ponta Delgada (São José)'],
  ['420314', 'Ponta Delgada (São Pedro)'],
  ['420315', 'Relva'],
  ['420316', 'Remédios'],
  ['420317', 'Rosto do Cão (Livramento)'],
  ['420318', 'Rosto do Cão (São Roque)'],
  ['420319', 'Santa Bárbara'],
  ['420320', 'Santo António'],
  ['420321', 'São Vicente Ferreira'],
  ['420322', 'Sete Cidades'],
  ['420323', 'Ajuda da Bretanha'],
  ['420324', 'Pilar da Bretanha'],
  ['420325', 'Santa Clara'],
  ['420401', 'Água Retorta'],
  ['420402', 'Faial da Terra'],
  ['420403', 'Furnas'],
  ['420404', 'Nossa Senhora dos Remédios'],
  ['420405', 'Povoação'],
  ['420406', 'Ribeira Quente'],
  ['420501', 'Calhetas'],
  ['420502', 'Fenais da Ajuda'],
  ['420503', 'Lomba da Maia'],
  ['420504', 'Lomba de São Pedro'],
  ['420505', 'Maia'],
  ['420506', 'Pico da Pedra'],
  ['420507', 'Porto Formoso'],
  ['420508', 'Rabo de Peixe'],
  ['420509', 'Ribeira Grande (Conceição)'],
  ['420510', 'Ribeira Grande (Matriz)'],
  ['420511', 'Ribeira Seca'],
  ['420512', 'Ribeirinha'],
  ['420513', 'Santa Bárbara'],
  ['420514', 'São Brás'],
  ['420601', 'Água de Alto'],
  ['420602', 'Ponta Garça'],
  ['420603', 'Ribeira das Tainhas'],
  ['420604', 'Vila Franca do Campo (São Miguel)'],
  ['420605', 'Vila Franca do Campo (São Pedro)'],
  ['420606', 'Ribeira Seca'],
  ['430101', 'Altares'],
  ['430102', 'Angra (Nossa Senhora da Conceição)'],
  ['430103', 'Angra (Santa Luzia)'],
  ['430104', 'Angra (São Pedro)'],
  ['430105', 'Angra (Sé)'],
  ['430106', 'Cinco Ribeiras'],
  ['430107', 'Doze Ribeiras'],
  ['430108', 'Feteira'],
  ['430109', 'Porto Judeu'],
  ['430110', 'Posto Santo'],
  ['430111', 'Raminho'],
  ['430112', 'Ribeirinha'],
  ['430113', 'Santa Bárbara'],
  ['430114', 'São Bartolomeu de Regatos'],
  ['430115', 'São Bento'],
  ['430116', 'São Mateus da Calheta'],
  ['430117', 'Serreta'],
  ['430118', 'Terra Chã'],
  ['430119', 'Vila de São Sebastião'],
  ['430201', 'Agualva'],
  ['430202', 'Biscoitos'],
  ['430203', 'Cabo da Praia'],
  ['430204', 'Fonte do Bastardo'],
  ['430205', 'Fontinhas'],
  ['430206', 'Lajes'],
  ['430207', 'Praia da Vitória (Santa Cruz)'],
  ['430208', 'Quatro Ribeiras'],
  ['430209', 'São Brás'],
  ['430210', 'Vila Nova'],
  ['430211', 'Porto Martins'],
  ['440101', 'Guadalupe'],
  ['440102', 'Luz'],
  ['440103', 'São Mateus'],
  ['440104', 'Santa Cruz da Graciosa'],
  ['450101', 'Calheta'],
  ['450102', 'Norte Pequeno'],
  ['450103', 'Ribeira Seca'],
  ['450104', 'Santo Antão'],
  ['450105', 'Topo (Nossa Senhora do Rosário)'],
  ['450201', 'Manadas (Santa Bárbara)'],
  ['450202', 'Norte Grande (Neves)'],
  ['450203', 'Rosais'],
  ['450204', 'Santo Amaro'],
  ['450205', 'Urzelina (São Mateus)'],
  ['450206', 'Velas (São Jorge)'],
  ['460101', 'Calheta de Nesquim'],
  ['460102', 'Lajes do Pico'],
  ['460103', 'Piedade'],
  ['460104', 'Ribeiras'],
  ['460105', 'Ribeirinha'],
  ['460106', 'São João'],
  ['460201', 'Bandeiras'],
  ['460202', 'Candelária'],
  ['460203', 'Criação Velha'],
  ['460204', 'Madalena'],
  ['460205', 'São Caetano'],
  ['460206', 'São Mateus'],
  ['460301', 'Prainha'],
  ['460302', 'Santa Luzia'],
  ['460303', 'Santo Amaro'],
  ['460304', 'Santo António'],
  ['460305', 'São Roque do Pico'],
  ['470101', 'Capelo'],
  ['470102', 'Castelo Branco'],
  ['470103', 'Cedros'],
  ['470104', 'Feteira'],
  ['470105', 'Flamengos'],
  ['470106', 'Horta (Angústias)'],
  ['470107', 'Horta (Conceição)'],
  ['470108', 'Horta (Matriz)'],
  ['470109', 'Pedro Miguel'],
  ['470110', 'Praia do Almoxarife'],
  ['470111', 'Praia do Norte'],
  ['470112', 'Ribeirinha'],
  ['470113', 'Salão'],
  ['480101', 'Fajã Grande'],
  ['480102', 'Fajãzinha'],
  ['480103', 'Fazenda'],
  ['480104', 'Lajedo'],
  ['480105', 'Lajes das Flores'],
  ['480106', 'Lomba'],
  ['480107', 'Mosteiro'],
  ['480201', 'Caveira'],
  ['480202', 'Cedros'],
  ['480203', 'Ponta Delgada'],
  ['480204', 'Santa Cruz das Flores'],
  ['490101', 'Corvo'],
].sort((a, b) => a[1].localeCompare(b[1], 'en', { sensitivity: 'base' }));
