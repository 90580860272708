import { Flex, Text, useColorModeValue } from '@chakra-ui/react';
import { Account, Information } from 'libs/ui/atoms/src';
import { VcTooltip } from 'libs/ui/atoms/src/lib/tooltip/Tooltip';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { UserSimple } from '../../../../../../api';
import { userRoleTKeyMap } from '../../../../../../components/user/roles-management/roles-utils';
import { getByCountryCode } from '../../../../../../libs/ui/atoms/src/lib/input-field/countries';
import { TooltipItem, TooltipRow } from '../../../../../../libs/ui/atoms/src/lib/tooltip/TooltipRow';

export interface UserTooltipProps {
  user: UserSimple;
}

export const UserTooltip = ({ user }: UserTooltipProps) => {
  const { t } = useTranslation();

  const nameColor = useColorModeValue('gray.600', 'white');
  const relationIconColor = useColorModeValue('brand.800', 'white');
  const infoPillColor = useColorModeValue('gray.700', 'white');

  const items = useMemo<TooltipItem[]>(() => {
    const items: TooltipItem[] = [
      { label: t('email'), value: user.email ?? '—' },
      { label: t('NIF'), value: user.nifNips ?? '—' },
      {
        label: t('relatedParties.nationality'),
        value: user.location ? getByCountryCode(user.location) : '—',
      },
      { label: t('address'), value: user.address ?? '—' },
      { label: t('postalCode'), value: user.postalCode ?? '—' },
      { label: t('roles'), value: user.roles?.map(i => t(userRoleTKeyMap[i])).join(', ') ?? '—' },
    ];

    return items;
  }, [user, t]);

  return (
    <VcTooltip
      iconChildren={<Information color={infoPillColor} w="16px" h="16px" />}
      contentChildren={
        <Flex flexDirection={'column'} gap="12px" p="16px">
          <Flex gap="16px" alignItems={'center'}>
            <Account color={relationIconColor} h={'20px'} w={'20px'} />

            <Text fontSize={'16px'} fontWeight="bold" color={nameColor}>
              {user.name}
            </Text>
          </Flex>

          <Flex flexDirection={'column'}>
            {items.map((i, idx) => (
              <TooltipRow key={idx} {...i} isSecondaryColor={idx % 2 === 0} />
            ))}
          </Flex>
        </Flex>
      }
    />
  );
};
