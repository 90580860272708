import { Worker as PdfWorker } from '@react-pdf-viewer/core';
import { Box, Flex, SimpleGrid, SimpleGridProps, useColorModeValue } from '@chakra-ui/react';
import ViewerPDF from 'components/viewerPDF/ViewerPDF';
import ViewerDoc from 'components/viewerDoc/ViewerDoc';

interface DocumentsViewerProps {
  documentCards: JSX.Element[];
  previewUrl?: string;
  compactView?: boolean;
  gridProps?: SimpleGridProps;
}

export function DocumentsViewer({ documentCards, previewUrl, compactView, gridProps }: DocumentsViewerProps) {
  const bgColor = useColorModeValue('white', 'navy.800');

  return (
    <Flex gap="4px" maxH="calc(100vh - 130px)" direction={{ base: 'column', md: 'row' }}>
      <SimpleGrid
        minH={{ base: '98px', md: 'auto' }}
        columns={1}
        display={{ base: 'flex', md: 'grid' }}
        alignItems="center"
        gridAutoRows="min-content"
        gap="24px"
        bg={bgColor}
        borderRadius="16px"
        p="16px"
        flex={1}
        overflowY="auto"
        boxSizing="content-box"
        {...gridProps}
      >
        {documentCards.map(card => (
          <Box
            cursor="pointer"
            transition=".3s"
            minW={{ base: '238px', md: 'auto' }}
            _hover={{
              transform: 'scale(1.01) translateY(-2px)',
              filter: 'drop-shadow(0 2px 4px rgba(0, 0, 0, 0.1))',
            }}
          >
            {card}
          </Box>
        ))}
      </SimpleGrid>

      {previewUrl &&
        (previewUrl.includes('.doc') ? (
          <Flex flex={1} borderRadius={'16'} padding="2" bgColor={bgColor} position="relative">
            <Box h="max(calc(100vh - 130px), 500px)" zIndex={0} flex={1} position="relative">
              <ViewerDoc url={previewUrl} />
            </Box>
          </Flex>
        ) : (
          <PdfWorker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
            <Box h="max(calc(100vh - 130px), 500px)" zIndex={0} flex={1} position="relative">
              <ViewerPDF fileUrl={previewUrl} />
            </Box>
          </PdfWorker>
        ))}
    </Flex>
  );
}
