import {
  RightOfFirstRefusalCurrency,
  RightOfFirstRefusalDensity,
  RightOfFirstRefusalItemsMeasure,
  RightOfFirstRefusalTypeOfBusiness,
} from 'api';

export const tByRightOfFirstRefusalTypeOfBusiness: Record<RightOfFirstRefusalTypeOfBusiness, string> = {
  [RightOfFirstRefusalTypeOfBusiness.BUY_AND_SELL]: 'rightOfFirstRefusal.typeOfBusiness.buyAndSell',
  [RightOfFirstRefusalTypeOfBusiness.PAYMENT_IN_PAYMENT]: 'rightOfFirstRefusal.typeOfBusiness.paymentInPayment',
};

export const tByRightOfFirstRefusalCurrency: Record<RightOfFirstRefusalCurrency, string> = {
  //  [RightOfFirstRefusalCurrency.BOLIVAR]: 'rightOfFirstRefusal.currency.bolivar',
  //  [RightOfFirstRefusalCurrency.DOLLAR]: 'rightOfFirstRefusal.currency.dollar',
  //  [RightOfFirstRefusalCurrency.SHIELDS]: 'rightOfFirstRefusal.currency.shields',
  [RightOfFirstRefusalCurrency.EUROS]: 'rightOfFirstRefusal.currency.euros',
  //  [RightOfFirstRefusalCurrency.FRENCH_FRANCS]: 'rightOfFirstRefusal.currency.frenchFrancs',
  //  [RightOfFirstRefusalCurrency.LUXEMBOURGISH_FRANCS]: 'rightOfFirstRefusal.currency.luxembourgishFrancs',
  //  [RightOfFirstRefusalCurrency.SWISS_FRANCS]: 'rightOfFirstRefusal.currency.swissFrancs',
  //  [RightOfFirstRefusalCurrency.YEN]: 'rightOfFirstRefusal.currency.yen',
  //  [RightOfFirstRefusalCurrency.POUNDS_STERLING]: 'rightOfFirstRefusal.currency.poundsSterling',
  //  [RightOfFirstRefusalCurrency.CORMAN_MARK]: 'rightOfFirstRefusal.currency.cormanMark',
  //  [RightOfFirstRefusalCurrency.PATACA]: 'rightOfFirstRefusal.currency.pataca',
  //  [RightOfFirstRefusalCurrency.ROAL]: 'rightOfFirstRefusal.currency.roal',
};

export const tByRightOfFirstRefusalDensity: Record<RightOfFirstRefusalDensity, string> = {
  [RightOfFirstRefusalDensity.BUSINESS]: 'rightOfFirstRefusal.destiny.business',
  [RightOfFirstRefusalDensity.HOUSING]: 'rightOfFirstRefusal.destiny.housing',
  [RightOfFirstRefusalDensity.INDUSTRY]: 'rightOfFirstRefusal.destiny.industry',
  [RightOfFirstRefusalDensity.OTHER]: 'rightOfFirstRefusal.destiny.other',
};

export const tByRightOfFirstRefusalItemsMeasure: Record<RightOfFirstRefusalItemsMeasure, string> = {
  [RightOfFirstRefusalItemsMeasure.ACRE]: 'rightOfFirstRefusal.itemsMeasure.acre',
  [RightOfFirstRefusalItemsMeasure.METERS_SQUARE]: 'rightOfFirstRefusal.itemsMeasure.metersSquare',
};
