const COUNTRIES_WITH_CODES = [
  { flag: '🇦🇼', value: 'AW', labelWithoutFlag: 'Aruba', label: '🇦🇼 Aruba', alpha3: 'ABW' },
  { flag: '🇦🇫', value: 'AF', labelWithoutFlag: 'Afghanistan', label: '🇦🇫 Afghanistan', alpha3: 'AFG' },
  { flag: '🇦🇴', value: 'AO', labelWithoutFlag: 'Angola', label: '🇦🇴 Angola', alpha3: 'AGO' },
  { flag: '🇦🇮', value: 'AI', labelWithoutFlag: 'Anguilla', label: '🇦🇮 Anguilla', alpha3: 'AIA' },
  { flag: '🇦🇽', value: 'AX', labelWithoutFlag: 'Åland Islands', label: '🇦🇽 Åland Islands', alpha3: 'ALA' },
  { flag: '🇦🇱', value: 'AL', labelWithoutFlag: 'Albania', label: '🇦🇱 Albania', alpha3: 'ALB' },
  { flag: '🇦🇩', value: 'AD', labelWithoutFlag: 'Andorra', label: '🇦🇩 Andorra', alpha3: 'AND' },
  {
    flag: '🇦🇪',
    value: 'AE',
    labelWithoutFlag: 'United Arab Emirates',
    label: '🇦🇪 United Arab Emirates',
    alpha3: 'ARE',
  },
  { flag: '🇦🇷', value: 'AR', labelWithoutFlag: 'Argentina', label: '🇦🇷 Argentina', alpha3: 'ARG' },
  { flag: '🇦🇲', value: 'AM', labelWithoutFlag: 'Armenia', label: '🇦🇲 Armenia', alpha3: 'ARM' },
  { flag: '🇦🇸', value: 'AS', labelWithoutFlag: 'American Samoa', label: '🇦🇸 American Samoa', alpha3: 'ASM' },
  { flag: '🇦🇶', value: 'AQ', labelWithoutFlag: 'Antarctica', label: '🇦🇶 Antarctica', alpha3: 'ATA' },
  {
    flag: '🇹🇫',
    value: 'TF',
    labelWithoutFlag: 'French Southern and Antarctic Lands',
    label: '🇹🇫 French Southern and Antarctic Lands',
    alpha3: 'ATF',
  },
  { flag: '🇦🇬', value: 'AG', labelWithoutFlag: 'Antigua and Barbuda', label: '🇦🇬 Antigua and Barbuda', alpha3: 'ATG' },
  { flag: '🇦🇺', value: 'AU', labelWithoutFlag: 'Australia', label: '🇦🇺 Australia', alpha3: 'AUS' },
  { flag: '🇦🇹', value: 'AT', labelWithoutFlag: 'Austria', label: '🇦🇹 Austria', alpha3: 'AUT' },
  { flag: '🇦🇿', value: 'AZ', labelWithoutFlag: 'Azerbaijan', label: '🇦🇿 Azerbaijan', alpha3: 'AZE' },
  { flag: '🇧🇮', value: 'BI', labelWithoutFlag: 'Burundi', label: '🇧🇮 Burundi', alpha3: 'BDI' },
  { flag: '🇧🇪', value: 'BE', labelWithoutFlag: 'Belgium', label: '🇧🇪 Belgium', alpha3: 'BEL' },
  { flag: '🇧🇯', value: 'BJ', labelWithoutFlag: 'Benin', label: '🇧🇯 Benin', alpha3: 'BEN' },
  { flag: '🇧🇫', value: 'BF', labelWithoutFlag: 'Burkina Faso', label: '🇧🇫 Burkina Faso', alpha3: 'BFA' },
  { flag: '🇧🇩', value: 'BD', labelWithoutFlag: 'Bangladesh', label: '🇧🇩 Bangladesh', alpha3: 'BGD' },
  { flag: '🇧🇬', value: 'BG', labelWithoutFlag: 'Bulgaria', label: '🇧🇬 Bulgaria', alpha3: 'BGR' },
  { flag: '🇧🇭', value: 'BH', labelWithoutFlag: 'Bahrain', label: '🇧🇭 Bahrain', alpha3: 'BHR' },
  { flag: '🇧🇸', value: 'BS', labelWithoutFlag: 'Bahamas', label: '🇧🇸 Bahamas', alpha3: 'BHS' },
  {
    flag: '🇧🇦',
    value: 'BA',
    labelWithoutFlag: 'Bosnia and Herzegovina',
    label: '🇧🇦 Bosnia and Herzegovina',
    alpha3: 'BIH',
  },
  { flag: '🇧🇱', value: 'BL', labelWithoutFlag: 'Saint Barthélemy', label: '🇧🇱 Saint Barthélemy', alpha3: 'BLM' },
  {
    flag: '🇸🇭',
    value: 'SH',
    labelWithoutFlag: 'Saint Helena, Ascension and Tristan da Cunha',
    label: '🇸🇭 Saint Helena, Ascension and Tristan da Cunha',
    alpha3: 'SHN',
  },
  { flag: '🇧🇾', value: 'BY', labelWithoutFlag: 'Belarus', label: '🇧🇾 Belarus', alpha3: 'BLR' },
  { flag: '🇧🇿', value: 'BZ', labelWithoutFlag: 'Belize', label: '🇧🇿 Belize', alpha3: 'BLZ' },
  { flag: '🇧🇲', value: 'BM', labelWithoutFlag: 'Bermuda', label: '🇧🇲 Bermuda', alpha3: 'BMU' },
  { flag: '🇧🇴', value: 'BO', labelWithoutFlag: 'Bolivia', label: '🇧🇴 Bolivia', alpha3: 'BOL' },
  { flag: '', value: 'BQ', labelWithoutFlag: 'Caribbean Netherlands', label: 'Caribbean Netherlands', alpha3: 'BES' },
  { flag: '🇧🇷', value: 'BR', labelWithoutFlag: 'Brazil', label: '🇧🇷 Brazil', alpha3: 'BRA' },
  { flag: '🇧🇧', value: 'BB', labelWithoutFlag: 'Barbados', label: '🇧🇧 Barbados', alpha3: 'BRB' },
  { flag: '🇧🇳', value: 'BN', labelWithoutFlag: 'Brunei', label: '🇧🇳 Brunei', alpha3: 'BRN' },
  { flag: '🇧🇹', value: 'BT', labelWithoutFlag: 'Bhutan', label: '🇧🇹 Bhutan', alpha3: 'BTN' },
  { flag: '🇧🇻', value: 'BV', labelWithoutFlag: 'Bouvet Island', label: '🇧🇻 Bouvet Island', alpha3: 'BVT' },
  { flag: '🇧🇼', value: 'BW', labelWithoutFlag: 'Botswana', label: '🇧🇼 Botswana', alpha3: 'BWA' },
  {
    flag: '🇨🇫',
    value: 'CF',
    labelWithoutFlag: 'Central African Republic',
    label: '🇨🇫 Central African Republic',
    alpha3: 'CAF',
  },
  { flag: '🇨🇦', value: 'CA', labelWithoutFlag: 'Canada', label: '🇨🇦 Canada', alpha3: 'CAN' },
  {
    flag: '🇨🇨',
    value: 'CC',
    labelWithoutFlag: 'Cocos (Keeling) Islands',
    label: '🇨🇨 Cocos (Keeling) Islands',
    alpha3: 'CCK',
  },
  { flag: '🇨🇭', value: 'CH', labelWithoutFlag: 'Switzerland', label: '🇨🇭 Switzerland', alpha3: 'CHE' },
  { flag: '🇨🇱', value: 'CL', labelWithoutFlag: 'Chile', label: '🇨🇱 Chile', alpha3: 'CHL' },
  { flag: '🇨🇳', value: 'CN', labelWithoutFlag: 'China', label: '🇨🇳 China', alpha3: 'CHN' },
  { flag: '🇨🇮', value: 'CI', labelWithoutFlag: 'Ivory Coast', label: '🇨🇮 Ivory Coast', alpha3: 'CIV' },
  { flag: '🇨🇲', value: 'CM', labelWithoutFlag: 'Cameroon', label: '🇨🇲 Cameroon', alpha3: 'CMR' },
  { flag: '🇨🇩', value: 'CD', labelWithoutFlag: 'DR Congo', label: '🇨🇩 DR Congo', alpha3: 'COD' },
  {
    flag: '🇨🇬',
    value: 'CG',
    labelWithoutFlag: 'Republic of the Congo',
    label: '🇨🇬 Republic of the Congo',
    alpha3: 'COG',
  },
  { flag: '🇨🇰', value: 'CK', labelWithoutFlag: 'Cook Islands', label: '🇨🇰 Cook Islands', alpha3: 'COK' },
  { flag: '🇨🇴', value: 'CO', labelWithoutFlag: 'Colombia', label: '🇨🇴 Colombia', alpha3: 'COL' },
  { flag: '🇰🇲', value: 'KM', labelWithoutFlag: 'Comoros', label: '🇰🇲 Comoros', alpha3: 'COM' },
  { flag: '🇨🇻', value: 'CV', labelWithoutFlag: 'Cape Verde', label: '🇨🇻 Cape Verde', alpha3: 'CPV' },
  { flag: '🇨🇷', value: 'CR', labelWithoutFlag: 'Costa Rica', label: '🇨🇷 Costa Rica', alpha3: 'CRI' },
  { flag: '🇨🇺', value: 'CU', labelWithoutFlag: 'Cuba', label: '🇨🇺 Cuba', alpha3: 'CUB' },
  { flag: '🇨🇼', value: 'CW', labelWithoutFlag: 'Curaçao', label: '🇨🇼 Curaçao', alpha3: 'CUW' },
  { flag: '🇨🇽', value: 'CX', labelWithoutFlag: 'Christmas Island', label: '🇨🇽 Christmas Island', alpha3: 'CXR' },
  { flag: '🇰🇾', value: 'KY', labelWithoutFlag: 'Cayman Islands', label: '🇰🇾 Cayman Islands', alpha3: 'CYM' },
  { flag: '🇨🇾', value: 'CY', labelWithoutFlag: 'Cyprus', label: '🇨🇾 Cyprus', alpha3: 'CYP' },
  { flag: '🇨🇿', value: 'CZ', labelWithoutFlag: 'Czechia', label: '🇨🇿 Czechia', alpha3: 'CZE' },
  { flag: '🇩🇪', value: 'DE', labelWithoutFlag: 'Germany', label: '🇩🇪 Germany', alpha3: 'DEU' },
  { flag: '🇩🇯', value: 'DJ', labelWithoutFlag: 'Djibouti', label: '🇩🇯 Djibouti', alpha3: 'DJI' },
  { flag: '🇩🇲', value: 'DM', labelWithoutFlag: 'Dominica', label: '🇩🇲 Dominica', alpha3: 'DMA' },
  { flag: '🇩🇰', value: 'DK', labelWithoutFlag: 'Denmark', label: '🇩🇰 Denmark', alpha3: 'DNK' },
  { flag: '🇩🇴', value: 'DO', labelWithoutFlag: 'Dominican Republic', label: '🇩🇴 Dominican Republic', alpha3: 'DOM' },
  { flag: '🇩🇿', value: 'DZ', labelWithoutFlag: 'Algeria', label: '🇩🇿 Algeria', alpha3: 'DZA' },
  { flag: '🇪🇨', value: 'EC', labelWithoutFlag: 'Ecuador', label: '🇪🇨 Ecuador', alpha3: 'ECU' },
  { flag: '🇪🇬', value: 'EG', labelWithoutFlag: 'Egypt', label: '🇪🇬 Egypt', alpha3: 'EGY' },
  { flag: '🇪🇷', value: 'ER', labelWithoutFlag: 'Eritrea', label: '🇪🇷 Eritrea', alpha3: 'ERI' },
  { flag: '🇪🇭', value: 'EH', labelWithoutFlag: 'Western Sahara', label: '🇪🇭 Western Sahara', alpha3: 'ESH' },
  { flag: '🇪🇸', value: 'ES', labelWithoutFlag: 'Spain', label: '🇪🇸 Spain', alpha3: 'ESP' },
  { flag: '🇪🇪', value: 'EE', labelWithoutFlag: 'Estonia', label: '🇪🇪 Estonia', alpha3: 'EST' },
  { flag: '🇪🇹', value: 'ET', labelWithoutFlag: 'Ethiopia', label: '🇪🇹 Ethiopia', alpha3: 'ETH' },
  { flag: '🇫🇮', value: 'FI', labelWithoutFlag: 'Finland', label: '🇫🇮 Finland', alpha3: 'FIN' },
  { flag: '🇫🇯', value: 'FJ', labelWithoutFlag: 'Fiji', label: '🇫🇯 Fiji', alpha3: 'FJI' },
  { flag: '🇫🇰', value: 'FK', labelWithoutFlag: 'Falkland Islands', label: '🇫🇰 Falkland Islands', alpha3: 'FLK' },
  { flag: '🇫🇷', value: 'FR', labelWithoutFlag: 'France', label: '🇫🇷 France', alpha3: 'FRA' },
  { flag: '🇫🇴', value: 'FO', labelWithoutFlag: 'Faroe Islands', label: '🇫🇴 Faroe Islands', alpha3: 'FRO' },
  { flag: '🇫🇲', value: 'FM', labelWithoutFlag: 'Micronesia', label: '🇫🇲 Micronesia', alpha3: 'FSM' },
  { flag: '🇬🇦', value: 'GA', labelWithoutFlag: 'Gabon', label: '🇬🇦 Gabon', alpha3: 'GAB' },
  { flag: '🇬🇧', value: 'GB', labelWithoutFlag: 'United Kingdom', label: '🇬🇧 United Kingdom', alpha3: 'GBR' },
  { flag: '🇬🇪', value: 'GE', labelWithoutFlag: 'Georgia', label: '🇬🇪 Georgia', alpha3: 'GEO' },
  { flag: '🇬🇬', value: 'GG', labelWithoutFlag: 'Guernsey', label: '🇬🇬 Guernsey', alpha3: 'GGY' },
  { flag: '🇬🇭', value: 'GH', labelWithoutFlag: 'Ghana', label: '🇬🇭 Ghana', alpha3: 'GHA' },
  { flag: '🇬🇮', value: 'GI', labelWithoutFlag: 'Gibraltar', label: '🇬🇮 Gibraltar', alpha3: 'GIB' },
  { flag: '🇬🇳', value: 'GN', labelWithoutFlag: 'Guinea', label: '🇬🇳 Guinea', alpha3: 'GIN' },
  { flag: '🇬🇵', value: 'GP', labelWithoutFlag: 'Guadeloupe', label: '🇬🇵 Guadeloupe', alpha3: 'GLP' },
  { flag: '🇬🇲', value: 'GM', labelWithoutFlag: 'Gambia', label: '🇬🇲 Gambia', alpha3: 'GMB' },
  { flag: '🇬🇼', value: 'GW', labelWithoutFlag: 'Guinea-Bissau', label: '🇬🇼 Guinea-Bissau', alpha3: 'GNB' },
  { flag: '🇬🇶', value: 'GQ', labelWithoutFlag: 'Equatorial Guinea', label: '🇬🇶 Equatorial Guinea', alpha3: 'GNQ' },
  { flag: '🇬🇷', value: 'GR', labelWithoutFlag: 'Greece', label: '🇬🇷 Greece', alpha3: 'GRC' },
  { flag: '🇬🇩', value: 'GD', labelWithoutFlag: 'Grenada', label: '🇬🇩 Grenada', alpha3: 'GRD' },
  { flag: '🇬🇱', value: 'GL', labelWithoutFlag: 'Greenland', label: '🇬🇱 Greenland', alpha3: 'GRL' },
  { flag: '🇬🇹', value: 'GT', labelWithoutFlag: 'Guatemala', label: '🇬🇹 Guatemala', alpha3: 'GTM' },
  { flag: '🇬🇫', value: 'GF', labelWithoutFlag: 'French Guiana', label: '🇬🇫 French Guiana', alpha3: 'GUF' },
  { flag: '🇬🇺', value: 'GU', labelWithoutFlag: 'Guam', label: '🇬🇺 Guam', alpha3: 'GUM' },
  { flag: '🇬🇾', value: 'GY', labelWithoutFlag: 'Guyana', label: '🇬🇾 Guyana', alpha3: 'GUY' },
  { flag: '🇭🇰', value: 'HK', labelWithoutFlag: 'Hong Kong', label: '🇭🇰 Hong Kong', alpha3: 'HKG' },
  {
    flag: '🇭🇲',
    value: 'HM',
    labelWithoutFlag: 'Heard Island and McDonald Islands',
    label: '🇭🇲 Heard Island and McDonald Islands',
    alpha3: 'HMD',
  },
  { flag: '🇭🇳', value: 'HN', labelWithoutFlag: 'Honduras', label: '🇭🇳 Honduras', alpha3: 'HND' },
  { flag: '🇭🇷', value: 'HR', labelWithoutFlag: 'Croatia', label: '🇭🇷 Croatia', alpha3: 'HRV' },
  { flag: '🇭🇹', value: 'HT', labelWithoutFlag: 'Haiti', label: '🇭🇹 Haiti', alpha3: 'HTI' },
  { flag: '🇭🇺', value: 'HU', labelWithoutFlag: 'Hungary', label: '🇭🇺 Hungary', alpha3: 'HUN' },
  { flag: '🇮🇩', value: 'ID', labelWithoutFlag: 'Indonesia', label: '🇮🇩 Indonesia', alpha3: 'IDN' },
  { flag: '🇮🇲', value: 'IM', labelWithoutFlag: 'Isle of Man', label: '🇮🇲 Isle of Man', alpha3: 'IMN' },
  { flag: '🇮🇳', value: 'IN', labelWithoutFlag: 'India', label: '🇮🇳 India', alpha3: 'IND' },
  {
    flag: '🇮🇴',
    value: 'IO',
    labelWithoutFlag: 'British Indian Ocean Territory',
    label: '🇮🇴 British Indian Ocean Territory',
    alpha3: 'IOT',
  },
  { flag: '🇮🇪', value: 'IE', labelWithoutFlag: 'Ireland', label: '🇮🇪 Ireland', alpha3: 'IRL' },
  { flag: '🇮🇷', value: 'IR', labelWithoutFlag: 'Iran', label: '🇮🇷 Iran', alpha3: 'IRN' },
  { flag: '🇮🇶', value: 'IQ', labelWithoutFlag: 'Iraq', label: '🇮🇶 Iraq', alpha3: 'IRQ' },
  { flag: '🇮🇸', value: 'IS', labelWithoutFlag: 'Iceland', label: '🇮🇸 Iceland', alpha3: 'ISL' },
  { flag: '🇮🇱', value: 'IL', labelWithoutFlag: 'Israel', label: '🇮🇱 Israel', alpha3: 'ISR' },
  { flag: '🇮🇹', value: 'IT', labelWithoutFlag: 'Italy', label: '🇮🇹 Italy', alpha3: 'ITA' },
  { flag: '🇯🇲', value: 'JM', labelWithoutFlag: 'Jamaica', label: '🇯🇲 Jamaica', alpha3: 'JAM' },
  { flag: '🇯🇪', value: 'JE', labelWithoutFlag: 'Jersey', label: '🇯🇪 Jersey', alpha3: 'JEY' },
  { flag: '🇯🇴', value: 'JO', labelWithoutFlag: 'Jordan', label: '🇯🇴 Jordan', alpha3: 'JOR' },
  { flag: '🇯🇵', value: 'JP', labelWithoutFlag: 'Japan', label: '🇯🇵 Japan', alpha3: 'JPN' },
  { flag: '🇰🇿', value: 'KZ', labelWithoutFlag: 'Kazakhstan', label: '🇰🇿 Kazakhstan', alpha3: 'KAZ' },
  { flag: '🇰🇪', value: 'KE', labelWithoutFlag: 'Kenya', label: '🇰🇪 Kenya', alpha3: 'KEN' },
  { flag: '🇰🇬', value: 'KG', labelWithoutFlag: 'Kyrgyzstan', label: '🇰🇬 Kyrgyzstan', alpha3: 'KGZ' },
  { flag: '🇰🇭', value: 'KH', labelWithoutFlag: 'Cambodia', label: '🇰🇭 Cambodia', alpha3: 'KHM' },
  { flag: '🇰🇮', value: 'KI', labelWithoutFlag: 'Kiribati', label: '🇰🇮 Kiribati', alpha3: 'KIR' },
  {
    flag: '🇰🇳',
    value: 'KN',
    labelWithoutFlag: 'Saint Kitts and Nevis',
    label: '🇰🇳 Saint Kitts and Nevis',
    alpha3: 'KNA',
  },
  { flag: '🇰🇷', value: 'KR', labelWithoutFlag: 'South Korea', label: '🇰🇷 South Korea', alpha3: 'KOR' },
  { flag: '🇽🇰', value: 'XK', labelWithoutFlag: 'Kosovo', label: '🇽🇰 Kosovo', alpha3: 'XKX' },
  { flag: '🇰🇼', value: 'KW', labelWithoutFlag: 'Kuwait', label: '🇰🇼 Kuwait', alpha3: 'KWT' },
  { flag: '🇱🇦', value: 'LA', labelWithoutFlag: 'Laos', label: '🇱🇦 Laos', alpha3: 'LAO' },
  { flag: '🇱🇧', value: 'LB', labelWithoutFlag: 'Lebanon', label: '🇱🇧 Lebanon', alpha3: 'LBN' },
  { flag: '🇱🇷', value: 'LR', labelWithoutFlag: 'Liberia', label: '🇱🇷 Liberia', alpha3: 'LBR' },
  { flag: '🇱🇾', value: 'LY', labelWithoutFlag: 'Libya', label: '🇱🇾 Libya', alpha3: 'LBY' },
  { flag: '🇱🇨', value: 'LC', labelWithoutFlag: 'Saint Lucia', label: '🇱🇨 Saint Lucia', alpha3: 'LCA' },
  { flag: '🇱🇮', value: 'LI', labelWithoutFlag: 'Liechtenstein', label: '🇱🇮 Liechtenstein', alpha3: 'LIE' },
  { flag: '🇱🇰', value: 'LK', labelWithoutFlag: 'Sri Lanka', label: '🇱🇰 Sri Lanka', alpha3: 'LKA' },
  { flag: '🇱🇸', value: 'LS', labelWithoutFlag: 'Lesotho', label: '🇱🇸 Lesotho', alpha3: 'LSO' },
  { flag: '🇱🇹', value: 'LT', labelWithoutFlag: 'Lithuania', label: '🇱🇹 Lithuania', alpha3: 'LTU' },
  { flag: '🇱🇺', value: 'LU', labelWithoutFlag: 'Luxembourg', label: '🇱🇺 Luxembourg', alpha3: 'LUX' },
  { flag: '🇱🇻', value: 'LV', labelWithoutFlag: 'Latvia', label: '🇱🇻 Latvia', alpha3: 'LVA' },
  { flag: '🇲🇴', value: 'MO', labelWithoutFlag: 'Macau', label: '🇲🇴 Macau', alpha3: 'MAC' },
  { flag: '🇲🇫', value: 'MF', labelWithoutFlag: 'Saint Martin', label: '🇲🇫 Saint Martin', alpha3: 'MAF' },
  { flag: '🇲🇦', value: 'MA', labelWithoutFlag: 'Morocco', label: '🇲🇦 Morocco', alpha3: 'MAR' },
  { flag: '🇲🇨', value: 'MC', labelWithoutFlag: 'Monaco', label: '🇲🇨 Monaco', alpha3: 'MCO' },
  { flag: '🇲🇩', value: 'MD', labelWithoutFlag: 'Moldova', label: '🇲🇩 Moldova', alpha3: 'MDA' },
  { flag: '🇲🇬', value: 'MG', labelWithoutFlag: 'Madagascar', label: '🇲🇬 Madagascar', alpha3: 'MDG' },
  { flag: '🇲🇻', value: 'MV', labelWithoutFlag: 'Maldives', label: '🇲🇻 Maldives', alpha3: 'MDV' },
  { flag: '🇲🇽', value: 'MX', labelWithoutFlag: 'Mexico', label: '🇲🇽 Mexico', alpha3: 'MEX' },
  { flag: '🇲🇭', value: 'MH', labelWithoutFlag: 'Marshall Islands', label: '🇲🇭 Marshall Islands', alpha3: 'MHL' },
  { flag: '🇲🇰', value: 'MK', labelWithoutFlag: 'North Macedonia', label: '🇲🇰 North Macedonia', alpha3: 'MKD' },
  { flag: '🇲🇱', value: 'ML', labelWithoutFlag: 'Mali', label: '🇲🇱 Mali', alpha3: 'MLI' },
  { flag: '🇲🇹', value: 'MT', labelWithoutFlag: 'Malta', label: '🇲🇹 Malta', alpha3: 'MLT' },
  { flag: '🇲🇲', value: 'MM', labelWithoutFlag: 'Myanmar', label: '🇲🇲 Myanmar', alpha3: 'MMR' },
  { flag: '🇲🇪', value: 'ME', labelWithoutFlag: 'Montenegro', label: '🇲🇪 Montenegro', alpha3: 'MNE' },
  { flag: '🇲🇳', value: 'MN', labelWithoutFlag: 'Mongolia', label: '🇲🇳 Mongolia', alpha3: 'MNG' },
  {
    flag: '🇲🇵',
    value: 'MP',
    labelWithoutFlag: 'Northern Mariana Islands',
    label: '🇲🇵 Northern Mariana Islands',
    alpha3: 'MNP',
  },
  { flag: '🇲🇿', value: 'MZ', labelWithoutFlag: 'Mozambique', label: '🇲🇿 Mozambique', alpha3: 'MOZ' },
  { flag: '🇲🇷', value: 'MR', labelWithoutFlag: 'Mauritania', label: '🇲🇷 Mauritania', alpha3: 'MRT' },
  { flag: '🇲🇸', value: 'MS', labelWithoutFlag: 'Montserrat', label: '🇲🇸 Montserrat', alpha3: 'MSR' },
  { flag: '🇲🇶', value: 'MQ', labelWithoutFlag: 'Martinique', label: '🇲🇶 Martinique', alpha3: 'MTQ' },
  { flag: '🇲🇺', value: 'MU', labelWithoutFlag: 'Mauritius', label: '🇲🇺 Mauritius', alpha3: 'MUS' },
  { flag: '🇲🇼', value: 'MW', labelWithoutFlag: 'Malawi', label: '🇲🇼 Malawi', alpha3: 'MWI' },
  { flag: '🇲🇾', value: 'MY', labelWithoutFlag: 'Malaysia', label: '🇲🇾 Malaysia', alpha3: 'MYS' },
  { flag: '🇾🇹', value: 'YT', labelWithoutFlag: 'Mayotte', label: '🇾🇹 Mayotte', alpha3: 'MYT' },
  { flag: '🇳🇦', value: 'NA', labelWithoutFlag: 'Namibia', label: '🇳🇦 Namibia', alpha3: 'NAM' },
  { flag: '🇳🇨', value: 'NC', labelWithoutFlag: 'New Caledonia', label: '🇳🇨 New Caledonia', alpha3: 'NCL' },
  { flag: '🇳🇪', value: 'NE', labelWithoutFlag: 'Niger', label: '🇳🇪 Niger', alpha3: 'NER' },
  { flag: '🇳🇫', value: 'NF', labelWithoutFlag: 'Norfolk Island', label: '🇳🇫 Norfolk Island', alpha3: 'NFK' },
  { flag: '🇳🇬', value: 'NG', labelWithoutFlag: 'Nigeria', label: '🇳🇬 Nigeria', alpha3: 'NGA' },
  { flag: '🇳🇮', value: 'NI', labelWithoutFlag: 'Nicaragua', label: '🇳🇮 Nicaragua', alpha3: 'NIC' },
  { flag: '🇳🇺', value: 'NU', labelWithoutFlag: 'Niue', label: '🇳🇺 Niue', alpha3: 'NIU' },
  { flag: '🇳🇱', value: 'NL', labelWithoutFlag: 'Netherlands', label: '🇳🇱 Netherlands', alpha3: 'NLD' },
  { flag: '🇳🇴', value: 'NO', labelWithoutFlag: 'Norway', label: '🇳🇴 Norway', alpha3: 'NOR' },
  { flag: '🇳🇵', value: 'NP', labelWithoutFlag: 'Nepal', label: '🇳🇵 Nepal', alpha3: 'NPL' },
  { flag: '🇳🇷', value: 'NR', labelWithoutFlag: 'Nauru', label: '🇳🇷 Nauru', alpha3: 'NRU' },
  { flag: '🇳🇿', value: 'NZ', labelWithoutFlag: 'New Zealand', label: '🇳🇿 New Zealand', alpha3: 'NZL' },
  { flag: '🇴🇲', value: 'OM', labelWithoutFlag: 'Oman', label: '🇴🇲 Oman', alpha3: 'OMN' },
  { flag: '🇵🇰', value: 'PK', labelWithoutFlag: 'Pakistan', label: '🇵🇰 Pakistan', alpha3: 'PAK' },
  { flag: '🇵🇦', value: 'PA', labelWithoutFlag: 'Panama', label: '🇵🇦 Panama', alpha3: 'PAN' },
  { flag: '🇵🇳', value: 'PN', labelWithoutFlag: 'Pitcairn Islands', label: '🇵🇳 Pitcairn Islands', alpha3: 'PCN' },
  { flag: '🇵🇪', value: 'PE', labelWithoutFlag: 'Peru', label: '🇵🇪 Peru', alpha3: 'PER' },
  { flag: '🇵🇭', value: 'PH', labelWithoutFlag: 'Philippines', label: '🇵🇭 Philippines', alpha3: 'PHL' },
  { flag: '🇵🇼', value: 'PW', labelWithoutFlag: 'Palau', label: '🇵🇼 Palau', alpha3: 'PLW' },
  { flag: '🇵🇬', value: 'PG', labelWithoutFlag: 'Papua New Guinea', label: '🇵🇬 Papua New Guinea', alpha3: 'PNG' },
  { flag: '🇵🇱', value: 'PL', labelWithoutFlag: 'Poland', label: '🇵🇱 Poland', alpha3: 'POL' },
  { flag: '🇵🇷', value: 'PR', labelWithoutFlag: 'Puerto Rico', label: '🇵🇷 Puerto Rico', alpha3: 'PRI' },
  { flag: '🇰🇵', value: 'KP', labelWithoutFlag: 'North Korea', label: '🇰🇵 North Korea', alpha3: 'PRK' },
  { flag: '🇵🇹', value: 'PT', labelWithoutFlag: 'Portugal', label: '🇵🇹 Portugal', alpha3: 'PRT' },
  { flag: '🇵🇾', value: 'PY', labelWithoutFlag: 'Paraguay', label: '🇵🇾 Paraguay', alpha3: 'PRY' },
  { flag: '🇵🇸', value: 'PS', labelWithoutFlag: 'Palestine', label: '🇵🇸 Palestine', alpha3: 'PSE' },
  { flag: '🇵🇫', value: 'PF', labelWithoutFlag: 'French Polynesia', label: '🇵🇫 French Polynesia', alpha3: 'PYF' },
  { flag: '🇶🇦', value: 'QA', labelWithoutFlag: 'Qatar', label: '🇶🇦 Qatar', alpha3: 'QAT' },
  { flag: '🇷🇪', value: 'RE', labelWithoutFlag: 'Réunion', label: '🇷🇪 Réunion', alpha3: 'REU' },
  { flag: '🇷🇴', value: 'RO', labelWithoutFlag: 'Romania', label: '🇷🇴 Romania', alpha3: 'ROU' },
  { flag: '🇷🇺', value: 'RU', labelWithoutFlag: 'Russia', label: '🇷🇺 Russia', alpha3: 'RUS' },
  { flag: '🇷🇼', value: 'RW', labelWithoutFlag: 'Rwanda', label: '🇷🇼 Rwanda', alpha3: 'RWA' },
  { flag: '🇸🇦', value: 'SA', labelWithoutFlag: 'Saudi Arabia', label: '🇸🇦 Saudi Arabia', alpha3: 'SAU' },
  { flag: '🇸🇩', value: 'SD', labelWithoutFlag: 'Sudan', label: '🇸🇩 Sudan', alpha3: 'SDN' },
  { flag: '🇸🇳', value: 'SN', labelWithoutFlag: 'Senegal', label: '🇸🇳 Senegal', alpha3: 'SEN' },
  { flag: '🇸🇬', value: 'SG', labelWithoutFlag: 'Singapore', label: '🇸🇬 Singapore', alpha3: 'SGP' },
  { flag: '🇬🇸', value: 'GS', labelWithoutFlag: 'South Georgia', label: '🇬🇸 South Georgia', alpha3: 'SGS' },
  {
    flag: '🇸🇯',
    value: 'SJ',
    labelWithoutFlag: 'Svalbard and Jan Mayen',
    label: '🇸🇯 Svalbard and Jan Mayen',
    alpha3: 'SJM',
  },
  { flag: '🇸🇧', value: 'SB', labelWithoutFlag: 'Solomon Islands', label: '🇸🇧 Solomon Islands', alpha3: 'SLB' },
  { flag: '🇸🇱', value: 'SL', labelWithoutFlag: 'Sierra Leone', label: '🇸🇱 Sierra Leone', alpha3: 'SLE' },
  { flag: '🇸🇻', value: 'SV', labelWithoutFlag: 'El Salvador', label: '🇸🇻 El Salvador', alpha3: 'SLV' },
  { flag: '🇸🇲', value: 'SM', labelWithoutFlag: 'San Marino', label: '🇸🇲 San Marino', alpha3: 'SMR' },
  { flag: '🇸🇴', value: 'SO', labelWithoutFlag: 'Somalia', label: '🇸🇴 Somalia', alpha3: 'SOM' },
  {
    flag: '🇵🇲',
    value: 'PM',
    labelWithoutFlag: 'Saint Pierre and Miquelon',
    label: '🇵🇲 Saint Pierre and Miquelon',
    alpha3: 'SPM',
  },
  { flag: '🇷🇸', value: 'RS', labelWithoutFlag: 'Serbia', label: '🇷🇸 Serbia', alpha3: 'SRB' },
  { flag: '🇸🇸', value: 'SS', labelWithoutFlag: 'South Sudan', label: '🇸🇸 South Sudan', alpha3: 'SSD' },
  {
    flag: '🇸🇹',
    value: 'ST',
    labelWithoutFlag: 'São Tomé and Príncipe',
    label: '🇸🇹 São Tomé and Príncipe',
    alpha3: 'STP',
  },
  { flag: '🇸🇷', value: 'SR', labelWithoutFlag: 'Suriname', label: '🇸🇷 Suriname', alpha3: 'SUR' },
  { flag: '🇸🇰', value: 'SK', labelWithoutFlag: 'Slovakia', label: '🇸🇰 Slovakia', alpha3: 'SVK' },
  { flag: '🇸🇮', value: 'SI', labelWithoutFlag: 'Slovenia', label: '🇸🇮 Slovenia', alpha3: 'SVN' },
  { flag: '🇸🇪', value: 'SE', labelWithoutFlag: 'Sweden', label: '🇸🇪 Sweden', alpha3: 'SWE' },
  { flag: '🇸🇿', value: 'SZ', labelWithoutFlag: 'Eswatini', label: '🇸🇿 Eswatini', alpha3: 'SWZ' },
  { flag: '🇸🇽', value: 'SX', labelWithoutFlag: 'Sint Maarten', label: '🇸🇽 Sint Maarten', alpha3: 'SXM' },
  { flag: '🇸🇨', value: 'SC', labelWithoutFlag: 'Seychelles', label: '🇸🇨 Seychelles', alpha3: 'SYC' },
  { flag: '🇸🇾', value: 'SY', labelWithoutFlag: 'Syria', label: '🇸🇾 Syria', alpha3: 'SYR' },
  {
    flag: '🇹🇨',
    value: 'TC',
    labelWithoutFlag: 'Turks and Caicos Islands',
    label: '🇹🇨 Turks and Caicos Islands',
    alpha3: 'TCA',
  },
  { flag: '🇹🇩', value: 'TD', labelWithoutFlag: 'Chad', label: '🇹🇩 Chad', alpha3: 'TCD' },
  { flag: '🇹🇬', value: 'TG', labelWithoutFlag: 'Togo', label: '🇹🇬 Togo', alpha3: 'TGO' },
  { flag: '🇹🇭', value: 'TH', labelWithoutFlag: 'Thailand', label: '🇹🇭 Thailand', alpha3: 'THA' },
  { flag: '🇹🇯', value: 'TJ', labelWithoutFlag: 'Tajikistan', label: '🇹🇯 Tajikistan', alpha3: 'TJK' },
  { flag: '🇹🇰', value: 'TK', labelWithoutFlag: 'Tokelau', label: '🇹🇰 Tokelau', alpha3: 'TKL' },
  { flag: '🇹🇲', value: 'TM', labelWithoutFlag: 'Turkmenistan', label: '🇹🇲 Turkmenistan', alpha3: 'TKM' },
  { flag: '🇹🇱', value: 'TL', labelWithoutFlag: 'Timor-Leste', label: '🇹🇱 Timor-Leste', alpha3: 'TLS' },
  { flag: '🇹🇴', value: 'TO', labelWithoutFlag: 'Tonga', label: '🇹🇴 Tonga', alpha3: 'TON' },
  { flag: '🇹🇹', value: 'TT', labelWithoutFlag: 'Trinidad and Tobago', label: '🇹🇹 Trinidad and Tobago', alpha3: 'TTO' },
  { flag: '🇹🇳', value: 'TN', labelWithoutFlag: 'Tunisia', label: '🇹🇳 Tunisia', alpha3: 'TUN' },
  { flag: '🇹🇷', value: 'TR', labelWithoutFlag: 'Turkey', label: '🇹🇷 Turkey', alpha3: 'TUR' },
  { flag: '🇹🇻', value: 'TV', labelWithoutFlag: 'Tuvalu', label: '🇹🇻 Tuvalu', alpha3: 'TUV' },
  { flag: '🇹🇼', value: 'TW', labelWithoutFlag: 'Taiwan', label: '🇹🇼 Taiwan', alpha3: 'TWN' },
  { flag: '🇹🇿', value: 'TZ', labelWithoutFlag: 'Tanzania', label: '🇹🇿 Tanzania', alpha3: 'TZA' },
  { flag: '🇺🇬', value: 'UG', labelWithoutFlag: 'Uganda', label: '🇺🇬 Uganda', alpha3: 'UGA' },
  { flag: '🇺🇦', value: 'UA', labelWithoutFlag: 'Ukraine', label: '🇺🇦 Ukraine', alpha3: 'UKR' },
  {
    flag: '🇺🇲',
    value: 'UM',
    labelWithoutFlag: 'United States Minor Outlying Islands',
    label: '🇺🇲 United States Minor Outlying Islands',
    alpha3: 'UMI',
  },
  { flag: '🇺🇾', value: 'UY', labelWithoutFlag: 'Uruguay', label: '🇺🇾 Uruguay', alpha3: 'URY' },
  { flag: '🇺🇸', value: 'US', labelWithoutFlag: 'United States', label: '🇺🇸 United States', alpha3: 'USA' },
  { flag: '🇺🇿', value: 'UZ', labelWithoutFlag: 'Uzbekistan', label: '🇺🇿 Uzbekistan', alpha3: 'UZB' },
  { flag: '🇻🇦', value: 'VA', labelWithoutFlag: 'Vatican City', label: '🇻🇦 Vatican City', alpha3: 'VAT' },
  {
    flag: '🇻🇨',
    value: 'VC',
    labelWithoutFlag: 'Saint Vincent and the Grenadines',
    label: '🇻🇨 Saint Vincent and the Grenadines',
    alpha3: 'VCT',
  },
  { flag: '🇻🇪', value: 'VE', labelWithoutFlag: 'Venezuela', label: '🇻🇪 Venezuela', alpha3: 'VEN' },
  {
    flag: '🇻🇬',
    value: 'VG',
    labelWithoutFlag: 'British Virgin Islands',
    label: '🇻🇬 British Virgin Islands',
    alpha3: 'VGB',
  },
  {
    flag: '🇻🇮',
    value: 'VI',
    labelWithoutFlag: 'United States Virgin Islands',
    label: '🇻🇮 United States Virgin Islands',
    alpha3: 'VIR',
  },
  { flag: '🇻🇳', value: 'VN', labelWithoutFlag: 'Vietnam', label: '🇻🇳 Vietnam', alpha3: 'VNM' },
  { flag: '🇻🇺', value: 'VU', labelWithoutFlag: 'Vanuatu', label: '🇻🇺 Vanuatu', alpha3: 'VUT' },
  { flag: '🇼🇫', value: 'WF', labelWithoutFlag: 'Wallis and Futuna', label: '🇼🇫 Wallis and Futuna', alpha3: 'WLF' },
  { flag: '🇼🇸', value: 'WS', labelWithoutFlag: 'Samoa', label: '🇼🇸 Samoa', alpha3: 'WSM' },
  { flag: '🇾🇪', value: 'YE', labelWithoutFlag: 'Yemen', label: '🇾🇪 Yemen', alpha3: 'YEM' },
  { flag: '🇿🇦', value: 'ZA', labelWithoutFlag: 'South Africa', label: '🇿🇦 South Africa', alpha3: 'ZAF' },
  { flag: '🇿🇲', value: 'ZM', labelWithoutFlag: 'Zambia', label: '🇿🇲 Zambia', alpha3: 'ZMB' },
  { flag: '🇿🇼', value: 'ZW', labelWithoutFlag: 'Zimbabwe', label: '🇿🇼 Zimbabwe', alpha3: 'ZWE' },
];

export const COUNTRIES_WITH_CODES_PREPARED = [
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  COUNTRIES_WITH_CODES.find(i => i.alpha3 === 'PRT')!,
  ...COUNTRIES_WITH_CODES.sort((a, b) =>
    a.labelWithoutFlag.localeCompare(b.labelWithoutFlag, 'en', { sensitivity: 'base' }),
  ).filter(i => i.alpha3 !== 'PRT'),
];

export const getByCountryCode = (code: string) => COUNTRIES_WITH_CODES_PREPARED.find(c => c.alpha3 === code)?.label;
