import { Flex, Text, useColorModeValue } from '@chakra-ui/react';
import { CivilStatus, RelatedParty, RelatedPartyEntityType, RelatedPartyRelationType } from 'api/related-parties';
import { Account, Information, OfficeBag } from 'libs/ui/atoms/src';
import { VcTooltip } from 'libs/ui/atoms/src/lib/tooltip/Tooltip';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import PhoneInput from 'react-phone-input-2';
import { getByCountryCode } from '../../../../../libs/ui/atoms/src/lib/input-field/countries';
import { TooltipItem, TooltipRow } from '../../../../../libs/ui/atoms/src/lib/tooltip/TooltipRow';
import { formatDateToYYYYMMDD } from '../../../../../utils/date';
import { getDistrict, getMunicipality, getParish } from '../../../../../utils/db-predial';

import './party-tooltip.css';
import {
  isShowMarriageRegime,
  relatedPartyIDDocumentTypeByValue,
  tByCivilStatus,
  tByMarriageRegime,
  tByRelatedPartyAge,
  tByRelatedPartyIDDocumentType,
} from './utils';

export interface RelatedPartyTooltipProps {
  relatedParty: RelatedParty;
}

export const RelatedPartyTooltip = ({ relatedParty }: RelatedPartyTooltipProps) => {
  const { t } = useTranslation();

  const nameColor = useColorModeValue('gray.600', 'white');
  const relationIconColor = useColorModeValue('brand.800', 'white');
  const infoPillColor = useColorModeValue('gray.700', 'white');

  const items = useMemo<TooltipItem[]>(() => {
    const items: TooltipItem[] = [
      {
        label: t('relatedParties.relation'),
        value: relatedParty.relationType ? t(`relatedParties.${relatedParty.relationType.toLowerCase()}`) : '—',
      },
      { label: t('NIF'), value: relatedParty.nif ?? '—' },
    ];

    if (relatedParty.civilStatus === CivilStatus.SINGLE) {
      items.push({
        label: t('relatedParties.age'),
        value: relatedParty.age ? t(tByRelatedPartyAge[relatedParty.age]) : '—',
      });
    }

    if (relatedParty.relationType === RelatedPartyRelationType.PERSON) {
      items.push(
        { label: t('email'), value: relatedParty.email || '—' },
        {
          label: t('phone'),
          value: relatedParty.phone ?? '—',
          valueIconChildren: relatedParty.phone ? (
            <PhoneInput
              inputStyle={{
                display: 'none',
                width: '30px',
              }}
              buttonStyle={{
                border: 'none',
                backgroundColor: 'transparent',
                borderRadius: '6px',
                padding: '1px',
                cursor: 'default',
                width: '40px',
              }}
              dropdownStyle={{
                display: 'none',
              }}
              containerClass="phone-container-tooltip"
              value={relatedParty.phone}
            />
          ) : undefined,
        },
      );
      items.push({
        label: t('relatedParties.nationality'),
        value: relatedParty.nacionalidade ? getByCountryCode(relatedParty.nacionalidade) : '—',
      });
      if (relatedParty.nacionalidade === 'PT') {
        items.push(
          ...[
            { label: t('relatedParties.placeOfBirthFreguesia'), value: relatedParty.placeOfBirthFreguesia ?? '—' },
            { label: t('relatedParties.placeOfBirthConcelho'), value: relatedParty.placeOfBirthConcelho ?? '—' },
          ],
        );
      }
      items.push({
        label: t('relatedParties.civilStatus'),
        value: relatedParty.civilStatus ? t(tByCivilStatus[relatedParty.civilStatus]) : '—',
      });
    }

    if (
      relatedParty.relationType === RelatedPartyRelationType.PERSON ||
      relatedParty.relationType === RelatedPartyRelationType.COMPANY
    ) {
      items.push({ label: t('address'), value: relatedParty.address ?? '—' });
    }

    if (isShowMarriageRegime(relatedParty.civilStatus)) {
      items.push(
        { label: t('relatedParties.marriedTo'), value: relatedParty.marriedTo ?? '—' },
        {
          label: t('relatedParties.marriageRegime'),
          value: relatedParty.marriageRegime ? t(tByMarriageRegime[relatedParty.marriageRegime]) : '—',
        },
      );
    }

    if (relatedParty.relationType === RelatedPartyRelationType.PERSON) {
      items.push(
        ...[
          {
            label: t('relatedParties.idDocumentType'),
            value: relatedParty.idDocumentType
              ? relatedPartyIDDocumentTypeByValue[relatedParty.idDocumentType]
                ? t(tByRelatedPartyIDDocumentType[relatedPartyIDDocumentTypeByValue[relatedParty.idDocumentType] ?? ''])
                : relatedParty.idDocumentType
              : '—',
          },
          { label: t('relatedParties.idDocumentNumber'), value: relatedParty.idDocumentNumber ?? '—' },
          {
            label: t('relatedParties.idDocumentIssuranceAuthority'),
            value: relatedParty.idDocumentIssuanceAuthority ?? '—',
          },
          {
            label: t('relatedParties.idDocumentIssueDate'),
            value: relatedParty.idDocumentIssueDate
              ? formatDateToYYYYMMDD(new Date(relatedParty.idDocumentIssueDate))
              : '—',
          },
          {
            label: t('relatedParties.idDocumentExpiryDate'),
            value: relatedParty.idDocumentExpiryDate
              ? formatDateToYYYYMMDD(new Date(relatedParty.idDocumentExpiryDate))
              : '—',
          },
        ],
      );
    }

    if (relatedParty.relationType === RelatedPartyRelationType.COMPANY) {
      items.push(
        ...[
          {
            label: t('relatedParties.corpAddress'),
            value: relatedParty.corpAddress ?? '—',
          },
          { label: t('relatedParties.corpAddressParish'), value: getDistrict(relatedParty.corpAddressDistrict) ?? '—' },
          {
            label: t('relatedParties.corpAddressMunicipality'),
            value: getMunicipality(relatedParty.corpAddressMunicipality) ?? '—',
          },
          {
            label: t('relatedParties.corpAddressParish'),
            value: getParish(relatedParty.corpAddressParish) ?? '—',
          },
          { label: t('relatedParties.corpCapital'), value: `€  ${relatedParty.corpCapital ?? '—'}` },
          { label: t('relatedParties.corpRepAuthority'), value: relatedParty.corpRepAuthority ?? '—' },
        ],
      );
    }

    if (relatedParty.entityType === RelatedPartyEntityType.BUYER) {
      items.push(...[{ label: t('relatedParties.isFiador'), value: relatedParty.isFiador ? t('yes') : t('no') }]);
    }

    return items;
  }, [relatedParty, t]);

  return (
    <VcTooltip
      iconChildren={<Information color={infoPillColor} w="16px" h="16px" />}
      contentChildren={
        <Flex flexDirection={'column'} gap="12px" p="16px">
          <Flex gap="16px" alignItems={'center'}>
            {relatedParty.relationType === RelatedPartyRelationType.COMPANY ? (
              <OfficeBag color={relationIconColor} h={'20px'} w={'20px'} />
            ) : (
              <Account color={relationIconColor} h={'20px'} w={'20px'} />
            )}

            <Text fontSize={'16px'} fontWeight="bold" color={nameColor}>
              {relatedParty.name}
            </Text>
          </Flex>

          <Flex flexDirection={'column'}>
            {items.map((i, idx) => (
              <TooltipRow key={idx} {...i} isSecondaryColor={idx % 2 === 0} />
            ))}
          </Flex>
        </Flex>
      }
    />
  );
};
