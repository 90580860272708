import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Flex } from '@chakra-ui/react';

export interface MaybeWrapAccordionProps {
  children: React.ReactNode;
  buttonChildren: React.ReactNode;
  isAccordion?: boolean;
}

export function MaybeWrapAccordion({ children, buttonChildren, isAccordion }: MaybeWrapAccordionProps) {
  return isAccordion ? (
    <Accordion allowMultiple allowToggle border="none">
      <AccordionItem border={'none'}>
        <AccordionButton
          p="0"
          _focus={{ boxShadow: 'none' }}
          _hover={{ bg: undefined }}
          pr="24px"
          maxW="100%"
          overflow={'hidden'}
        >
          <Flex flex={1}>{buttonChildren}</Flex>

          <AccordionIcon />
        </AccordionButton>

        <AccordionPanel p="0">{children}</AccordionPanel>
      </AccordionItem>
    </Accordion>
  ) : (
    <Flex direction="column" gap="16px">
      {buttonChildren}
      {children}
    </Flex>
  );
}
