import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { environment } from '../environments/environment';
import { RootState } from '../store';
import { sec } from './security';

// const getAccessToken = (state: RootState) => state.auth.accessToken;
const getContextToken = (state: RootState) => state.auth.contextToken;

export const api = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl: environment.apiUrl,
    prepareHeaders: async (headers, { getState }) => {
      const state = getState() as RootState;
      // const accessToken = getAccessToken(state);
      // TODO hotfix. Should rethink and rearrange it
      const accessToken = await sec?.getAccessTokenSilently()?.({
        authorizationParams: {
          audience: environment?.audience,
          scope: environment?.scope,
        },
      });

      if (accessToken) {
        headers.set('Authorization', `Bearer ${accessToken}`);
      }
      const contextToken = getContextToken(state);
      if (contextToken) {
        headers.set('Context', contextToken);
      }
    },
  }),
  endpoints: () => ({}),
});
