import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Flex,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { VCAccordionType } from './accordion-type';

interface VCAccordionPropsBase {
  type: VCAccordionType;

  bodyChildren: React.ReactNode;
}

interface VCAccordionPropsDefault extends VCAccordionPropsBase {
  type: VCAccordionType.DEFAULT;

  title: string;
}

interface VCAccordionPropsCustomButton extends VCAccordionPropsBase {
  type: VCAccordionType.CUSTOM_BUTTON;

  buttonChildren: React.ReactNode;
}

export type VCAccordionProps = VCAccordionPropsDefault | VCAccordionPropsCustomButton;

export const VCAccordion = (props: VCAccordionProps) => {
  const { type, bodyChildren } = props;
  const { title } = props as VCAccordionPropsDefault;
  const { buttonChildren } = props as VCAccordionPropsCustomButton;

  const textColor = useColorModeValue('brand.800', 'white');

  return (
    <Accordion allowMultiple allowToggle border="none">
      <AccordionItem border={'none'}>
        <AccordionButton
          p="0"
          _focus={{ boxShadow: 'none' }}
          _hover={{ bg: undefined }}
          pr="24px"
          maxW="100%"
          overflow={'hidden'}
        >
          <Flex flex={1} p="24px">
            {type === VCAccordionType.DEFAULT ? (
              <Text fontSize={'20px'} color={textColor} fontWeight="700">
                {title}
              </Text>
            ) : (
              buttonChildren
            )}
          </Flex>

          <AccordionIcon />
        </AccordionButton>

        <AccordionPanel p="0">{bodyChildren}</AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};
