import { useEffect, useState } from 'react';

// min * sec * ms
const CHECK_APP_VERSION_INTERVAL = 5 * 60 * 1000;

const fetchAppVersion = async () => {
  const response = await fetch('/version.json');
  const data = await response.json();
  return data.version;
};

export interface VersionCheckProps {
  onVersionChange: () => void;
}

export const useVersionCheck = (props: VersionCheckProps) => {
  const { onVersionChange } = props;

  const [appVersion, setAppVersion] = useState();

  useEffect(() => {
    const interval = setInterval(async () => {
      const latestVersion = await fetchAppVersion().catch(console.error);

      if (appVersion && latestVersion && latestVersion !== appVersion) {
        onVersionChange();
      }

      setAppVersion(latestVersion);
    }, CHECK_APP_VERSION_INTERVAL);

    return () => clearInterval(interval);
  }, [appVersion, onVersionChange]);
};
