import { Box } from '@chakra-ui/react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { routesPublic } from '../../routes';
import { getRoutesFromRouteTypes } from '../utils/routes';

const getRoute = () => {
  return window.location.pathname !== '/full-screen-maps';
};

export function PublicLayout() {
  return (
    <Box
      float="right"
      minHeight="100vh"
      height="100%"
      overflow="auto"
      position="relative"
      maxHeight="100%"
      w="100%"
      transition="all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)"
      transitionDuration=".2s, .2s, .35s"
      transitionProperty="top, bottom, width"
      transitionTimingFunction="linear, linear, ease"
    >
      {getRoute() && (
        <Box mx="auto" p={{ base: '10px', md: '30px' }} pe="20px" minH="100vh">
          <Routes>
            {getRoutesFromRouteTypes(routesPublic)}
            <Route element={<Navigate to="/error" />} />
          </Routes>
        </Box>
      )}
    </Box>
  );
}
