import { List } from 'lodash';
import { api } from './api';

export interface JobDocument {
  id: string;
  name: string;
  description: string;
  isInitialized: boolean;
  url: string;
  type: DocumentType;
  createdAt: string;
  createdBy: string;
  updatedAt: string;
  updatedBy: string;
  status: DocumentStatus;
}

export type JobDocumentWithJobId = JobDocument & { jobId: string };

// Types that can be defined by system only
export enum DocumentType {
  CADERNETA_PREDIAL_URBANA = 'caderneta_predial_urbana',
  CADERNETA_PREDIAL_RUSTICA = 'caderneta_predial_rústica',
  CERTIDAO_PERMANENTE = 'certidão_permanente',
  CERTIDAO_PERMANENTE_UNIT = 'certidão_permanente_unit',
  CERTIDAO_PERMANENTE_REGISTO_COMERCIAL = 'certidão_permanente_registo_comercial',
  CERTIFICADO_ENERGETICO = 'certificado_energético',
}

// Types that can be defined by user, processed separately
export enum DocumentTypeAuxillary {
  DOCUMENTOS_DE_PAGAMENTO = 'documentos_de_pagamento',
  PROCURAÇÃO = 'procuração',
  HABILITAÇÃO_DE_HERDEIROS = 'habilitação_de_herdeiros',
  CERTIDÃO_DE_ÓBITO = 'certidão_de_óbito',
  MODELO_1_DO_IMPOSTO_DO_SELO = 'modelo_1_do_imposto_do_selo',
  CERTIDÃO_DE_PARTILHA_DE_HERANÇA = 'certidão_de_partilha_de_herança',
  ASSENTO_DE_NASCIMENTO = 'assento_de_nascimento',
  ASSENTO_DE_CASAMENTO = 'assento_de_casamento',
  LICENÇA_DE_UTILIZAÇÃO = 'licença_de_utilização',
  CERTIDÃO_DE_ISENÇÃO_DE_LICENÇA_DE_UTILIZAÇÃO = 'certidão_de_isenção_de_licença_de_utilização',
  FICHA_TÉCNICA_DE_HABITAÇÃO = 'ficha_técnica_de_habitação',
  ACTAS_DE_CONDOMINIO = 'actas_de_condominio',
  PLANTA = 'planta',
  CERTIDÃO_TOPONÍMICA = 'certidão_toponímica',
  OUTROS = 'outros',
  CERTIFICADO_ENERGETICO_SCAN = 'sce',
}

export const DocumentTypesOrdered: List<DocumentType | DocumentTypeAuxillary> = [
  DocumentTypeAuxillary.CERTIFICADO_ENERGETICO_SCAN,
  DocumentTypeAuxillary.LICENÇA_DE_UTILIZAÇÃO,

  DocumentType.CADERNETA_PREDIAL_URBANA,
  DocumentType.CADERNETA_PREDIAL_RUSTICA,
  DocumentType.CERTIDAO_PERMANENTE,
  DocumentType.CERTIDAO_PERMANENTE_UNIT,
  DocumentType.CERTIDAO_PERMANENTE_REGISTO_COMERCIAL,

  DocumentTypeAuxillary.PROCURAÇÃO,
  DocumentTypeAuxillary.DOCUMENTOS_DE_PAGAMENTO,
  DocumentTypeAuxillary.HABILITAÇÃO_DE_HERDEIROS,
  DocumentTypeAuxillary.CERTIDÃO_DE_ÓBITO,
  DocumentTypeAuxillary.MODELO_1_DO_IMPOSTO_DO_SELO,
  DocumentTypeAuxillary.CERTIDÃO_DE_PARTILHA_DE_HERANÇA,
  DocumentTypeAuxillary.ASSENTO_DE_NASCIMENTO,
  DocumentTypeAuxillary.ASSENTO_DE_CASAMENTO,
  // DocumentTypeAuxillary.LICENÇA_DE_UTILIZAÇÃO,
  DocumentTypeAuxillary.CERTIDÃO_DE_ISENÇÃO_DE_LICENÇA_DE_UTILIZAÇÃO,
  DocumentTypeAuxillary.FICHA_TÉCNICA_DE_HABITAÇÃO,
  DocumentTypeAuxillary.ACTAS_DE_CONDOMINIO,
  DocumentTypeAuxillary.PLANTA,
  DocumentTypeAuxillary.CERTIDÃO_TOPONÍMICA,
  DocumentTypeAuxillary.OUTROS,
  // DocumentTypeAuxillary.SCE,
];

export const DocumentTypeUserSelectable = {
  ...DocumentTypeAuxillary,
};

// export type DocumentTypeUserSelectable = DocumentTypeDynamic | DocumentTypeAuxillary;

// Map constants to user-friendly strings
export const DocumentTranslatedCategoryByType: Record<DocumentType | DocumentTypeAuxillary, string> = {
  [DocumentType.CADERNETA_PREDIAL_URBANA]: 'pages.details.categories.urbanBuildingBooklet',
  [DocumentType.CERTIDAO_PERMANENTE]: 'pages.details.categories.permanentCertificate',
  [DocumentType.CERTIDAO_PERMANENTE_UNIT]: 'pages.details.categories.permanentUnitCertificate',
  [DocumentType.CERTIFICADO_ENERGETICO]: 'pages.details.categories.energeticCertificate',

  [DocumentType.CADERNETA_PREDIAL_RUSTICA]: 'pages.details.categories.cadernetaPredialRustica',
  [DocumentType.CERTIDAO_PERMANENTE_REGISTO_COMERCIAL]: 'pages.details.categories.permanentCertificateRegistoComercial',

  [DocumentTypeAuxillary.PROCURAÇÃO]: 'pages.details.categories.procuração',
  [DocumentTypeAuxillary.DOCUMENTOS_DE_PAGAMENTO]: 'pages.details.categories.paymentDocuments',
  [DocumentTypeAuxillary.HABILITAÇÃO_DE_HERDEIROS]: 'pages.details.categories.habilitaçãoDeHerdeiros',
  [DocumentTypeAuxillary.CERTIDÃO_DE_ÓBITO]: 'pages.details.categories.certidãoDeÓbito',
  [DocumentTypeAuxillary.MODELO_1_DO_IMPOSTO_DO_SELO]: 'pages.details.categories.modelo1DoImpostoDoSelo',
  [DocumentTypeAuxillary.CERTIDÃO_DE_PARTILHA_DE_HERANÇA]: 'pages.details.categories.certidãoDePartilhaDeHerança',
  [DocumentTypeAuxillary.ASSENTO_DE_NASCIMENTO]: 'pages.details.categories.assentoDeNascimento',
  [DocumentTypeAuxillary.ASSENTO_DE_CASAMENTO]: 'pages.details.categories.assentoDeCasamento',
  [DocumentTypeAuxillary.LICENÇA_DE_UTILIZAÇÃO]: 'pages.details.categories.licençaDeUtilização',
  [DocumentTypeAuxillary.CERTIDÃO_DE_ISENÇÃO_DE_LICENÇA_DE_UTILIZAÇÃO]:
    'pages.details.categories.certidãoDeIsençãoDeLicençaDeUtilização',
  [DocumentTypeAuxillary.FICHA_TÉCNICA_DE_HABITAÇÃO]: 'pages.details.categories.fichaTécnicaDeHabitação',
  [DocumentTypeAuxillary.ACTAS_DE_CONDOMINIO]: 'pages.details.categories.actasDeCondominio',
  [DocumentTypeAuxillary.PLANTA]: 'pages.details.categories.planta',
  [DocumentTypeAuxillary.CERTIDÃO_TOPONÍMICA]: 'pages.details.categories.certidãoToponímica',
  [DocumentTypeAuxillary.OUTROS]: 'pages.details.categories.outros',

  [DocumentTypeAuxillary.CERTIFICADO_ENERGETICO_SCAN]: 'pages.details.categories.energeticCertificateScan',
};

// Map user-friendly strings to constants
export const DocumentTypeByCategory: Record<string, DocumentType> = {
  [DocumentTranslatedCategoryByType[DocumentType.CADERNETA_PREDIAL_URBANA]]: DocumentType.CADERNETA_PREDIAL_URBANA,
  [DocumentTranslatedCategoryByType[DocumentType.CERTIDAO_PERMANENTE]]: DocumentType.CERTIDAO_PERMANENTE,
  [DocumentTranslatedCategoryByType[DocumentType.CERTIFICADO_ENERGETICO]]: DocumentType.CERTIFICADO_ENERGETICO,
  [DocumentTranslatedCategoryByType[DocumentType.CERTIDAO_PERMANENTE_UNIT]]: DocumentType.CERTIDAO_PERMANENTE_UNIT,
  [DocumentTranslatedCategoryByType[DocumentType.CADERNETA_PREDIAL_RUSTICA]]: DocumentType.CADERNETA_PREDIAL_RUSTICA,
};

export enum DocumentStatus {
  CREATED = 'Created',
  INITIALIZED = 'Initialized',
  ERRORED = 'Errored',
  FATAL = 'Fatal',
  UNACCEPTABLE = 'Unacceptable',
  PROPERTY_ID_MISMATCH = 'PROPERTY_ID_MISMATCH',
}

export interface JobDocumentCreateDto {
  jobId: string;
  name?: string | null;
  type?: string | null;
}

export interface JobDocumentUpdateDto {
  id: string;
  jobId: string;
  name?: string;
  type?: DocumentType | DocumentTypeAuxillary;
}

export interface JobDocumentUploadDto {
  id: string;
  formData: FormData;
}

export interface JobDocumentAddCertidaoWithAccessCodeDto {
  jobId: string;
  dto: {
    code: string;
  };
}

export interface JobDocumentAddCadernetaWithAccessCodeDto {
  jobId: string;
  dto: {
    codeIssuerTIN: string;
    codeValidation: string;
  };
}

export enum CertidaoRegistoComercialRelationType {
  BUYER = 'BUYER',
  SELLER = 'SELLER',
}
export interface JobDocumentAddCertidaoRegistoComercialWithAccessCodeDto {
  jobId: string;
  dto: {
    code: string;
    relationType: CertidaoRegistoComercialRelationType;
  };
}

const jobDocumentsApi = api.injectEndpoints({
  endpoints: build => ({
    getJobDocument: build.query<JobDocument, { id: string }>({
      query: ({ id }) => ({
        url: `/document/${id}`,
      }),
    }),
    getJobDocuments: build.query<JobDocumentWithJobId[], { ids: string[] }>({
      query: ({ ids }) => ({
        url: `/document/by-ids?ids=${ids.join(',')}`,
      }),
    }),
    createJobDocument: build.mutation<JobDocument, JobDocumentCreateDto>({
      query: dto => ({
        url: `/document`,
        body: dto,
        method: 'POST',
      }),
    }),
    updateJobDocument: build.mutation<JobDocumentWithJobId, JobDocumentUpdateDto>({
      query: dto => ({
        url: `/document`,
        body: dto,
        method: 'PUT',
      }),
    }),
    deleteJobDocument: build.mutation<JobDocumentWithJobId, { id: string }>({
      query: ({ id }) => ({
        url: `/document/${id}`,
        method: 'DELETE',
      }),
    }),
    uploadJobDocument: build.mutation<JobDocumentWithJobId, JobDocumentUploadDto>({
      query: ({ id, formData }) => ({
        url: `/document/${id}/upload`,
        body: formData,
        method: 'POST',
      }),
    }),
    addCertidaoWithAccessCode: build.mutation<JobDocumentWithJobId[], JobDocumentAddCertidaoWithAccessCodeDto>({
      query: ({ jobId, dto }) => ({
        url: `/document/${jobId}/appendCertidao`,
        body: dto,
        method: 'POST',
      }),
    }),
    addCadernetaWithAccessCode: build.mutation<JobDocumentWithJobId[], JobDocumentAddCadernetaWithAccessCodeDto>({
      query: ({ jobId, dto }) => ({
        url: `/document/${jobId}/appendCaderneta`,
        body: dto,
        method: 'POST',
      }),
    }),
    addCertidaoRegistoComercialWithAccessCode: build.mutation<
      JobDocumentWithJobId[],
      JobDocumentAddCertidaoRegistoComercialWithAccessCodeDto
    >({
      query: ({ jobId, dto }) => ({
        url: `/document/${jobId}/appendCertidaoRegistoComercial`,
        body: dto,
        method: 'POST',
      }),
    }),
  }),
});

export const {
  useGetJobDocumentQuery,
  useLazyGetJobDocumentQuery,
  useCreateJobDocumentMutation,
  useUpdateJobDocumentMutation,
  useLazyGetJobDocumentsQuery,
  useDeleteJobDocumentMutation,
  useUploadJobDocumentMutation,
  useAddCertidaoWithAccessCodeMutation,
  useAddCadernetaWithAccessCodeMutation,
  useAddCertidaoRegistoComercialWithAccessCodeMutation,
  endpoints: JobDocumentsPrivateEndpoints,
} = jobDocumentsApi;
