import { FormControl, FormLabel, Text, useColorModeValue } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { COUNTRIES_WITH_CODES_PREPARED } from './countries';
import { SelectInputInputField } from './selectField';

export interface NationalityFormInputProps {
  isReadonly?: boolean;
  isRequired?: boolean;
  value?: string | null;
  label: string;
  onChange: (value: string) => void;
  onBlur?: () => void;
}

export const NationalityFormInput = (props: NationalityFormInputProps) => {
  const { isReadonly, isRequired, label, value, onChange, onBlur } = props;

  const labelColor = useColorModeValue('_gray.500', 'whiteAlpha.700');

  const { t } = useTranslation();

  return (
    <FormControl>
      <FormLabel mb="4px" color={labelColor} fontSize="14px" fontWeight="bold">
        {label ?? t('relatedParties.nationality')}
        {isRequired && (
          <Text as={'span'} color={'red'}>
            {' *'}
          </Text>
        )}
      </FormLabel>
      <SelectInputInputField
        isReadonly={isReadonly}
        placeholder={label ?? t('relatedParties.nationality')}
        items={COUNTRIES_WITH_CODES_PREPARED.map(i => ({
          value: i.alpha3,
          label: i.labelWithoutFlag + ' ' + i.flag,
        }))}
        value={value ?? undefined}
        onChange={onChange}
        onBlur={onBlur}
      />
    </FormControl>
  );
};
