import { useMemo } from 'react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { Menu, MenuButton, Button, MenuList, Flex, MenuItem, useColorModeValue } from '@chakra-ui/react';

export interface SelectionMenuOption {
  title: string;
  value: any;
}
export interface SelectionMenuProps {
  options: SelectionMenuOption[];
  value: any;
  onChange: (value: string) => void;
}
export const SelectionMenu = ({ value, options, onChange }: SelectionMenuProps) => {
  const menuBg = useColorModeValue('white', 'navy.800');

  const currentOption = useMemo(() => options.find(o => o.value === value), [options, value]);

  return (
    <Menu>
      <MenuButton
        as={Button}
        rightIcon={<ChevronDownIcon />}
        borderRadius={100}
        bg={menuBg}
        fontSize="14px"
        h="46px"
        isDisabled={options.length === 1}
      >
        <span style={{ textTransform: 'capitalize' }}>{currentOption?.title}</span>
      </MenuButton>
      <MenuList borderRadius="20px" minW="70px">
        <Flex direction="column" alignItems="center" p="0 4px" gap="4px">
          {options.map(
            option =>
              value !== option.value && (
                <MenuItem
                  key={option.value}
                  borderRadius="20px"
                  fontSize="14px"
                  justifyContent="center"
                  style={{ textTransform: 'capitalize' }}
                  onClick={() => onChange(option.value)}
                >
                  {option.title}
                </MenuItem>
              ),
          )}
        </Flex>
      </MenuList>
    </Menu>
  );
};
